const data = {
  category: "withholding_tax_recipient",
  table: "withholding_tax_recipient",
  subFields: {
    recipient_number: {},
    post_name: {},
    recipient_name: {},
    recipient_name_furigana: {},
    address: {},
    birthday: {},
    is_joining: {},
    is_leaving: {},
    joining_on: {},
    leaving_on: {},
    company_name: {},
    company_address: {},
    company_phone_number: {},
  },
};

export default data;
