import "bootstrap/dist/css/bootstrap.min.css";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProfileFile, ProfileSubField } from "./profileFieldValues";
import Icon from "../../component/Icon";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toTimeLabel } from "../../app/util";

dayjs.extend(utc);

function ProfileSubFieldValue({
  subField,
  file,
  onFileDownload,
}: {
  subField: ProfileSubField;
  file?: ProfileFile;
  onFileDownload?: (fileId: string, key: string) => void;
}) {
  if (subField.type === "file" && file) {
    const addedFiles = file.files.filter(({ deleted_at }) => deleted_at === null);
    // 申請書（record_id <= 0）の場合は削除されたファイルは表示しない
    const deletedFiles = subField.record.id > 0 ? file.files.filter(({ deleted_at }) => deleted_at !== null) : [];
    return (
      <div>
        {addedFiles.length > 0 && (
          <>
            {addedFiles.map(({ key, name, created_at }) => (
              <div key={key} className="--bullet">
                <OverlayTrigger
                  key={key}
                  placement="top"
                  delay={{ show: 50, hide: 50 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {`${created_at ? `${toTimeLabel(created_at)}追加` : ""}`}
                    </Tooltip>
                  )}
                >
                  <span>{name}</span>
                </OverlayTrigger>
                <Button
                  size="sm"
                  className="ms-2"
                  onClick={() => {
                    onFileDownload && onFileDownload(file.id, key);
                    return;
                  }}
                >
                  <Icon width={16} height={16} type="download" />
                </Button>
              </div>
            ))}
          </>
        )}
        {deletedFiles.length > 0 && (
          <>
            <div className="--bold --text-light-color mt-1">削除されたファイル</div>
            {deletedFiles.map(({ key, name, deleted_at }) => (
              <div key={key} className="--bullet">
                <OverlayTrigger
                  key={key}
                  placement="top"
                  delay={{ show: 50, hide: 50 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {`${deleted_at ? `${toTimeLabel(deleted_at)}削除` : ""}`}
                    </Tooltip>
                  )}
                >
                  <span className="--text-light-color">{name}</span>
                </OverlayTrigger>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
  return (
    <div className="--pre-wrap">
      {subField.type === "postCode" && "〒 "}
      {subField.type === "boolean" && (subField.value ? "はい" : "いいえ")}
      {[
        "string",
        "longtext",
        "postCode",
        "bankNumber",
        "bankBranchNumber",
        "bankAccountNo",
        "bankAccountNameKana",
        "nameKana",
        "addressKana",
        "email",
        "reference",
        "phoneNumber",
        "time",
        "float",
      ].some((field) => field === subField.type)
        ? subField.value
        : ""}
      {subField.type === "number" && typeof subField.value === "number" && (subField.value || subField.value === 0)
        ? subField.value.toLocaleString("ja-JP")
        : ""}
      {subField.type === "date" && typeof subField.value === "string" && subField.value
        ? dayjs(subField.value).format("YYYY 年 MM 月 DD 日")
        : ""}
      {subField.type === "datetime" && typeof subField.value === "string" && subField.value
        ? dayjs(subField.value).format("YYYY 年 MM 月 DD 日 HH 時 mm 分")
        : ""}
      {(["options", "yearMonth"].includes(subField.type) &&
        subField.labelValueOptions?.find(({ value }) => value === subField.value)?.label) ??
        subField.label ??
        subField.value}
      {subField.type === "code" && typeof subField.label === "string" ? subField.label : ""}
      {subField.type === "checkbox" &&
        subField.labelValueOptions
          ?.filter(({ label, value }) => Array.isArray(subField.value) && subField.value.includes(`${value || label}`))
          .map(({ label }) => label)
          .join(", ")}
    </div>
  );
}

export default ProfileSubFieldValue;
