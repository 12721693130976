import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import "react-calendar/dist/Calendar.css";
import { getMyNumberSettings, selectMyNumberState } from "../myNumber/myNumberSlice";

function MyNumberConfig() {
  const dispatch = useAppDispatch();
  const { textForEmployee, textForFamily, holdImageFiles, pendingYears, pendingMonths, notificationTemplate } =
    useAppSelector(selectMyNumberState);
  const [isUnsavedChanges, $isUnsavedChanges] = useState(false);

  const [next, $next] = useState({
    textForEmployee: "",
    textForFamily: "",
    pendingYears: 0,
    pendingMonths: 0,
    holdImageFiles: true,
    notificationTemplate: "",
  });

  useEffect(() => {
    dispatch(getMyNumberSettings());
  }, []);
  useEffect(() => {
    $next({
      textForEmployee,
      textForFamily,
      pendingYears,
      pendingMonths,
      holdImageFiles,
      notificationTemplate,
    });
  }, [textForEmployee, textForFamily, pendingYears, pendingMonths, holdImageFiles, pendingYears, notificationTemplate]);

  const commit = () => {
    return false;
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <div>
            <Link to={"/_/my_number/download_log"}>
              <Button variant="outline-primary">一括ダウンロードログを表示</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>退職者または削除予定としたマイナンバーが完全に削除されるまでの期間</Col>
        <Col md={2}>
          <Form.Control
            type="number"
            value={next.pendingYears}
            onChange={(e) => {
              $next({ ...next, pendingYears: parseInt(e.target.value) });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
        <Col md={1}>年</Col>
        <Col md={2}>
          <Form.Control
            type="number"
            value={next.pendingMonths}
            onChange={(e) => {
              $next({ ...next, pendingMonths: parseInt(e.target.value) });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
        <Col md={1}>か月</Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>確認資料の保管</Col>
        <Col md={6}>
          <Form.Check
            inline
            type="checkbox"
            id={`holdImages`}
            label={"マイナンバー確認後も確認資料を保持する"}
            checked={next.holdImageFiles}
            onChange={() => {
              $next({ ...next, holdImageFiles: !next.holdImageFiles });
              $isUnsavedChanges(true);
            }}
          ></Form.Check>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>利用目的（社員本人向け）</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForEmployee}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForEmployee: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>利用目的（配偶者・家族向け）</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForFamily}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForFamily: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>一括提出依頼のデフォルト文面</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.notificationTemplate}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                notificationTemplate: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <Button onClick={commit} disabled={!isUnsavedChanges} variant="primary">
              保存
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MyNumberConfig;
