import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import MemberSelector from "./MemberSelector";
import { selectSectionState } from "../../features/section/sectionSlice";
import { selectUserState, selectHasOthersReportAccessPermissions } from "../../features/login/userSlice";
import { Account } from "../../features/profile/profileValues";
import { selectProfileState, searchCompanyMembers, clearFilteredAccounts } from "../../features/profile/profileSlice";
import {
  getUserReportTemplates,
  singleDownloadReportByBaseDate,
  singleDownloadReportByValidFrom,
  selectReportState,
  getPersonal,
  getSalaryAndBonus,
  getWithholdingTax,
} from "../report/reportSlice";
import dayjs from "dayjs";

function App() {
  const { accountId } = useParams();
  const {
    userInitialized,
    userReportTemplates: reportTemplates,
    withholdingTaxMetaData,
    salaryMetaData,
    personalData,
  } = useAppSelector(selectReportState);
  const { user } = useAppSelector(selectUserState);
  const { filteredAccounts } = useAppSelector(selectProfileState);
  const { sections } = useAppSelector(selectSectionState);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    checkedAccount: null as Account | null,
    checkedAccountId: 0,
    selectedPointDate: dayjs(),
  });

  const canSwitchTargetAccount = useAppSelector(selectHasOthersReportAccessPermissions);
  const onSelectedAccountChange = (id: number) => {
    if (id > 0 && id !== state.checkedAccountId) {
      const a = filteredAccounts.find((a) => a.id === id);
      $state({ ...state, checkedAccountId: id, checkedAccount: a ? { ...a } : null });
      const nextURL = id === user.id ? `/_/report/` : `/_/report/${id}`;
      window.history.replaceState({}, "", nextURL);
    }
  };

  const onKeywordChange = (keyword: string, current_company_code: string) => {
    if (keyword) {
      dispatch(searchCompanyMembers({ conditions: { keyword }, current_company_code }));
    } else {
      dispatch(clearFilteredAccounts());
    }
  };

  useEffect(() => {
    if (userInitialized) return;
    dispatch(getUserReportTemplates());
  }, [userInitialized]);
  useEffect(() => {
    dispatch(
      searchCompanyMembers({
        conditions: { accountId: state.checkedAccountId.toString() },
        current_company_code: user.current_company.code,
      })
    );
    dispatch(getPersonal({ accountId: state.checkedAccountId, baseDate: state.selectedPointDate }));
    dispatch(getWithholdingTax({ accountId: state.checkedAccountId, before: state.selectedPointDate }));
    dispatch(getSalaryAndBonus({ accountId: state.checkedAccountId, before: state.selectedPointDate }));
  }, [state.checkedAccountId, user.current_company.code]);

  useEffect(() => {
    if (user.id === 0) return;
    if (state.checkedAccountId === 0) {
      $state({ ...state, checkedAccountId: accountId ? +accountId : user.id });
    }
  }, [user]);

  useEffect(() => {
    /*
      現在選択中のユーザーが対象者リストからなくなったときに不都合が生じるので
      別で記憶する。
*/
    const a = filteredAccounts.find((a) => a.id === state.checkedAccountId);
    if (a) {
      $state({ ...state, checkedAccount: { ...a } });
    }
  }, [filteredAccounts]);

  useEffect(() => {
    return () => {
      dispatch(clearFilteredAccounts());
    };
  }, []);

  const accountCandidates = useMemo(() => {
    if (filteredAccounts.some((_) => _.id === state.checkedAccountId) || !state.checkedAccount) return filteredAccounts;
    return [...filteredAccounts, state.checkedAccount];
  }, [filteredAccounts, state.checkedAccount]);

  const _renderReports = (id: string) => {
    if (id === "roster_of_workers_single_download") {
      return (
        <Row>
          <Col>
            {personalData.length > 0 ? (
              <a
                className="--inline-link --cursor-pointer"
                onClick={(e) => {
                  dispatch(
                    singleDownloadReportByBaseDate({
                      resource: "roster_of_workers_single_download",
                      accountId: state.checkedAccountId,
                      baseDate: dayjs(),
                    })
                  );
                }}
              >
                ダウンロード
              </a>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </Col>
        </Row>
      );
    } else if (id === "withholding_tax_single_download") {
      return (
        <Row>
          <Col>
            {withholdingTaxMetaData.map((d, i) => {
              return (
                <div key={`withholding_tax_${i}`}>
                  <a
                    className="--inline-link --cursor-pointer"
                    onClick={() => {
                      dispatch(
                        singleDownloadReportByValidFrom({
                          resource: d.downloadResource,
                          accountId: d.accountId,
                          validFrom: dayjs(d.validFrom),
                        })
                      );
                    }}
                  >
                    {d.title}
                  </a>
                </div>
              );
            })}
            {withholdingTaxMetaData.length > 0 ? (
              <div className="mt-4">
                <Link
                  to={`/_/report/list/withholding/${state.checkedAccountId === user.id ? "" : state.checkedAccountId}`}
                >
                  <Button variant="outline-primary">全ての源泉徴収票を見る</Button>
                </Link>
              </div>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </Col>
        </Row>
      );
    } else if (id === "salary_slip_single_download") {
      return (
        <Row>
          <Col>
            {salaryMetaData.map((d, i) => {
              if (i > 2) return;
              return (
                <div key={`salary_${i}`}>
                  <a
                    className="--inline-link --cursor-pointer"
                    onClick={() => {
                      dispatch(
                        singleDownloadReportByValidFrom({
                          resource: d.downloadResource,
                          accountId: d.accountId,
                          validFrom: dayjs(d.validFrom),
                        })
                      );
                    }}
                  >
                    {d.title}
                  </a>
                </div>
              );
            })}
            {salaryMetaData.length > 0 ? (
              <div className="mt-4">
                <Link to={`/_/report/list/salary/${state.checkedAccountId === user.id ? "" : state.checkedAccountId}`}>
                  <Button variant="outline-primary">全ての給与・賞与明細を見る</Button>
                </Link>
              </div>
            ) : (
              <div>ダウンロードできる内容がありません。</div>
            )}
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"fileOutput"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">労務書類出力（個別）</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          {userInitialized ? (
            <Container>
              {canSwitchTargetAccount && (
                <Row>
                  <Col md="2">
                    <div className="--bold pt-md-3">対象者</div>
                  </Col>
                  <Col md="10">
                    <MemberSelector
                      accounts={accountCandidates}
                      sections={sections}
                      defaultAccountId={state.checkedAccountId ? state.checkedAccountId : undefined}
                      onSelectedAccountChange={onSelectedAccountChange}
                      onKeywordChange={onKeywordChange}
                    />
                  </Col>
                </Row>
              )}
              {state.checkedAccountId > 0
                ? reportTemplates.map((reportTemplate) => {
                    return (
                      <Row className="mt-4" key={reportTemplate.id}>
                        <Col>
                          <Card className="my-1 my-md-0">
                            <Card.Header className="--bold">{reportTemplate.title}</Card.Header>
                            <Card.Body>{_renderReports(reportTemplate.id)}</Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })
                : null}
            </Container>
          ) : (
            <Container>
              <Row>
                <Col>労務書類のダウンロード中・・・</Col>
              </Row>
            </Container>
          )}
        </main>
      </div>
    </div>
  );
}

export default App;
