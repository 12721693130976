import { call, callCollaborator } from "./api";
export const EMAIL_FORMAT_REGEX = /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]*@[a-zA-Z0-9_-]+[a-zA-Z0-9._-]+$/;
export const TIME_REGEX = /^[0-2][0-9]:[0-5][0-9]$/;
export const POSTCODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;
export const BANK_ACCOUNT_NAME_KANA_REGEX =
  /^[a-zａ-ｚA-ZＡ-Ｚ0-9０-９\,\.()\-\/\\ \u30a1-\u30f6\uff66-\uff9f，．「」（）‐ー／￥　]+$/;
export const ZENKAKU_KANA_SPACE_REGEX = /^[\u30a1-\u30fc　 ]*$/;
export const ZENKAKU_KANA_NUMBER_SPACE_REGEX = /^[0-9０-９\－\-\u30a1-\u30f6\u30fc\u2010 　]+$/;
export const PHONE_NUMBER_REGEX = /^[0-9\-]+$/;
export const ALPHA_NUMERIC_FORMAT_REGEX = /^[A-Za-z0-9]*$/;
export const MY_NUMBER_REGEX = /^[0-9]{12}$/;
const SPACE_REGEX = /\s/;

const API_VALIDATE_COMPANY = { api: "company_manager", version: 1, resource: "validate_company" };
const API_VALIDATE_PASSWORD = { api: "account_manager", version: 1, resource: "validate_password" };
const API_VALIDATE_ACCOUNT = { api: "account_manager", version: 1, resource: "validate_account" };

const VALIDATION_KEYS = {
  unique_company_code: API_VALIDATE_COMPANY,
  unique_login_code: API_VALIDATE_ACCOUNT,
  length: API_VALIDATE_PASSWORD,
  complexity: API_VALIDATE_PASSWORD,
  current: API_VALIDATE_PASSWORD,
} as {
  [key: string]: any;
};

export const isSpace = (str: string) => {
  return SPACE_REGEX.test(str);
};

export const validateAsync = async (option: {
  value: {
    password?: string;
    main_company_code?: string;
    login_code?: string;
  };
  key: string;
  policy_company_code?: string;
}) => {
  let promise = new Promise(async (resolve, reject) => {
    if (!option?.value || Object.keys(option.value).length === 0) {
      reject("option.value is invalid");
      return;
    }
    let data = {
      [option.key]: option.value,
    } as {
      [key: string]: string | boolean;
    };
    if (option.policy_company_code) {
      data.policy_company_code = option.policy_company_code;
    }
    // パスワードの複雑さチェック時は必ずスペースが含まれているかチェックする
    if (option.key === "complexity") {
      if (isSpace(option.value.password ?? "")) {
        data.result = false;
        data.space = true;
        resolve(data);
        return;
      }
    }
    const apiParam = VALIDATION_KEYS[option.key];
    if (!apiParam) {
      reject("option.key is invalid");
      return;
    }
    if (apiParam.resource === "validate_account") {
      const validatedRes = await call("post", `${apiParam.api}/${apiParam.resource}`)(data);
      resolve(validatedRes.data.result[0][option.key] ?? false);
    } else {
      const validatedRes = await callCollaborator("post", `${apiParam.api}/${apiParam.resource}`)(data);
      resolve(validatedRes.data.results[0].result[0][option.key] ?? false);
    }
  });
  return promise;
};
