const data = {
  category: "resident_tax",
  table: "resident_tax",
  subFields: {
    payment_municipality_number: {},
    payment_municipality_name: {},
    submission_municipality_number: {},
    submission_municipality_name: {},
    destination_number: {},
    collection_type: {},
    resident_tax_annual: {},
    resident_tax_june: {},
    resident_tax_july: {},
    resident_tax_august: {},
    resident_tax_september: {},
    resident_tax_october: {},
    resident_tax_november: {},
    resident_tax_december: {},
    resident_tax_january: {},
    resident_tax_february: {},
    resident_tax_march: {},
    resident_tax_april: {},
    resident_tax_may: {},
  },
};
export default data;
