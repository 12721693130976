const data = {
  category: "withholding_tax",
  table: "withholding_tax",
  subFields: {
    category: {},
    payment: {},
    salary_income: {},
    income_deduction: {},
    withholding_tax: {},
    is_spouse_deductible1: {},
    is_spouse_deductible2: {},
    is_deductible_spouse_aged: {},
    exemption_for_spouse: {},
    dependent_specific1: {},
    dependent_specific2: {},
    dependent_aged1: {},
    dependent_aged2: {},
    dependent_aged3: {},
    dependent_other1: {},
    dependent_other2: {},
    under_16_years: {},
    handicapped_special1: {},
    handicapped_special2: {},
    handicapped_other: {},
    non_resident: {},
    social_insurance: {},
    social_insurance_in: {},
    life_insurance: {},
    earthquake_insurance: {},
    housing_loan: {},
    summary: {},
    new_life_insurance: {},
    old_life_insurance: {},
    medical_care_insurance: {},
    new_private_pension_insurance: {},
    old_private_pension_insurance: {},
    housing_loan_applicable_number: {},
    housing_loan_deductible_amount: {},
    housing_loan1_residence_on: {},
    housing_loan1_tax_deduction_div: {},
    housing_loan1_year_end_balance: {},
    housing_loan2_residence_on: {},
    housing_loan2_tax_deduction_div: {},
    housing_loan2_year_end_balance: {},
    spouse_name: {},
    spouse_furigana: {},
    spouse_div: {},
    total_income_of_spouse: {},
    national_pension_insurance: {},
    old_long_term_damage_insurance: {},
    basic_deduction: {},
    adjustment_deduction: {},
    relative1_name: {},
    relative1_furigana: {},
    relative1_div: {},
    relative2_name: {},
    relative2_furigana: {},
    relative2_div: {},
    relative3_name: {},
    relative3_furigana: {},
    relative3_div: {},
    relative4_name: {},
    relative4_furigana: {},
    relative4_div: {},
    relative_young1_name: {},
    relative_young1_furigana: {},
    relative_young1_div: {},
    relative_young2_name: {},
    relative_young2_furigana: {},
    relative_young2_div: {},
    relative_young3_name: {},
    relative_young3_furigana: {},
    relative_young3_div: {},
    relative_young4_name: {},
    relative_young4_furigana: {},
    relative_young4_div: {},
    is_minor: {},
    is_alien: {},
    is_retirement_death: {},
    is_disasters: {},
    is_b_column: {},
    is_disabled_person_special: {},
    is_disabled_person_other: {},
    is_widow: {},
    is_single_parent: {},
    is_work_study_student: {},
  },
};

export default data;
