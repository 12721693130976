import { MultiSelect, Option } from "react-multi-select-component";
import { ChoiceItem } from "./profileValues";

function MultiSelectItem({
  choices,
  onChange,
  labelledBy,
}: {
  choices: ChoiceItem[];
  onChange: (selected: string[]) => any;
  labelledBy?: string;
}) {
  const onSearchItemChange = (selected: Option[]) => {
    onChange(selected.map((s) => s.value));
  };

  return (
    <MultiSelect
      options={choices.map((c) => {
        const { value, label } = c;
        return { label: label || value, value };
      })}
      value={choices
        .filter((c) => c.checked)
        .map((c) => {
          const { value, label } = c;
          return { label: label || value, value };
        })}
      onChange={(selected: Option[]) => onSearchItemChange(selected)}
      overrideStrings={{
        allItemsAreSelected: "全選択",
        clearSearch: "クリア",
        clearSelected: "クリア",
        noOptions: "選択肢なし",
        search: "検索",
        selectAll: "全選択",
        selectAllFiltered: "全選択(検索中)",
        selectSomeItems: "選択してください",
      }}
      labelledBy={labelledBy || "Select"}
    />
  );
}

export default MultiSelectItem;
