const data = {
  category: "bank_account",
  table: "bank_account",
  subFields: {
    bank_account_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payroll_payment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payroll_cash_payment_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payroll_cash_fixed_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`payroll_${i}_payment_category_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_company_bank_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_company_bank_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_payee_bank_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_payee_bank_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_payee_branch_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_payee_branch_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_deposit_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_account_no_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_account_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`payroll_${i}_account_name_kana_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
        };
        if (i > 1) {
          _ = {
            ..._,
            [`payroll_${i}_fixed_amount_$N`]: {
              tag: "tb_",
              tagGroupIndex: 1,
            },
          };
        }
      });
      return _;
    })(),
    bonus_payment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_cash_payment_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    bonus_cash_fixed_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`bonus_${i}_payment_category_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_company_bank_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_company_bank_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_payee_bank_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_payee_bank_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_payee_branch_number_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_payee_branch_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_deposit_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_account_no_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_account_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`bonus_${i}_account_name_kana_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
        };
        if (i > 1) {
          _ = {
            ..._,
            [`bonus_${i}_fixed_amount_$N`]: {
              tag: "tb_",
              tagGroupIndex: 1,
            },
          };
        }
      });
      return _;
    })(),
  },
  labelMap: {
    ja: {
      bank_account_history: "振込口座履歴",
    },
  },
};

export default data;
