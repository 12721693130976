const data = {
  category: "training",
  table: "training",
  subFields: {
    training_code: { required: true },
    completion_on: {},
    remarks: {},
    relation_file_id: {},
  },
};
export default data;
