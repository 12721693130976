const data = {
  category: "resignee",
  table: "resignee",
  subFields: {
    resignation_type: {},
    resignation_reason_type: {},
    resignation_reason: {},
    postcode: {},
    address: {},
    address_kana: {},
    phone_number: {},
    absence_in_last_month_type: {},
    contract_agreement_type: {},
    contract_termination_notice_type: {},
    non_renewal_clause_type: {},
    contract_renewal_from_worker_type: {},
    documentation_of_unemployement_type: {},
    optional_continuation_insurance_type: {},
    resignation_resident_taxes_type: {},
    transfer_workplace_name: {},
    transfer_position_name: {},
    remarks: {},
  },
};

export default data;
