const data = {
  category: "secondment_acceptance",
  table: "secondment_acceptance",
  subFields: {
    planned_end_on: {},
    seconded_from_company_code: {},
    seconded_from_postcode: {},
    seconded_from_address: {},
    seconded_from_phone_number: {},
    seconded_from_person_responsible: {},
    assistance_fee: {},
    seconded_from_employee_number: {},
    seconded_from_section: {},
    seconded_from_position: {},
    seconded_from_job: {},
    seconded_from_duty: {},
    seconded_from_grade: {},
    previous_extend_planned_end_on: {},
    secondment_place_code: {},
    remarks: {},
  },
};
export default data;
