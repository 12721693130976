import { useRef, Fragment, useState } from "react";
import { useDropzone } from "react-dropzone";
import { HandlerEvent } from "../app/util";
import ModalDialog from "./ModalDialog";

export type DecodedFileData = {
  name: string;
  type: string;
  size?: number;
  dataURIprefix: string;
  dataURI: string;
};

function App({
  onFileLoad,
  onFileLoadError,
  accepts,
}: {
  onFileLoad?: (decodedFileData: DecodedFileData) => any;
  onFileLoadError?: () => any;
  accepts?: string[];
} = {}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return $isModalOpen(true);
    fileToDataURI(file)
      .then((dataURI) => {
        typeof onFileLoad === "function" && onFileLoad(dataURI);
      })
      .catch(() => {
        typeof onFileLoadError === "function" && onFileLoadError();
      });
  };
  const accept = accepts?.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {});
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });
  const [isModalOpen, $isModalOpen] = useState(false);
  return (
    <Fragment>
      <div {...getRootProps()}>
        <div style={{ backgroundColor: "#f3f6f9" }} className="border border-primary rounded p-4">
          ここにファイルをドラッグ&ドロップするか、 クリックしてファイルを選択してください。
        </div>
      </div>
      <input
        {...getInputProps()}
        type="file"
        className="--hidden"
        ref={inputRef}
        accept={accepts?.join(",")}
        onClick={(e) => {
          // 同じファイルを再度指定できるようにvalueを初期化する
          if (!(e.target instanceof HTMLInputElement)) {
            return;
          }
          e.target.value = "";
        }}
        onChange={(e: HandlerEvent) => {
          if (!e?.target?.files?.length) return;
          const file = e.target.files[0];
          const isAcceptable = !accepts || accepts?.some((accept) => file.type.match(accept));
          if (!isAcceptable) return $isModalOpen(true);
          fileToDataURI(file)
            .then((dataURI) => {
              typeof onFileLoad === "function" && onFileLoad(dataURI);
            })
            .catch(() => {
              typeof onFileLoadError === "function" && onFileLoadError();
            });
        }}
      />
      <ModalDialog
        show={isModalOpen}
        onConfirm={() => $isModalOpen(false)}
        message="受け付けていないファイル形式です。"
        type="alert"
      />
    </Fragment>
  );
}

export const fileToDataURI = (file: File) => {
  return new Promise<DecodedFileData>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const dataURI = reader.result as string;
      const [dataURIprefix, dataBody] = dataURI.split(",");
      if (!dataBody) {
        return reject();
      }
      resolve({
        name: file.name,
        type: file.type,
        size: file.size,
        dataURIprefix,
        dataURI: dataBody,
      });
    };
  });
};

export default App;
