const data = {
  category: "labor_contract",
  table: "labor_contract",
  subFields: {
    week_prescribed_working_hours: {},
    day_prescribed_working_hours: {},
    week_prescribed_working_days: {},
    salary_classification_type: {},
    earned_income_type: {},
    wage_structure_type: {},
    attendance_category_code: {},
  },
};
export default data;
