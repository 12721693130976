const data = {
  category: "assignment2",
  table: "assignment2",
  subFields: {
    assignment2_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_concurrent_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    full_section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    position_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    position_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      assignment2_history: "所属部署・役職履歴",
    },
  },
};

export default data;
