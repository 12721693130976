import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../css/style.scss";
import { Placement } from "react-bootstrap/esm/types";
type Props = {
  children: string;
  placement?: Placement;
  delay?: { show: number; hide: number };
  tooltipText: string;
  variant?: string;
  disabled?: boolean;
  className?: string;
  onClick?: Function;
};

function App({
  children,
  placement = "top",
  delay = { show: 50, hide: 50 },
  tooltipText,
  variant,
  disabled,
  className,
  onClick,
}: Props) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
    >
      {/** disabledの場合にもtooltipが表示されるようにspanで囲う */}
      <span>
        <Button variant={variant} disabled={disabled} className={className} onClick={() => onClick && onClick()}>
          {children}
        </Button>
      </span>
    </OverlayTrigger>
  );
}

export default App;
