import { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { INSERT_ITEM, CONTENT_MAX_LENGTH } from "./mailValues";
import { getMail, selectMailState, unselectMail, testSendMail, updateMail } from "./mailSlice";
import { selectUserState } from "../login/userSlice";
import { showToast } from "../notification/notificationSlice";
import { Container, Row, Col, Button, ListGroup, Form, Badge } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedMail } = useAppSelector(selectMailState);
  const { user } = useAppSelector(selectUserState);
  const [state, $state] = useState({
    isEditing: false,
    name: "",
    subject: "",
    content: "",
    activeModal: "",
  });
  const contentRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    return () => {
      dispatch(unselectMail());
    };
  }, []);

  useEffect(() => {
    if (id)
      dispatch(getMail({ id })).then((res) => {
        // 存在しないidの場合、メール文面一覧へ遷移させる
        if (!res.payload) navigate("/_/mail/");
      });
  }, [id]);

  const errorMessages = useMemo(() => {
    const errorMessages = [] as { place: string; message: string }[];
    if (state.name === "") errorMessages.push({ place: "name", message: "入力してください" });
    if (state.subject === "") errorMessages.push({ place: "subject", message: "入力してください" });
    if (state.content === "") errorMessages.push({ place: "content", message: "入力してください" });
    return errorMessages;
  }, [state]);

  const replacedContent = useMemo(() => {
    const regex = /\{([^}]*)\}/g;
    const contentSegments = selectedMail.content.split(regex);
    return contentSegments.map((segment, index) => {
      if (index % 2 === 1) {
        const item = INSERT_ITEM.find((item) => item.code === segment);
        if (item) {
          return <span key={index} style={{ color: "#0000ff" }}>{`{${item.name}}`}</span>;
        }
      }
      return segment;
    });
  }, [selectedMail]);

  const testSend = async () => {
    await dispatch(testSendMail());
    $state({ ...state, activeModal: "" });
    dispatch(
      showToast({
        id: `toast-${Date.now()}`,
        content: "テストメールを送信しました。",
      })
    );
  };

  const update = async () => {
    await dispatch(
      updateMail({ id: selectedMail.id, name: state.name, subject: state.subject, content: state.content })
    );
    $state({ ...state, isEditing: false, activeModal: "" });
  };

  const insertCode = (code: string) => {
    if (contentRef.current) {
      let sentence = state.content;
      const textarea = contentRef.current;
      const before = sentence.substring(0, textarea.selectionStart);
      const word = `{${code}}`;
      const after = sentence.substring(textarea.selectionStart, sentence.length);
      sentence = before + word + after;
      $state({ ...state, content: sentence });
      // 状態更新後にフォーカスとカーソル位置を設定する
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(before.length + word.length, before.length + word.length);
      }, 0);
    }
  };

  // 不正な差し込みをされないようチェックする
  const validateInsertItem = (): boolean => {
    const value = state.content.replace(/\r?\n/g, "");
    const regex = /\{([^}]*)\}/g;
    const matches = Array.from(value.matchAll(regex));
    const insertItemCodes = INSERT_ITEM.map((item) => item.code);
    const isError = matches.some((match) => !insertItemCodes.includes(match[1]));
    return isError;
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"mail"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">{selectedMail.name}</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-3">
              <Col>
                {!state.isEditing ? (
                  <Button
                    onClick={() => {
                      $state({
                        ...state,
                        name: selectedMail.name,
                        subject: selectedMail.subject,
                        content: selectedMail.content,
                        isEditing: true,
                      });
                    }}
                  >
                    編集
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        $state({ ...state, isEditing: false });
                      }}
                      variant="outline-danger"
                    >
                      キャンセル
                    </Button>
                    <Button
                      onClick={() => {
                        if (validateInsertItem()) {
                          $state({ ...state, activeModal: "invalid_insert_item" });
                          return;
                        }
                        $state({ ...state, activeModal: "before_update" });
                      }}
                      disabled={errorMessages.length > 0}
                      className="mx-2"
                    >
                      更新
                    </Button>
                  </>
                )}
                <Button
                  className="float-end"
                  variant="outline-primary"
                  onClick={() => {
                    if (user.mail_address === "") {
                      $state({ ...state, activeModal: "no_mail_address" });
                      return;
                    }
                    $state({ ...state, activeModal: "before_test_send" });
                  }}
                  hidden={state.isEditing}
                >
                  テスト送信
                </Button>
              </Col>
            </Row>
            {state.isEditing && (
              <Row className="mb-1">
                <Col>
                  <span className="--required-label"></span> は必須項目です。
                </Col>
              </Row>
            )}
            <ListGroup className="mb-4">
              {state.isEditing && (
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={2}>
                      <div className={classNames({ "--bold": true, "--required-label": true })}>名前</div>
                    </Col>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        id="name"
                        value={state.name}
                        onChange={(e) => {
                          $state({ ...state, name: e.target.value });
                        }}
                      />
                      {(() => {
                        const m = errorMessages?.find((message) => message.place === "name");
                        return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                      })()}
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={2}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>件名</div>
                  </Col>
                  <Col md={10}>
                    {state.isEditing ? (
                      <>
                        <Form.Control
                          type="text"
                          id="subject"
                          value={state.subject}
                          onChange={(e) => {
                            $state({ ...state, subject: e.target.value });
                          }}
                        />
                        {(() => {
                          const m = errorMessages?.find((message) => message.place === "subject");
                          return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
                        })()}
                      </>
                    ) : (
                      <div className="--pre-wrap">{selectedMail.subject}</div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={2}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>本文</div>
                  </Col>
                  <Col md={10}>
                    {state.isEditing ? (
                      <>
                        <Form.Control
                          as="textarea"
                          id="content"
                          maxLength={CONTENT_MAX_LENGTH}
                          rows={10}
                          ref={contentRef}
                          value={state.content}
                          onChange={(e) => {
                            $state({ ...state, content: e.target.value });
                          }}
                        />
                        <div className="mt-1">
                          {state.content.length} / {CONTENT_MAX_LENGTH}
                          {(() => {
                            const m = errorMessages?.find((message) => message.place === "content");
                            return m ? <span className="--text-annotation --font-s ms-2">{m.message}</span> : null;
                          })()}
                        </div>
                      </>
                    ) : (
                      <div
                        className="--pre-wrap"
                        onCopy={(e) => e.preventDefault()}
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        {replacedContent}
                      </div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              {state.isEditing && (
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={2}>
                      <div className={classNames({ "--bold": true })}>差し込み項目</div>
                    </Col>
                    <Col md={10}>
                      <div>クリックすると要素を本文に差し込めます。</div>
                      <div className="mt-1">
                        <Badge pill bg="danger" className="me-2">
                          !
                        </Badge>
                        <span className="--text-annotation">
                          差し込み項目「パスワード生成」を利用した場合、メール送信のたびにパスワードが変更されますのでご注意ください。​
                        </span>
                      </div>
                      <div>
                        {INSERT_ITEM.map((item) => (
                          <Button
                            className="me-2 my-1 "
                            style={{ width: "300px" }}
                            variant="outline-secondary"
                            key={item.code}
                            onClick={() => insertCode(item.code)}
                          >
                            {item.name}
                          </Button>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
            </ListGroup>
            <ModalDialog
              show={state.activeModal === "before_test_send"}
              message="テスト送信します。よろしいですか？"
              onConfirm={testSend}
              onCancel={() => {
                $state({ ...state, activeModal: "" });
              }}
            />
            <ModalDialog
              show={state.activeModal === "no_mail_address"}
              message="メールアドレスが設定されていないため、テスト送信はできません。"
              onConfirm={() => {
                $state({ ...state, activeModal: "" });
              }}
              type="alert"
            />
            <ModalDialog
              show={state.activeModal === "before_update"}
              message="更新します。よろしいですか？"
              onConfirm={update}
              onCancel={() => {
                $state({ ...state, activeModal: "" });
              }}
            />
            <ModalDialog
              show={state.activeModal === "invalid_insert_item"}
              message="差し込み項目に不備があります。確認してください。"
              onConfirm={() => {
                $state({ ...state, activeModal: "" });
              }}
              type="alert"
            />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
