import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form, Alert, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getSectors,
  getRegularColumns,
  getDefaultLabelColumns,
  getRenamedColumns,
  commitRenamedColumns,
  selectClientState,
  selectActiveSectors,
  uploadRenamedColumns,
  downloadRenamedColumns,
} from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import { testResponse } from "../../app/util";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import classNames from "classnames";

function RenameColumnList() {
  const { target } = useParams();
  const { renamedColumns, defaultLabelColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    sectorId: target ?? "",
    inputtedColumns: {} as { [column: string]: string },
    switchingSectorId: "",
    activeModal: "",
    isUploading: false,
  });

  const renameTargetSectors = useMemo(() => {
    return activeSectors.filter(({ has_rename_targets }) => has_rename_targets);
  }, [activeSectors]);

  useEffect(() => {
    dispatch(getRenamedColumns());
    dispatch(getSectors());
  }, []);

  useEffect(() => {
    const isValidSectorId = renameTargetSectors?.some(({ sector_id }) => sector_id === state.sectorId);
    if (isValidSectorId) {
      window.history.replaceState({}, "", `/_/masters/fields/edit/${state.sectorId}`);
      if (!defaultLabelColumns[state.sectorId]) {
        dispatch(getDefaultLabelColumns({ sectorId: state.sectorId }));
      }
    } else {
      window.history.replaceState({}, "", `/_/masters/fields`);
    }
  }, [state.sectorId, renameTargetSectors]);

  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    $state({ ...state, isUploading: true });
    try {
      await dispatch(
        uploadRenamedColumns({
          sector: state.sectorId,
          file: decodedFileData.dataURI,
          name: decodedFileData.name,
        })
      );
    } catch (error) {
    } finally {
      $state({ ...state, isUploading: false });
    }
  };
  const download = async () => {
    await dispatch(downloadRenamedColumns({ sector: state.sectorId }));
  };

  const defaultIdLabels = useMemo(() => {
    return defaultLabelColumns[state.sectorId]?.reduce(
      (prev, { id, label }) => ({ ...prev, [id]: label }),
      {} as { [column: string]: string }
    );
  }, [state.sectorId, defaultLabelColumns]);

  const duplicatedValues = useMemo(() => {
    const currentIdLabels = {
      ...defaultIdLabels,
      ...renamedColumns[state.sectorId],
      ...Object.keys(state.inputtedColumns).reduce((prev, current) => {
        if (state.inputtedColumns[current] === "") return { ...prev, [current]: defaultIdLabels[current] };
        return { ...prev, [current]: state.inputtedColumns[current] };
      }, {}),
    };
    return Object.values(currentIdLabels).filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
  }, [state, defaultIdLabels]);

  const matchedSector: any = useMemo(() => {
    const matchedSector = renameTargetSectors.find(({ sector_id }) => sector_id === state.sectorId);
    if (matchedSector && matchedSector.sector_id === "profile_u_attendance") {
      return {
        ...matchedSector,
        categoryTypes: [{ label: "日数・時間", categoryType: "days_times" }],
      };
    } else if (
      matchedSector &&
      (matchedSector.sector_id === "profile_u_salary" || matchedSector?.sector_id === "profile_u_bonus")
    ) {
      return {
        ...matchedSector,
        categoryTypes: [
          { label: "支給", categoryType: "payments" },
          { label: "控除", categoryType: "deductions" },
          { label: "その他", categoryType: "others" },
          { label: "備考", categoryType: "remarks" },
        ],
      };
    }
    return matchedSector;
  }, [renameTargetSectors, state.sectorId]);

  if (!matchedSector?.logical_name) {
    return <Container className="mt-4"></Container>;
  }
  return (
    <Container>
      {state.sectorId !== "" && (
        <div className="pt-4">
          <h2 className="Headline--section mb-2">{matchedSector.logical_name}の項目名変更</h2>
          <div className="my-2">
            {state.isUploading ? (
              <Alert variant={"info"}>リクエストを受け付けました。処理完了までしばらくお待ちください。</Alert>
            ) : (
              <Uploader
                onFileLoad={onFileLoad}
                accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
              />
            )}
          </div>
          <Button className="mb-2 mx-2" variant="outline-secondary" onClick={download}>
            ダウンロード
          </Button>
          {matchedSector.categoryTypes?.length > 0 ? (
            <>
              {(() => {
                if (matchedSector.sector_id === "profile_u_attendance") {
                  return (
                    <Alert variant="info">
                      項目名を変更した場合、給与明細にも変更が反映されます。
                      <br />
                      「勤怠日数予備1」などの予備項目は、変更した場合のみ給与明細への出力対象となります。（「出勤日数」など元々設定されている項目は出力対象です。）
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                } else if (matchedSector.sector_id === "profile_u_salary") {
                  return (
                    <Alert variant="info">
                      項目名を変更した場合、給与明細にも変更が反映されます。
                      <br />
                      「支給項目1」などの予備項目は、変更した場合のみ給与明細への出力対象となります。（「基本給」など元々設定されている項目は出力対象です。）
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                } else if (matchedSector.sector_id === "profile_u_bonus") {
                  return (
                    <Alert variant="info">
                      項目名を変更した場合、賞与明細にも変更が反映されます。
                      <br />
                      「賞与支給項目1」などの予備項目は、変更した場合のみ賞与明細への出力対象となります。（「賞与」など元々設定されている項目は出力対象です。）
                      <br />
                      ※ただし、出力対象でもデータが空欄の場合は出力されません。
                    </Alert>
                  );
                }
              })()}
              <Accordion defaultActiveKey="" className="mb-3">
                {matchedSector.categoryTypes.map((c: any) => {
                  return (
                    <Accordion.Item eventKey={c.categoryType} key={c.categoryType}>
                      <Accordion.Header>{c.label}</Accordion.Header>
                      <Accordion.Body>
                        {defaultLabelColumns[state.sectorId]
                          ?.filter((column) => column.rename_target && c.categoryType.includes(column.category_type))
                          ?.map(({ id, label }, i) => {
                            const inputted = state.inputtedColumns[id]; // いま入力している値
                            const renamed = renamedColumns[state.sectorId]?.[id]; // DBに設定している値
                            const value = inputted ?? renamed ?? "";
                            const isActive = !id.match(/(_item|_reserve)\d+/) || renamed;
                            return (
                              <Row key={`${i}`} className="mb-2">
                                <Col>
                                  <Form.Label className="--bold">{label}</Form.Label>
                                  <span
                                    className={classNames({
                                      "Badge--ok": isActive,
                                      "Badge--waiting": !isActive,
                                      "ms-2": true,
                                    })}
                                  >
                                    {isActive ? "使用中" : "不使用"}
                                  </span>
                                  <Form.Control
                                    type="text"
                                    placeholder={label}
                                    value={value}
                                    onChange={(e) => {
                                      $state({
                                        ...state,
                                        inputtedColumns: { ...state.inputtedColumns, [id]: e.target.value },
                                      });
                                    }}
                                  />
                                  {duplicatedValues.includes(value) ? (
                                    <span className="--text-annotation mt-1 --font-s">
                                      他の項目名と重複するため設定できません。
                                    </span>
                                  ) : null}
                                </Col>
                              </Row>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          ) : (
            <>
              {defaultLabelColumns[state.sectorId]
                ?.filter(({ rename_target }) => rename_target)
                ?.map(({ id, label }, i) => {
                  const inputted = state.inputtedColumns[id]; // いま入力している値
                  const renamed = renamedColumns[state.sectorId]?.[id]; // DBに設定している値
                  const value = inputted ?? renamed ?? "";
                  const isActive = !id.match(/(_allowance|_payment|_deduction)\d+/) || renamed;
                  return (
                    <Row key={`${i}`} className="mb-2">
                      <Col>
                        <Form.Label className="--bold">{label}</Form.Label>
                        <span
                          className={classNames({
                            "Badge--ok": isActive,
                            "Badge--waiting": !isActive,
                            "ms-2": true,
                          })}
                        >
                          {isActive ? "使用中" : "不使用"}
                        </span>
                        <Form.Control
                          type="text"
                          placeholder={label}
                          value={value}
                          onChange={(e) => {
                            $state({
                              ...state,
                              inputtedColumns: { ...state.inputtedColumns, [id]: e.target.value },
                            });
                          }}
                        />
                        {duplicatedValues.includes(value) ? (
                          <span className="--text-annotation mt-1 --font-s">
                            他の項目名と重複するため設定できません。
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                  );
                })}
            </>
          )}
          <Button onClick={() => $state({ ...state, activeModal: "commit" })} disabled={duplicatedValues.length > 0}>
            保存
          </Button>
        </div>
      )}

      <ModalDialog
        show={state.activeModal === "commit"}
        message="保存します。よろしいですか？"
        onConfirm={() => {
          const renameColumns = { ...renamedColumns[state.sectorId], ...state.inputtedColumns };
          dispatch(
            commitRenamedColumns({
              sectorId: state.sectorId,
              renameColumns: {
                ...Object.keys(renameColumns).reduce((prev, current) => {
                  return { ...prev, [current]: renameColumns[current] };
                }, {}),
              },
            })
          ).then(() => {
            dispatch(getRegularColumns({ sectorId: state.sectorId }));
          });
          $state({ ...state, activeModal: "", inputtedColumns: {} });
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
      <ModalDialog
        show={state.activeModal === "switch"}
        message="入力済の内容は破棄されますが、切り替えてよろしいですか？"
        onConfirm={() => {
          $state({
            ...state,
            sectorId: state.switchingSectorId,
            inputtedColumns: {},
            activeModal: "",
            switchingSectorId: "",
          });
        }}
        onCancel={() => {
          $state({ ...state, switchingSectorId: "", activeModal: "" });
        }}
        type="destructiveConfirm"
        confirmButtonName="破棄して切替"
      />
    </Container>
  );
}

export default RenameColumnList;
