import { useState, useMemo, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { selectCurrentCompany } from "../login/userSlice";
import { Container, Row, Col, Button, ListGroup, Form, Alert, Tab, Nav } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { GuestAccount } from "./profileValues";
import { AssessRole, serviceLabels } from "../permission/permissionValues";
import {
  getServiceContracts,
  postAssessRoles,
  deleteAssessRoles,
  getAssessRoles,
  selectPermissionState,
} from "../permission/permissionSlice";
import Table from "../../component/Table";
import Icon from "../../component/Icon";
import ModalDialog from "../../component/ModalDialog";
import { searchAccountWithToken } from "../profile/profileSlice";
import { setLoading } from "../notification/notificationSlice";
import { postVirtualTheme } from "../theme/themeSlice";
import { testResponse } from "../../app/util";

function App() {
  const dispatch = useAppDispatch();
  const current_company = useAppSelector(selectCurrentCompany);
  const { serviceContracts, assessRoles } = useAppSelector(selectPermissionState);
  const [errorMessage, $errorMessage] = useState("");
  const [activeModal, $activeModal] = useState("");
  const [activeTabKey, $activeTabKey] = useState("");
  const [selectedRole, $selectedRole] = useState<AssessRole | null>(null);

  useEffect(() => {
    if (current_company.id > 0) {
      dispatch(getServiceContracts({ companyId: current_company.id })); // ゲストの場合 current_company.id ではない
    }
  }, [current_company, dispatch]);

  useEffect(() => {
    // デフォルトのタブ
    if (serviceContracts.length > 0) {
      switchTab(`${serviceContracts[0].serviceId}`);
    }
  }, [serviceContracts]);

  const modifiedAssessRoles = useMemo(() => {
    return assessRoles.reduce(
      (prev, current) => {
        const sameAccountRole = prev.find((_) => _.role.accountId === current.accountId);
        if (sameAccountRole) {
          return [
            ...prev.filter((_) => _.role.id !== sameAccountRole.role.id),
            {
              role: current,
              roleNames: [...sameAccountRole.roleNames, current.roleName],
            },
          ];
        } else {
          return [...prev, { role: current, roleNames: [current.roleName] }];
        }
      },
      [] as {
        role: AssessRole;
        roleNames: string[];
      }[]
    );
  }, [assessRoles]);

  const navs = useMemo(() => {
    return serviceContracts.map((sc) => {
      return {
        id: sc.serviceId,
        name: serviceLabels[sc.serviceName].label,
      };
    });
  }, [serviceContracts]);

  const switchTab = async (key: string | null) => {
    if (!key) return;
    dispatch(setLoading(true));
    await dispatch(getAssessRoles({ serviceId: +key }));
    $activeTabKey(key);
    dispatch(setLoading(false));
  };

  const deleteRole = async () => {
    if (!selectedRole) return;
    const res = testResponse(
      await dispatch(
        deleteAssessRoles({
          assessRoles: [selectedRole],
        })
      )
    );
    if (!res) {
      $errorMessage("処理中にエラーが発生しました。");
      $activeModal("updateError");
    } else {
      $activeModal("");
    }
  };

  return (
    <div>
      <Container>
        <Row className="mb-2">
          <Col>
            <div>サービス別に利用可能な権限を持っているアカウントを確認できます。</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Container
              onSelect={(key: string | null) => {
                switchTab(key);
              }}
              activeKey={activeTabKey}
            >
              <Nav variant="tabs">
                {navs.map((service) => (
                  <Nav.Item key={service.id}>
                    <Nav.Link eventKey={service.id}>{service.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                <Row>
                  <Col className="--font-s --bold mt-2">合計 : {modifiedAssessRoles.length}件</Col>
                </Row>
                <div className="mt-2">
                  {modifiedAssessRoles.length > 0 && (
                    <Table
                      col={(() => {
                        const col = [
                          {
                            name: "ログインID",
                            colName: "loginId",
                            width: 100,
                            filterable: true,
                          },
                          {
                            name: "名前",
                            width: 150,
                            colName: "name",
                            filterable: true,
                          },
                          {
                            name: "ログイン時の企業名",
                            filterable: true,
                          },
                          {
                            name: "管理者",
                            colName: "admin",
                            width: 64,
                          },
                          {
                            name: "ユーザ",
                            colName: "user",
                            width: 64,
                          },
                          {
                            name: "アクション",
                            colName: "actions",
                            width: 400,
                          },
                        ];
                        if (activeTabKey === "9") {
                          col.splice(5, 0, {
                            name: "その他",
                            colName: "other",
                            width: 64,
                          });
                        }
                        return col;
                      })()}
                      row={modifiedAssessRoles.map(({ role: a, roleNames }) => {
                        return {
                          key: a.id,
                          data: (() => {
                            const data = [a.loginCode, a.name, a.mainCompanyName, "", "", ""];
                            if (activeTabKey === "9") data.splice(5, 0, "");
                            return data;
                          })(),
                          appendAfter: {
                            name:
                              a.mainCompanyCode !== a.currentCompanyCode ? (
                                <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                              ) : null,
                            admin: roleNames.includes("admin") ? (
                              <Icon type="check" width={20} height={20} fill="#19ac48" className="d-block mx-auto" />
                            ) : null,
                            user: roleNames.includes("user") ? (
                              <Icon type="check" width={20} height={20} fill="#19ac48" className="d-block mx-auto" />
                            ) : null,
                            other: roleNames.some((_) => _ !== "admin" && _ !== "user") ? (
                              <Icon type="check" width={20} height={20} fill="#19ac48" className="d-block mx-auto" />
                            ) : null,
                            actions:
                              a.mainCompanyCode !== a.currentCompanyCode ? (
                                <div className="--flex --font-s">
                                  {roleNames.includes("admin") ? (
                                    <Button
                                      onClick={() => {
                                        $selectedRole({ ...a, roleName: "admin" });
                                        $activeModal("before_commit");
                                      }}
                                      variant="outline-danger"
                                      size="sm"
                                      className="mx-1 --text-s"
                                    >
                                      管理者権限を削除
                                    </Button>
                                  ) : null}
                                  {roleNames.includes("user") ? (
                                    <Button
                                      onClick={() => {
                                        $selectedRole({ ...a, roleName: "user" });
                                        $activeModal("before_commit");
                                      }}
                                      variant="outline-danger"
                                      size="sm"
                                      className="mx-1 --text-s"
                                    >
                                      ユーザ権限を削除
                                    </Button>
                                  ) : null}
                                </div>
                              ) : null,
                          },
                        };
                      })}
                      usePagenation={true}
                      useKeywordFilter={true}
                    />
                  )}
                </div>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
        <ModalDialog
          show={activeModal === "updateError"}
          onConfirm={() => {
            $activeModal("");
          }}
          onCancel={() => {
            $activeModal("");
          }}
          message={errorMessage}
          type="alert"
        />
        <ModalDialog
          show={activeModal === "before_commit"}
          onCancel={() => {
            $activeModal("");
            $selectedRole(null);
          }}
          onConfirm={deleteRole}
          message="削除してよろしいですか？"
        />
      </Container>
    </div>
  );
}
export default App;
