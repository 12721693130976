import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { call, callCollaborator } from "../../app/api";

const SLICE_NAME = "theme";

interface ThemeState {
  processing: boolean;
}

const initialState: ThemeState = {
  processing: false,
};

export const postVirtualTheme = createAsyncThunk(
  SLICE_NAME + "/postVirtualTheme",
  async ({ accountId }: { accountId: number }) => {
    const res = await callCollaborator(
      "post",
      `theme_manager/actionplan`
    )({
      title: "virtual theme",
      detail: "",
      score: [],
      owner: accountId,
      object: -1,
      workgroup: -1,
      status: "running",
      metatype: "request",
    });
    return res.data.results[0].result;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postVirtualTheme.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(postVirtualTheme.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(postVirtualTheme.fulfilled, (state, action) => {
      state.processing = false;
    });
  },
});

export const selectThemeState = (state: RootState) => {
  return state.theme as ThemeState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
