import { Button, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import "../css/style.scss";

type Props = {
  show: boolean;
  message: string;
  type?: "confirm" | "alert" | "destructiveConfirm";
  onCancel?: Function;
  onConfirm: Function;
  onHide?: Function;
  confirmButtonName?: string;
  showCancelButton?: boolean;
};

const ModalDialog = ({
  show,
  message,
  type = "confirm",
  onCancel,
  onConfirm,
  onHide,
  confirmButtonName = "OK",
  showCancelButton = true,
}: Props) => {
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal
      show={true}
      onHide={() => {
        if (onHide) return onHide();
        if (onCancel) return onCancel();
        onConfirm();
      }}
      size="lg"
      centered
    >
      <Modal.Body className="--pre-wrap">{message}</Modal.Body>
      <Modal.Footer>
        {type !== "alert" && showCancelButton && (
          <Button
            onClick={() => {
              onCancel && onCancel();
            }}
            variant="outline-secondary"
          >
            キャンセル
          </Button>
        )}
        <Button
          onClick={() => {
            onConfirm && onConfirm();
          }}
          variant={type === "destructiveConfirm" ? "danger" : "primary"}
        >
          {confirmButtonName}
        </Button>
      </Modal.Footer>
    </Modal>,
    document.body
  );
};

export default ModalDialog;
