const data = {
  category: "industrial_accident",
  table: "industrial_accident",
  subFields: {
    office_code: {},
    business_type_number: {},
    business_type: {},
    employee_type: {},
  },
};
export default data;
