import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  selectApplyState,
  unselectPreviewTemplate,
  selectTemplateAsProfileField,
  getApplyTemplateSummaries,
  getPreviewTemplate,
} from "./applySlice";
import ProfileField from "../profile/ProfileField";
import { ProfileFieldStatus } from "../profile/profileValues";
import { ProfileSubField } from "../profile/profileFieldValues";

function ApplicationConfigPreview() {
  const { target } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { templateSummaries, previewTemplate } = useAppSelector(selectApplyState);
  const { originalProfileField, validateProfileField } = useAppSelector(selectTemplateAsProfileField);

  const [state, $state] = useState({
    pageIndex: 0,
  });
  useEffect(() => {
    dispatch(getApplyTemplateSummaries());
    return () => {
      dispatch(unselectPreviewTemplate());
    };
  }, []);

  const applicationType = useMemo(() => {
    return target ?? "";
  }, [target]);

  const [profileField, $profileField] = useState({ ...originalProfileField });
  const [fieldStatus, $fieldStatus] = useState({} as ProfileFieldStatus);

  useEffect(() => {
    if (templateSummaries.length === 0) return;
    if (
      !templateSummaries.some(
        ({ application_type, template_status }) =>
          application_type === applicationType && template_status !== "obsolete"
      )
    ) {
      navigate("/_/masters/application");
    } else {
      dispatch(getPreviewTemplate({ application_type: applicationType })).then((res: any) => {
        if (res.payload.inputs_on_create.length > 0) $state({ ...state, pageIndex: -1 });
      });
    }
  }, [templateSummaries, applicationType]);

  const pagingControls = useMemo(() => {
    if (previewTemplate.input_pages.length === 1) return null;
    return (
      <Nav
        variant="tabs"
        className="my-2"
        activeKey={state.pageIndex}
        onSelect={(pageIndex) => {
          if (pageIndex !== null) $state({ ...state, pageIndex: +pageIndex });
        }}
      >
        {previewTemplate.input_pages.map(({ name, display }, i) => {
          if (display === false) return null;
          return (
            <Nav.Item key={i}>
              <Nav.Link eventKey={i}>{name}</Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    );
  }, [previewTemplate, fieldStatus, state.pageIndex]);

  const inputsOnCreateKeys = useMemo(() => {
    return previewTemplate.inputs_on_create.map((input) => input.key);
  }, [previewTemplate]);

  const hasInputOnCreateErrors = useMemo(() => {
    return Object.keys(fieldStatus).some((fsKey) => {
      return (
        inputsOnCreateKeys.some((key) => fsKey === `${profileField.fieldName}/${key}`) &&
        fieldStatus[fsKey].errorMessage
      );
    });
  }, [fieldStatus, inputsOnCreateKeys]);

  useEffect(() => {
    if (originalProfileField.fieldName && previewTemplate.id) {
      const next = { ...originalProfileField };
      next.subFields = next.subFields.map((sf) => {
        const currentSf = profileField.subFields.find((_sf) => _sf.id === sf.id);
        return { ...sf, count: 0, value: currentSf?.value ?? sf.value }; // ファイル添付は効かないのでcountは0固定
      });
      $profileField(next);
      // 初回のバリデート実施
      const { subFields } = validateProfileField(next.subFields);
      const fieldName = next.fieldName;
      let nextFieldStatus = {} as ProfileFieldStatus;
      subFields.forEach((sf) => {
        const path = `${fieldName}/${sf.id}`;
        nextFieldStatus = {
          ...nextFieldStatus,
          [path]: { validated: !sf.errorMessage, errorMessage: sf.errorMessage ?? "" },
        };
      });
      $fieldStatus(nextFieldStatus);
      if (inputsOnCreateKeys.length === 0) $state({ ...state, pageIndex: 0 });
    }
  }, [originalProfileField, inputsOnCreateKeys]);

  const onChange = async (next: ProfileSubField[], updatedSubFieldIndex: number) => {
    const f = profileField.fieldName;
    let { validated, subFields } = validateProfileField(next);
    subFields = subFields.map((sf, i) => {
      if (i !== updatedSubFieldIndex) return sf;
      if (sf.type === "options" || sf.type === "checkbox") return { ...sf, label: sf.value };
      return sf;
    });
    const nextFieldStatus = { ...fieldStatus };
    nextFieldStatus[f] = nextFieldStatus[f] || { validated: true, errorMessage: "" };
    nextFieldStatus[f].validated = validated;
    nextFieldStatus[f].errorMessage = validated ? "" : "入力内容を確認してください";
    subFields.forEach((sf) => {
      const path = `${f}/${sf.id}`;
      nextFieldStatus[path] = nextFieldStatus[path] || { validated: true, errorMessage: "" };
      nextFieldStatus[path].validated = !!sf.errorMessage;
      nextFieldStatus[path].errorMessage = sf.errorMessage ?? "";
    });
    $profileField({ ...profileField, subFields });
    $fieldStatus(nextFieldStatus);
  };

  return (
    <div className="Layout">
      <div className="Layout__full --bg-white-transparent">
        {profileField.fieldName && (
          <Container>
            {inputsOnCreateKeys.length > 0 && (
              <>
                <h2 className="Headline--section mb-2">作成時入力項目</h2>
                <ProfileField
                  fieldName={profileField.fieldName}
                  labelMap={profileField.labelMap}
                  subFields={profileField.subFields}
                  isEditing={state.pageIndex === -1}
                  useControl={false}
                  showHeader={false}
                  fieldStatus={fieldStatus}
                  onChange={onChange}
                  pageIndex={-1}
                />
                {state.pageIndex === -1 ? (
                  <Button
                    className="my-2"
                    disabled={hasInputOnCreateErrors}
                    onClick={() => {
                      $state({ ...state, pageIndex: 0 });
                      dispatch(
                        getPreviewTemplate({
                          application_type: applicationType,
                          inputs: profileField.subFields
                            .filter(({ pageIndex }) => pageIndex === -1)
                            .reduce((prev, sf) => ({ ...prev, [sf.id]: sf.value }), {}),
                        })
                      );
                    }}
                  >
                    申請時入力内容を表示
                  </Button>
                ) : (
                  <Button className="my-2" onClick={() => $state({ ...state, pageIndex: -1 })}>
                    作成時入力項目を編集
                  </Button>
                )}
              </>
            )}
            {state.pageIndex !== -1 && (
              <>
                <h2 className="Headline--section mb-2">申請時入力項目</h2>
                {pagingControls}
                <ProfileField
                  fieldName={profileField.fieldName}
                  labelMap={profileField.labelMap}
                  subFields={profileField.subFields}
                  isEditing={true}
                  useControl={false}
                  showHeader={false}
                  fieldStatus={fieldStatus}
                  onChange={onChange}
                  pageIndex={state.pageIndex}
                />
              </>
            )}
          </Container>
        )}
      </div>
    </div>
  );
}

export default ApplicationConfigPreview;
