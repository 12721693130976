import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Mentions.scss";
import { useEffect, useState, Fragment } from "react";
import { useAppSelector, useAppDispatch } from "../app/store";
import { Row, Col, Form, Button, Accordion, ListGroup, Collapse, Container } from "react-bootstrap";
import { Mention, MentionsInput } from "react-mentions";
import { Link } from "react-router-dom";
import {
  selectThreadState,
  getThreadComments,
  postThreadComment,
  postReplyComment,
  putThreadComment,
  deleteThreadComment,
  commentValue,
} from "../features/client/threadSlice";
import { getRoles, selectPermissionState, getPermissions } from "../features/permission/permissionSlice";
import { ThreadBoxItem } from "../features/client/clientValues";
import { toTimeLabel } from "../app/util";
import { selectUserState } from "../features/login/userSlice";
import ModalDialog from "./ModalDialog";

function App({ threadBoxItem, onChange }: { threadBoxItem: ThreadBoxItem; onChange?: () => any }) {
  const dispatch = useAppDispatch();
  const { threadComments } = useAppSelector(selectThreadState);
  const { roles, permissions } = useAppSelector(selectPermissionState);
  const [state, $state] = useState({
    editings: [] as string[],
    commentValue: [] as commentValue[],
    checkValues: {
      open: true,
      close: false,
    },
    open: false,
    delComment: {} as commentValue,
    activeModal: "",
  });
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    dispatch(getThreadComments({ webScreen: threadBoxItem.webScreen }));
    dispatch(getRoles());
  }, [threadBoxItem.webScreen]);
  useEffect(() => {
    if (roles.length > 0) dispatch(getPermissions({}));
  }, [roles]);
  const selectUsers = permissions
    .filter((p) => p.account_id !== user.id && p.roles !== "")
    .map((m) => {
      return {
        id: m.account_id,
        display: `@${m.name}`,
      };
    });
  const sendComment = async (threadId: string = "", id: string = "") => {
    let commentValue = state.commentValue.find((_) => _.thread_id === threadId && _.id === id);
    if (commentValue) {
      if (commentValue.body === "") {
        return;
      }
      if (threadId === "") {
        await dispatch(postThreadComment({ commentValue: commentValue }));
      } else {
        await dispatch(postReplyComment({ commentValue: commentValue }));
      }
      // 送信後は state.commentValue の該当データのbody, mention_tosをクリア
      const newComments = state.commentValue.map((_) => {
        if (_.thread_id === threadId && _.id === id) {
          _.body = "";
          _.mention_tos = [];
        }
        return _;
      });
      $state({ ...state, commentValue: newComments });
      typeof onChange === "function" && onChange();
    }
  };

  const updateComment = async (id: string, threadId: string = "") => {
    let commentValue = state.commentValue.find((_) => _.id === id && _.thread_id === threadId);
    if (commentValue) {
      if (commentValue.body === "") {
        return;
      }
      await dispatch(putThreadComment({ commentValue }));
      // 送信後は state.commentValue, state.editings の該当データを削除
      const newComments = state.commentValue.filter((_) => _.id !== id);
      const newEditings = state.editings.filter((_) => _ !== id);
      $state({ ...state, commentValue: newComments, editings: newEditings });
      typeof onChange === "function" && onChange();
    }
  };

  const deleteComment = async () => {
    if (state.delComment.id) {
      await dispatch(deleteThreadComment({ commentValue: state.delComment }));
      // 送信後は state.commentValue, state.editings の該当データを削除
      const newComments = state.commentValue.filter((_) => _.id !== state.delComment.id);
      const newEditings = state.editings.filter((_) => _ !== state.delComment.id);
      $state({
        ...state,
        commentValue: newComments,
        editings: newEditings,
        activeModal: "",
        delComment: {} as commentValue,
      });
      typeof onChange === "function" && onChange();
    }
  };

  const selectComment = (type: string) => {
    const next = {
      open: type === "open" ? !state.checkValues.open : state.checkValues.open,
      close: type === "close" ? !state.checkValues.close : state.checkValues.close,
    };
    $state({ ...state, checkValues: next });
  };

  const updateIsOpen = (id: string, isOpen: boolean) => {
    const commentValue = {
      id: id,
      is_open: isOpen,
    } as commentValue;
    dispatch(putThreadComment({ commentValue }));
    // 解決済みに変更の場合は state.commentValue, state.editings の該当データを削除
    if (!isOpen) {
      const comment = threadComments.find((_) => _.id === id);
      let newEditings = state.editings.filter((_) => _ !== id);
      let newComments = state.commentValue.filter((_) => _.id !== id);
      // 返信コメントについても同じ処理を行う
      comment?.replies.forEach((r) => {
        newEditings = newEditings.filter((_) => _ !== r.id);
        newComments = newComments.filter((_) => _.id !== r.id);
      });
      $state({ ...state, commentValue: newComments, editings: newEditings });
    }
  };

  return (
    <Fragment>
      <Container className="Thread-box">
        {threadComments.length > 0
          ? ["open", "close"].map((c, i) => (
              <Form.Check
                inline
                key={`check-${i}`}
                id={`check-${i}`}
                checked={c === "open" ? state.checkValues.open === true : state.checkValues.close === true}
                className="mx-2"
                type="checkbox"
                label={c === "open" ? "未解決" : "解決済み"}
                onChange={() => selectComment(c)}
              />
            ))
          : null}
        {threadComments.length > 0 &&
          threadComments
            .filter((t) =>
              state.checkValues.open && !state.checkValues.close
                ? t.isOpen
                : !state.checkValues.open && state.checkValues.close
                ? !t.isOpen
                : t.isOpen || !t.isOpen
            )
            .map((data) => (
              <section className="my-1" key={`sec-${data.id}`}>
                <Accordion defaultActiveKey="" key={`acc-${data.id}`}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      // アコーディオンヘッダーにある入力フォームに半角スペースが入力された場合、アコーディオンを開閉しないようにする
                      onKeyUp={(e) => {
                        if (e.key === " ") e.preventDefault();
                      }}
                    >
                      <div className="container-md">
                        <div className="--font-s mb-1">
                          {`${toTimeLabel(data.postedAt)} ${
                            permissions.find((m) => m.account_id === data.author)?.name
                          }`}
                          <span className="pl-5"> </span>
                          {data.author === user.id ? (
                            <span onClick={(e) => e.stopPropagation()}>
                              <Form.Check
                                type="switch"
                                key={`switch-${data.id}`}
                                id={`switch-${data.id}`}
                                label={data.isOpen ? "未解決" : "解決済"}
                                onChange={() => updateIsOpen(data.id, !data.isOpen)}
                                checked={!data.isOpen}
                                className="text-secondary"
                                inline
                              />
                            </span>
                          ) : null}
                        </div>
                        <div>
                          <Row className="pt-2">
                            <Col md={12}>
                              <MentionsInput
                                value={state.commentValue.find((_) => _.id === data.id)?.body ?? data.body}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e, _, __, mentions) => {
                                  const mention_tos = mentions && mentions.map((m) => +m["id"]);
                                  const next = state.commentValue.map((_) => {
                                    return {
                                      ..._,
                                      body: _.id === data.id ? e.target.value : _.body,
                                      mention_tos: _.id === data.id ? mention_tos : _.mention_tos,
                                    };
                                  });
                                  $state({ ...state, commentValue: next });
                                }}
                                className="Mentions"
                                placeholder="確認依頼したい点や疑問点などをコメントしてください。"
                                disabled={!state.editings.some((_) => _ === data.id)}
                              >
                                <Mention
                                  trigger="@"
                                  markup="[__display__]{id:__id__}"
                                  data={selectUsers}
                                  className="Mentions__mention"
                                />
                              </MentionsInput>
                            </Col>
                          </Row>
                        </div>
                        {data.author === user.id && data.isOpen && !state.editings.some((_) => _ === data.id) && (
                          <div className="mt-2">
                            <Link
                              className="btn btn-outline-secondary btn-sm"
                              role="button"
                              to=""
                              onClick={(e) => {
                                e.stopPropagation();
                                let nextComments = state.commentValue;
                                if (!state.commentValue.some((_) => _.id === data.id)) {
                                  const newComment = {
                                    id: data.id,
                                    thread_id: "",
                                    body: data.body,
                                    is_open: data.isOpen,
                                    web_screen: data.webScreen,
                                  } as commentValue;
                                  nextComments = [...state.commentValue, newComment];
                                }
                                const next = [...state.editings, data.id];
                                $state({ ...state, commentValue: nextComments, editings: next });
                              }}
                            >
                              編集
                            </Link>
                            {!data.replies ||
                              (data.replies.length < 1 && (
                                <Link
                                  className="btn btn-danger btn-sm ms-1 float-end"
                                  role="button"
                                  to=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let delComment = state.commentValue.find((_) => _.id === data.id);
                                    if (!delComment) {
                                      delComment = {
                                        id: data.id,
                                        thread_id: "",
                                      } as commentValue;
                                    }
                                    $state({ ...state, delComment: delComment, activeModal: "before_delete" });
                                  }}
                                >
                                  削除
                                </Link>
                              ))}
                          </div>
                        )}
                        {data.author === user.id && data.isOpen && state.editings.some((_) => _ === data.id) && (
                          <div className="mt-2">
                            <Link
                              className="btn btn-outline-secondary btn-sm"
                              role="button"
                              to=""
                              onClick={(e) => {
                                e.stopPropagation();
                                const next = state.editings.filter((_) => _ !== data.id);
                                const nextComment = state.commentValue.map((_) => {
                                  return {
                                    ..._,
                                    body: _.id === data.id ? data.body : _.body,
                                  };
                                });
                                $state({ ...state, editings: next, commentValue: nextComment });
                              }}
                            >
                              キャンセル
                            </Link>
                            <Link
                              className="btn btn-primary btn-sm ms-1"
                              role="button"
                              to=""
                              onClick={(e) => {
                                e.stopPropagation();
                                updateComment(data.id);
                              }}
                            >
                              更新
                            </Link>
                          </div>
                        )}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {data.replies &&
                        data.replies.length > 0 &&
                        data.replies.map((c) => (
                          <Row key={c.id}>
                            <Col>
                              <ListGroup>
                                <ListGroup.Item key={`li-${c.id}`}>
                                  <div className="--font-s mb-1">{`${toTimeLabel(c.postedAt)} ${
                                    permissions.find((m) => m.account_id === c.author)?.name
                                  }`}</div>
                                  <div>
                                    <Row className="pt-2">
                                      <Col md={12}>
                                        <MentionsInput
                                          value={state.commentValue.find((_) => _.id === c.id)?.body ?? c.body}
                                          onChange={(e, _, __, mentions) => {
                                            const mention_tos = mentions && mentions.map((m) => +m["id"]);
                                            const next = state.commentValue.map((_) => {
                                              return {
                                                ..._,
                                                thread_id: _.id === c.id ? c.threadId : _.thread_id,
                                                body: _.id === c.id ? e.target.value : _.body,
                                                mention_tos: _.id === c.id ? mention_tos : _.mention_tos,
                                                web_screen: threadBoxItem.webScreen,
                                              };
                                            });
                                            $state({ ...state, commentValue: next });
                                          }}
                                          className="Mentions"
                                          placeholder="確認依頼したい点や疑問点などをコメントしてください。"
                                          disabled={!state.editings.some((_) => _ === c.id)}
                                        >
                                          <Mention
                                            trigger="@"
                                            markup="[__display__]{id:__id__}"
                                            data={selectUsers}
                                            className="Mentions__mention"
                                          />
                                        </MentionsInput>
                                      </Col>
                                    </Row>
                                  </div>
                                  {c.author === user.id && data.isOpen && !state.editings.some((_) => _ === c.id) && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        className="ms-1"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          let nextComments = state.commentValue;
                                          if (!state.commentValue.some((_) => _.id === c.id)) {
                                            const newComment = {
                                              id: c.id,
                                              thread_id: data.id,
                                              body: c.body,
                                            } as commentValue;
                                            nextComments = [...state.commentValue, newComment];
                                          }
                                          const next = [...state.editings, c.id];
                                          $state({ ...state, commentValue: nextComments, editings: next });
                                        }}
                                      >
                                        編集
                                      </Button>
                                      <Button
                                        variant="danger"
                                        className="float-end"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          let delComment = state.commentValue.find((_) => _.id === c.id);
                                          if (!delComment) {
                                            delComment = {
                                              id: c.id,
                                              thread_id: data.id,
                                            } as commentValue;
                                          }
                                          $state({
                                            ...state,
                                            delComment: delComment,
                                            activeModal: "before_delete",
                                          });
                                        }}
                                      >
                                        削除
                                      </Button>
                                    </div>
                                  )}
                                  {c.author === user.id && data.isOpen && state.editings.some((_) => _ === c.id) && (
                                    <div className="mt-2">
                                      <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => {
                                          const next = state.editings.filter((_) => _ !== c.id);
                                          const nextComments = state.commentValue.map((_) => {
                                            return {
                                              ..._,
                                              body: _.id === c.id ? c.body : _.body,
                                            };
                                          });
                                          $state({ ...state, editings: next, commentValue: nextComments });
                                        }}
                                      >
                                        キャンセル
                                      </Button>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="ms-1"
                                        onClick={() => {
                                          updateComment(c.id, data.id);
                                        }}
                                      >
                                        更新
                                      </Button>
                                    </div>
                                  )}
                                </ListGroup.Item>
                              </ListGroup>
                            </Col>
                          </Row>
                        ))}
                      {data.isOpen ? (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Row className="pt-2">
                            <Col md={10}>
                              <MentionsInput
                                value={state.commentValue.find((_) => _.id === `${data.id}-new`)?.body ?? ""}
                                onClick={() => {
                                  if (!state.commentValue.some((_) => _.id === `${data.id}-new`)) {
                                    const newComment = {
                                      id: `${data.id}-new`,
                                      thread_id: data.id,
                                      body: "",
                                      mention_tos: [] as number[],
                                      web_screen: threadBoxItem.webScreen,
                                    } as commentValue;
                                    const next = [...state.commentValue, newComment];
                                    $state({ ...state, commentValue: next });
                                  }
                                }}
                                onChange={(e, _, __, mentions) => {
                                  const mention_tos = mentions && mentions.map((m) => +m["id"]);
                                  const body = e.target.value;
                                  const next = state.commentValue.map((_) => {
                                    return {
                                      ..._,
                                      body: _.id === `${data.id}-new` ? body : _.body,
                                      mention_tos: _.id === `${data.id}-new` ? mention_tos : _.mention_tos,
                                    };
                                  });
                                  $state({ ...state, commentValue: next });
                                }}
                                className="Mentions"
                                placeholder="ユーザーにメンションして通知するには「@」を入力します。"
                              >
                                <Mention
                                  trigger="@"
                                  markup="[__display__]{id:__id__}"
                                  data={selectUsers}
                                  className="Mentions__mention"
                                />
                              </MentionsInput>
                            </Col>
                            <Col md={2}>
                              <Button
                                variant="primary"
                                type="reset"
                                onClick={() => sendComment(data.id, `${data.id}-new`)}
                              >
                                送信
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </section>
            ))}
        <div>
          <Button
            onClick={() => $state({ ...state, open: !state.open })}
            variant="outline-primary"
            aria-controls="thread-commnet-collapse-form"
            aria-expanded={state.open}
            className="mt-2"
          >
            コメントを追加する
          </Button>
        </div>
        <Collapse in={state.open}>
          <div id="thread-comment-collapse-form">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row className="pt-2">
                <Col md={10}>
                  <MentionsInput
                    value={state.commentValue.find((_) => _.id === "" && _.thread_id === "")?.body ?? ""}
                    onClick={() => {
                      if (!state.commentValue.some((_) => _.id === "" && _.thread_id === "")) {
                        const newComment = {
                          id: "",
                          thread_id: "",
                          body: "",
                          mention_tos: [] as number[],
                          web_screen: threadBoxItem.webScreen,
                        } as commentValue;
                        const next = [...state.commentValue, newComment];
                        $state({ ...state, commentValue: next });
                      }
                    }}
                    onChange={(e, _, __, mentions) => {
                      const mention_tos = mentions && mentions.map((m) => +m["id"]);
                      const body = e.target.value;
                      const next = state.commentValue.map((_) => {
                        return {
                          ..._,
                          body: _.id === "" && _.thread_id === "" ? body : _.body,
                          mention_tos: _.id === "" && _.thread_id === "" ? mention_tos : _.mention_tos,
                        };
                      });
                      $state({ ...state, commentValue: next });
                    }}
                    className="Mentions"
                    placeholder="ユーザーにメンションして通知するには「@」を入力します。"
                  >
                    <Mention
                      trigger="@"
                      markup="[__display__]{id:__id__}"
                      data={selectUsers}
                      className="Mentions__mention"
                    />
                  </MentionsInput>
                </Col>
                <Col md={2}>
                  <Button variant="primary" onClick={() => sendComment()}>
                    送信
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Collapse>
      </Container>
      {/* 削除ボタン押下時のモーダル */}
      <ModalDialog
        show={state.activeModal === "before_delete"}
        onConfirm={deleteComment}
        onCancel={() => {
          $state({ ...state, activeModal: "", delComment: {} as commentValue });
        }}
        message="削除します。よろしいですか？"
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
    </Fragment>
  );
}
export default App;
