import { useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import Sidebar from "../../component/Sidebar";
import { switchCompany } from "../../features/login/idTokenSlice";
import { selectUserState } from "../../features/login/userSlice";
import { serviceLabels } from "../../features/permission/permissionValues";
import { Container, Row } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const tableCols = [
  {
    name: "企業名",
    width: 400,
  },
  {
    name: "企業コード",
  },
];

function App() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const { companyChoices, current_company } = user;
  const switchableCompanies = useMemo(() => {
    return (
      companyChoices
        .filter((c) => c.company_id !== current_company.id)
        // プロファイルを利用中の企業のみ表示
        .filter((c) => Object.keys(c.services).some((s) => c.services[s].includes(9)))
        .sort((a, b) => {
          if (!a.vendor_company_code) return -1;
          else if (!b.vendor_company_code) return 1;
          else return 0;
        })
    );
  }, [companyChoices]);
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"switch_company"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">操作対象企業</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <h2 className="Headline--section">現在の操作対象企業</h2>
              <Table
                col={tableCols}
                row={[
                  {
                    data: [
                      `${current_company.code !== user.main_company ? "" : "【メイン企業】"} ${
                        current_company.company_name
                      }`,
                      current_company.code,
                    ],
                  },
                ]}
              />
            </Row>
            <Row className="mt-4">
              <h2 className="Headline--section">切替可能な企業</h2>
              <Table
                col={tableCols}
                row={switchableCompanies.map((c) => {
                  return {
                    data: [
                      `${c.company_code !== user.main_company ? "" : "【メイン企業】"} ${c.company_name}`,
                      c.company_code,
                    ],
                    action: {
                      label: "切替",
                      handler: async (_, [__, companyCode]) => {
                        const roles = Object.keys(c.services)
                          .filter((role) => c.services[role].includes(serviceLabels["profile"].id))
                          .map((role) => role);
                        await dispatch(
                          switchCompany({
                            companyCode: companyCode as string,
                            role: roles[0],
                            isProfile: true,
                            isServiceSwitch: false,
                          })
                        );
                        window.location.href = "/_/dashboard";
                      },
                    },
                  };
                })}
              />
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
