const data = {
  category: "bonus",
  table: "bonus",
  subFields: {
    bonus_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_base_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_board_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_bonus_item10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_care_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_welfare_pension_fund_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_employment_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_income_tax_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_amount_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_payment_taxable_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_payment_non_taxable_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_taxable_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_tax_over_under_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_deductions_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_after_deduction_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_cash_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      bonus_history: "賞与履歴",
    },
  },
};

export default data;
