const data = {
  category: "secondment_acceptance",
  table: "secondment_acceptance",
  subFields: {
    secondment_acceptance_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    planned_end_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_company_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_company_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_person_responsible_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    assistance_fee_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_employee_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_section_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_position_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_job_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_duty_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    seconded_from_grade_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_extend_planned_end_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    secondment_place_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    secondment_place_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      secondment_acceptance_history: "出向受入履歴",
    },
  },
};

export default data;
