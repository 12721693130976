const data = {
  category: "assignment2",
  table: "position2_summary",
  subFields: {
    position2_summary: {
      type: "tableHandler",
      tag: "tb_",
    },
    position_code_$N: {
      type: "string",
      tag: "tb_",
      tagGroupIndex: 1,
    },
    position_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      position2_summary: "役職の通算経験年数（主務）",
    },
  },
};
export default data;
