import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { isSpace, validateAsync, ALPHA_NUMERIC_FORMAT_REGEX } from "../../app/validator";
import { useAppSelector, useAppDispatch } from "../../app/store";
import ModalDialog from "../../component/ModalDialog";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import { selectPermissionState, getServiceContracts, postAssessRoles } from "../permission/permissionSlice";
import { selectClient, selectClientState } from "../client/clientSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { postAccount } from "../profile/profileSlice";
import { accountLanguages } from "../profile/profileValues";
import { Container, Row, Col, Button, ListGroup, Form, Dropdown, DropdownButton } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { testResponse } from "../../app/util";
import { canUseLowQualityMode, rotate } from "../../app/imageModifier";
import { EMAIL_FORMAT_REGEX } from "../../app/validator";
import { AssessRole, serviceLabels, getPasswordComplexityText } from "../permission/permissionValues";
import classNames from "classnames";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedClient } = useAppSelector(selectClientState);
  const current_company = useAppSelector(selectCurrentCompany);
  const { serviceContracts } = useAppSelector(selectPermissionState);
  const [next, $next] = useState({
    name: "",
    loginCode: "",
    mailAddress: "",
    password: "",
    isActive: true,
    language: "ja",
    isRemind: false,
    remarks: "",
  } as {
    name: string;
    loginCode: string;
    mailAddress: string;
    password: string;
    isActive: boolean;
    language: string;
    isRemind: boolean;
    remarks: string;
  });
  const [nextAssessRoles, $nextAssessRoles] = useState([] as AssessRole[]);
  const [activeModal, $activeModal] = useState("");
  const [standByFileUrl, $standByFileUrl] = useState("");
  const [updateErrorMessage, $updateErrorMessage] = useState("");
  const [valueErrors, $valueErrors] = useState(
    {} as {
      [key: string]: string;
    }
  );
  const [asyncValidationErrors, $asyncValidationErrors] = useState(
    {} as {
      [key: string]: string;
    }
  );
  const [enteredFields, $enteredFields] = useState([] as string[]);
  const [processing, $processing] = useState(false);
  const assessment_services = Object.values(serviceLabels)
    .filter((s: any) => s.group === "assessment")
    .map((s: any) => s.id);
  const profile_services = Object.values(serviceLabels)
    .filter((s: any) => s.group === "profile")
    .map((s: any) => s.id);

  useEffect(() => {
    if (current_company.id) {
      dispatch(selectClient({ id: current_company.id }));
      dispatch(getServiceContracts({ companyId: current_company.id }));
    }
  }, [current_company, dispatch]);
  useEffect(() => {
    if (selectedClient) {
      dispatch(getServiceContracts({ companyId: +selectedClient.id }));
    }
  }, [selectedClient]);

  const isValueFilled = useMemo(() => {
    const hasValue = next.loginCode && next.name && next.password;
    const anyRoleSelected = nextAssessRoles.length > 0;
    const isError =
      Object.keys(valueErrors)
        .map((key) => valueErrors[key])
        .filter((_) => _).length > 0;
    return hasValue && anyRoleSelected && !isError;
  }, [next, nextAssessRoles, valueErrors]);

  useEffect(() => {
    const nextValueErrors = {
      loginCode: "",
    } as { [key: string]: string };

    if (enteredFields.includes("loginCode")) {
      if (!next.loginCode) nextValueErrors.loginCode = "入力してください。";
      else if (!ALPHA_NUMERIC_FORMAT_REGEX.test(next.loginCode))
        nextValueErrors.loginCode = `半角英数字のみを入力してください`;
    }
    if (enteredFields.includes("name") && !next.name) {
      nextValueErrors.name = "入力してください";
    }
    if (enteredFields.includes("mailAddress") && !EMAIL_FORMAT_REGEX.test(next.mailAddress)) {
      nextValueErrors.mailAddress = "メールアドレスの形式になっていません";
    }
    if (enteredFields.includes("password")) {
      if (!next.password) {
        nextValueErrors.password = "入力してください";
      } else if (isSpace(next.password)) {
        nextValueErrors.password = "パスワードにスペースを含めることはできません";
      }
    }
    $valueErrors(nextValueErrors);
  }, [next]);

  const checked = (roleName: string, serviceName: string) => {
    const matchedServiceContract = serviceContracts.find((_) => _.serviceName === serviceName);
    const matchedRoles = nextAssessRoles.filter((r) => r.serviceId === matchedServiceContract?.serviceId);
    return (
      <Form.Check
        key={`checkbox_${roleName}_${serviceName}`}
        type="checkbox"
        checked={matchedRoles.some((r) => r.roleName === roleName)}
        onChange={(e) => {
          if (!matchedServiceContract || !selectedClient) return;
          if (e.target.checked) {
            $nextAssessRoles([
              ...nextAssessRoles,
              {
                accountId: -1,
                companyId: current_company.id,
                currentCompanyCode: current_company.code,
                currentCompanyName: selectedClient.name,
                id: -1,
                loginCode: "",
                mainCompanyName: selectedClient.name,
                mainCompanyCode: "",
                name: next.name,
                roleName: roleName,
                serviceId: matchedServiceContract.serviceId,
              },
            ]);
          } else {
            $nextAssessRoles(
              nextAssessRoles.filter((r) => r.serviceId !== matchedServiceContract.serviceId || r.roleName !== roleName)
            );
          }
          $valueErrors({
            ...valueErrors,
            assessRoles: "",
          });
        }}
      />
    );
  };

  const accessibleServices = (groupName: string, services: any) => {
    return (
      <div>
        <ListGroup.Item>
          <Row>
            <Col md={3}>
              <div className="--bold">{groupName}</div>
            </Col>
            <Col md={9}></Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item className="text-center">
          <Row className="my-2">
            <Col md={6}></Col>
            <Col md={3} className="--bold">
              管理者
            </Col>
            <Col md={3} className="--bold">
              ユーザ
            </Col>
          </Row>
          {serviceContracts
            .filter((_) => services.includes(_.serviceId))
            .map((sc) => {
              return (
                <Row className="my-2" key={sc.serviceOrder}>
                  <Col md={6}>{serviceLabels[sc.serviceName].label}</Col>
                  <Col md={3}>{checked("admin", sc.serviceName)}</Col>
                  <Col md={3}>{[1, 5].includes(sc.serviceId) ? "" : checked("user", sc.serviceName)}</Col>
                </Row>
              );
            })
            .filter((_) => _)}
          {valueErrors.assessRoles && (
            <Row>
              <Col>
                <div className="--text-annotation mt-1 --font-s">{valueErrors.assessRoles}</div>
              </Col>
            </Row>
          )}
        </ListGroup.Item>
      </div>
    );
  };
  const save = async () => {
    if (!isValueFilled || processing) return;
    $processing(true);
    const _asyncValidationErrors = {} as { [key: string]: string };
    // ログインIDの重複チェック
    const [isLoginCodeUnique, passwordLengthValidation, isPasswordComplexityValidation] = await Promise.all([
      validateAsync({
        key: "unique_login_code",
        value: {
          main_company_code: current_company.code,
          login_code: next.loginCode,
        },
      }),
      validateAsync({
        key: "length",
        value: {
          password: next.password,
        },
        policy_company_code: current_company.code,
      }) as Promise<{ result: boolean; length: number }>,
      validateAsync({
        key: "complexity",
        value: {
          password: next.password,
        },
        policy_company_code: current_company.code,
      }) as Promise<{ result: boolean; complexity: number }>,
    ]);
    let updateErrorMessage = "";
    if (!isLoginCodeUnique) {
      _asyncValidationErrors.loginCode = "ログインIDが他のアカウントと重複しています。";
      updateErrorMessage += "ログインIDが他のアカウントと重複しています。";
    }
    _asyncValidationErrors.password = "";
    if (!passwordLengthValidation.result) {
      const thisErrorMessage = `パスワードは ${passwordLengthValidation.length} 文字以上で設定してください。`;
      _asyncValidationErrors.password += thisErrorMessage;
      updateErrorMessage += thisErrorMessage;
    }
    if (!isPasswordComplexityValidation.result) {
      const thisErrorMessage = `パスワードは${getPasswordComplexityText(
        isPasswordComplexityValidation.complexity
      )}必要があります。`;
      _asyncValidationErrors.password += thisErrorMessage;
      updateErrorMessage += thisErrorMessage;
    }
    $updateErrorMessage(updateErrorMessage);
    $asyncValidationErrors(_asyncValidationErrors);
    $processing(false);
    if (!isLoginCodeUnique || !passwordLengthValidation.result || !isPasswordComplexityValidation.result) {
      $activeModal("updateError");
      return;
    }
    $activeModal("before_commit");
  };

  const commit = async () => {
    if (processing) return;
    $processing(true);
    const result = testResponse(
      await dispatch(
        postAccount({
          name: next.name,
          loginCode: next.loginCode,
          mailAddress: next.mailAddress,
          password: next.password,
          mainCompanyCode: current_company.code,
          isActive: next.isActive,
          language: next.language,
          isRemind: next.isRemind,
          remarks: next.remarks,
          image: standByFileUrl,
        })
      )
    );
    if (!result) {
      $updateErrorMessage("アカウント登録中にエラーが発生しました。");
      $activeModal("updateError");
      $processing(false);
      return;
    }
    const roleResult = testResponse(
      await dispatch(
        postAssessRoles({
          assessRoles: nextAssessRoles.map((r) => {
            return {
              accountId: result.payload.id,
              companyId: r.companyId,
              currentCompanyCode: r.currentCompanyCode,
              currentCompanyName: r.currentCompanyName,
              id: -1,
              loginCode: "",
              mainCompanyName: r.mainCompanyName,
              mainCompanyCode: r.mainCompanyCode,
              name: r.name,
              roleName: r.roleName,
              serviceId: r.serviceId,
            };
          }),
        })
      )
    );
    if (!roleResult) {
      $updateErrorMessage("利用可能なサービスの登録中にエラーが発生しました。");
      $activeModal("updateError");
      $processing(false);
      return;
    }
    $processing(false);
    navigate(`/_/account/detail/${result.payload.id}`);
  };

  const onImageUpload = (data: DecodedFileData) => {
    $standByFileUrl(`data:${data.type};base64,${data.dataURI}`);
  };

  const deleteImage = () => {
    $standByFileUrl("");
  };

  const rotateImage = async () => {
    try {
      const { image } = await rotate({
        image: standByFileUrl,
        quality: 1,
      });
      $standByFileUrl(image);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Container>
        <Row className="mb-2">
          <Col>
            <span className="--required-label"></span> は必須項目です。
          </Col>
        </Row>
        <ListGroup className="mb-4">
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold --required-label">ログインID</div>
              </Col>
              <Col md={9}>
                <div>
                  <Form.Control
                    type="text"
                    id="loginId"
                    value={next.loginCode}
                    onChange={(e) => {
                      $next({ ...next, loginCode: e.target.value });
                      $enteredFields([...enteredFields.filter((_) => _ !== "loginCode"), "loginCode"]);
                      $asyncValidationErrors({ ...asyncValidationErrors, loginCode: "" });
                    }}
                    maxLength={10}
                  />
                  {valueErrors.loginCode && (
                    <div className="--text-annotation mt-1 --font-s">{valueErrors.loginCode}</div>
                  )}
                  {asyncValidationErrors.loginCode && (
                    <div className="--text-annotation mt-1 --font-s">{asyncValidationErrors.loginCode}</div>
                  )}
                </div>
                <div className="mt-2 --font-s">※半角英数字10文字まで入力できます。</div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold --required-label">名前</div>
              </Col>
              <Col md={9}>
                <div>
                  <Form.Control
                    type="text"
                    id="name"
                    value={next.name}
                    onChange={(e) => {
                      $next({ ...next, name: e.target.value });
                      $enteredFields([...enteredFields.filter((_) => _ !== "name"), "name"]);
                    }}
                  />
                  {valueErrors.name && <div className="--text-annotation mt-1 --font-s">{valueErrors.name}</div>}
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">プロフィール画像</div>
              </Col>
              <Col md={9}>
                <div className="--pre-wrap">
                  <div>
                    <figure className={"Profile-card__image mb-2 " + classNames({ "--empty": !standByFileUrl })}>
                      {standByFileUrl && <img src={standByFileUrl} />}
                    </figure>
                    <Uploader onFileLoad={onImageUpload} accepts={["image/jpeg", "image/png"]} />
                    {standByFileUrl && (
                      <div className="mt-2">
                        {canUseLowQualityMode && (
                          <Button onClick={rotateImage} variant="outline-primary">
                            写真を回転
                          </Button>
                        )}
                        <Button onClick={deleteImage} variant="outline-danger" className="mx-1">
                          写真を削除
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">メールアドレス</div>
              </Col>
              <Col md={9}>
                <Form.Control
                  type="text"
                  id="mailaddress"
                  value={next.mailAddress}
                  onChange={(e) => {
                    $next({ ...next, mailAddress: e.target.value });
                    $enteredFields([...enteredFields.filter((_) => _ !== "mailAddress"), "mailAddress"]);
                  }}
                />
                {valueErrors.mailAddress && (
                  <div className="--text-annotation mt-1 --font-s">{valueErrors.mailAddress}</div>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold --required-label">パスワード</div>
              </Col>
              <Col md={9}>
                <Form.Control
                  type="password"
                  id="password"
                  value={next.password}
                  onChange={(e) => {
                    $next({ ...next, password: e.target.value });
                    $enteredFields([...enteredFields.filter((_) => _ !== "password"), "password"]);
                    $asyncValidationErrors({ ...asyncValidationErrors, password: "" });
                  }}
                />
                {valueErrors.password && <div className="--text-annotation mt-1 --font-s">{valueErrors.password}</div>}
                {asyncValidationErrors.password && (
                  <div className="--text-annotation mt-1 --font-s">{asyncValidationErrors.password}</div>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">言語</div>
              </Col>
              <Col md={9}>
                <DropdownButton
                  variant="outline-primary"
                  title={accountLanguages.find((_l) => _l.value === next.language)?.label ?? "言語を選択"}
                >
                  {accountLanguages.map((l, i) => {
                    return (
                      <Dropdown.Item
                        key={`target_${l.value}_${i}`}
                        onClick={() => {
                          $next({ ...next, language: l.value });
                        }}
                      >
                        {l.label}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">リマインドメール</div>
              </Col>
              <Col md={9}>
                <Form.Check
                  type="checkbox"
                  id="isRemind"
                  label="有効"
                  checked={next.isRemind}
                  onChange={() => {
                    $next({ ...next, isRemind: !next.isRemind });
                  }}
                  inline
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="--align-items-center">
              <Col md={3}>
                <div className="--bold">備考</div>
              </Col>
              <Col md={9}>
                <Form.Control
                  type="text"
                  id="remarks"
                  value={next.remarks}
                  onChange={(e) => {
                    $next({ ...next, remarks: e.target.value });
                  }}
                />
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <h2 className="Headline--section mb-2 --required-label">この企業で利用可能なサービス</h2>
        <Row className="mb-2">
          <Col>
            １つ以上選択してください。人事労務に「管理者」「ユーザー」以外の独自のロールを設定していてこのアカウントに付与したい場合、登録後に更新してください。
          </Col>
        </Row>
        {serviceContracts.some((_) => assessment_services.includes(_.serviceId)) && (
          <ListGroup>{accessibleServices("人材開発", assessment_services)}</ListGroup>
        )}
        {serviceContracts.some((_) => profile_services.includes(_.serviceId)) && (
          <ListGroup className="mt-4">{accessibleServices("人事労務", profile_services)}</ListGroup>
        )}
        <Row className="mt-4">
          <Col>
            <Button onClick={save} disabled={!isValueFilled} className="mx-1">
              登録
            </Button>
          </Col>
        </Row>
      </Container>
      <ModalDialog
        show={activeModal === "updateError"}
        onConfirm={() => {
          $activeModal("");
        }}
        message={updateErrorMessage}
        type="alert"
      />
      <ModalDialog
        show={activeModal === "before_commit"}
        onCancel={() => {
          $activeModal("");
        }}
        onConfirm={commit}
        message={"登録しますか？"}
      />
    </div>
  );
}

export default App;
