const data = {
  category: "salary",
  table: "salary",
  subFields: {
    salary_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_base_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_board_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_legal_overtime_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_extra_legal_overtime_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_overtime_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_worked_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_worked_holiday_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_worked_legal_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_worked_legal_holiday_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_overwork_over_45_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_overwork_over_60_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_transportation_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_allowance_transportation_taxable_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_deduction_late_early_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_deduction_absence_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item16_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item17_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item18_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item19_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_salary_item20_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_care_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_health_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_welfare_pension_fund_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_employment_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_income_tax_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_resident_tax_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    deduction_item10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_amount_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_payment_taxable_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_payment_non_taxable_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_taxable_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_tax_over_under_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_total_deductions_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_after_deduction_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_bank3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    other_amount_cash_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      salary_history: "給与履歴",
    },
  },
};

export default data;
