const data = {
  category: "assignment_old",
  table: "section_old_summary",
  subFields: {
    section_summary: {
      type: "tableHandler",
      tag: "tb_",
    },
    section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      section_old_summary: "部署の通算在籍年数",
    },
  },
};

export default data;
