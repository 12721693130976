import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers, selectIntegratedMembers } from "../../features/profile/profileSlice";
import { selectClientState } from "../../features/client/clientSlice";
import { selectUserState } from "../../features/login/userSlice";
import { getAllThreadComments, selectAllComments } from "../../features/client/threadSlice";
import Sidebar from "../../component/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, ListGroup, Col, Row } from "react-bootstrap";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);
  const { sectorSettingStatus } = useAppSelector(selectClientState);
  const comments = useAppSelector(selectAllComments);
  const integratedMembers = useAppSelector(selectIntegratedMembers);
  const displayData: { id: string; author: number; time: string; body: string; link: string; isOpen: boolean }[] =
    useMemo(() => {
      if (!user.id) return [];
      return comments
        .filter((c) => c.author !== user.id)
        .map((c) => {
          const links = c.webScreen ? c.webScreen.split("/") : [];
          return {
            id: c.id,
            author: c.author,
            time: c.time,
            body: c.body,
            link: links[1] ? `/_/import/?sector=${links[1]}` : "",
            isOpen: c.isOpen,
          };
        });
    }, [comments, sectorSettingStatus, user]);
  const move = (link?: string) => {
    if (!link) return;
    navigate(link);
  };
  useEffect(() => {
    dispatch(getAllThreadComments());
  }, []);
  useEffect(() => {
    dispatch(getMembers());
  }, []);
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">受信したコメント</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          {displayData.length > 0 ? (
            <Container>
              <ListGroup>
                {displayData.map((c) => {
                  return (
                    <ListGroup.Item
                      variant={c.isOpen ? "primary" : "secondary"}
                      key={c.id}
                      action
                      onClick={() => move(c.link)}
                    >
                      <div className="--font-s mb-1">
                        {c.time} {integratedMembers[c.author]?.name ?? ""} {!c.isOpen && "（解決済）"}
                      </div>
                      <ListGroup.Item>{c.body.replaceAll(/{id:[0-9]+}/g, "")}</ListGroup.Item>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col>
                  <div>まだ受信したコメントはありません。</div>
                </Col>
              </Row>
            </Container>
          )}
        </main>
      </div>
    </div>
  );
}

export default App;
