import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Modal, Button, Alert, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { fieldViewData, USER_TABLE_PREFIX, DiffDownloadPolicy } from "../../features/profile/profileValues";
import {
  downloadDiffList,
  getSearchCondition,
  postSearchCondition,
  deleteSearchCondition,
  selectProfileState,
} from "../../features/profile/profileSlice";
import { userColumnChoices } from "./profileFieldValues";
import FieldListSelector from "../../features/profile/FieldListSelector";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import {
  getSectors,
  getRegularColumns,
  selectClientState,
  selectActiveSectors,
} from "../../features/client/clientSlice";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { RegularColumn } from "../client/clientValues";
import ModalDialog from "../../component/ModalDialog";
import OrderEditor from "../../component/OrderEditor";
import { getQuery } from "../../app/util";

function ProfileList() {
  const dispatch = useAppDispatch();
  // クエリパラメータを変換
  const { search } = useLocation();
  const { dateFrom, dateTo, options, requiredColumns, tables, policy, outputFormat } = useMemo(() => {
    const { dateFrom, dateTo, options, requiredColumns, tables, policy, outputFormat } = getQuery(search);
    try {
      return {
        dateFrom,
        dateTo,
        options: JSON.parse(options),
        requiredColumns: (JSON.parse(requiredColumns) as string[]).reduce((prev, current) => {
          const [sectorId, column] = current.split(".");
          const prevColumns = prev[sectorId] ?? [];
          const nextColumns = [...prevColumns, column];
          return { ...prev, [sectorId]: nextColumns };
        }, {} as { [key: string]: string[] }),
        tables: JSON.parse(tables) as string[],
        policy: policy as DiffDownloadPolicy,
        outputFormat: outputFormat as string,
      };
    } catch (e) {
      return {
        options: { dateFormat: "yyyy-MM-dd", enrollment_type__in: ["在籍", "休職"] as string[] },
        requiredColumns: {} as { [key: string]: string[] },
        tables: [] as string[],
        policy: "latest",
        outputFormat: "xlsx",
      };
    }
  }, [search]);
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const { searchConditions } = useAppSelector(selectProfileState);
  const [state, $state] = useState({
    selectedPointDate1: dateFrom ? dayjs(dateFrom) : dayjs().add(-1, "month"),
    selectedPointDate2: dateFrom ? dayjs(dateTo) : dayjs(),
    dateError: "",
    fieldViewSectors: tables.map((id) => {
      return { id, label: "", checked: true, subFields: [] };
    }) as fieldViewData[],
    requiredFieldViewSectors: Object.keys(requiredColumns).map((id) => {
      const subFields = requiredColumns[id].map((c) => ({ id: c, label: "", checked: true }));
      return { id, label: "", checked: true, subFields };
    }) as fieldViewData[],
    enrollmentTypeOptions: userColumnChoices["profile_u_personal"]["enrollment_type"].map((e, i) => {
      return {
        id: `enrollment_type${i + 1}`,
        label: e,
        checked: options.enrollment_type__in?.includes(e) ?? false,
      };
    }),
    retreiveOptions: [
      {
        id: "latest",
        label: "指定日時点の最新データ",
        checked: policy === "latest" || !policy,
      },
      {
        id: "matched",
        label: "指定日と開始日が一致するデータ",
        checked: policy === "matched",
      },
      {
        id: "updated",
        label: "指定日間に更新されたデータ",
        checked: policy === "updated",
      },
    ] as {
      id: DiffDownloadPolicy;
      label: string;
      checked: boolean;
    }[],
    outputFormat: [
      {
        id: "xlsx",
        label: "通常の形式",
        checked: outputFormat === "xlsx" || !outputFormat,
      },
      {
        id: "xlsx_external",
        label: "外部連携用エクセル",
        checked: outputFormat === "xlsx_external",
      },
      {
        id: "csvzip_external",
        label: "外部連携用CSV",
        checked: outputFormat === "csvzip_external",
      },
    ] as {
      id: string;
      label: string;
      checked: boolean;
    }[],
    dateOptions: [
      {
        id: "format1",
        label: "yyyy-MM-dd",
        checked: options.dateFormat === "yyyy-MM-dd",
      },
      {
        id: "format2",
        label: "yyyy/MM/dd",
        checked: options.dateFormat === "yyyy/MM/dd",
      },
    ],
    dataOptions: [
      {
        id: "separate_each_table",
        label: "テーブルごとにシートを分けて出力する",
        checked: options.separate_each_table === true,
      },
      {
        id: "not_include_master_label",
        label: "コード名称の変更は出力しない",
        checked: options.not_include_master_label === true,
      },
    ],
    order: [] as { label: string; id: string; fixed: boolean }[],
  });
  const [isModalActive, $isModalActive] = useState(false);
  const [searchConditionModal, $searchConditionModal] = useState({
    isShow: false,
    isConfirming: false,
    label: "",
  });
  const [searchConditionListModal, $searchConditionListModal] = useState({
    isShow: false,
    selectingId: "",
    isConfirming: false,
  });
  const selectField = (id: string, parentFieldId?: string) => {
    $state({
      ...state,
      fieldViewSectors: state.fieldViewSectors.map((sector) => {
        return {
          ...sector,
          checked: !parentFieldId && sector.id === id ? !sector.checked : sector.checked,
          subFields: sector.subFields.map((s) => {
            if (parentFieldId !== sector.id || id !== s.id) return s;
            return {
              ...s,
              checked: !s.checked,
            };
          }),
        };
      }),
      order: (() => {
        if (parentFieldId) return state.order;
        return state.order.some((_) => _.id === id)
          ? state.order.filter((_) => _.id !== id)
          : [
              ...state.order,
              {
                id,
                label: activeSectors.find((_) => _.sector_id === `${USER_TABLE_PREFIX}${id}`)?.logical_name ?? "--",
                fixed: false,
              },
            ];
      })(),
    });
  };
  const selectRequiredField = (id: string, parentFieldId?: string) => {
    $state({
      ...state,
      requiredFieldViewSectors: state.requiredFieldViewSectors.map((sector) => {
        return {
          ...sector,
          checked: !parentFieldId && sector.id === id ? !sector.checked : sector.checked,
          subFields: sector.subFields.map((s) => {
            if (parentFieldId !== sector.id || id !== s.id) return s;
            return { ...s, checked: !s.checked };
          }),
        };
      }),
    });
  };
  const fetchSubFieldsData = (id: string, isExpanded: boolean) => {
    if (isExpanded && !sectorRegularColumns[`${USER_TABLE_PREFIX}${id}`]) {
      dispatch(getRegularColumns({ sectorId: `${USER_TABLE_PREFIX}${id}` }));
    }
  };

  const isNormalOutput = useMemo(() => {
    return state.outputFormat.find((_) => _.checked)?.id === "xlsx";
  }, [state.outputFormat]);

  const [checkedSubFields, requiredColumnLabel] = useMemo(() => {
    const checkedSubFields = state.requiredFieldViewSectors.reduce((prev, current) => {
      const checkedSubFields = current.subFields
        .filter((s) => s.checked)
        .map((s) => ({
          ...s,
          sectorId: current.id,
        }));
      return [...prev, ...checkedSubFields];
    }, [] as { id: string; sectorId: string; label: string; checked: boolean }[]);
    const requiredColumnLabel =
      checkedSubFields.length > 0
        ? state.requiredFieldViewSectors
            .map(({ label, subFields }) => {
              const checkedSunFields = subFields.filter(({ checked }) => checked);
              if (checkedSunFields.length === 0) return;
              return `${label}(${checkedSunFields.map(({ label }) => label).join(", ")})`;
            })
            .filter((_) => _)
            .join(", ")
        : "（未選択）";
    return [checkedSubFields, requiredColumnLabel];
  }, [state.requiredFieldViewSectors]);

  const download = () => {
    dispatch(downloadDiffList(createConditionParams()));
    $isModalActive(true);
  };

  const createConditionParams = () => {
    return {
      dateFrom: state.selectedPointDate1.format("YYYY-MM-DD"),
      dateTo: state.selectedPointDate2.format("YYYY-MM-DD"),
      policy: state.retreiveOptions.find((_) => _.checked)?.id ?? "matched",
      outputFormat: state.outputFormat.find((_) => _.checked)?.id ?? "xlsx",
      tables: state.order.map((_) => _.id),
      requiredColumns: isNormalOutput ? checkedSubFields.map((_) => `${_.sectorId}.${_.id}`) : [],
      options: (() => {
        return {
          enrollment_type__in: state.enrollmentTypeOptions.filter((_) => _.checked).map((_) => _.label) as string[],
          ...(isNormalOutput
            ? state.dataOptions.reduce((prev, current) => {
                return { ...prev, [current.id]: current.checked };
              }, {} as { [key: string]: boolean })
            : {}),
          dateFormat: state.dateOptions.find((_) => _.checked)?.label ?? "yyyy-MM-dd",
        };
      })(),
    };
  };

  const applyPreset = (json: any) => {
    const query = Object.keys(json)
      .filter((key) =>
        ["dateTo", "dateFrom", "options", "requiredColumns", "tables", "policy", "outputFormat"].includes(key)
      )
      .map((key) => `${key}=${typeof json[key] === "string" ? json[key] : JSON.stringify(json[key])}`)
      .join("&");
    window.location.href = `${window.location.origin}${window.location.pathname}?${query}`;
  };

  const deleteCondition = async () => {
    await dispatch(deleteSearchCondition({ id: searchConditionListModal.selectingId }));
    const res = await dispatch(getSearchCondition());
    $searchConditionListModal({
      ...searchConditionListModal,
      isConfirming: false,
      selectingId: "",
      isShow: res.payload.length > 0,
    });
  };

  const hideSearchConditionModal = () => {
    $searchConditionModal({ ...searchConditionModal, isShow: false, isConfirming: false });
  };

  const hideSearchConditionListModal = () => {
    $searchConditionListModal({ ...searchConditionListModal, isShow: false, isConfirming: false });
  };

  const saveSearchCondition = async () => {
    const condition = {
      label: searchConditionModal.label,
      ...createConditionParams(),
    };
    await dispatch(postSearchCondition(condition));
    await dispatch(getSearchCondition());
    hideSearchConditionModal();
  };

  // 表示項目を設定
  useEffect(() => {
    if (activeSectors.length > 0) {
      // e.g.) sectors=address_subField1$subField2,assignment_subField3
      let fieldViewSectors = [] as fieldViewData[];
      let requiredFieldViewSectors = [] as fieldViewData[];
      const userDataSectors = activeSectors.filter((s) => s.sector_id.startsWith(USER_TABLE_PREFIX));
      for (const i in userDataSectors) {
        const sector = userDataSectors[i];
        const sectorId = sector.sector_id;
        const replacedSectorId = sectorId.replace(USER_TABLE_PREFIX, "");
        const regularColumns = sectorRegularColumns[sectorId];
        const field = {
          id: replacedSectorId,
          label: sector.logical_name,
          checked: state.fieldViewSectors.find((_) => _.id === replacedSectorId)?.checked ?? false,
          subFields: [],
        };
        const checked = state.requiredFieldViewSectors.find((_) => _.id === replacedSectorId)?.checked ?? false;
        if (!regularColumns && checked) {
          dispatch(getRegularColumns({ sectorId }));
        }
        const requiredField = {
          id: replacedSectorId,
          label: sector.logical_name,
          checked,
          subFields: regularColumns
            ? regularColumns.map((column: RegularColumn) => {
                const { id, label } = column;
                const checked =
                  state.requiredFieldViewSectors
                    .find((_) => _.id === replacedSectorId)
                    ?.subFields.find((_) => _.id === id)?.checked ?? false;
                return { id, label, checked };
              })
            : state.requiredFieldViewSectors.find((_) => _.id === replacedSectorId)?.subFields ?? [],
        };
        fieldViewSectors = [...fieldViewSectors, field];
        requiredFieldViewSectors = [...requiredFieldViewSectors, requiredField];
      }
      const order =
        state.order.length > 0
          ? state.order
          : fieldViewSectors.filter(({ checked }) => checked).map(({ id, label }) => ({ id, label, fixed: false }));
      $state({ ...state, fieldViewSectors, requiredFieldViewSectors, order });
    }
  }, [activeSectors, sectorRegularColumns]);

  useEffect(() => {
    dispatch(getSectors());
    dispatch(getSearchCondition());
  }, []);

  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="--flex">
                <Button
                  variant="outline-primary"
                  className="mx-2"
                  disabled={searchConditions.length === 0}
                  onClick={() =>
                    $searchConditionListModal({ ...searchConditionListModal, isShow: true, selectingId: "" })
                  }
                >
                  保存された絞り込み条件を使う
                </Button>
                <Button
                  variant="outline-primary"
                  className="mx-2"
                  onClick={() => $searchConditionModal({ ...searchConditionModal, label: "", isShow: true })}
                >
                  現在の絞り込み条件を保存する
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="3">
          <div className="--bold pt-md-3">指定日</div>
        </Col>
        <Col md="9">
          {state.dateError && (
            <Row className="mb-2 --align-items-center">
              <Col>
                <Alert variant="warning">{state.dateError}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="2" className="pt-md-3">
              日付 1
            </Col>
            <Col md="10">
              <SnapshotTimeSelector
                selectedPointDate={state.selectedPointDate1}
                useFarthestDaySelector={false}
                useFarthestPastDaySelector={false}
                onChange={({ selectedPointDate }: onChangeOption) => {
                  if (!selectedPointDate) return;
                  else if (selectedPointDate.diff(state.selectedPointDate2, "day") >= 0) {
                    $state({
                      ...state,
                      dateError: "日付 1 が日付 2 よりも前になるように選択してください。",
                    });
                    return;
                  }
                  $state({
                    ...state,
                    selectedPointDate1: selectedPointDate,
                    dateError: "",
                  });
                }}
              />
              <div className="mt-1">
                <Button
                  variant="link"
                  className="mx-1"
                  onClick={() => {
                    $state({
                      ...state,
                      selectedPointDate1: state.selectedPointDate2.clone().add(-1, "month"),
                      dateError: "",
                    });
                  }}
                >
                  日付 2 の 1 ヶ月前に設定
                </Button>
                <Button
                  variant="link"
                  className="mx-1"
                  onClick={() => {
                    $state({
                      ...state,
                      selectedPointDate1: state.selectedPointDate2.clone().add(-1, "year"),
                      dateError: "",
                    });
                  }}
                >
                  日付 2 の 1 年前に設定
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 --align-items-center">
            <Col md="2">日付 2</Col>
            <Col md="10">
              <SnapshotTimeSelector
                selectedPointDate={state.selectedPointDate2}
                useFarthestDaySelector={false}
                useFarthestPastDaySelector={false}
                onChange={({ selectedPointDate }: onChangeOption) => {
                  if (!selectedPointDate) return;
                  else if (selectedPointDate.diff(state.selectedPointDate1, "day") <= 0) {
                    $state({
                      ...state,
                      dateError: "日付 1 が日付 2 よりも前になるように選択してください。",
                    });
                    return;
                  }
                  $state({
                    ...state,
                    selectedPointDate2: selectedPointDate,
                    dateError: "",
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="my-1 mt-2">
            <Col>
              {state.retreiveOptions.map((option, i) => {
                return (
                  <Form.Check
                    type="radio"
                    label={`${i + 1}. ${option.label}`}
                    key={`option_h_${option.id}`}
                    id={`option_h_${option.id}`}
                    checked={option.checked}
                    value={option.id}
                    onChange={() => {
                      $state({
                        ...state,
                        retreiveOptions: state.retreiveOptions.map((o) => {
                          return { ...o, checked: o.id === option.id };
                        }),
                      });
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="3">
          <div className="--bold pt-md-3">出力形式</div>
        </Col>
        <Col md="9">
          {state.outputFormat.map((option, i) => {
            return (
              <Form.Check
                type="radio"
                label={option.label}
                key={`option_h_${option.id}`}
                id={`option_h_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    outputFormat: state.outputFormat.map((o) => {
                      return { ...o, checked: o.id === option.id };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="3">
          <div className="--bold pt-md-3">対象テーブル</div>
        </Col>
        <Col md="9">
          <FieldListSelector
            fields={state.fieldViewSectors}
            onFieldSelected={selectField}
            useSubFieldsTrigger={false}
          />
        </Col>
      </Row>
      {state.order.length > 0 ? (
        <Row className="mt-4 --align-items-center">
          <Col md="3">
            <div className="--bold">出力順序</div>
          </Col>
          <Col md="9">
            <OrderEditor
              data={state.order}
              onChange={(order) => {
                $state({
                  ...state,
                  order,
                });
              }}
            />
          </Col>
        </Row>
      ) : null}
      {isNormalOutput && (
        <Row className="mt-4">
          <Col md="3">
            <div className="--bold pt-md-3">必ず表示する情報</div>
          </Col>
          <Col md="9">
            <div className="mb-2">指定した期間に差分があるかどうかに関わらず出力する情報を設定できます。</div>
            <FieldListSelector
              fields={state.requiredFieldViewSectors}
              onFieldSelected={selectRequiredField}
              onSubFieldsTriggerClick={fetchSubFieldsData}
              useSubFieldsTrigger={true}
              showSubFieldsOnly={true}
              alwaysShowTrigger={true}
              summary={requiredColumnLabel}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-4 --align-items-center">
        <Col md="3">
          <div className="--bold">在籍区分</div>
        </Col>
        <Col md="9">
          {state.enrollmentTypeOptions.map((option) => {
            return (
              <Form.Check
                type="checkbox"
                label={option.label}
                key={`option_h_${option.id}`}
                id={`option_h_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    enrollmentTypeOptions: state.enrollmentTypeOptions.map((o) => {
                      if (o.id !== option.id) return o;
                      return { ...o, checked: !o.checked };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      {isNormalOutput && (
        <Row className="mt-4 --align-items-center">
          <Col md="3">
            <div className="--bold">出力オプション</div>
          </Col>
          <Col md="9">
            {state.dataOptions.map((option) => {
              return (
                <Form.Check
                  type="checkbox"
                  label={option.label}
                  key={`option_history_${option.id}`}
                  id={`option_history_${option.id}`}
                  checked={option.checked}
                  value={option.id}
                  onChange={() => {
                    $state({
                      ...state,
                      dataOptions: state.dataOptions.map((o) => {
                        if (o.id !== option.id) return o;
                        return { ...o, checked: !o.checked };
                      }),
                    });
                  }}
                />
              );
            })}
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col md="3">
          <div className="--bold pt-md-3">日付の形式</div>
        </Col>
        <Col md="9">
          {state.dateOptions.map((option) => {
            return (
              <Form.Check
                type="radio"
                label={option.label}
                key={`option_history_date_${option.id}`}
                id={`option_history_date_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    dateOptions: state.dateOptions.map((o) => {
                      return { ...o, checked: o.id === option.id };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button variant="primary" disabled={state.order.length === 0} onClick={download}>
            ダウンロード
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={isModalActive}
        onConfirm={() => $isModalActive(false)}
        message="ファイルの作成を開始しました。ダウンロード可能になったら通知されます。"
        type="alert"
      />
      <Modal show={searchConditionModal.isShow} onHide={hideSearchConditionModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title>絞り込み条件の保存</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={4}>
              <Form.Label className="--required-label --bold">ラベル</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={searchConditionModal.label}
                onChange={(e) => {
                  $searchConditionModal({ ...searchConditionModal, label: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideSearchConditionModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            onClick={() => $searchConditionModal({ ...searchConditionModal, isConfirming: true })}
            variant="primary"
            disabled={searchConditionModal.label === ""}
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={searchConditionModal.isConfirming}
        onConfirm={saveSearchCondition}
        onCancel={() => $searchConditionModal({ ...searchConditionModal, isConfirming: false })}
        message="絞り込み条件を保存します。よろしいですか？"
        type="confirm"
      />
      <Modal show={searchConditionListModal.isShow} onHide={hideSearchConditionListModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title>保存された絞り込み条件を使う</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-1">
            <Col md={12}>
              <div className="--overflow-auto">
                <ListGroup as="ol">
                  {searchConditions.map((condition, i) => {
                    return (
                      <ListGroup.Item key={i}>
                        {condition.label}
                        <Button
                          variant="danger"
                          className="float-end"
                          size="sm"
                          onClick={() => {
                            $searchConditionListModal({
                              ...searchConditionListModal,
                              selectingId: condition.id,
                              isConfirming: true,
                            });
                          }}
                        >
                          削除
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-2 float-end"
                          size="sm"
                          onClick={() => applyPreset(condition)}
                        >
                          適用
                        </Button>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideSearchConditionListModal} variant="outline-secondary">
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={searchConditionListModal.isConfirming}
        onConfirm={deleteCondition}
        onCancel={() => $searchConditionListModal({ ...searchConditionListModal, isConfirming: false })}
        message="絞り込み条件を削除します。よろしいですか？"
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
    </Container>
  );
}

export default ProfileList;
