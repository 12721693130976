import { useState, useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { showToast } from "../../features/notification/notificationSlice";
import { selectClientState, getRegularColumns, selectActiveSectors } from "./clientSlice";
import Sidebar from "../../component/Sidebar";
import Uploader from "../../component/Uploader";
import Icon from "../../component/Icon";
import { Container, Row, Col, Form, Button, Dropdown, Card } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import ModalDialog from "../../component/ModalDialog";

type SerializedRow = {
  index: number;
  label: string;
  suggested: { table: string; column: string }[];
  associated: { table: string; column: string }[];
};
const columnPerPage = 5;

function App() {
  const dispatch = useAppDispatch();
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const [state, $state] = useState({
    uploaded: false,
    saved: false,
    serialized: [] as SerializedRow[],
  });
  const [keyword, $keyword] = useState("");
  const [commitModalOpen, $commitModalOpen] = useState(false);
  const [destroyModalOpen, $destroyModalOpen] = useState(false);
  const [showIgnoredRows, $showIgnoredRows] = useState(false);
  const [filteredSerializedData, maxPage, columnLengthToMap, associatedTableNames] = useMemo(() => {
    const filteredSerializedData = state.serialized
      .filter((s) => (keyword ? s.label.indexOf(keyword) !== -1 : true))
      .filter((s) => (showIgnoredRows ? s.associated.some((a) => !!a.table) : true));
    const maxPage =
      Math.floor(filteredSerializedData.length / columnPerPage) +
      (filteredSerializedData.length % columnPerPage > 0 ? 1 : 0);
    const columnLengthToMap = state.serialized.filter((_) => _.associated.some((a) => a.table)).length;
    const associatedLogicalNames = state.serialized.reduce((prev, current) => {
      const _prev = [...prev];
      current.associated
        .map((a) => a.table)
        .forEach((tableName) => {
          if (tableName && !_prev.includes(tableName)) _prev.push(tableName);
        });
      return _prev;
    }, [] as string[]);
    const associatedTableNames = associatedLogicalNames.map((n) => {
      return activeSectors.find((s) => s.sector_id === n)?.logical_name ?? "";
    });
    return [filteredSerializedData, maxPage, columnLengthToMap, associatedTableNames];
  }, [state.serialized, activeSectors, keyword, showIgnoredRows]);
  const [currentPageIndex, $currentPageIndex] = useState(1);
  useEffect(() => {
    if (currentPageIndex > maxPage) {
      $currentPageIndex(1);
    }
  }, [state.serialized, keyword, showIgnoredRows]);
  useEffect(() => {
    const concernedTables = state.serialized.reduce((prev, current) => {
      const _ = [...prev];
      [...current.suggested, ...current.associated].forEach(({ table }) => {
        if (table && !_.includes(table)) _.push(table);
      });
      return _;
    }, [] as string[]);
    concernedTables.forEach((table) => {
      if (!table || sectorRegularColumns[table]) return;
      dispatch(getRegularColumns({ sectorId: table }));
    });
  }, [state.serialized]);

  const handleUploadedFile = async () => {
    const serializedData = [
      {
        index: 0,
        label: "事業所コード",
        suggested: [{ table: "profile_m_section", column: "section_code" }],
        associated: [{ table: "profile_m_section", column: "section_code" }],
      },
      {
        index: 1,
        label: "事業所名",
        suggested: [{ table: "profile_m_section", column: "section_name" }],
        associated: [{ table: "", column: "" }],
      },
      {
        index: 2,
        label: "従業員コード",
        suggested: [{ table: "", column: "" }],
        associated: [{ table: "", column: "" }],
      },
      {
        index: 3,
        label: "社員氏名",
        suggested: [
          { table: "profile_u_personal", column: "legal_name" },
          { table: "profile_u_address", column: "household_head_name" },
        ],
        associated: [
          { table: "profile_u_personal", column: "legal_name" },
          { table: "profile_u_address", column: "household_head_name" },
        ],
      },
      {
        index: 4,
        label: "社員氏名カナ",
        suggested: [{ table: "profile_u_personal", column: "legal_name_kana" }],
        associated: [{ table: "profile_u_personal", column: "legal_name_kana" }],
      },
      {
        index: 5,
        label: "性別",
        suggested: [{ table: "profile_u_personal", column: "gender_type" }],
        associated: [{ table: "profile_u_personal", column: "gender_type" }],
      },
      {
        index: 6,
        label: "生年月日",
        suggested: [{ table: "profile_u_personal", column: "birthday" }],
        associated: [{ table: "", column: "" }],
      },
      {
        index: 7,
        label: "郵便番号",
        suggested: [{ table: "profile_u_address", column: "certificate_postcode" }],
        associated: [{ table: "profile_u_address", column: "certificate_postcode" }],
      },
      {
        index: 8,
        label: "住所1",
        suggested: [{ table: "profile_u_address", column: "prefecture" }],
        associated: [{ table: "profile_u_address", column: "prefecture" }],
      },
      {
        index: 9,
        label: "住所2",
        suggested: [{ table: "profile_u_address", column: "city_town_village" }],
        associated: [{ table: "profile_u_address", column: "city_town_village" }],
      },
    ];
    $state({
      ...state,
      uploaded: true,
      serialized: serializedData,
    });
  };
  const generateFiles = () => {
    dispatch(
      showToast({
        id: "files",
        content: "アップロードファイルの作成を開始しました。",
      })
    );
    $commitModalOpen(false);
  };
  const destroy = () => {
    $state({
      ...state,
      uploaded: false,
      serialized: [],
    });
    $destroyModalOpen(false);
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"import"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">アップロードファイルの作成サポート</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            {!state.uploaded && (
              <Row className="mb-4">
                <Col>
                  <div className="mb-2">分析するファイルをアップロードしてください。</div>
                  <Uploader onFileLoad={handleUploadedFile} />
                </Col>
              </Row>
            )}
            {state.uploaded && (
              <Row className="mb-4">
                <Col>
                  <Card>
                    <Card.Body>
                      <div className="--bullet">
                        項目数 : <span className="--bold">{state.serialized.length}</span>
                        {state.serialized.length !== filteredSerializedData.length ? (
                          <span>
                            （表示中 : <span className="--bold">{filteredSerializedData.length}</span>）
                          </span>
                        ) : null}
                      </div>
                      <div className="--bullet">
                        インポート先を指定した項目数 : <span className="--bold">{columnLengthToMap}</span>
                      </div>
                      <div className="--bullet">
                        アップロードファイルを作成予定のテーブル :{" "}
                        {associatedTableNames.length > 0 ? associatedTableNames.join(", ") : "なし"}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {state.uploaded && (
              <Row>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        id={`filter`}
                        placeholder="絞り込みキーワードを入力"
                        value={keyword}
                        onChange={(e) => {
                          $keyword(e.target.value);
                        }}
                      />
                    </Col>
                    {maxPage > 0 && (
                      <Col md={6}>
                        <div className="Pagenation">
                          <div
                            onClick={() => currentPageIndex > 1 && $currentPageIndex(currentPageIndex - 1)}
                            className={classNames({
                              Pagenation__button: currentPageIndex > 1,
                              "Pagenation__button--disabled": currentPageIndex === 1,
                            })}
                          >
                            <Icon type="caret-left-fill"></Icon>
                          </div>
                          <div className="Pagenation__pages">
                            {currentPageIndex} / {maxPage} ページ
                          </div>
                          <div
                            onClick={() => currentPageIndex < maxPage && $currentPageIndex(currentPageIndex + 1)}
                            className={classNames({
                              Pagenation__button: currentPageIndex < maxPage,
                              "Pagenation__button--disabled": currentPageIndex === maxPage,
                            })}
                          >
                            <Icon type="caret-right-fill"></Icon>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-4 mt-1">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label={"インポート先の指定がある行のみ表示する"}
                        id={"show_ignored_rows"}
                        checked={showIgnoredRows}
                        onChange={() => {
                          $showIgnoredRows(!showIgnoredRows);
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="Mapping-list">
                    <div className="Mapping-list__item">
                      <div className="Mapping-list__item-value">
                        <Row>
                          <Col sm={3} className="--bold">
                            項目
                          </Col>
                          <Col sm={4} className="--bold">
                            推測結果
                          </Col>
                          <Col sm={5} className="--bold">
                            インポート先
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {filteredSerializedData.map((s, i) => {
                      if (i < (currentPageIndex - 1) * columnPerPage || currentPageIndex * columnPerPage - 1 < i)
                        return;
                      return (
                        <div className="Mapping-list__item" key={`row_${s.index}`}>
                          <div className="Mapping-list__item-value">
                            <Row>
                              <Col sm={3}>{s.label}</Col>
                              <Col sm={4}>
                                {s.suggested
                                  ? s.suggested.map(({ table, column }, ii) => {
                                      const _s = activeSectors.find((s) => s.sector_id === table);
                                      const _c = sectorRegularColumns[table]?.find(
                                        (_) =>
                                          _.id === column &&
                                          (!_.reference_field ||
                                            (_.reference_field && (_.id.endsWith("_code") || _.id.endsWith("name"))))
                                      );
                                      return (
                                        <Row key={`row_${s.index}_${ii}`}>
                                          <Col sm={6}>{_s?.logical_name}</Col>
                                          <Col sm={6}>{_c?.label ?? "--"}</Col>
                                        </Row>
                                      );
                                    })
                                  : ""}
                              </Col>
                              <Col sm={5}>
                                {s.associated.map((a, ai) => {
                                  const _s = activeSectors.find((s) => s.sector_id === a.table);
                                  const isThisColumnToUse = !!_s?.logical_name;
                                  const logical_name = _s?.logical_name ?? "インポートしない";
                                  const columnOptions =
                                    sectorRegularColumns[a.table]
                                      ?.filter(
                                        (c) =>
                                          !c.edit_only &&
                                          (!c.reference_field ||
                                            (c.reference_field && (c.id.endsWith("_code") || c.id.endsWith("name"))))
                                      )
                                      .map((c) => {
                                        return {
                                          label: c.label,
                                          id: c.id,
                                        };
                                      }) ?? [];
                                  return (
                                    <Row key={`associated_${ai}`}>
                                      <Col>
                                        <Dropdown className="mb-1">
                                          <Dropdown.Toggle
                                            variant={(() => {
                                              switch (logical_name) {
                                                case "インポートしない":
                                                  return "outline-secondary";
                                                default:
                                                  return "outline-primary";
                                              }
                                            })()}
                                          >
                                            {logical_name}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() => {
                                                $state({
                                                  ...state,
                                                  serialized: state.serialized.map((row, _i) => {
                                                    if (s.index !== _i) return row;
                                                    return {
                                                      ...row,
                                                      associated: row.associated.map((_a, _ai) => {
                                                        if (ai !== _ai) return _a;
                                                        return {
                                                          ..._a,
                                                          table: "",
                                                        };
                                                      }),
                                                    };
                                                  }),
                                                });
                                              }}
                                            >
                                              インポートしない
                                            </Dropdown.Item>
                                            {activeSectors.map((sector, ii) => (
                                              <Dropdown.Item
                                                key={`sector-${ii}`}
                                                onClick={() => {
                                                  $state({
                                                    ...state,
                                                    serialized: state.serialized.map((row, _i) => {
                                                      if (s.index !== _i) return row;
                                                      return {
                                                        ...row,
                                                        associated: row.associated.map((_a, _ai) => {
                                                          if (ai !== _ai) return _a;
                                                          return {
                                                            ..._a,
                                                            table: sector.sector_id,
                                                          };
                                                        }),
                                                      };
                                                    }),
                                                  });
                                                }}
                                              >
                                                {sector.logical_name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Col>
                                      <Col>
                                        {isThisColumnToUse &&
                                          columnOptions &&
                                          (() => {
                                            const currentLabel =
                                              columnOptions.find(({ id }) => id === a.column)?.label ??
                                              "（選択してください）";
                                            return (
                                              <Dropdown className="mb-1">
                                                <Dropdown.Toggle variant={"outline-primary"}>
                                                  {currentLabel}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  {columnOptions.map((_, ii) => (
                                                    <Dropdown.Item
                                                      key={`sector-column-${ii}`}
                                                      onClick={() => {
                                                        $state({
                                                          ...state,
                                                          serialized: state.serialized.map((row, _i) => {
                                                            if (s.index !== _i) return row;
                                                            return {
                                                              ...row,
                                                              associated: row.associated.map((_a, _ai) => {
                                                                if (ai !== _ai) return _a;
                                                                return {
                                                                  ..._a,
                                                                  column: _.id,
                                                                };
                                                              }),
                                                            };
                                                          }),
                                                        });
                                                      }}
                                                    >
                                                      {_.label}
                                                    </Dropdown.Item>
                                                  ))}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            );
                                          })()}
                                      </Col>
                                    </Row>
                                  );
                                })}
                                <Row>
                                  <Col className="--font-s">
                                    <a
                                      className="--text-link"
                                      onClick={() => {
                                        $state({
                                          ...state,
                                          serialized: state.serialized.map((row, _i) => {
                                            if (s.index !== _i) return row;
                                            return {
                                              ...row,
                                              associated: [...row.associated, { table: "", column: "" }],
                                            };
                                          }),
                                        });
                                      }}
                                    >
                                      インポート先を追加する
                                    </a>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            )}
            {state.uploaded && (
              <Row className="mt-4">
                <Col>
                  <Button
                    variant="danger"
                    disabled={!state.saved}
                    className="m-1"
                    onClick={() => $destroyModalOpen(true)}
                  >
                    編集中の内容を破棄してやり直す
                  </Button>
                  <Button className="m-1" variant="outline-primary" onClick={() => false}>
                    編集中の内容を保存する
                  </Button>
                  <Button
                    disabled={associatedTableNames.length === 0}
                    className="m-1"
                    variant="primary"
                    onClick={() => $commitModalOpen(true)}
                  >
                    アップロードファイルを作成する
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
      <ModalDialog
        show={commitModalOpen}
        onConfirm={generateFiles}
        onCancel={() => $commitModalOpen(false)}
        message={"アップロードファイルを作成します。よろしいですか？"}
      />
      <ModalDialog
        show={destroyModalOpen}
        onConfirm={() => {
          $destroyModalOpen(false);
          destroy();
        }}
        onCancel={() => {
          $destroyModalOpen(false);
        }}
        type="destructiveConfirm"
        confirmButtonName="破棄"
        message="表示中の内容を破棄します。よろしいですか？"
      />
    </div>
  );
}

export default App;
