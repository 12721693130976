const data = {
  category: "emergency_contact",
  table: "emergency_contact",
  subFields: {
    emergency_contact_name: {},
    emergency_contact_relationship_type: {},
    emergency_contact_address: {},
    emergency_contact_phone_number: {},
  },
};

export default data;
