import { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import Table from "../../component/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MyNumber,
  MyNumberLog,
  getMyNumberLogs,
  myNumberLogActionDictionary,
  selectLogsWithAccount,
} from "./myNumberSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import dayjs from "dayjs";
import { getMembers, selectProfileState } from "../profile/profileSlice";

type Props = {
  isActive: boolean;
  name: string;
  myNumber?: MyNumber;
  onClose: () => any;
};

function MyNumberLogsModal({ isActive, myNumber, name, onClose }: Props) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectLogsWithAccount);
  const [prevDataLength, $prevDataLength] = useState(0);
  useEffect(() => {
    if (myNumber) {
      dispatch(getMyNumberLogs({ myNumberId: myNumber.id }));
    }
  }, [myNumber]);
  useEffect(() => {
    if (prevDataLength < data.length) {
      $prevDataLength(data.length);
      const accountToFetch = data
        .filter(({ account }) => !account)
        .map(({ log }) => log.subject)
        .filter((_, i, self) => self.indexOf(_) === i);
      // 不足しているアカウントを取得
      if (accountToFetch.length > 0) {
        dispatch(getMembers());
      }
    }
  }, [prevDataLength, data]);
  return (
    <Modal show={isActive} onHide={onClose} size="lg" centered>
      <Modal.Header>
        <Modal.Title>マイナンバー操作ログ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="--align-items-center">
          <Col md="3" className="--bold">
            対象者
          </Col>
          <Col md="9">{name}</Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Table
              col={[
                {
                  name: "種別",
                },
                {
                  name: "操作者",
                },
                {
                  name: "日時",
                },
              ]}
              row={data.map(({ log, account }) => {
                return {
                  data: [
                    myNumberLogActionDictionary[log.action_type],
                    account?.name ?? "",
                    dayjs(log.time).format("YYYY/MM/DD HH:mm:ss"),
                  ],
                };
              })}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyNumberLogsModal;
