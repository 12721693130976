const data = {
  category: "accessible_section",
  table: "accessible_section",
  subFields: {
    accessible_section_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    full_section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    position_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    position_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    accessibility_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      accessible_section_history: "アクセス可能な部署",
    },
  },
};
export default data;
