import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { userColumnChoices } from "../profile/profileFieldValues";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getRegularColumns, selectClientState, getProfileOptions, commitProfileOptions } from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import { RegularColumn, EditingProfileOption } from "./clientValues";
import Table from "../../component/Table";
import Icon from "../../component/Icon";

function OptionEdit() {
  const { target } = useParams();
  const { sectorRegularColumns, profileOptions } = useAppSelector(selectClientState);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    isConfirming: false,
    editingColumn: "",
    editingOptions: [] as EditingProfileOption[],
  });

  const sectorId = useMemo(() => {
    return target ?? "";
  }, [target]);

  useEffect(() => {
    if (sectorId) {
      dispatch(getRegularColumns({ sectorId }));
      dispatch(getProfileOptions({ sector: sectorId }));
    }
  }, [sectorId]);

  const editableOptionColumns = useMemo(() => {
    return (
      sectorRegularColumns[sectorId]?.filter(
        ({ input_type, option_editable }) => input_type === "options" && option_editable
      ) ?? ([] as RegularColumn[])
    );
  }, [sectorRegularColumns, sectorId]);

  const columnChoices = useMemo(() => {
    if (userColumnChoices[sectorId] && editableOptionColumns) {
      return editableOptionColumns.reduce((prev, current) => {
        const choices = userColumnChoices[sectorId][current.id].map((label) => ({
          label,
          editable: false,
          hidden: false,
        }));
        return { ...prev, [current.id]: choices ?? [] };
      }, {});
    }
  }, [sectorId, editableOptionColumns, userColumnChoices]);

  const options: { [key: string]: EditingProfileOption[] } = useMemo(() => {
    return (
      Object.keys(profileOptions).reduce((prev, current) => {
        if (!editableOptionColumns.some(({ id }) => current === id)) return prev;
        const _options =
          profileOptions[current]?.map(({ label, hidden }) => ({
            label,
            editable: false,
            hidden,
          })) ?? ([] as EditingProfileOption[]);
        return {
          ...prev,
          [current]: _options,
        };
      }, columnChoices ?? {}) ?? ({} as { [key: string]: EditingProfileOption[] })
    );
  }, [profileOptions]);

  const closeModal = () => {
    $state({ ...state, editingColumn: "", isConfirming: false, editingOptions: [] });
  };

  const moveIndex = (value: number, index: number) => {
    if (state.editingColumn === "") return;
    const current = state.editingOptions;
    const next = [...current];
    const toTurn = index + value; // 今移動先にある列。選択した列の直前か直後
    current.forEach((_, i) => {
      if (i === index) next[toTurn] = _;
      else if (i === toTurn) next[index] = _;
      else next[i] = _;
    });
    $state({ ...state, editingOptions: next });
  };

  const [duplicatedLabels, displayOptionLength] = useMemo(() => {
    const labels = state.editingOptions.map((o) => o.label);
    const _duplicatedLabels = labels.filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
    const _displayOptions = state.editingOptions.filter(({ hidden }) => !hidden);
    return [_duplicatedLabels, _displayOptions.length];
  }, [state.editingOptions]);

  return (
    <Container>
      <div className="pt-4">
        <h2 className="Headline--section mb-2">選択肢設定</h2>
        <Table
          col={[{ name: "項目名" }, { name: "ユーザに表示されている選択肢" }, { name: "アクション" }]}
          row={editableOptionColumns.map(({ label, id }) => {
            return {
              data: [
                label,
                (options[id] ?? [])
                  .filter(({ hidden }) => !hidden)
                  .map(({ label }) => label)
                  .join(", "),
                <>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      $state({ ...state, editingColumn: id, editingOptions: options[id] ?? [] });
                    }}
                  >
                    編集
                  </Button>
                </>,
              ],
            };
          })}
        />
      </div>
      <ModalDialog
        show={state.isConfirming}
        message="保存します。よろしいですか？"
        onConfirm={async () => {
          await dispatch(
            commitProfileOptions({
              sectorId,
              options: {
                [state.editingColumn]: state.editingOptions.map(({ label, hidden }) => ({ label, hidden })),
              },
            })
          );
          dispatch(getRegularColumns({ sectorId }));
          closeModal();
        }}
        onCancel={() => {
          $state({ ...state, isConfirming: false });
        }}
      />
      <Modal show={state.editingColumn !== ""} onHide={closeModal} size="lg" centered>
        <Modal.Body>
          <Row className="mb-1">
            <span className="--font-s">
              保存した選択肢は削除できません。ユーザに表示したくない選択肢は「非表示」に設定してください。
            </span>
            <Col md={12} className="--overflow-auto">
              {state.editingOptions.map(({ label, editable, hidden }, i) => {
                return (
                  <Row key={`editingOption${i}`}>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        value={label}
                        disabled={!editable}
                        onChange={(e) => {
                          if (!editable) return;
                          $state({
                            ...state,
                            editingOptions: state.editingOptions.map((v, _i) =>
                              i === _i ? { ...v, label: e.target.value } : v
                            ),
                          });
                        }}
                      />
                      {label === "" && <div className="--font-s --text-annotation">入力してください</div>}
                      {duplicatedLabels.includes(label) && (
                        <div className="--font-s --text-annotation">重複しています</div>
                      )}
                    </Col>
                    <Col md={2}>
                      <Button variant="link" disabled={i === 0} onClick={() => moveIndex(-1, i)}>
                        <Icon width={15} height={15} type="caret-up-fill" />
                      </Button>
                      <Button
                        variant="link"
                        disabled={i === state.editingOptions.length - 1}
                        onClick={() => moveIndex(1, i)}
                      >
                        <Icon width={15} height={15} type="caret-down-fill" />
                      </Button>
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      {editable ? (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => {
                            $state({
                              ...state,
                              editingOptions: state.editingOptions.filter((_, _i) => _i !== i),
                            });
                          }}
                        >
                          削除
                        </Button>
                      ) : (
                        <Form.Check
                          type="switch"
                          label={hidden ? "非表示" : "表示"}
                          checked={!hidden}
                          onChange={() => {
                            $state({
                              ...state,
                              editingOptions: state.editingOptions.map((v, _i) =>
                                i === _i ? { ...v, hidden: !v.hidden } : v
                              ),
                            });
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
            {displayOptionLength === 0 && (
              <div className="--font-s --text-annotation">表示する選択肢を一つ以上設定してください</div>
            )}
          </Row>
          <div className="mt-2">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() =>
                $state({
                  ...state,
                  editingOptions: [
                    ...state.editingOptions,
                    {
                      label: "",
                      editable: true,
                      hidden: false,
                    },
                  ],
                })
              }
            >
              選択肢を追加
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
          <Button
            disabled={
              state.editingOptions.length === 0 ||
              state.editingOptions.some(({ label }) => label === "") ||
              duplicatedLabels.length > 0 ||
              displayOptionLength === 0
            }
            onClick={() => $state({ ...state, isConfirming: true })}
          >
            保存
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default OptionEdit;
