import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getClients, selectClientState } from "../client/clientSlice";
import { selectUserState } from "../../features/login/userSlice";
import Sidebar from "../../component/Sidebar";
import SettingCard, { SettingCardProps } from "../../component/SettingCard";
import SettingSteps, { SettingStepProps } from "../../component/SettingSteps";
import { Container, Row, Col, Button, Card, Form, Dropdown, ListGroup } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { policies } = useAppSelector(selectUserState);
  const [steps, settingCards]: [SettingStepProps[], SettingCardProps[]] = useMemo(() => {
    if (
      Object.keys(policies)
        .filter((key: string) => key.includes("master_data_manager"))
        ?.some((api) => policies[api]?.includes("POST"))
    ) {
      return [
        [
          {
            label: "アカウント",
            active: true,
            disabled: false,
          },
          {
            label: "部署・役職",
            active: false,
            disabled: false,
          },
          {
            label: "従業員情報",
            active: false,
            disabled: true,
          },
          {
            label: "申請書",
            active: false,
            disabled: true,
          },
          {
            label: "確認・テスト",
            active: false,
            disabled: true,
          },
          {
            label: "運用開始",
            active: false,
            disabled: true,
          },
        ],
        [
          {
            title: "従業員情報のエクスポート",
            description:
              "可能な場合、現在お使いのシステムから在籍中の従業員の名前・メールアドレス（必須ではない）をエクスポートしてください。",
            checkLists: [
              {
                checked: true,
                label: "従業員情報をエクスポートした",
              },
            ],
            folded: true,
          },
          {
            title: "アカウントの登録準備",
            description:
              "在籍中の従業員の名前・メールアドレス等を CSV 形式で一覧にします。サンプルファイルをダウンロードして更新してください。",
            links: [
              {
                label: "サンプルファイルをダウンロード",
                to: "/_/dashboard",
              },
            ],
            checkLists: [
              {
                checked: false,
                label: "アップロード用の CSV ファイルを用意した",
              },
            ],
            folded: false,
          },
          {
            title: "アカウントの登録",
            description: "CSV ファイルからアカウントを一括登録しましょう。",
            links: [
              {
                label: "アップロード画面",
                to: "/_/dashboard",
              },
            ],
            checkLists: [
              {
                checked: false,
                label: "アカウントを登録した",
              },
            ],
            folded: false,
          },
        ],
      ];
    } else {
      return [
        [
          {
            label: "準備中",
            active: true,
            disabled: false,
          },
          {
            label: "登録内容の確認",
            active: false,
            disabled: true,
          },
          {
            label: "テスト",
            active: false,
            disabled: true,
          },
          {
            label: "運用開始",
            active: false,
            disabled: true,
          },
        ],
        [
          {
            title: "準備中",
            description: "管理者がデータの初期設定を進めています。",
            checkLists: [],
            folded: false,
          },
        ],
      ];
    }
  }, [policies]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                <h2 className="Headline--section">運用準備作業リスト</h2>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={2} className="mb-2">
                <SettingSteps data={steps} onChange={() => false} />
              </Col>
              <Col md={10}>
                {settingCards.map((s, i) => (
                  <SettingCard data={s} key={`settingCard_${i}`} onChange={() => false} className="mb-2" />
                ))}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
