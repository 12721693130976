const data = {
  category: "reward_punishment",
  table: "reward_punishment",
  subFields: {
    reward_punishment_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    reward_punishment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    content_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    expiration_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    incentive_reduction_payments_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      reward_punishment_history: "賞罰履歴",
    },
  },
};

export default data;
