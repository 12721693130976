const data = {
  category: "assignment_old",
  table: "assignment_old",
  subFields: {
    section_code: {},
    position_code: {},
    is_concurrent: {},
  },
};

export default data;
