import { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [state, $state] = useState({
    plan: 1,
    name: "",
    nameOfPersonInCharge: "",
  });

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"import"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">新規顧客の作成</h1>
        <Form className="Grouping mt-3 bg-white">
          <Container>
            <Row>
              <Col>
                <h2 className="Headline--section mb-3">基本情報</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label htmlFor="clientName">顧客名</Form.Label>
                <Form.Control
                  type="text"
                  id="clientName"
                  placeholder="例: フォスターリンク株式会社"
                  onChange={(e) => $state({ ...state, name: e.target.value })}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label htmlFor="nameOfPersonInCharge">担当者名</Form.Label>
                <Form.Control
                  type="text"
                  id="nameOfPersonInCharge"
                  placeholder=""
                  onChange={(e) => $state({ ...state, nameOfPersonInCharge: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="Headline--section mt-4">プラン</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  checked={state.plan === 1}
                  type="radio"
                  id={`default-0`}
                  label={`基本プラン`}
                  onChange={(e) => $state({ ...state, plan: 1 })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  checked={state.plan === 2}
                  type="radio"
                  id={`default-1`}
                  label={`専門家サポートプラン`}
                  onChange={(e) => $state({ ...state, plan: 2 })}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Link to={"/_/dashboard/"}>
                  <Button variant="primary">作成する</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </div>
  );
}

export default App;
