const data = {
  category: "resident_tax",
  table: "resident_tax",
  subFields: {
    resident_tax_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_municipality_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_municipality_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    submission_municipality_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    submission_municipality_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    destination_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    collection_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_annual_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_june_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_july_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_august_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_september_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_october_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_november_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_december_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_january_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_february_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_march_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_april_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resident_tax_may_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      resident_tax_history: "住民税履歴",
    },
  },
};

export default data;
