import ClassNames from "classnames";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Button, Spinner } from "react-bootstrap";
import { logout, useAppDispatch, useAppSelector } from "../app/store";
import Header from "../component/Header";
import ToastView from "../features/notification/ToastView";
import { selectUserState } from "../features/login/userSlice";
import { selectFatalErrorState } from "../features/error/fatalErrorSlice";
import { selectNotificationState } from "../features/notification/notificationSlice";
import { store } from "../app/store";
import { deleteError } from "../features/error/fatalErrorSlice";
import Icon from "../component/Icon";

function App(props: any) {
  const dispatch = useAppDispatch();
  const { initialized } = useAppSelector(selectUserState);
  const { fatalError, error } = useAppSelector(selectFatalErrorState);
  const { loading } = useAppSelector(selectNotificationState);
  const _logout = () => {
    dispatch(logout());
  };
  return (
    <div className={ClassNames({ Root: !initialized, "Root--ready": initialized })}>
      <ToastView />
      <Header />
      {loading && (
        <div className="Overlay">
          <div className="Overlay__content">
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
      {fatalError.length === 0 ? (
        <>
          {error.length > 0 && (
            <div className="Overlay">
              <div className="Overlay__content">
                {error.map(({ type, message, id }) => (
                  <Alert
                    variant={type === "error" ? "danger" : "warning"}
                    key={id}
                    onClose={() => {
                      store.dispatch(deleteError(id));
                    }}
                    dismissible
                  >
                    {message}
                  </Alert>
                ))}
              </div>
            </div>
          )}
          <div className="Root__content">{props.content}</div>
        </>
      ) : (
        <div className="Root__content">
          <div className="Layout">
            <div className="Layout__main">
              {fatalError.map(({ type, message }, i) => (
                <Alert variant={type === "error" ? "danger" : "warning"} key={`error${i}`}>
                  <Alert.Heading>処理中にエラーが発生しました</Alert.Heading>
                  <div>{message}</div>
                </Alert>
              ))}
              <Button onClick={_logout}>再ログインする</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
