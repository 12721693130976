import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { SEND_STATUS, TODO_STATUS, TARGET, SendMailCondition, SearchCondition, Account } from "./mailSettingValues";
import {
  getMailSetting,
  getSendMailTask,
  getProjects,
  getWholeProjects,
  getWorkgroups,
  getAccounts,
  unselectMailSetting,
  clearWorkgroups,
  removeWorkgroup,
  selectMailSettingState,
} from "./mailSettingSlice";
import { getMails, getMail, selectMailState } from "../mail/mailSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { Container, Row, Col, Button, ListGroup, Badge, Card, Form, Modal } from "react-bootstrap";
import Table from "../../component/Table";
import Sidebar from "../../component/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedMailSetting, sendMailTask, projects, wholeProjects, workgroups, accounts, projectLosted } =
    useAppSelector(selectMailSettingState);
  const { mails, selectedMail } = useAppSelector(selectMailState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [state, $state] = useState({
    isEditing: false,
    name: "",
    mailTemplateId: "",
    eta: new Date(),
    fromName: "",
    replyTo: "",
    cc: [] as string[],
    bcc: [] as string[],
    target: "",
    conditions: [] as SendMailCondition[],
    selectedAccounts: [] as Account[],
    activeModal: "",
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
  });

  useEffect(() => {
    return () => {
      dispatch(unselectMailSetting());
    };
  }, []);

  useEffect(() => {
    if (id)
      dispatch(getMailSetting({ id })).then((res) => {
        // 存在しないidの場合、メール送信設定一覧へ遷移させる
        if (!res.payload) navigate("/_/mail_setting/");
      });
  }, [id]);

  useEffect(() => {
    if (current_company.id) {
      if (state.isEditing) {
        dispatch(getMails({ conditions: {} }));
      } else if (selectedMailSetting.mail_template_id) {
        dispatch(getMail({ id: selectedMailSetting.mail_template_id }));
      }
    }
  }, [current_company, dispatch, state.isEditing, selectedMailSetting.mail_template_id]);

  useEffect(() => {
    if (state.isEditing) {
      dispatch(getWholeProjects());
    } else {
      if (selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") {
        dispatch(getSendMailTask({ target: selectedMailSetting.target, conditions: selectedMailSetting.conditions }));
      }
      switch (selectedMailSetting.target) {
        case "workflow":
          const projectIds = selectedMailSetting.conditions.map((c) => c.project_id);
          dispatch(getProjects({ conditions: { projectIds } }));
          dispatch(getWorkgroups(selectedMailSetting.conditions));
          break;
        case "account":
          if (selectedMailSetting.conditions[0]?.id__in?.length > 0) {
            const conditions = {} as SearchCondition;
            conditions["accountIds"] = selectedMailSetting.conditions[0].id__in;
            dispatch(getAccounts({ conditions }));
          }
          break;
        default:
          break;
      }
    }
  }, [state.isEditing, selectedMailSetting]);

  useEffect(() => {
    if (state.activeModal === "search_accounts") {
      const conditions = state.searchKeyword ? { keyword: state.searchKeyword } : {};
      dispatch(getAccounts({ conditions }));
    }
  }, [state.activeModal, state.searchKeyword]);

  const userTimeZone = useMemo(() => {
    return current_company.timezone ?? "Asia/Tokyo";
  }, [current_company]);

  const addItem = (listName: keyof typeof state) => {
    if (Array.isArray(state[listName])) {
      const list = [
        ...(state[listName] as string[] | SendMailCondition[]),
        listName === "conditions"
          ? {
              id__in: [],
              remarks: "",
              project_id: 0,
              work_group_id: 0,
              workflow_id: 0,
              service_id: 0,
              status: [],
            }
          : "",
      ];
      $state({ ...state, [listName]: list });
    }
  };

  const removeItem = (listName: keyof typeof state, index: number) => {
    if (Array.isArray(state[listName])) {
      const list = [...(state[listName] as string[] | SendMailCondition[])];
      list.splice(index, 1);
      $state({ ...state, [listName]: list });
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"mail_setting"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">{selectedMailSetting.name}</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            {(selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") && (
              <Row className="mb-3">
                <Col>
                  {!state.isEditing ? (
                    <Button
                      onClick={() => {
                        $state({
                          ...state,
                          name: selectedMailSetting.name,
                          mailTemplateId: selectedMail.id,
                          eta: new Date(selectedMailSetting.eta),
                          fromName: selectedMailSetting.from_name,
                          replyTo: selectedMailSetting.reply_to,
                          cc: selectedMailSetting.cc,
                          bcc: selectedMailSetting.bcc,
                          target: selectedMailSetting.target,
                          conditions: selectedMailSetting.conditions,
                          selectedAccounts: selectedMailSetting.target === "account" ? accounts : [],
                          timeoutId: null,
                          inputKeyword: "",
                          searchKeyword: "",
                          isEditing: true,
                        });
                      }}
                    >
                      編集
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          $state({ ...state, isEditing: false });
                        }}
                        variant="outline-danger"
                      >
                        キャンセル
                      </Button>
                      <Button onClick={() => {}} disabled={true} className="mx-2">
                        更新
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            )}
            {state.isEditing && (
              <Row className="mb-1">
                <Col>
                  <span className="--required-label"></span> は必須項目です。
                </Col>
              </Row>
            )}
            <ListGroup
              className={classNames({
                "mb-4": true,
                "mt-3": selectedMailSetting.status !== "waiting" && selectedMailSetting.status !== "ready",
              })}
            >
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>タイトル</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <Form.Control
                        type="text"
                        value={state.name}
                        onChange={(e) => {
                          $state({ ...state, name: e.target.value });
                        }}
                      />
                    ) : (
                      <div className="--pre-wrap">{selectedMailSetting.name}</div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>
                      メール文面
                    </div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <Row>
                        <Col md={6}>
                          <Form.Select
                            value={state.mailTemplateId}
                            onChange={(e) => $state({ ...state, mailTemplateId: e.target.value })}
                          >
                            {mails.map((m) => {
                              return (
                                <option key={m.id} value={m.id}>
                                  {m.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </Row>
                    ) : (
                      <div className="--pre-wrap">
                        {selectedMail.name ?? (
                          <>
                            <Badge pill bg="danger" className="me-2">
                              !
                            </Badge>
                            <span className="--text-annotation">メール文面が削除されています。</span>
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>送信日時</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <Row>
                        <Col md={4}>
                          <DatePicker
                            maxDate={dayjs().add(1, "years").tz(userTimeZone).endOf("day").toDate()}
                            dateFormat={"yyyy-MM-dd HH:mm"}
                            selected={state.eta}
                            showTimeSelect
                            className="form-control"
                            timeIntervals={5}
                            onChange={(selected) => {
                              if (!selected) return;
                              $state({ ...state, eta: selected });
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <div className="--pre-wrap">{`${dayjs(selectedMailSetting.eta)
                        .tz(userTimeZone)
                        .format("YYYY-MM-DD HH:mm")} (${userTimeZone})`}</div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>差出人名</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <Form.Control
                        type="text"
                        value={state.fromName}
                        onChange={(e) => {
                          $state({ ...state, fromName: e.target.value });
                        }}
                      />
                    ) : (
                      <div className="--pre-wrap">{selectedMailSetting.from_name}</div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>差出人メールアドレス</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{selectedMailSetting.from_address}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true, "--required-label": state.isEditing })}>
                      返信先メールアドレス（管理者）
                    </div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <Form.Control
                        type="text"
                        value={state.replyTo}
                        onChange={(e) => {
                          $state({ ...state, replyTo: e.target.value });
                        }}
                      />
                    ) : (
                      <div className="--pre-wrap">{selectedMailSetting.reply_to}</div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>CC</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <>
                        {state.cc.map((cc, i) => (
                          <div key={`cc_${i}`} className="mb-2">
                            <Row className="g-2">
                              <Col md={6}>
                                <Form.Control
                                  type="text"
                                  value={cc}
                                  onChange={(e) => {
                                    const next = [...state.cc];
                                    next[i] = e.target.value;
                                    $state({ ...state, cc: next });
                                  }}
                                />
                              </Col>
                              <Col md={6} className="--flex --align-items-center">
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => {
                                    removeItem("cc", i);
                                  }}
                                >
                                  削除
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => {
                            addItem("cc");
                          }}
                        >
                          CCを追加
                        </Button>
                      </>
                    ) : (
                      <ul>
                        {selectedMailSetting.cc.map((cc, i) => (
                          <li key={`cc_${i}`} className="--pre-wrap">
                            {cc}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>BCC</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      <>
                        {state.bcc.map((bcc, i) => (
                          <div key={`bcc_${i}`} className="mb-2">
                            <Row className="g-2">
                              <Col md={6}>
                                <Form.Control
                                  type="text"
                                  value={bcc}
                                  onChange={(e) => {
                                    const next = [...state.bcc];
                                    next[i] = e.target.value;
                                    $state({ ...state, bcc: next });
                                  }}
                                />
                              </Col>
                              <Col md={6} className="--flex --align-items-center">
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => {
                                    removeItem("bcc", i);
                                  }}
                                >
                                  削除
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => {
                            addItem("bcc");
                          }}
                        >
                          BCCを追加
                        </Button>
                      </>
                    ) : (
                      <ul>
                        {selectedMailSetting.bcc.map((bcc, i) => (
                          <li key={`bcc_${i}`} className="--pre-wrap">
                            {bcc}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              {!state.isEditing && (
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={4}>
                      <div className={classNames({ "--bold": true })}>ステータス</div>
                    </Col>
                    <Col md={8}>
                      <div className="--pre-wrap">
                        {SEND_STATUS.find((s) => s.value === selectedMailSetting.status)?.name ?? "未送信"}
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
              {!state.isEditing &&
                (selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") && (
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true })}>送信予定件数</div>
                      </Col>
                      <Col md={8}>
                        {projectLosted && selectedMailSetting.target === "workflow" ? (
                          <div className="--pre-wrap">
                            <Badge pill bg="danger" className="me-2">
                              !
                            </Badge>
                            <span className="--text-annotation">プロジェクトが削除されています。</span>
                          </div>
                        ) : (
                          <div className="--pre-wrap">{sendMailTask.count}</div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
            </ListGroup>
            <ListGroup className="mb-4">
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>送信条件</div>
                  </Col>
                  <Col md={8}>
                    {state.isEditing ? (
                      TARGET.map((t) => (
                        <Form.Check
                          type="radio"
                          id={`target_${t.value}`}
                          key={`target_${t.value}`}
                          label={t.name}
                          value={t.value}
                          checked={state.target === t.value}
                          onChange={(e) => {
                            $state({ ...state, target: e.target.value, conditions: [], selectedAccounts: [] });
                            if (e.target.value === "workflow") {
                              dispatch(clearWorkgroups());
                            }
                          }}
                          inline
                        />
                      ))
                    ) : (
                      <div className="--pre-wrap">
                        {TARGET.find((t) => t.value === selectedMailSetting.target)?.name ?? ""}
                      </div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {state.isEditing
                  ? (() => {
                      switch (state.target) {
                        case "workflow":
                          return (
                            <>
                              {state.conditions.map((condition, index) => (
                                <Card key={`condition_${index}`} className="MailSettingConditions-card">
                                  <Card.Body>
                                    <Row className="mb-2">
                                      <Col xs={4}>
                                        <div className="MailSettingConditions-card__name">プロジェクト</div>
                                      </Col>
                                      <Col xs={3}>
                                        <div className="MailSettingConditions-card__name">フェーズ</div>
                                      </Col>
                                      <Col xs={5}>
                                        <div className="MailSettingConditions-card__name">ステータス</div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <Form.Select
                                          value={condition.project_id}
                                          onChange={(e) => {
                                            const projectId = Number(e.target.value);
                                            const next = [...state.conditions];
                                            next[index] = {
                                              ...next[index],
                                              project_id: projectId,
                                              workflow_id:
                                                wholeProjects.find((p) => p.project_id === projectId)?.workflow_id ?? 0,
                                              service_id:
                                                wholeProjects.find((p) => p.project_id === projectId)?.service_id ?? 0,
                                            };
                                            $state({ ...state, conditions: next });
                                            if (e.target.value !== "0") {
                                              dispatch(getWorkgroups(next));
                                            } else {
                                              dispatch(removeWorkgroup({ index }));
                                            }
                                          }}
                                        >
                                          <option value={0}>---</option>
                                          {wholeProjects.map((p) => {
                                            return (
                                              <option key={`project_${index}_${p.project_id}`} value={p.project_id}>
                                                {p.name}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </Col>
                                      <Col xs={3}>
                                        <Form.Select
                                          value={condition.work_group_id}
                                          onChange={(e) => {
                                            const workgroupId = Number(e.target.value);
                                            const next = [...state.conditions];
                                            next[index] = { ...next[index], work_group_id: workgroupId };
                                            $state({ ...state, conditions: next });
                                          }}
                                        >
                                          <option value={0}>---</option>
                                          {workgroups[index]?.map((w) => {
                                            return (
                                              <option key={`work_group_${index}_${w.value}`} value={w.value}>
                                                {w.name}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </Col>
                                      <Col xs={4}>
                                        {TODO_STATUS.map((s) => {
                                          return (
                                            <Form.Check
                                              inline
                                              type="checkbox"
                                              id={`todo_status_${index}_${s.value}`}
                                              key={`todo_status_${index}_${s.value}`}
                                              label={s.name}
                                              checked={condition.status.includes(s.value)}
                                              className="mx-2"
                                              onChange={() => {
                                                const next = [...state.conditions];
                                                next[index] = {
                                                  ...next[index],
                                                  status: next[index].status.includes(s.value)
                                                    ? next[index].status.filter((_s) => _s !== s.value)
                                                    : [...next[index].status, s.value],
                                                };
                                                $state({ ...state, conditions: next });
                                              }}
                                            />
                                          );
                                        })}
                                      </Col>
                                      <Col xs={1}>
                                        {index > 0 && (
                                          <Button
                                            variant="outline-danger"
                                            className="float-end"
                                            size="sm"
                                            onClick={() => {
                                              removeItem("conditions", index);
                                              dispatch(removeWorkgroup({ index }));
                                            }}
                                          >
                                            削除
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              ))}
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className={classNames({ "mt-2": state.conditions.length > 0 })}
                                onClick={() => {
                                  addItem("conditions");
                                }}
                              >
                                送信条件を追加
                              </Button>
                            </>
                          );
                        case "account":
                          return (
                            <Card className="MailSettingConditions-card">
                              <Card.Body>
                                <Row className="mb-2">
                                  <Col xs={4}>
                                    <div className="MailSettingConditions-card__name">ログインIDで絞り込む</div>
                                  </Col>
                                  <Col xs={8}>
                                    <div className="MailSettingConditions-card__name">備考で絞り込む</div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4}>
                                    <Button
                                      variant="outline-primary"
                                      className="mb-2"
                                      onClick={() => {
                                        $state({ ...state, activeModal: "search_accounts" });
                                      }}
                                    >
                                      検索する
                                    </Button>
                                    <ul>
                                      {state.selectedAccounts.map((a) => (
                                        <li
                                          key={a.id}
                                          className="ms-3 --pre-wrap MailSettingConditions-card__account-item"
                                        >
                                          <span className="me-2">
                                            {a.name}({a.login_code})
                                          </span>
                                          <Button
                                            variant="outline-danger"
                                            className="MailSettingConditions-card__account-item-delete"
                                            size="sm"
                                            onClick={() => {
                                              const nextConditions = [...state.conditions];
                                              nextConditions[0] = {
                                                ...nextConditions[0],
                                                id__in: (nextConditions[0]?.id__in || []).filter((id) => id !== a.id),
                                              };
                                              const nextSelectedAccounts = state.selectedAccounts.filter(
                                                (_a) => _a.id !== a.id
                                              );
                                              $state({
                                                ...state,
                                                conditions: nextConditions,
                                                selectedAccounts: nextSelectedAccounts,
                                              });
                                            }}
                                          >
                                            削除
                                          </Button>
                                        </li>
                                      ))}
                                    </ul>
                                    <div className="mt-2 --pre-wrap">
                                      <Badge pill bg="info" className="me-2">
                                        !
                                      </Badge>
                                      <span>ログインIDと備考はAND条件です。</span>
                                    </div>
                                  </Col>
                                  <Col xs={8}>
                                    <Form.Control
                                      type="text"
                                      value={state.conditions[0]?.remarks ?? ""}
                                      placeholder="キーワードを入力"
                                      onChange={(e) => {
                                        const next = [...state.conditions];
                                        next[0] = { ...next[0], remarks: e.target.value };
                                        $state({ ...state, conditions: next });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          );
                        default:
                          return null;
                      }
                    })()
                  : (() => {
                      switch (selectedMailSetting.target) {
                        case "workflow":
                          return selectedMailSetting.conditions.map((condition, index) => (
                            <Card key={`condition_${index}`} className="MailSettingConditions-card">
                              <Card.Body>
                                <Row className="mb-2">
                                  <Col xs={4}>
                                    <div className="MailSettingConditions-card__name">プロジェクト</div>
                                  </Col>
                                  <Col xs={4}>
                                    <div className="MailSettingConditions-card__name">フェーズ</div>
                                  </Col>
                                  <Col xs={4}>
                                    <div className="MailSettingConditions-card__name">ステータス</div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4}>
                                    <div className="--pre-wrap">
                                      {projects.find((p) => p.project_id === condition.project_id)?.name ?? (
                                        <>
                                          <Badge pill bg="danger" className="me-2">
                                            !
                                          </Badge>
                                          <span className="--text-annotation">プロジェクトが削除されています。</span>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={4}>
                                    <div className="--pre-wrap">
                                      {workgroups[index]?.find((w) => w.value === condition.work_group_id)?.name ?? ""}
                                    </div>
                                  </Col>
                                  <Col xs={4}>
                                    <ul>
                                      {condition.status.map((s, i) => (
                                        <li key={`status_${index}_${i}`} className="--pre-wrap">
                                          {TODO_STATUS.find((t) => t.value === s)?.name ?? ""}
                                        </li>
                                      ))}
                                    </ul>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          ));
                        case "account":
                          return (
                            <Card className="MailSettingConditions-card">
                              <Card.Body>
                                <Row className="mb-2">
                                  <Col xs={4}>
                                    <div className="MailSettingConditions-card__name">ログインID</div>
                                  </Col>
                                  <Col xs={8}>
                                    <div className="MailSettingConditions-card__name">備考</div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={4}>
                                    <ul>
                                      {accounts.map((a) => (
                                        <li
                                          key={a.id}
                                          className="ms-3 --pre-wrap MailSettingConditions-card__account-item"
                                        >
                                          {a.name}({a.login_code})
                                        </li>
                                      ))}
                                    </ul>
                                    <div className="mt-1 --pre-wrap">
                                      <Badge pill bg="info" className="me-2">
                                        !
                                      </Badge>
                                      <span>ログインIDと備考はAND条件です。</span>
                                    </div>
                                  </Col>
                                  <Col xs={8}>
                                    <div className="--pre-wrap">{selectedMailSetting.conditions[0].remarks} </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          );
                        default:
                          return null;
                      }
                    })()}
              </ListGroup.Item>
            </ListGroup>
            {!state.isEditing &&
              (selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") && (
                <Row>
                  <Col>
                    <h2 className="Headline--section">送信先一覧</h2>
                    <div className="mt-1">
                      <Badge pill bg="info" className="me-2">
                        !
                      </Badge>
                      <span>送信時に変動する場合があります。​</span>
                    </div>
                    <Table
                      col={[
                        {
                          name: "ログインID",
                          colName: "login_code",
                          width: 300,
                        },
                        {
                          name: "名前",
                          width: 300,
                        },
                        {
                          name: "メールアドレス",
                          width: 250,
                        },
                      ]}
                      row={sendMailTask.receivers.map((r) => {
                        return {
                          id: r.id,
                          key: r.id,
                          data: [r.login_code, r.name, r.mail_address],
                          appendAfter: {
                            login_code:
                              r.is_billing === false ? (
                                <div className="mx-1 badge rounded-pill bg-info">テスト用</div>
                              ) : r.is_guest ? (
                                <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                              ) : null,
                          },
                        };
                      })}
                    />
                    <Button variant="outline-secondary" className="mt-2 float-end" disabled={true}>
                      さらに表示（全 　 件中 　 件表示中）
                    </Button>
                  </Col>
                </Row>
              )}
            <Modal
              show={state.activeModal === "search_accounts"}
              onHide={() => {
                $state({ ...state, activeModal: "" });
              }}
              size="xl"
              centered
            >
              <Modal.Body className="MailSettingConditions-card__account-search-modal-body">
                <Row className="mb-1">
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          value={state.inputKeyword}
                          className="mb-2"
                          placeholder="キーワードを入力"
                          onChange={(e) => {
                            const keyword = e.target.value;
                            // 打ち終わって500ms後に検索のリクエストをする
                            if (state.timeoutId) {
                              window.clearTimeout(state.timeoutId);
                            }
                            const timeoutId = window.setTimeout(() => {
                              $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                            }, 500);
                            $state({ ...state, timeoutId, inputKeyword: keyword });
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="ms-1 mt-1">
                      検索結果：{accounts.length}件中 {accounts.length}件表示中
                    </div>
                    <div className="MailSettingConditions-card__account-search-modal-table">
                      <Table
                        col={[
                          {
                            name: "ログインID",
                            colName: "login_code",
                            className: "--flex --align-items-center",
                            width: 200,
                          },
                          {
                            name: "名前",
                            width: 250,
                            className: "--flex --align-items-center",
                          },
                          {
                            name: "メールアドレス",
                            width: 250,
                            className: "--flex --align-items-center",
                          },
                          {
                            name: "アクション",
                            width: 100,
                            className: "--flex --align-items-center",
                          },
                        ]}
                        row={accounts.map((r) => {
                          return {
                            id: r.id,
                            key: r.id,
                            data: [
                              r.login_code,
                              r.name,
                              r.mail_address,
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => {
                                  const nextConditions = [...state.conditions];
                                  nextConditions[0] = {
                                    ...nextConditions[0],
                                    id__in: (nextConditions[0]?.id__in || []).includes(r.id)
                                      ? nextConditions[0].id__in.filter((id) => id !== r.id)
                                      : [...(nextConditions[0]?.id__in || []), r.id],
                                  };
                                  const nextSelectedAccounts = state.selectedAccounts.some((a) => a.id === r.id)
                                    ? state.selectedAccounts.filter((a) => a.id !== r.id)
                                    : [...state.selectedAccounts, r];
                                  $state({
                                    ...state,
                                    conditions: nextConditions,
                                    selectedAccounts: nextSelectedAccounts,
                                  });
                                }}
                              >
                                追加
                              </Button>,
                            ],
                            appendAfter: {
                              login_code:
                                r.is_billing === false ? (
                                  <div className="mx-1 badge rounded-pill bg-info">テスト用</div>
                                ) : r.is_guest ? (
                                  <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                                ) : null,
                            },
                          };
                        })}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    $state({ ...state, activeModal: "" });
                  }}
                  variant="outline-secondary"
                >
                  閉じる
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
