import { useAppSelector } from "../../app/store";
import { selectClientState } from "../../features/client/clientSlice";
import Root from "../../component/Root";
import OperationDashboard from "./OperationDashboard";
import PreparationDashboard from "./PreparationDashboard";
import NoRole from "../../features/error/NoRole";
import "bootstrap/dist/css/bootstrap.min.css";

function DashboardSwitcher() {
  const { phase } = useAppSelector(selectClientState);
  if (phase === "preparation") {
    return <Root content={<PreparationDashboard />} />;
  } else if (phase === "operation") {
    return <Root content={<OperationDashboard />} />;
  } else {
    return <Root content={<NoRole />} />;
  }
}

export default DashboardSwitcher;
