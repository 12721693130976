import { useState, useEffect, useMemo, ReactNode } from "react";
import { Link } from "react-router-dom";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Icon from "./Icon";
import classNames from "classnames";
import { Form, Row, Col } from "react-bootstrap";

export type TableCol = {
  name: ReactNode;
  colName?: string;
  fieldName?: string;
  className?: string;
  width?: number | string;
  left?: number;
  filterable?: boolean;
};

type Props = {
  col?: TableCol[];
  row: {
    id?: string | number;
    data: (ReactNode | ReactNode[])[];
    link?: string;
    externalLink?: string;
    action?: { handler: (id: string | number, data: (string | string[])[], col: TableCol[]) => any };
    appendAfter?: { [key: string]: ReactNode };
  }[];
  usePagenation?: boolean;
  useKeywordFilter?: boolean;
  checkedIds?: (string | number)[];
  onCheck?: (checkedIds: (string | number)[]) => any;
};

const rowsPerPage = 20;
const isVisiblePageIndex = (target: number, current: number, totalLength: number) => {
  // current の前後 5 ページと先頭・末尾のページを表示する
  if (target < 0 || target > totalLength - 1) return false;
  else if (target === 0 || target === totalLength - 1) return true;
  else if (Math.abs(current - target) < 5) return true;
  else return false;
};

function App({ col = [], row, usePagenation = false, useKeywordFilter = false, checkedIds, onCheck }: Props) {
  const [state, $state] = useState({
    currentPageIndex: 0,
    currentPageIndexWhileFiltering: 0,
    pages: [] as { pageIndex: number }[],
    keyword: "",
    allCheck: false,
  });

  const getText = (node: ReactNode): string | string[] => {
    if (node instanceof Array) return node.map((n) => getText(n)).join("");
    else if (node && typeof node === "object" && "props" in node) return getText(node?.props?.children);
    return `${node}`;
  };

  const targetColumnIndex = useMemo(() => {
    return col.map((c, i) => (c.filterable ? i : -1)).filter((i) => i >= 0);
  }, [col]);

  const filteredRow = useMemo(() => {
    return row.filter((r) => {
      if (!useKeywordFilter) return true;
      else if (state.keyword === "") return true;
      return r.data.some((_, i) => targetColumnIndex.includes(i) && getText(_).indexOf(state.keyword) !== -1);
    });
  }, [row, useKeywordFilter, state.keyword, targetColumnIndex]);

  const actualIndex = useMemo(() => {
    return state.keyword.length > 0 ? state.currentPageIndexWhileFiltering : state.currentPageIndex;
  }, [state.keyword, state.currentPageIndex, state.currentPageIndexWhileFiltering]);

  const filteredRowInCurrentPage = useMemo(() => {
    return usePagenation ? filteredRow.slice(actualIndex * rowsPerPage, (actualIndex + 1) * rowsPerPage) : filteredRow;
  }, [filteredRow, actualIndex]);

  const selectIndex = (pageIndex: number) => {
    if (pageIndex < 0 || pageIndex > state.pages.length - 1) return;
    $state({
      ...state,
      [state.keyword.length === 0 ? "currentPageIndex" : "currentPageIndexWhileFiltering"]: pageIndex,
    });
  };

  const onKeywordChange = (keyword: string) => {
    $state({
      ...state,
      keyword,
      currentPageIndexWhileFiltering: keyword.length === 0 ? 0 : state.currentPageIndexWhileFiltering,
    });
  };

  useEffect(() => {
    const _pages = new Array(Math.ceil(filteredRow.length / rowsPerPage)).fill(1);
    $state({
      ...state,
      pages: _pages.map((_, i) => {
        return {
          pageIndex: i,
        };
      }),
    });
  }, [filteredRow, actualIndex]);

  useEffect(() => {
    if (checkedIds?.length === 0) $state({ ...state, allCheck: false });
  }, [checkedIds]);

  useEffect(() => {
    if (state.currentPageIndex >= state.pages.length) {
      selectIndex(state.pages.length - 1);
    }
  }, [state.currentPageIndex, state.pages]);

  return (
    <div className="Table">
      <Row className="Table__attachment">
        {useKeywordFilter && (
          <Col md={6}>
            <Form.Control
              type="text"
              id={`filter`}
              placeholder="絞り込みキーワードを入力"
              value={state.keyword}
              onChange={(e) => {
                onKeywordChange(`${e.target.value}`);
              }}
            />
          </Col>
        )}
        {usePagenation && state.pages.length > 0 && (
          <Col md={useKeywordFilter ? 6 : 12}>
            <div className="Pagenation">
              <div
                onClick={() => selectIndex(actualIndex - 1)}
                className={classNames({
                  Pagenation__button: actualIndex > 0,
                  "Pagenation__button--disabled": actualIndex === 0,
                })}
              >
                <Icon type="caret-left-fill"></Icon>
              </div>
              <div className="Pagenation__pages">
                {state.pages
                  .map(({ pageIndex }) => {
                    const isVisible = isVisiblePageIndex(pageIndex, actualIndex, state.pages.length);
                    const isPrevVisible = isVisiblePageIndex(pageIndex - 1, actualIndex, state.pages.length);
                    if (!isVisible) {
                      if (isPrevVisible) {
                        return (
                          <div
                            key={`pagenation_emitted${pageIndex}`}
                            className="Pagenation__page"
                            onClick={() => selectIndex(pageIndex)}
                          >
                            ...
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                    return (
                      <div
                        key={`pagenation${pageIndex}`}
                        className={classNames({
                          Pagenation__page: pageIndex !== actualIndex,
                          "Pagenation__page--current": pageIndex === actualIndex,
                        })}
                        onClick={() => selectIndex(pageIndex)}
                      >
                        {pageIndex + 1}
                      </div>
                    );
                  })
                  .filter((_) => _)}
              </div>
              <div
                onClick={() => selectIndex(actualIndex + 1)}
                className={classNames({
                  Pagenation__button: actualIndex < state.pages.length - 1,
                  "Pagenation__button--disabled": actualIndex === state.pages.length - 1,
                })}
              >
                <Icon type="caret-right-fill"></Icon>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <div className="Table__header">
        <div className="Table__header-inner">
          {checkedIds && onCheck && (
            <div className="Table__header-col Table__col--form-check">
              <Form.Check
                type="checkbox"
                id="allCheck"
                onChange={() => {
                  $state({ ...state, allCheck: !state.allCheck });
                  const next = state.allCheck ? [] : (filteredRow.map(({ id }) => id) as (string | number)[]);
                  onCheck(next);
                }}
                checked={state.allCheck}
              />
            </div>
          )}
          {col?.map((c, _ci) => (
            <div
              key={`th_${c.name}_${_ci}`}
              style={{
                width: typeof c.width === "number" || typeof c.width === "string" ? c.width : undefined,
                left:
                  c.left && c.className === "--sticky"
                    ? _ci === 0
                      ? 0
                      : col.slice(0, _ci).reduce((acc, curr) => acc + (curr.left || 0), 0)
                    : undefined,
              }}
              className={`Table__header-col${c.className ? c.className : ""}`}
            >
              {c.name}
            </div>
          ))}
        </div>
      </div>
      {filteredRowInCurrentPage.length === 0 && <div className="Table__row-empty"></div>}
      {filteredRowInCurrentPage.length > 0 &&
        filteredRowInCurrentPage
          .map((r, i) => {
            const inner = (
              <div className="Table__row-inner">
                {checkedIds && onCheck && (
                  <div className="Table__col Table__col--form-check">
                    <Form.Check
                      type="checkbox"
                      id={`check_${i}`}
                      onChange={() => {
                        if (!r.id) return;
                        const next = checkedIds.includes(r.id)
                          ? checkedIds.filter((id) => id !== r.id)
                          : [...checkedIds, r.id];
                        onCheck(next);
                      }}
                      checked={r.id ? checkedIds.includes(r.id) : false}
                    />
                  </div>
                )}
                {r.data.map((v, colIndex) =>
                  Array.isArray(v) ? (
                    <div
                      key={`row${i}_${colIndex}`}
                      className={"Table__col" + (col[colIndex]?.className ? `${col[colIndex]?.className}` : "")}
                      style={{
                        width: col[colIndex]?.width,
                        left: col[colIndex]?.left
                          ? colIndex === 0
                            ? 0
                            : col.slice(0, colIndex).reduce((acc, curr) => acc + (curr.left || 0), 0) // それまでの幅を合計
                          : undefined, // leftが定義されていない場合はundefined
                      }}
                    >
                      {v.map((_v, ii) => (
                        <div key={`row${i}_${v[0]}_value${ii}`}>{_v}</div>
                      ))}
                    </div>
                  ) : (
                    <div
                      key={`row${i}_${colIndex}`}
                      className={"Table__col" + (col[colIndex]?.className ? `${col[colIndex]?.className}` : "")}
                      style={{
                        width: col[colIndex]?.width,
                        left: col[colIndex]?.left
                          ? colIndex === 0
                            ? 0
                            : col.slice(0, colIndex).reduce((acc, curr) => acc + (curr.left || 0), 0) // それまでの幅を合計
                          : undefined, // leftが定義されていない場合はundefined
                      }}
                    >
                      {v}
                      {r.appendAfter?.[col[colIndex]?.colName ?? ""] ?? null}
                    </div>
                  )
                )}
              </div>
            );
            return r.link ? (
              <Link className="Table__row --block-link" to={r.link} key={`row${i}`} data-key={i}>
                {inner}
              </Link>
            ) : r.externalLink ? (
              <a className="Table__row --block-link" href={r.externalLink} target="_blank" key={`row${i}`} data-key={i}>
                {inner}
              </a>
            ) : r.action ? (
              <div
                className="Table__row--action --block-link"
                key={`row${i}`}
                onClick={() => {
                  r.action?.handler(
                    r.id || i,
                    r.data.map((d) => getText(d)),
                    col
                  );
                }}
              >
                {inner}
              </div>
            ) : (
              <div className="Table__row" key={`row${i}`}>
                {inner}
              </div>
            );
          })
          .filter((_) => _)}
    </div>
  );
}

export default App;
