const data = {
  category: "employment_insurance",
  table: "employment_insurance",
  subFields: {
    employment_insurance_type: {},
    employment_insurance_number: {},
    employment_insurance_on: {},
    loss_of_employment_insurance_on: {},
  },
};
export default data;
