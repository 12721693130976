import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import {
  getMyNumberViews,
  getFamilyData,
  selectMyNumberViewPerAccount,
  myNumberStatusDictionary,
} from "../myNumber/myNumberSlice";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import dayjs from "dayjs";

function MyNumberUpload() {
  // const dispatch = useAppDispatch();
  const [state, $state] = useState({
    isUploading: false, // アップロード中
    selected: false, // 何かしらがアップロードされた
    committed: false, // 送信済み
  });
  const [uploadingData, $uploadingData] = useState<
    {
      loginCode: string;
      accountName: string;
      myNumberName: string;
      relationship: string;
      value: string;
    }[]
  >([]);
  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    $state({ ...state, isUploading: true });
    try {
      // await dispatch(
      //   uploadRenamedColumns({
      //     sector: state.sectorId,
      //     file: decodedFileData.dataURI,
      //     name: decodedFileData.name,
      //   })
      // );
      console.log(decodedFileData);
      $uploadingData([
        {
          loginCode: "ito",
          accountName: "伊藤博",
          myNumberName: "伊藤博",
          relationship: "社員本人",
          value: "123412341234",
        },
        {
          loginCode: "kurosaki",
          accountName: "黒崎加奈",
          myNumberName: "黒崎加奈",
          relationship: "社員本人",
          value: "778899002233",
        },
        {
          loginCode: "kurosaki",
          accountName: "黒崎加奈",
          myNumberName: "黒崎みどり",
          relationship: "家族",
          value: "818181818181",
        },
        {
          loginCode: "sasaki",
          accountName: "佐々木朱音",
          myNumberName: "佐々木壮太",
          relationship: "配偶者",
          value: "711182229333",
        },
        {
          loginCode: "sasaki",
          accountName: "佐々木朱音",
          myNumberName: "佐々木陽菜",
          relationship: "家族",
          value: "833394440222",
        },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      $state({ ...state, isUploading: false, selected: true });
    }
  };

  const commit = async () => {
    if (state.isUploading) return;
    $state({ ...state, isUploading: true });
    try {
      await new Promise<void>((resolve, reject) => {
        setTimeout(() => resolve(), 1000);
      });
    } catch (error) {
    } finally {
      $state({ ...state, isUploading: false, committed: true });
    }
  };

  const downloadFormat = () => {
    return;
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー - 一括アップロード</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            {!state.committed && !state.selected && (
              <div>
                <Row>
                  <Col>
                    <h3 className="Headline--section-sub mb-2">アップロード</h3>
                    <Uploader
                      onFileLoad={onFileLoad}
                      accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">登録用ファイルのダウンロード</h3>
                    <div className="mb-2">アップロード可能な形式のファイルをダウンロードできます。</div>
                    <div className="mt-2">
                      <Button onClick={downloadFormat} variant="outline-primary">
                        記入例のみのファイルをダウンロード
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Button onClick={downloadFormat} variant="outline-primary">
                        未登録分の一括登録用のファイルをダウンロード
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {state.committed && (
              <Row>
                <Col>
                  <div>
                    アップロードのリクエストを受け付けました。この処理には時間がかかる場合があります。処理が完了しましたら、処理結果を通知します。
                  </div>
                </Col>
              </Row>
            )}
            {!state.committed && uploadingData.length > 0 && (
              <Row className="mt-4">
                <Col>
                  <Alert variant="info">登録可能です。</Alert>
                  <div className="mb-2 flex">
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        $uploadingData([]);
                        $state({ ...state, selected: false });
                      }}
                      className="mx-1"
                    >
                      キャンセル
                    </Button>
                    <Button variant="primary" onClick={commit}>
                      登録
                    </Button>
                  </div>
                  <Table
                    col={[
                      {
                        name: "社員のログインID",
                      },
                      {
                        name: "社員名",
                      },
                      {
                        name: "マイナンバー保有者名",
                      },
                      {
                        name: "関係性",
                        width: 100,
                      },
                      {
                        name: "マイナンバー",
                      },
                    ]}
                    row={uploadingData.map((d, di) => ({
                      id: di,
                      data: [d.loginCode, d.accountName, d.myNumberName, d.relationship, d.value],
                    }))}
                    usePagenation={true}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MyNumberUpload;
