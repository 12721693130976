const data = {
  category: "project",
  table: "project",
  subFields: {
    project_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    project_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    project_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    project_role_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      project_history: "プロジェクト履歴",
    },
  },
};

export default data;
