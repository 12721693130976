import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import Sidebar from "../../component/Sidebar";
import {
  getFamilyData,
  getMyNumber,
  getMyNumberViews,
  MyNumber,
  postMyNumber,
  selectMyNumberState,
  selectMyNumberViewPerAccount,
  clearMyNumberValue,
  getStatusMessages,
} from "./myNumberSlice";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import { useNavigate } from "react-router-dom";
import { MY_NUMBER_DISPLAY_MS } from "./myNumberValues";

function MyNumberUserDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selfAccount } = useAppSelector(selectProfileState);
  const { myNumberViews, myNumberValue, statusMessages } = useAppSelector(selectMyNumberState);
  const views = useAppSelector(selectMyNumberViewPerAccount);
  const [displayRequestedId, $displayRequestedId] = useState<string>("");
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");

  useEffect(() => {
    if (selfAccount?.id) {
      dispatch(getMembers({ accountId: selfAccount.id }));
      dispatch(getMyNumberViews({ accountId: selfAccount.id }));
      dispatch(getFamilyData({ account_id: selfAccount.id }));
    }
  }, [selfAccount]);
  const matchedView = useMemo(() => {
    if (!selfAccount?.id) return null;
    return views.find((a) => a.accountId === selfAccount.id);
  }, [views, selfAccount]);

  const selfMyNumberViews = useMemo(() => {
    if (!selfAccount?.id) return [];
    return myNumberViews.filter((a) => a.account_id === selfAccount.id);
  }, [myNumberViews]);

  useEffect(() => {
    // 差し戻し時のメッセージを取得
    const rejectedMyNumberIds = selfMyNumberViews.filter((d) => d.status === "rejected").map((d) => d.id) as string[];
    if (rejectedMyNumberIds.length > 0) {
      dispatch(getStatusMessages({ myNumberIds: rejectedMyNumberIds }));
    }
  }, [selfMyNumberViews]);

  useEffect(() => {
    // マイナンバーの値は、一定時間表示された後にクリアする
    if (myNumberValue !== "") setTimeout(() => dispatch(clearMyNumberValue()), MY_NUMBER_DISPLAY_MS);
  }, [myNumberValue]);

  const handler = ({ id: myNumberId, action, name }: { id: string; action: string; name: string }) => {
    if (action === "browse") {
      $displayRequestedId(myNumberId);
      dispatch(getMyNumber({ id: myNumberId }));
    } else if (action === "register") {
      const dummyMyNumberView = (() => {
        const data = views.find((a) => a.accountId === matchedView?.accountId);
        if (!data) return;
        const matched = [data.self, data.spouse, ...data.dependent].find((d) => d.myNumber?.id === myNumberId);
        return matched?.myNumber;
      })();
      if (dummyMyNumberView?.id?.startsWith("_temp")) {
        dispatch(
          postMyNumber({
            type: dummyMyNumberView?.type ?? "self",
            dependentSerial: dummyMyNumberView?.dependent_serial ?? undefined,
          })
        ).then((res: any) => {
          const id = res.payload.id;
          navigate(`/_/my_number/user/edit/${id}`);
        });
        return;
      } else if (dummyMyNumberView?.id) {
        navigate(`/_/my_number/user/edit/${dummyMyNumberView.id}`);
      }
    } else if (action === "list_logs") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
              </Col>
            </Row>
            {matchedView && (
              <div>
                <Row>
                  <Col>
                    <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                    <MyNumberRow
                      id={matchedView.self.myNumber?.id ?? ""}
                      value={matchedView.self.myNumber?.id === displayRequestedId ? myNumberValue : ""}
                      name={matchedView.self.account?.name ?? ""}
                      status={matchedView.self.myNumber?.status ?? ""}
                      status_reason={statusMessages[matchedView.self.myNumber?.id ?? ""]}
                      canReview={false}
                      handler={handler}
                    />
                  </Col>
                </Row>
                {matchedView.spouse.account || matchedView.dependent.some((d) => d.account) ? (
                  <Row className="--align-items-center mt-4">
                    <Col>
                      <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                      {matchedView.spouse?.account && matchedView.spouse?.myNumber && (
                        <MyNumberRow
                          id={matchedView.spouse.myNumber.id}
                          value={matchedView.spouse.myNumber.id === displayRequestedId ? myNumberValue : ""}
                          name={matchedView.spouse.account.name}
                          status={matchedView.spouse.myNumber.status}
                          status_reason={statusMessages[matchedView.spouse.myNumber.id]}
                          canReview={false}
                          handler={handler}
                          className="mb-1"
                        />
                      )}
                      {matchedView.dependent.length > 0 &&
                        matchedView.dependent.map((d) => {
                          if (!d.account || !d.myNumber) return null;
                          return (
                            <MyNumberRow
                              key={d.myNumber.id}
                              id={d.myNumber.id}
                              value={d.myNumber.id === displayRequestedId ? myNumberValue : ""}
                              name={d.account.name}
                              status={d.myNumber.status}
                              status_reason={statusMessages[d.myNumber.id]}
                              canReview={false}
                              handler={handler}
                              className="mb-1"
                            />
                          );
                        })}
                    </Col>
                  </Row>
                ) : null}
              </div>
            )}
            <MyNumberLogsModal
              isActive={isLogModalActive}
              name={targetAccountName}
              myNumber={targetMyNumber}
              onClose={() => $isLogModalActive(false)}
            />
          </Container>
        </main>
      </div>
    </div>
  );
}

export default MyNumberUserDetailView;
