export const canUseLowQualityMode = (() => {
  let canvas = document.createElement("canvas");
  let x = "function" === typeof canvas.getContext && canvas.getContext("2d");
  if (!x) return false;
  try {
    canvas.width = 10;
    canvas.height = 10;
    x.fillStyle = "#000";
    x.fillRect(0, 0, canvas.width, canvas.height);
    x.fill();
    let result = canvas.toDataURL("image/jpeg", 0.6);
    return !!(result && result.indexOf("image/jpeg") !== -1);
  } catch (e) {
    return false;
  }
})();

export const modify = (o: {
  image: string;
  width?: number;
  height?: number;
  quality: number;
  ratio: number;
  fileType?: string;
}) => {
  return new Promise<{ image: string }>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const x = "function" === typeof canvas.getContext && canvas.getContext("2d");
    if (!x) return reject(new Error("Canvas is not supported."));

    try {
      canvas.width = o.width || 200;
      canvas.height = o.height || 200;
      const img = new Image();
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        const l = ((w > h ? h : w) / 10) * 8; // 短辺の80%を基準の長さにする
        const ratio = "number" === typeof o.ratio ? o.ratio : 1; // 描画範囲の縦横比（w/h)
        const q = o.quality > 0 && o.quality <= 1 ? o.quality : 1;
        const fileType = q === 1 || o.fileType === "image/png" ? "image/png" : "image/jpeg";
        x.drawImage(
          img,
          (w - l) / 2, // 元の画像のクリップ範囲の左上にあたるx座標
          (h - l) / 2, // 元の画像のクリップ範囲の左上にあたるy座標
          l, // クリップ範囲のx方向の長さ
          l * ratio, // クリップ範囲のy方向の長さ
          0, // 描画範囲の左上のx座標
          0, // 描画範囲の左上のy座標
          canvas.width, // 描画範囲のx方向の長さ
          canvas.height // 描画範囲のy方向の長さ
        );
        resolve({
          image: canvas.toDataURL(fileType, q),
        });
      };
      img.src = o.image;
    } catch (e) {
      reject(e);
    }
  });
};

export const rotate = (o: { image: string; quality: number; fileType?: string }) => {
  return new Promise<{ image: string }>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const x = "function" === typeof canvas.getContext && canvas.getContext("2d");
    if (!x) return reject(new Error("Canvas is not supported."));

    try {
      const img = new Image();
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        const q = o.quality > 0 && o.quality <= 1 ? o.quality : 1;
        const fileType = q === 1 || o.fileType === "image/png" ? "image/png" : "image/jpeg";
        canvas.width = w;
        canvas.height = h;
        x.translate(canvas.width / 2, canvas.height / 2);
        x.rotate((90 / 180) * Math.PI);
        x.drawImage(
          img,
          (canvas.width / 2) * -1, // 描画範囲左上のx座標
          (canvas.height / 2) * -1, // 描画範囲左上のy座標
          w,
          h
        );
        resolve({
          image: canvas.toDataURL(fileType, q),
        });
      };
      img.src = o.image;
    } catch (e) {
      reject(e);
    }
  });
};
