import { useEffect, useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import OrderEditor from "../../component/OrderEditor";
import {
  selectPermissionState,
  getRoleBehaviors,
  getApiSummaries,
  commitRoleBehaviors,
  putRolePriority,
} from "./permissionSlice";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const { roleBehaviors, apiSummaries } = useAppSelector(selectPermissionState);
  const [state, $state] = useState({
    order: [] as { id: string; label: string; fixed: boolean }[],
    isSaving: false,
  });
  const [activeModal, $activeModal] = useState("");

  useEffect(() => {
    if (state.isSaving) return;
    $state({
      ...state,
      order: roleBehaviors
        .map((r) => {
          return {
            id: r.roleName,
            label: r.label,
            fixed: r.isReadymadeRole,
            order: r.order,
          };
        })
        .sort((a, b) => {
          if (a.fixed && !b.fixed) return -1;
          else if (!a.fixed && b.fixed) return 1;
          else return a.order - b.order;
        }),
    });
  }, [roleBehaviors]);

  useEffect(() => {
    dispatch(getApiSummaries());
  }, []);
  useEffect(() => {
    if (apiSummaries.length > 0) dispatch(getRoleBehaviors({ apiSummaries }));
  }, [apiSummaries.length]);

  const save = async () => {
    $state({ ...state, isSaving: true });
    const actions = state.order
      .map(({ id, fixed }, i) => {
        const roleBehavior = roleBehaviors.find((r) => r.roleName === id);
        if (fixed || !roleBehavior) return;
        return putRolePriority({
          roleDocId: roleBehavior.roleDocId,
          order: i,
        });
      })
      .filter((_) => _);
    await Promise.all(actions.map((a) => dispatch(a)));
    $state({ ...state, isSaving: false });
  };
  return (
    <Container>
      <Row className="mt-4">
        <Col>上から順に表示が優先されます。</Col>
        <Row className="mt-4 mb-3">
          <Col>
            <Button
              onClick={() => {
                $activeModal("before_save");
              }}
              className="mx-1"
              variant="primary"
            >
              保存
            </Button>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col>
          <OrderEditor
            data={state.order}
            onChange={(order) => {
              $state({ ...state, order: order });
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            onClick={() => {
              $activeModal("before_save");
            }}
            className="mx-1"
            variant="primary"
          >
            保存
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={activeModal === "before_save"}
        onConfirm={() => {
          $activeModal("");
          save();
        }}
        onCancel={() => $activeModal("")}
        message={"保存します。よろしいですか？"}
      />
    </Container>
  );
}

export default App;
