const data = {
  category: "bonus",
  table: "bonus",
  subFields: {
    payment_bonus_base: {},
    payment_bonus_board: {},
    payment_bonus_item1: {},
    payment_bonus_item2: {},
    payment_bonus_item3: {},
    payment_bonus_item4: {},
    payment_bonus_item5: {},
    payment_bonus_item6: {},
    payment_bonus_item7: {},
    payment_bonus_item8: {},
    payment_bonus_item9: {},
    payment_bonus_item10: {},
    deduction_health_insurance: {},
    deduction_health_care_insurance: {},
    deduction_health_pension_insurance: {},
    deduction_welfare_pension_fund: {},
    deduction_employment_insurance: {},
    deduction_income_tax: {},
    deduction_item1: {},
    deduction_item2: {},
    deduction_item3: {},
    deduction_item4: {},
    deduction_item5: {},
    deduction_item6: {},
    deduction_item7: {},
    deduction_item8: {},
    deduction_item9: {},
    deduction_item10: {},
    other_total_amount_insurance: {},
    other_total_amount: {},
    other_amount_payment_taxable: {},
    other_amount_payment_non_taxable: {},
    other_taxable_amount: {},
    other_tax_over_under: {},
    other_total_deductions: {},
    other_amount_after_deduction: {},
    other_amount_bank1: {},
    other_amount_bank2: {},
    other_amount_bank3: {},
    other_amount_cash: {},
    remarks: {},
  },
};
export default data;
