const data = {
  category: "job",
  table: "job",
  subFields: {
    job_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    job_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    job_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      job_history: "職種履歴",
    },
  },
};

export default data;
