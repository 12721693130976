import { LangDictionary } from "../../app/translate";
const Dictionary = {
  // todo type
  TODO_TYPE_40_YEARS_OLD: { ja: "40歳到達", en: "40 years old" },
  TODO_TYPE_60_YEARS_OLD: { ja: "60歳到達", en: "60 years old" },
  TODO_TYPE_65_YEARS_OLD: { ja: "65歳到達", en: "65 years old" },
  TODO_TYPE_JOINED_ON: { ja: "入社日", en: "joined on" },
  TODO_TYPE_LICENSE_EXPIRATION_ON: { ja: "資格有効期限", en: "license expiration on" },
  TODO_TYPE_TERM_CONTRACT_END_ON: { ja: "有期雇用契約満了", en: "term contract end on" },
  TODO_TYPE_RESIGNED_ON: { ja: "退職日", en: "resigned on" },
  // period type
  PERIOD_TYPE_RELEVANT_YEAR: { ja: "該当年", en: "applicable year" },
  PERIOD_TYPE_RELEVANT_MONTH: { ja: "該当月", en: "applicable month" },
  PERIOD_TYPE_MONTH_BEFORE: { ja: "か月前", en: "months before" },
  PERIOD_TYPE_MONTH_AFTER: { ja: "か月後", en: "months before" },
  PERIOD_TYPE_DAY_BEFORE: { ja: "日前", en: "days before" },
  PERIOD_TYPE_DAY_AFTER: { ja: "日後", en: "days after" },
  PERIOD_TYPE_DAY_EXACT: { ja: "当日", en: "the day" },
  // interval type
  INTERVAL_TYPE_YEARLY: { ja: "毎年", en: "yearly" },
  INTERVAL_TYPE_MONTHLY: { ja: "毎月", en: "monthly" },
  INTERVAL_TYPE_DAILY: { ja: "毎日", en: "daily" },
  // due date type
  DUE_DATE_BASE_TARGET_DATE: { ja: "対象日の", en: "target date" },
  DUE_DATE_BASE_REMIND_DATE: { ja: "作成日の", en: "notification date" },
  // subject type
  SUBJECT_TYPE_SELF: { ja: "本人", en: "the person themselves" },
  SUBJECT_TYPE_ROLE: { ja: "ロール", en: "roles" },
  SUBJECT_TYPE_SECTION: { ja: "部署", en: "section" },
  SUBJECT_TYPE_POSITION: { ja: "役職", en: "positions" },
} as LangDictionary;
export default Dictionary;
