const data = {
  category: "resignee",
  table: "resignee",
  subFields: {
    resignee_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_reason_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_reason_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    absence_in_last_month_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_agreement_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_termination_notice_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    non_renewal_clause_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_renewal_from_worker_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    documentation_of_unemployement_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    optional_continuation_insurance_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_resident_taxes_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    transfer_position_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    transfer_workplace_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      resignee_history: "退職履歴",
    },
  },
};

export default data;
