import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Form, ListGroup, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { fieldViewData, USER_TABLE_PREFIX, MultiTablesDownloadPolicy } from "../../features/profile/profileValues";
import { downloadMultiTableList } from "../../features/profile/profileSlice";
import { userColumnChoices } from "./profileFieldValues";
import FieldListSelector from "../../features/profile/FieldListSelector";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import { selectClientState, selectActiveSectors } from "../../features/client/clientSlice";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { RegularColumn } from "../client/clientValues";
import ModalDialog from "../../component/ModalDialog";
import OrderEditor from "../../component/OrderEditor";

function ProfileList() {
  const dispatch = useAppDispatch();
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const [state, $state] = useState({
    selectedPointDate: dayjs(),
    fieldViewSectors: [] as fieldViewData[],
    dateError: "",
    retreiveOptions: [
      {
        id: "latest",
        label: "指定日時点の最新データ",
        checked: false,
      },
      {
        id: "by_date",
        label: "指定日までの全データ",
        checked: false,
      },
      {
        id: "matched",
        label: "指定日と開始日が一致する全データ",
        checked: true,
      },
      {
        id: "by_today",
        label: "開始日が指定日から本日までの全データ",
        checked: false,
      },
      {
        id: "updated_by_today",
        label: "指定日から本日までに作成・更新されたデータ",
        checked: false,
      },
      {
        id: "created_before_and_updated_by_today",
        label: "指定日より前に作成され、指定日から本日までに更新されたデータ",
        checked: false,
      },
    ] as {
      id: MultiTablesDownloadPolicy;
      label: string;
      checked: boolean;
    }[],
    enrollmentTypeOptions: userColumnChoices["profile_u_personal"]["enrollment_type"].map((e, i) => {
      return {
        id: `enrollment_type${i + 1}`,
        label: e,
        checked: ["在籍", "休職"].includes(e),
      };
    }),
    dateOptions: [
      {
        id: "format1",
        label: "yyyy-MM-dd",
        checked: true,
      },
      {
        id: "format2",
        label: "yyyy/MM/dd",
        checked: false,
      },
    ],
    dataOptions: [
      {
        id: "include_updated_at",
        label: "最新更新日付を出力する",
        checked: true,
      },
    ],
    order: [] as { label: string; id: string; fixed: boolean }[],
  });
  const [isModalActive, $isModalActive] = useState(false);
  const selectField = (tableName: string) => {
    $state({
      ...state,
      fieldViewSectors: state.fieldViewSectors.map((sector) => {
        if (sector.id !== tableName) return sector;
        return { ...sector, checked: !sector.checked };
      }),
      order: state.order.some((_) => _.id === tableName)
        ? state.order.filter((_) => _.id !== tableName)
        : [
            ...state.order,
            {
              id: tableName,
              label:
                activeSectors.find((_) => _.sector_id === `${USER_TABLE_PREFIX}${tableName}`)?.logical_name ?? "--",
              fixed: false,
            },
          ],
    });
  };

  const download = () => {
    dispatch(
      downloadMultiTableList({
        date: state.selectedPointDate,
        policy: state.retreiveOptions.find((_) => _.checked)?.id ?? "matched",
        tables: state.order.map((_) => _.id),
        options: (() => {
          return {
            enrollment_type__in: state.enrollmentTypeOptions.filter((_) => _.checked).map((_) => _.label) as string[],
            ...state.dataOptions.reduce((prev, current) => {
              return { ...prev, [current.id]: current.checked };
            }, {} as { [key: string]: boolean }),
            dateFormat: state.dateOptions.find((_) => _.checked)?.label ?? "format1",
          };
        })(),
      })
    );
    $isModalActive(true);
  };

  // 表示項目を設定
  useEffect(() => {
    if (activeSectors.length > 0 && state.fieldViewSectors.length === 0) {
      // 表示項目データ作成
      let fieldViewSectors = [] as fieldViewData[];
      const userDataSectors = activeSectors.filter((s) => s.sector_id.startsWith(USER_TABLE_PREFIX));
      for (const i in userDataSectors) {
        const sector = userDataSectors[i];
        const sectorId = sector.sector_id;
        const regularColumns = sectorRegularColumns[sectorId];
        const subFields = regularColumns
          ? regularColumns.map((column: RegularColumn) => {
              const { id, label } = column;
              return {
                id,
                label,
                checked: false,
              };
            })
          : [];
        const field = {
          id: sectorId.replace(USER_TABLE_PREFIX, ""),
          label: sector.logical_name,
          checked: false,
          subFields,
        };
        fieldViewSectors = [...fieldViewSectors, field];
      }
      $state({ ...state, fieldViewSectors });
    }
  }, [activeSectors, state.fieldViewSectors]);

  return (
    <Container className="pt-4">
      <Row>
        <Col md="2">
          <div className="--bold pt-md-3">指定日</div>
        </Col>
        <Col md="10">
          {state.dateError && (
            <Row className="mb-2 --align-items-center">
              <Col>
                <Alert variant="warning">{state.dateError}</Alert>
              </Col>
            </Row>
          )}
          <Row className="--align-items-center">
            <Col>
              <SnapshotTimeSelector
                selectedPointDate={state.selectedPointDate}
                onChange={({ selectedPointDate }: onChangeOption) => {
                  if (!selectedPointDate) return;
                  if (selectedPointDate.isAfter(dayjs(), "day")) {
                    $state({
                      ...state,
                      dateError: "本日までの日付から選択してください。",
                    });
                    return;
                  }
                  $state({
                    ...state,
                    dateError: "",
                    selectedPointDate,
                  });
                }}
              />
            </Col>
          </Row>
          <Row className="my-1 mt-2">
            <Col>
              {state.retreiveOptions.map((option, i) => {
                return (
                  <Form.Check
                    type="radio"
                    label={`${i + 1}. ${option.label}`}
                    key={`option_m_${option.id}`}
                    id={`option_m_${option.id}`}
                    checked={option.checked}
                    value={option.id}
                    onChange={() => {
                      $state({
                        ...state,
                        retreiveOptions: state.retreiveOptions.map((o) => {
                          return { ...o, checked: o.id === option.id };
                        }),
                      });
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="2">
          <div className="--bold pt-md-3">対象テーブル</div>
        </Col>
        <Col md="10">
          <FieldListSelector
            fields={state.fieldViewSectors}
            onFieldSelected={selectField}
            useSubFieldsTrigger={false}
          />
        </Col>
      </Row>
      {state.order.length > 0 ? (
        <Row className="mt-4 --align-items-center">
          <Col md="2">
            <div className="--bold">出力順序</div>
          </Col>
          <Col md="10">
            <OrderEditor
              data={state.order}
              onChange={(order) => {
                $state({
                  ...state,
                  order,
                });
              }}
            />
          </Col>
        </Row>
      ) : null}
      <Row className="mt-4 --align-items-center">
        <Col md="2">
          <div className="--bold">在籍区分</div>
        </Col>
        <Col md="10">
          {state.enrollmentTypeOptions.map((option) => {
            return (
              <Form.Check
                type="checkbox"
                label={option.label}
                key={`option_m_${option.id}`}
                id={`option_m_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    enrollmentTypeOptions: state.enrollmentTypeOptions.map((o) => {
                      if (o.id !== option.id) return o;
                      return { ...o, checked: !o.checked };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md="2">
          <div className="--bold">出力オプション</div>
        </Col>
        <Col md="10">
          {state.dataOptions.map((option) => {
            return (
              <Form.Check
                type="checkbox"
                label={option.label}
                key={`option_multiple_${option.id}`}
                id={`option_multiple_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    dataOptions: state.dataOptions.map((o) => {
                      if (o.id !== option.id) return o;
                      return { ...o, checked: !o.checked };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="2">
          <div className="--bold pt-md-3">日付の形式</div>
        </Col>
        <Col md="10">
          {state.dateOptions.map((option) => {
            return (
              <Form.Check
                type="radio"
                label={option.label}
                key={`option_multiple_${option.id}`}
                id={`option_multiple_${option.id}`}
                checked={option.checked}
                value={option.id}
                onChange={() => {
                  $state({
                    ...state,
                    dateOptions: state.dateOptions.map((o) => {
                      return { ...o, checked: o.id === option.id };
                    }),
                  });
                }}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button variant="primary" disabled={state.order.length === 0} onClick={download}>
            ダウンロード
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={isModalActive}
        onConfirm={() => $isModalActive(false)}
        message="ファイルの作成を開始しました。ダウンロード可能になったら通知されます。"
        type="alert"
      />
    </Container>
  );
}

export default ProfileList;
