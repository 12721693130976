import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type ManualError = {
  type: string;
  message: string;
  id?: string;
};
interface FatalErrorState {
  fatalError: ManualError[];
  error: ManualError[];
}

/*
  state の初期状態
*/
const initialState: FatalErrorState = {
  fatalError: [],
  error: [],
};

export const slice = createSlice({
  name: "fatalError",
  initialState,
  reducers: {
    handleFatalError: (state, action) => {
      state.fatalError = [{ type: action.payload.type, message: action.payload.message }, ...state.fatalError];
    },
    handleError: (state, action) => {
      state.error = [
        { type: action.payload.type, message: action.payload.message, id: crypto.randomUUID() },
        ...state.error,
      ];
    },
    deleteError: (state, action) => {
      state.error = state.error.filter((error) => error.id !== action.payload);
    },
  },
});

export const { handleFatalError, handleError, deleteError } = slice.actions;

export const selectFatalErrorState = (state: RootState) => {
  return state.fatalError as FatalErrorState;
};

const Module = {
  name: "fatalError",
  reducer: slice.reducer,
};
export default Module;
