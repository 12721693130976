import profileTodoTypesJson from "../../profile_todo_types.json";
// 通知設定
export type RemindSetting = {
  id?: string;
  key?: string;
  todo_type: string;
  title: string;
  content?: string;
  period_type: string;
  period_value?: number | null;
  interval_type: string;
  timing?: number | null;
  subject_type: string;
  subject_values: string[];
  due_date_base: string;
  due_date_period_value: number;
  due_date_period_type: string;
  is_active: boolean;
};

export type Todo = {
  id: string;
  title: string;
  content?: string;
  subject: number[];
  object: number;
  created_at: number;
  due_date: Date;
  done: boolean;
  type: string;
};

export const PeriodTypes = {
  RELEVANT_YEAR: {
    isValueRequired: false,
    selectableIntervalTypes: ["YEARLY"],
  },
  RELEVANT_MONTH: {
    isValueRequired: false,
    selectableIntervalTypes: ["MONTHLY"],
  },
  MONTH_BEFORE: {
    isValueRequired: true,
    valueRange: [1, 12],
    selectableIntervalTypes: ["MONTHLY"],
    forDueDate: true,
  },
  MONTH_AFTER: {
    isValueRequired: true,
    valueRange: [1, 12],
    selectableIntervalTypes: ["MONTHLY"],
    forDueDate: true,
  },
  DAY_BEFORE: {
    isValueRequired: true,
    valueRange: [1, 31],
    selectableIntervalTypes: ["DAILY"],
    forDueDate: true,
  },
  DAY_AFTER: {
    isValueRequired: true,
    valueRange: [1, 31],
    selectableIntervalTypes: ["DAILY"],
    forDueDate: true,
  },
  DAY_EXACT: {
    isValueRequired: false,
    selectableIntervalTypes: ["DAILY"],
    forDueDate: true,
  },
} as {
  [key: string]: {
    isValueRequired: boolean;
    valueRange?: [number, number];
    selectableIntervalTypes?: string[];
    forDueDate?: boolean;
  };
};

export const IntervalTypes = {
  YEARLY: {
    isValueRequired: true,
    valueRange: [1, 12],
    unit: "月 1 日",
  },
  MONTHLY: {
    isValueRequired: true,
    valueRange: [1, 28],
    unit: "日",
  },
  DAILY: {
    isValueRequired: false,
  },
} as {
  [key: string]: {
    isValueRequired: boolean;
    valueRange?: [number, number];
    unit?: string;
  };
};

export const DueDateTypes = ["TARGET_DATE", "REMIND_DATE"];

export const GET_TODOS__DEFAULT_LIMIT = 10;

export const profileTodoTypes = (() => {
  const profileTodoTypes = profileTodoTypesJson as {
    [type: string]: { table_name: string; search_history?: boolean };
  };

  return Object.keys(profileTodoTypes).reduce((prev, current) => {
    const profileTodoType = profileTodoTypes[current];
    return {
      ...prev,
      [current]: {
        field: profileTodoType.table_name.replace("profile_u_", ""),
        searchHistory: profileTodoType.search_history ?? false,
      },
    };
  }, {}) as {
    [type: string]: {
      field: string;
      searchHistory: boolean;
    };
  };
})();

export const SubjectTypes = ["SELF", "ROLE", "POSITION", "SECTION"];
