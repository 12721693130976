import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { call } from "../../app/api";
import { toTimeLabel } from "../../app/util";
import dayjs from "dayjs";

const SLICE_NAME = "thread";

interface ThreadCommentItem {
  id: string;
  webScreen: string;
  body: string;
  author: number;
  isOpen: boolean;
  postedAt: number;
  lastUpdatedAt: number | null;
  replies: ThreadReplyItem[];
}

interface ThreadReplyItem {
  id: string;
  webScreen: string;
  body: string;
  author: number;
  threadId: string;
  mentionTos: number[];
  postedAt: number;
}

export interface ThreadCommentSummary {
  id: string;
  body: string;
  author: number;
  threadId: string;
  isOpen: boolean;
  isRecentComment: boolean;
  time: string;
  webScreen: string;
}

interface ThreadState {
  threadComments: ThreadCommentItem[];
  parentThreadComments: ThreadCommentItem[];
  allThreadComments: ThreadCommentItem[];
}

export type commentValue = {
  id: string;
  body: string;
  web_screen: string;
  thread_id: string;
  mention_tos: number[];
  sector: string;
  status: string;
  comment: string;
  is_open: boolean;
  last_updated_at: number;
};

/*
  state の初期状態
*/
const initialState: ThreadState = {
  threadComments: [],
  parentThreadComments: [],
  allThreadComments: [],
};

export const getThreadComments = createAsyncThunk(
  SLICE_NAME + "/getThreadComments",
  async ({ webScreen }: { webScreen?: string } | undefined = {}) => {
    if (!webScreen) return { result: [] as ThreadCommentItem[] };
    const parent = await call(
      "get",
      "activity_manager/thread_comment"
    )({
      web_screen: webScreen,
      thread_id: "",
    });
    const parentComments = parent.data.result.map((data: any) => ({
      id: data.id,
      body: data.body,
      isOpen: data.is_open,
      author: data.author,
      webScreen: data.web_screen,
      postedAt: data.updated_at,
      replies: [],
    })) as ThreadCommentItem[];
    const reply = await call("get", "activity_manager/thread_comment")({ thread_id__ne: null });
    const replyComments = reply.data.result.map((data: any) => ({
      id: data.id,
      body: data.body,
      author: data.author,
      postedAt: data.updated_at,
      threadId: data.thread_id,
      mentionTos: data.mention_tos,
    })) as ThreadReplyItem[];
    parentComments.map((parentComment) => {
      const replyList = replyComments.filter((_) => _.threadId === parentComment.id);
      parentComment.replies = replyList;
    });
    return { result: parentComments };
  }
);

export const getParentThreadComments = createAsyncThunk(SLICE_NAME + "/getParentThreadComments", async () => {
  const parents = await call("get", "activity_manager/thread_comment")({ thread_id: "" });
  const parentComments = parents.data.result
    .filter((data: any) => !data.thread_id)
    .map((data: any) => {
      return {
        id: data.id,
        body: data.body,
        isOpen: data.is_open,
        author: data.author,
        webScreen: data.web_screen,
        postedAt: data.updated_at,
        lastUpdatedAt: data.last_updated_at,
      };
    }) as ThreadCommentItem[];
  return { result: parentComments };
});

export const getAllThreadComments = createAsyncThunk(SLICE_NAME + "/getAllThreadComments", async () => {
  const allComments = await call("get", "activity_manager/thread_comment")();
  const parentComments = allComments.data.result
    .filter((data: any) => !data.thread_id)
    .map((data: any) => {
      return {
        id: data.id,
        body: data.body,
        isOpen: data.is_open,
        author: data.author,
        webScreen: data.web_screen,
        postedAt: data.created_at,
        replies: [],
      };
    }) as ThreadCommentItem[];
  const replyComments = allComments.data.result
    .filter((data: any) => data.thread_id)
    .map((data: any) => ({
      id: data.id,
      body: data.body,
      author: data.author,
      postedAt: data.created_at,
      threadId: data.thread_id,
      mentionTos: data.mention_tos,
    })) as ThreadReplyItem[];
  parentComments.map((parentComment) => {
    const replyList = replyComments.filter((_) => _.threadId === parentComment.id);
    parentComment.replies = replyList;
  });
  return { result: parentComments };
});

export const postThreadComment = createAsyncThunk(
  SLICE_NAME + "/postThreadComment",
  async ({ commentValue }: { commentValue: commentValue }) => {
    const newComment = await call(
      "post",
      "activity_manager/thread_comment"
    )({
      body: commentValue.body,
      mention_tos: commentValue.mention_tos,
      web_screen: commentValue.web_screen,
    });
    if (newComment.data.status >= 400) throw new Error("failed in post thread comment");
    const next = newComment.data.result.map((data: any) => {
      return {
        id: data.id,
        body: data.body,
        isOpen: true,
        author: data.author,
        webScreen: data?.web_screen,
        postedAt: data.updated_at,
        lastUpdatedAt: data.updated_at,
        replies: [],
      };
    }) as ThreadCommentItem[];
    return { result: next };
  }
);

export const postReplyComment = createAsyncThunk(
  SLICE_NAME + "/postReplyComment",
  async ({ commentValue }: { commentValue: commentValue }) => {
    const newComment = await call(
      "post",
      "activity_manager/thread_comment"
    )({
      body: commentValue.body,
      thread_id: commentValue.thread_id,
      mention_tos: commentValue.mention_tos,
      web_screen: commentValue.web_screen,
    });
    if (newComment.data.status >= 400) throw new Error("failed in post thread comment");
    const replyComments = newComment.data.result.map((data: any) => ({
      id: data.id,
      body: data.body,
      author: data.author,
      postedAt: data.updated_at,
      threadId: data.thread_id,
      mentionTos: data.mention_tos,
      webScreen: data?.web_screen,
    })) as ThreadReplyItem[];
    // 親コメントに更新日時を保存
    const params = {
      id: newComment.data.result[0].thread_id,
      last_updated_at: newComment.data.result[0].updated_at,
    } as commentValue;
    await call("put", "activity_manager/thread_comment")(params);
    return { result: replyComments[0] };
  }
);

export const putThreadComment = createAsyncThunk(
  SLICE_NAME + "/putThreadComment",
  async ({ commentValue }: { commentValue: commentValue }) => {
    const data = commentValue.body
      ? {
          id: commentValue.id,
          body: commentValue.body,
          mention_tos: commentValue.mention_tos,
        }
      : { id: commentValue.id, is_open: commentValue.is_open };
    const res = await call("put", "activity_manager/thread_comment")(data);
    if (res.data.status >= 400) throw new Error("failed in put thread comment");
    const result = !res.data.result[0].thread_id
      ? res.data.result
      : (res.data.result.map((data: any) => ({
          id: data.id,
          body: data.body,
          author: data.author,
          postedAt: data.updated_at,
          threadId: data.thread_id,
          mentionTos: data.mention_tos,
          webScreen: data?.web_screen,
        })) as ThreadCommentItem[]);
    const params = {} as commentValue;
    if (commentValue.body && res.data.result[0].thread_id) {
      // 親コメントに更新日時を保存
      params.id = res.data.result[0].thread_id;
      params.last_updated_at = res.data.result[0].updated_at;
      await call("put", "activity_manager/thread_comment")(params);
    }
    return { result };
  }
);

export const deleteThreadComment = createAsyncThunk(
  SLICE_NAME + "/deleteThreadComment",
  async ({ commentValue }: { commentValue: commentValue }) => {
    const res = await call("delete", `activity_manager/thread_comment?id=${commentValue.id}`)();
    if (res.data.status >= 400) throw new Error("failed in delete thread comment");
    return { result: res.data.result };
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getThreadComments.fulfilled, (state, action) => {
      state.threadComments = action.payload.result;
    });
    builder.addCase(getParentThreadComments.fulfilled, (state, action) => {
      state.parentThreadComments = action.payload.result;
    });
    builder.addCase(getAllThreadComments.fulfilled, (state, action) => {
      state.allThreadComments = action.payload.result;
    });
    builder.addCase(postThreadComment.fulfilled, (state, action) => {
      state.threadComments = [...state.threadComments, ...action.payload.result];
      state.parentThreadComments = [...state.parentThreadComments, ...action.payload.result];
    });
    builder.addCase(postReplyComment.fulfilled, (state, action) => {
      const next = state.threadComments.map((threadComment) => {
        if (threadComment.id === action.payload.result.threadId) {
          threadComment.replies = [...threadComment.replies, action.payload.result];
        }
        return threadComment;
      });
      state.threadComments = next;
    });
    builder.addCase(putThreadComment.fulfilled, (state, action) => {
      const next = state.threadComments.map((threadComment) => {
        if (!action.payload.result[0].threadId) {
          return {
            ...threadComment,
            body: threadComment.id === action.payload.result[0].id ? action.payload.result[0].body : threadComment.body,
            isOpen:
              threadComment.id === action.payload.result[0].id
                ? action.payload.result[0].is_open
                : threadComment.isOpen,
          };
        } else {
          if (threadComment.id === action.payload.result[0].threadId) {
            const newReplies = threadComment.replies.map((reply) => {
              return {
                ...reply,
                body: reply.id === action.payload.result[0].id ? action.payload.result[0].body : reply.body,
              };
            });
            threadComment.replies = newReplies;
          }
          return threadComment;
        }
      });
      state.threadComments = next;
    });
    builder.addCase(deleteThreadComment.fulfilled, (state, action) => {
      if (action.payload.result && !action.payload.result[0].thread_id) {
        // 親コメント削除
        const next = state.threadComments.filter((t) => t.id !== action.payload.result[0].id);
        state.threadComments = next;
      } else {
        // 返信コメント削除
        const next = state.threadComments.map((threadComment) => {
          if (threadComment.id === action.payload.result[0].thread_id) {
            threadComment.replies = threadComment.replies.filter((reply) => reply.id !== action.payload.result[0].id);
          }
          return threadComment;
        });
        state.threadComments = next;
      }
    });
  },
});

export const selectThreadState = (state: RootState) => {
  return state.thread as ThreadState;
};

export const selectComments = (state: RootState): ThreadCommentSummary[] => {
  return state.thread.parentThreadComments
    .reduce((prev: ThreadCommentItem[], current: ThreadCommentItem) => {
      return [...prev, current];
    }, [])
    .sort((a: ThreadCommentItem, b: ThreadCommentItem) => b.postedAt - a.postedAt)
    .map((c: any) => {
      return {
        id: c.id,
        body: c.body,
        author: c.author,
        time: toTimeLabel(c.lastUpdatedAt ?? c.postedAt),
        isRecentComment: dayjs().diff(c.lastUpdatedAt ?? c.postedAt, "hour") <= 24 * 3,
        isOpen: c.isOpen === true,
        webScreen: c.webScreen,
      };
    });
};

export const selectAllComments = (state: RootState): ThreadCommentSummary[] => {
  return state.thread.allThreadComments
    .reduce((prev: ThreadCommentItem[], current: ThreadCommentItem) => {
      return [...prev, current, ...current.replies];
    }, [])
    .sort((a: ThreadCommentItem | ThreadReplyItem, b: ThreadCommentItem | ThreadReplyItem) => b.postedAt - a.postedAt)
    .map((c: any) => {
      const parent = c.threadId ? state.thread.allThreadComments.find((_: any) => _.id === c.threadId) : c;
      return {
        id: c.id,
        body: c.body,
        author: c.author,
        time: toTimeLabel(c.postedAt),
        isRecentComment: dayjs().diff(c.postedAt, "hour") <= 24 * 3,
        threadId: parent.id,
        isOpen: parent?.isOpen === true,
        webScreen: parent.webScreen,
      };
    });
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
