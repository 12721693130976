import { useState, useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Container, Row, Col, Button, ListGroup, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import Sidebar from "../../component/Sidebar";
import ModalDialog from "../../component/ModalDialog";
import { getMyAccount, updateMyPassword, myAccountState } from "./myAccountSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { setLoading } from "../notification/notificationSlice";
import { validateAsync } from "../../app/validator";
import { getPasswordComplexityText } from "../permission/permissionValues";

function App() {
  const dispatch = useAppDispatch();
  const { myAccount } = useAppSelector(myAccountState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [activeModal, $activeModal] = useState("");
  const [updateErrorMessage, $updateErrorMessage] = useState("");
  const [next, $next] = useState({
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  } as {
    oldPassword: string;
    newPassword: string;
    rePassword: string;
  });
  const [oldPasswordError, $oldPasswordError] = useState("");
  const [newPasswordError, $newPasswordError] = useState("");
  const [rePasswordError, $rePasswordError] = useState("");

  const isValueError = useMemo(() => {
    if (!next.oldPassword || !next.newPassword || !next.rePassword) {
      return true;
    }
    if (oldPasswordError || newPasswordError || rePasswordError) {
      return true;
    }
    return false;
  }, [next, oldPasswordError, newPasswordError, rePasswordError]);

  const save = async () => {
    if (!myAccount || isValueError) return;
    $activeModal("before_commit");
  };

  const commit = async () => {
    if (!myAccount) {
      $activeModal("");
      return;
    }
    try {
      dispatch(setLoading(true));
      await dispatch(
        updateMyPassword({
          oldPassword: next.oldPassword,
          newPassword: next.newPassword,
          language: myAccount.language,
        })
      );
    } catch (e) {
      $activeModal("updateError");
      $updateErrorMessage("処理中にエラーが発生しました。");
    } finally {
      dispatch(setLoading(false));
      closeModal();
      $next({ oldPassword: "", newPassword: "", rePassword: "" });
    }
  };

  const closeModal = () => {
    $activeModal("");
  };

  const validateCurrentPassword = async () => {
    if (!next.oldPassword) {
      $oldPasswordError("入力してください。");
      return;
    }
    const valid: any = await validateAsync({
      key: "current",
      value: {
        password: next.oldPassword,
      },
      policy_company_code: current_company.code,
    });
    if (!valid.result) {
      $oldPasswordError("パスワードが異なります。");
    } else {
      $oldPasswordError("");
    }
  };

  const validateNewPassword = async () => {
    if (!next.newPassword) {
      $newPasswordError("入力してください。");
      return;
    }
    const [passwordLengthValidation, isPasswordComplexityValidation] = await Promise.all([
      validateAsync({
        key: "length",
        value: {
          password: next.newPassword,
        },
        policy_company_code: current_company.code,
      }) as Promise<{ result: boolean; length: number }>,
      validateAsync({
        key: "complexity",
        value: {
          password: next.newPassword,
        },
        policy_company_code: current_company.code,
      }) as Promise<{ result: boolean; complexity: number }>,
    ]);
    if (!passwordLengthValidation.result) {
      $newPasswordError(`パスワードは ${passwordLengthValidation.length} 文字以上で設定してください。`);
    } else if (!isPasswordComplexityValidation.result) {
      $newPasswordError(
        `パスワードは${getPasswordComplexityText(isPasswordComplexityValidation.complexity)}必要があります。`
      );
    } else {
      $newPasswordError("");
    }
  };

  const confirmNewPassword = async () => {
    if (!next.rePassword) {
      $rePasswordError("入力してください。");
      return;
    }
    if (next.newPassword !== next.rePassword) {
      $rePasswordError("パスワードが一致しません。");
    } else {
      $rePasswordError("");
    }
  };

  useEffect(() => {
    dispatch(getMyAccount());
  }, []);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="not_select" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">パスワードの変更</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-3">
              <Col>
                <Button onClick={save} disabled={isValueError} className="mx-2">
                  更新
                </Button>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <span className="--required-label"></span> は必須項目です。
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <ListGroup className="mb-4">
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true, "--required-label": true })}>現在のパスワード</div>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          id="oldPassword"
                          placeholder="現在のパスワードを入力"
                          onChange={(e) => {
                            $next({ ...next, oldPassword: e.target.value });
                          }}
                          onBlur={() => {
                            validateCurrentPassword();
                          }}
                          value={next.oldPassword}
                        />
                        {oldPasswordError && <div className="--text-annotation mt-1 --font-s">{oldPasswordError}</div>}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true, "--required-label": true })}>新規パスワード</div>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          id="newPassword"
                          placeholder="新規パスワードを入力"
                          onChange={(e) => {
                            $next({ ...next, newPassword: e.target.value });
                          }}
                          onBlur={() => {
                            validateNewPassword();
                          }}
                          value={next.newPassword}
                        />
                        {newPasswordError && <div className="--text-annotation mt-1 --font-s">{newPasswordError}</div>}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true, "--required-label": true })}>
                          パスワードの確認入力
                        </div>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          id="rePassword"
                          placeholder="パスワードの確認入力"
                          onChange={(e) => {
                            $next({ ...next, rePassword: e.target.value });
                          }}
                          onBlur={() => {
                            confirmNewPassword();
                          }}
                          value={next.rePassword}
                        />
                        {rePasswordError && <div className="--text-annotation mt-1 --font-s">{rePasswordError}</div>}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ModalDialog
        show={activeModal === "updateError"}
        onConfirm={() => {
          $activeModal("");
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message={updateErrorMessage}
        type="alert"
      />
      <ModalDialog
        show={activeModal === "before_commit"}
        onCancel={() => {
          $activeModal("");
        }}
        onConfirm={commit}
        message="更新しますか？"
      />
    </div>
  );
}

export default App;
