import { Form, Col, Row, Card, Tab, Nav, Container, OverlayTrigger, Tooltip, Breadcrumb } from "react-bootstrap";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  SECTION_SEARCH_LIMIT,
  clearFilteredSections,
  getParentCodeSections,
  selectSectionState,
  filterSections,
  getSections,
} from "./sectionSlice";
import { Section } from "./sectionValues";
import dayjs from "dayjs";
import Icon from "../../component/Icon";

function SectionSelector({
  selectedSectionCodes,
  onChange,
}: {
  selectedSectionCodes: string[];
  onChange: (selectedSectionCodes: string[]) => any;
}) {
  const [state, $state] = useState({
    keyword: "",
    timeoutId: null as number | null,
    sectionTracks: [""],
    activeTabKey: "hierarchy" as string,
    selectedSections: [] as Section[],
  });
  const dispatch = useAppDispatch();
  const { filteredSections, parentCodeSections } = useAppSelector(selectSectionState);

  const onChangeKeyWord = (keyword: string) => {
    // 入力が終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      if (keyword)
        dispatch(
          filterSections({
            baseDate: dayjs(),
            limit: SECTION_SEARCH_LIMIT,
            keyword,
          })
        );
      else dispatch(clearFilteredSections());
      $state({ ...state, timeoutId: null, keyword });
    }, 500);

    $state({ ...state, timeoutId, keyword });
  };

  const checkSection = (section: Section) => {
    // 部署にチェックされた
    const { sectionCode } = section;
    const selectedSections = state.selectedSections.some((section) => section.sectionCode === sectionCode)
      ? state.selectedSections
      : [...state.selectedSections, section];
    const nextCodes = selectedSectionCodes.includes(sectionCode)
      ? selectedSectionCodes.filter((_) => _ !== sectionCode)
      : [...selectedSectionCodes, section.sectionCode];
    onChange(nextCodes);
    $state({ ...state, selectedSections });
  };

  useEffect(() => {
    dispatch(clearFilteredSections());
    // 最初の階層の部署を取得
    dispatch(getParentCodeSections({ baseDate: dayjs() })).then((result: any) => {
      const topLevelSections = result.payload as { [parentCode: string]: Section[] };
      // 最上位の部署の子部署を取得
      dispatch(
        getParentCodeSections({
          baseDate: dayjs(),
          parentCodes: topLevelSections[""].map(({ sectionCode }) => sectionCode),
        })
      );
    });
    if (selectedSectionCodes.length) {
      // 既に選択済の部署を取得
      dispatch(
        getSections({
          baseDate: dayjs(),
          sectionCodes: selectedSectionCodes,
        })
      ).then((res: any) => {
        const selectedSections = res.payload as Section[];
        $state({ ...state, selectedSections });
      });
    }
    return () => {};
  }, []);

  const displaySections = useMemo(() => {
    return parentCodeSections[[...state.sectionTracks].reverse()[0]] ?? [];
  }, [state.sectionTracks, parentCodeSections]);

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-1">
            <Card.Header>選択中の部署</Card.Header>
            <Card.Body>
              <div className="--font-s mb-2">
                ※選択された部署直下に所属のアカウントのみ対象になります。
                子部署配下のアカウントも対象にしたい場合は、子部署も併せて選択してください。
              </div>
              <Row>
                {state.selectedSections.map((section) => {
                  return (
                    <Col md="6" key={`selected_section_${section.id}`}>
                      <Form.Check
                        checked={selectedSectionCodes.includes(section.sectionCode)}
                        id={`selected_section_${section.id}`}
                        type="checkbox"
                        label={section.name}
                        onChange={() => checkSection(section)}
                      />
                    </Col>
                  );
                })}
                {state.selectedSections.length === 0 && "（なし）"}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tab.Container
            onSelect={(key) => {
              $state({ ...state, activeTabKey: key ? key : "hierarchy" });
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              <Nav.Item key="hierarchy">
                <Nav.Link eventKey="hierarchy">階層検索</Nav.Link>
              </Nav.Item>
              <Nav.Item key="keyword">
                <Nav.Link eventKey="keyword">キーワード検索</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane key="hierarchy" eventKey="hierarchy">
                <Container className="mt-2">
                  <Breadcrumb>
                    {(() => {
                      const breadCrumb = [] as ReactNode[];
                      let sections = [] as Section[];
                      state.sectionTracks.forEach((st, i) => {
                        const active = i === state.sectionTracks.length - 1;
                        const section = sections.find((s) => s.sectionCode === st);
                        breadCrumb.push(
                          <Breadcrumb.Item
                            active={active}
                            key={`bread_crumb_${i}`}
                            onClick={() => {
                              const next = state.sectionTracks.slice(0, i + 1);
                              $state({ ...state, sectionTracks: next });
                            }}
                          >
                            {section?.name ?? "Top"}
                          </Breadcrumb.Item>
                        );
                        sections = parentCodeSections[st];
                      });
                      return breadCrumb;
                    })()}
                  </Breadcrumb>
                  <Row>
                    {displaySections?.map((section, i) => {
                      return (
                        <Col md="6" key={`display_section_${i}}`}>
                          <Form.Check
                            inline={true}
                            checked={selectedSectionCodes.includes(section.sectionCode)}
                            id={`display_section_${i}}`}
                            type="checkbox"
                            label={section.name}
                            onChange={() => checkSection(section)}
                          />
                          {parentCodeSections[section.sectionCode]?.length > 0 ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={(props) => (
                                <Tooltip id={`button-tooltip_${i}`} {...props}>
                                  子部署を表示
                                </Tooltip>
                              )}
                            >
                              <span
                                onClick={() => {
                                  // 未取得の場合のみ子部署を取得しにいく
                                  const parentCodes = (parentCodeSections[section.sectionCode] ?? [])
                                    .filter(({ sectionCode }) => !parentCodeSections[sectionCode])
                                    .map(({ sectionCode }) => sectionCode);
                                  if (parentCodes.length > 0) {
                                    dispatch(
                                      getParentCodeSections({
                                        baseDate: dayjs(),
                                        parentCodes,
                                      })
                                    );
                                  }
                                  $state({ ...state, sectionTracks: [...state.sectionTracks, section.sectionCode] });
                                }}
                              >
                                <Icon width={15} height={15} type="caret-right-square" className="__Link" />
                              </span>
                            </OverlayTrigger>
                          ) : null}
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </Tab.Pane>
              <Tab.Pane title="キーワード検索" key="keyword" eventKey="keyword">
                <Container className="mt-2">
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        id="search"
                        value={state.keyword}
                        className="mb-1"
                        placeholder="部署名で絞り込む"
                        onChange={(e) => {
                          onChangeKeyWord(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {filteredSections.map((section) => {
                      return (
                        <Col md="6" key={`filtered_section_${section.id}`}>
                          <Form.Check
                            checked={selectedSectionCodes.includes(section.sectionCode)}
                            id={`filtered_section_${section.id}`}
                            type="checkbox"
                            label={section.name}
                            onChange={() => checkSection(section)}
                          />
                        </Col>
                      );
                    })}
                    {filteredSections.length === SECTION_SEARCH_LIMIT && (
                      <Col>{`${SECTION_SEARCH_LIMIT}件以上存在します。選択したい部署が表示されていない場合はキーワードを変更してください。`}</Col>
                    )}
                    {filteredSections.length === 0 && (
                      <Col>該当する部署が存在しません。キーワードを変更してください。</Col>
                    )}
                  </Row>
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </>
  );
}

export default SectionSelector;
