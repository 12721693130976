const data = {
  category: "education",
  table: "education",
  subFields: {
    education_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    entry_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    graduation_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dropout_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    education_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    school_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    faculty_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    department_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    major_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      education_history: "学歴",
    },
  },
};

export default data;
