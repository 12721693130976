const data = {
  category: "duty",
  table: "duty",
  subFields: {
    duty_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    duty_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    duty_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      duty_history: "職務履歴",
    },
  },
};

export default data;
