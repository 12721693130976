const data = {
  category: "transportation_allowance",
  table: "transportation_allowance",
  subFields: {
    transportation_allowance_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    nearest_station_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    duration_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`transportation_${i}_name_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_start_segment_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_end_segment_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_via_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_interval_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_payment_start_month_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_allowance_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_means_type_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
          [`transportation_${i}_one_way_fare_$N`]: {
            tag: "tb_",
            tagGroupIndex: 1,
          },
        };
      });
      return _;
    })(),
    car_etc_means_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    car_etc_means_specific_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    car_etc_distance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    car_etc_allowance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    car_etc_interval_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    car_etc_payment_start_month_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      transportation_allowance_history: "通勤手当履歴",
    },
  },
};

export default data;
