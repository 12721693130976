const data = {
  category: "union",
  table: "union",
  subFields: {
    union_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    union_member_category_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    union_member_category_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    union_post_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    union_post_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      union_history: "組合履歴",
    },
  },
};

export default data;
