import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import { getMembers } from "../profile/profileSlice";
import { getRoles, selectPermissionState, getPermissions, getRoleBehaviors, getApiSummaries } from "./permissionSlice";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { roleBehaviors, apiSummaries } = useAppSelector(selectPermissionState);
  useEffect(() => {
    dispatch(getRoles());
    dispatch(getMembers());
    dispatch(getPermissions({ conditions: {}, page: 1 }));
    dispatch(getApiSummaries());
  }, []);
  useEffect(() => {
    if (apiSummaries.length > 0) dispatch(getRoleBehaviors({ apiSummaries }));
  }, [apiSummaries.length]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Link to={"/_/masters/role/create"}>
            <Button className="mx-1" variant="outline-primary">
              ロールを作成する
            </Button>
          </Link>
          <Link to={"/_/masters/role/priority"}>
            <Button className="mx-1" variant="outline-primary">
              表示優先度を変更する
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Table
            col={[
              { name: "ロール名", width: "30%" },
              { name: "説明", width: "70%" },
            ]}
            row={roleBehaviors.map((r) => {
              return {
                data: [`${r.label} (${r.roleName})`, r.description],
                link: `/_/masters/role/edit/${r.roleName}`,
              };
            })}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
