const data = {
  category: "license",
  table: "license",
  subFields: {
    license_code: {},
    license_acquisition_on: {},
    expiration_on: {},
    license_number: {},
    remarks: {},
    relation_file_id: {},
  },
};
export default data;
