const data = {
  category: "mid_career",
  table: "mid_career",
  subFields: {
    mid_career_income_amount: {},
    mid_career_social_insurance: {},
    mid_career_income_tax: {},
    joining_resident_taxes_type: {},
  },
};
export default data;
