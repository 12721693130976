const data = {
  category: "personal",
  table: "personal",
  subFields: {
    name: {},
    last_name: { virtual_field: true },
    first_name: { virtual_field: true },
    name_kana: {},
    last_name_kana: { virtual_field: true },
    first_name_kana: { virtual_field: true },
    name_en: {},
    last_name_en: { virtual_field: true },
    first_name_en: { virtual_field: true },
    employment_type: {},
    enrollment_type: {},
    gender_type: {},
    blood_type: {},
    birthday: {},
    age: {},
    extension_phone_number: {},
    outside_phone_number: {},
    company_cellphone_number: {},
    joined_on: {},
    resigned_on: {},
    joined_years: {},
    exclusion_years: { defaultValue: 0 },
    is_acceptance: {},
    birthplace: {},
    is_solo_transfer: {},
    marital_status_type: {},
    previous_name: { editable: false },
    previous_last_name: {},
    previous_first_name: {},
    previous_name_kana: { editable: false },
    previous_last_name_kana: {},
    previous_first_name_kana: {},
    remarks: {},
  },
};

export default data;
