import { useContext, createContext } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "./app/store";
import { selectTokenState, selectIsIdTokenExpired } from "./features/login/idTokenSlice";
import { selectUserState } from "./features/login/userSlice";

interface AuthContextType {
  idToken: string;
  roles: string[];
  initialized: boolean;
  location: {
    pathname: string;
    hash: string;
    search: string;
  };
}

let AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { idToken } = useAppSelector(selectTokenState);
  const { roles, initialized } = useAppSelector(selectUserState);

  const value = {
    idToken,
    roles,
    initialized,
    location: {
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    },
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children, role, path }: { children: JSX.Element; role: string[]; path?: string }) {
  const { idToken, roles, initialized, location } = useAuth();
  const { pathname, hash, search } = location;
  const { user } = useAppSelector(selectUserState);
  const isSessionTimeout = useAppSelector(selectIsIdTokenExpired);
  if (!initialized) return children;

  /*
    - 画面表示を許可する処理
  */
  if (role.length === 0) return children;
  if (!idToken) {
    window.location.href = "/";
  }
  if (initialized && user.profile_roles.length === 0) {
    // アセスメントのロールしかない場合はアセスメントのダッシュボードへ
    window.location.href = "/assess/dashboard";
    return <div></div>;
  }
  // ロールはプロファイルのロールがあればOKとする（TODO: 問題なければおいおい削除）
  // if (
  //   (() => {
  //     // 必要なロールを判定
  //     const testers = role.map((r) => {
  //       let roleStory = r.split(".").filter((_) => _);
  //       let actualPathStory = pathname.split("/").filter((_) => _);
  //       return (v: string, path?: string) => {
  //         const values = v.split(".").filter((_) => _);
  //         if (values[0] !== roleStory[0]) {
  //           // "admin", "user" など最上位の権限が不足
  //           return false;
  //         }
  //         if (roleStory.length === 1) return true;
  //         if (values[1] !== roleStory[1]) {
  //           // "admin.ROOM", "user.ROOM" など下の権限が不足
  //           return false;
  //         }
  //         if (roleStory.length === 2) return true;
  //         try {
  //           const pathMap = path ? path.split("/").filter((_) => _) : [""];
  //           const option = JSON.parse(roleStory[2]);
  //           const optionalCheckResults: boolean[] = Object.keys(option).map((key) => {
  //             const thisOptionsIndex = pathMap.findIndex((a) => a === `:${key}`);
  //             const valueFromActualPath = actualPathStory[thisOptionsIndex]; // 具体的な ID など
  //             const testTarget = values[2]; // JSON
  //             const thisUsersOption = JSON.parse(testTarget); // { [key]: [ID,ID] }]
  //             return thisUsersOption[key].includes(valueFromActualPath);
  //           });
  //           return optionalCheckResults.every((_) => _);
  //         } catch (e) {
  //           return false;
  //         }
  //       };
  //     });
  //     const result = roles.some((value) => testers.some((t) => t(value, path)));
  //     return !result;
  //   })()
  // ) {
  //   // ロールが不足している
  //   window.location.href = `/_/no_enough_role/?roles=${roles.join(",")}`;
  // } else if (isSessionTimeout) {
  if (isSessionTimeout) {
    // セッションタイムアウト
    localStorage.setItem("returnTo", `${pathname}${hash}${search}`);
    window.location.href = `/_/session_timeout/`;
  }
  return children;
}
