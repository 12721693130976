import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { selectClientState, getPayrollSetting, postPayrollSetting, putPayrollSetting } from "./clientSlice";
import { PayrollSetting, PAYROLL_SYSTEMS } from "../client/clientValues";
import ModalDialog from "../../component/ModalDialog";
import { useNavigate } from "react-router-dom";

function PayrollSettingEdit() {
  const { payrollSetting } = useAppSelector(selectClientState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, $state] = useState({
    activeModal: "",
    payrollSystem: "",
  });

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, [dispatch]);

  const _setInitialValues = (payrollSetting: PayrollSetting) => {
    $state({
      ...state,
      payrollSystem: payrollSetting.payroll_system,
    });
  };

  useEffect(() => {
    if (payrollSetting.id) {
      // DBのデータから取得
      _setInitialValues(payrollSetting);
    }
  }, [payrollSetting]);

  const saveSetting = async () => {
    let setting = {
      payroll_system: state.payrollSystem,
    } as PayrollSetting;
    if (payrollSetting.id) {
      setting.id = payrollSetting.id;
      await dispatch(putPayrollSetting(setting));
    } else {
      await dispatch(postPayrollSetting(setting));
    }
    navigate("/_/masters/external");
  };

  return (
    <Container>
      <div className="pt-4">
        <h2 className="Headline--section mb-2">外部システム連携の設定</h2>
        <Row key="payrollSetting" className="mb-2">
          <Form.Label className="--required-label --bold">連携する外部システムを選択</Form.Label>
          <Col md={4}>
            <Form.Select
              value={state.payrollSystem}
              onChange={(e) => $state({ ...state, payrollSystem: e.target.value })}
            >
              <option value="">---</option>
              {Object.keys(PAYROLL_SYSTEMS).map((value, i) => {
                return (
                  <option key={`option${i}`} value={value}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
        <Button onClick={() => $state({ ...state, activeModal: "commit" })} disabled={state.payrollSystem == ""}>
          保存
        </Button>
      </div>
      <ModalDialog
        show={state.activeModal === "commit"}
        message="保存します。よろしいですか？"
        onConfirm={async () => {
          saveSetting();
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
    </Container>
  );
}

export default PayrollSettingEdit;
