import { useState } from "react";
import { Form, Row, Col, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProfileSubField, FieldValue, ProfileFile, NumberFormType } from "../../features/profile/profileFieldValues";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import ModalDialog from "../../component/ModalDialog";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SnapshotTimeSelector from "./SnapshotTimeSelector";
import MultiSelectItem from "./MultiSelectItem";
import ProfileSubFieldValue from "./ProfileSubFieldValue";
import { toTimeLabel } from "../../app/util";
import TimeSelector from "./TimeSelector";
import Select from "react-select";

dayjs.extend(utc);

const EMPTY_OPTION = {
  label: "- - -",
  value: "",
};

function ProfileSubFieldInput({
  subField,
  onChange,
  entered,
  errorMessage,
  placeholder,
  file,
  dataType,
  label,
  onFileAdd,
  onFileDelete,
  onFileDownload,
}: {
  subField: ProfileSubField;
  onChange: (v: FieldValue) => any;
  entered: boolean;
  errorMessage?: string;
  placeholder?: string;
  file?: ProfileFile;
  dataType: "user" | "master" | "application";
  label?: string;
  onFileAdd?: (subField: ProfileSubField, decodedFileData: DecodedFileData) => any;
  onFileDelete?: (fileId: string, key: string) => any;
  onFileDownload?: (fileId: string, key: string) => void;
}) {
  const [numberFormType, setNumberFormType] = useState<NumberFormType>("text");
  const [fileUploaderState, $fileUploaderState] = useState({
    isOpen: false,
  });
  const [fileDeleteState, $fileDeleteState] = useState({
    isOpen: false,
    delFile: {} as { id: string; key: string },
  });
  const formatNumberValue = (numberFormType: NumberFormType, value: FieldValue) => {
    if (typeof value !== "number" || !value) return "";
    switch (numberFormType) {
      case "number":
        return value.toString();
      case "text":
        return value.toLocaleString("ja-JP");
    }
  };
  const closeFileUploadModal = () => {
    $fileUploaderState({
      isOpen: false,
    });
  };
  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    onFileAdd && onFileAdd(subField, decodedFileData);
    closeFileUploadModal();
  };
  const closeFileDeleteModal = () => {
    $fileDeleteState({
      delFile: {} as { id: string; key: string },
      isOpen: false,
    });
  };
  const deleteFile = () => {
    if (fileDeleteState.delFile.id) {
      onFileDelete && onFileDelete(fileDeleteState.delFile.id, fileDeleteState.delFile.key);
      closeFileDeleteModal();
    }
  };
  if (!subField.editable) {
    if (subField.referenceField) {
      return <div>（保存すると値が更新されます）</div>;
    } else if (subField.virtual_field) {
      return <div>（保存後に表示します）</div>;
    } else {
      return <ProfileSubFieldValue subField={subField} file={file} onFileDownload={onFileDownload} />;
    }
  }
  if (
    subField.type === "string" ||
    subField.type === "email" ||
    subField.type === "bankAccountNameKana" ||
    subField.type === "nameKana" ||
    subField.type === "addressKana" ||
    subField.type === "float"
  ) {
    const maxLengthRule = subField.rules?.find((_) => _[0] === "<=" && typeof _[1] === "number" && _[1] > 0);
    return (
      <div>
        <Form.Control
          type="text"
          id={`${subField.id}_input`}
          placeholder={placeholder}
          value={subField.value === null ? "" : `${subField.value}`}
          onChange={(e) => {
            onChange(`${e.target.value}`);
          }}
        />
        {typeof subField.value === "string" && maxLengthRule ? (
          <div className="--font-s">
            文字数: {subField.value.length} / {maxLengthRule[1]}
          </div>
        ) : null}
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "longtext") {
    const maxLengthRule = subField.rules?.find((_) => _[0] === "<=" && typeof _[1] === "number" && _[1] > 0);
    return (
      <div>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          value={subField.value === null ? "" : `${subField.value}`}
          style={{ height: "100px" }}
          onChange={(e) => {
            onChange(`${e.target.value}`);
          }}
        />
        {maxLengthRule ? (
          <div className="--font-s">
            文字数: {typeof subField.value === "string" ? subField.value.length : 0} / {maxLengthRule[1]}
          </div>
        ) : null}
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "date") {
    if (typeof subField.value !== "string" && subField.value !== null) return null;
    return (
      <div>
        <Row>
          <Col md={12}>
            <SnapshotTimeSelector
              selectedPointDate={subField.value ? dayjs(subField.value) : null}
              onChange={({ selectedPointDate }) => {
                if (!selectedPointDate) onChange("");
                else onChange(selectedPointDate.format("YYYY-MM-DD"));
              }}
              isInput={true}
              subFieldId={subField.id}
            />
          </Col>
        </Row>
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "datetime") {
    if (typeof subField.value !== "string") return null;
    const d = subField.value ? new Date(subField.value) : null;
    return (
      <div>
        <Row>
          <Col md={4}>
            <div className="d-flex align-items-center">
              <DatePicker
                dateFormat={"yyyy-MM-dd hh:mm"}
                selected={d}
                showTimeSelect
                timeIntervals={1}
                onChange={(selected) => {
                  if (!selected) onChange("");
                  onChange(dayjs(selected).format("YYYY-MM-DD hh:mm"));
                }}
              />
            </div>
          </Col>
        </Row>
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "time") {
    if (typeof subField.value !== "string" && subField.value !== null) return null;
    return (
      <div>
        <Row>
          <Col md={12}>
            <TimeSelector onChange={(value) => onChange(value)} selectedValue={`${subField.value}`} />
          </Col>
        </Row>
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "number") {
    return (
      <div>
        <Form.Control
          type={numberFormType}
          id={`${subField.id}_input`}
          placeholder={placeholder}
          value={formatNumberValue(numberFormType, subField.value) || (subField.value === 0 ? +subField.value : "")}
          onChange={(e) => {
            onChange(e.target.value === "" ? null : +e.target.value);
          }}
          onWheel={(e) => e.currentTarget.blur()}
          onBlur={() => setNumberFormType("text")}
          onFocus={() => setNumberFormType("number")}
        />
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "postCode") {
    return (
      <div>
        <div>
          <div className="d-flex align-items-center">
            <span className="mx-1">〒</span>
            <Form.Control
              type="text"
              id={`${subField.id}`}
              placeholder={placeholder}
              value={subField.value === null ? "" : `${subField.value}`}
              onChange={(e) => onChange(e.target.value)}
              maxLength={8}
            />
          </div>
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
        <div className="--font-s mt-1">※ ハイフンありで入力してください</div>
      </div>
    );
  } else if (subField.type === "phoneNumber") {
    return (
      <div>
        <div>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              id={`${subField.id}`}
              placeholder={placeholder}
              value={subField.value === null ? "" : `${subField.value}`}
              onChange={(e) => onChange(e.target.value)}
              maxLength={20}
            />
          </div>
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
      </div>
    );
  } else if (subField.type === "bankNumber") {
    return (
      <div>
        <div>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              id={`${subField.id}`}
              placeholder={placeholder}
              value={subField.value === null ? "" : `${subField.value}`}
              onChange={(e) => onChange(e.target.value)}
              maxLength={4}
            />
          </div>
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
      </div>
    );
  } else if (subField.type === "bankBranchNumber") {
    return (
      <div>
        <div>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              id={`${subField.id}`}
              placeholder={placeholder}
              value={subField.value === null ? "" : `${subField.value}`}
              onChange={(e) => onChange(e.target.value)}
              maxLength={3}
            />
          </div>
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
      </div>
    );
  } else if (subField.type === "bankAccountNo") {
    return (
      <div>
        <div>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              id={`${subField.id}`}
              placeholder={placeholder}
              value={subField.value === null ? "" : `${subField.value}`}
              onChange={(e) => onChange(e.target.value)}
              maxLength={7}
            />
          </div>
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
      </div>
    );
  } else if (subField.type === "boolean") {
    return (
      <div>
        <div className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            id={`${subField.id}`}
            checked={!!subField.value}
            onChange={() => onChange(!subField.value)}
          />
        </div>
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (
    (subField.type === "code" || subField.type === "options" || subField.type === "yearMonth") &&
    Array.isArray(subField.labelValueOptions)
  ) {
    const { labelValueOptions } = subField;
    const isNumber = typeof labelValueOptions[0]?.value === "number";
    const options = [EMPTY_OPTION, ...labelValueOptions.map(({ label, value }) => ({ label, value }))];
    return (
      <div className="align-items-center">
        <Select
          className="select"
          defaultValue={options.find(({ value }) => value === subField.value) ?? EMPTY_OPTION}
          onChange={(selected: { label: string; value: string | number } | null) => {
            const value = selected?.value ?? "";
            onChange(isNumber ? +value : value);
          }}
          options={options}
        />
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else if (subField.type === "file") {
    if (dataType !== "application" && subField.record.id < 0) {
      // POSTの場合
      return (
        <div>
          保存後に添付可能になります。
          {file && file.files.some(({ deleted_at }) => !deleted_at) && (
            <>
              <Form.Check
                type="checkbox"
                id={`${subField.id}`}
                checked={!!subField.value}
                label="以下添付ファイルを引き継ぐ"
                onChange={() => onChange(subField.value ? null : file.id)}
              />
              <ProfileSubFieldValue subField={subField} file={file} onFileDownload={onFileDownload} />
            </>
          )}
        </div>
      );
    } else {
      const addedFiles = file ? file.files.filter(({ deleted_at }) => deleted_at === null) : [];
      // 申請書の場合は削除されたファイルは表示しない
      const deletedFiles =
        dataType !== "application" && file ? file.files.filter(({ deleted_at }) => deleted_at !== null) : [];
      return (
        <div>
          <Modal
            show={fileUploaderState.isOpen}
            onHide={closeFileUploadModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <Row className="mb-2">
                <Col>
                  <h2 className="Headline--section mb-2">{label ?? "添付ファイル"}の追加</h2>
                  <div className="my-2">
                    <Uploader onFileLoad={onFileLoad} />
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeFileUploadModal} variant="outline-secondary">
                キャンセル
              </Button>
            </Modal.Footer>
          </Modal>
          {addedFiles.length > 0 && (
            <>
              {addedFiles.map(({ key, name, created_at }) => (
                <div key={key} className="--bullet">
                  <OverlayTrigger
                    key={key}
                    placement="top"
                    delay={{ show: 50, hide: 50 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {`${created_at ? `${toTimeLabel(created_at)}追加` : ""}`}
                      </Tooltip>
                    )}
                  >
                    <span>{name}</span>
                  </OverlayTrigger>
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => {
                      if (!file) return;
                      $fileDeleteState({
                        delFile: { id: file.id, key: key },
                        isOpen: true,
                      });
                      return;
                    }}
                  >
                    削除
                  </Button>
                </div>
              ))}
            </>
          )}
          {deletedFiles.length > 0 && (
            <>
              <div className="--bold --text-light-color mt-1">削除されたファイル</div>
              {deletedFiles
                .filter(({ deleted_at }) => deleted_at !== null)
                .map(({ key, name, deleted_at }) => (
                  <div key={key} className="--bullet">
                    <OverlayTrigger
                      key={key}
                      placement="top"
                      delay={{ show: 50, hide: 50 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {`${deleted_at ? `${toTimeLabel(deleted_at)}削除` : ""}`}
                        </Tooltip>
                      )}
                    >
                      <span className="--text-light-color">{name}</span>
                    </OverlayTrigger>
                  </div>
                ))}
            </>
          )}
          <Button
            variant="outline-primary"
            size="sm"
            className="my-1"
            onClick={() => {
              $fileUploaderState({
                ...fileUploaderState,
                isOpen: true,
              });
            }}
          >
            ファイル追加
          </Button>
          {/* 削除ボタン押下時のモーダル */}
          <ModalDialog
            show={fileDeleteState.isOpen}
            onConfirm={deleteFile}
            onCancel={closeFileDeleteModal}
            message="削除すると復元できません。こちらのファイルを引き継いだレコードがある場合、参照できなくなります。削除してよろしいですか？"
            type="destructiveConfirm"
            confirmButtonName="削除"
          />
          {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
        </div>
      );
    }
  } else if (subField.type === "checkbox" && Array.isArray(subField.labelValueOptions)) {
    const labelValueOptions = subField.labelValueOptions;
    return (
      <div className="align-items-center">
        <MultiSelectItem
          choices={labelValueOptions.map(({ label, value }) => {
            value = (value ?? label) as string;
            return {
              label,
              value,
              checked: Array.isArray(subField.value) && subField.value?.includes(value),
            };
          })}
          onChange={(selected: string[]) => onChange(selected)}
        />
        {entered && errorMessage && <div className="--font-s --text-annotation mt-1">{errorMessage}</div>}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default ProfileSubFieldInput;
