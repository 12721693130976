const data = {
  category: "license",
  table: "license",
  subFields: {
    license_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    license_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    license_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    license_acquisition_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    expiration_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    license_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      license_history: "免許資格履歴",
    },
  },
};

export default data;
