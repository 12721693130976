import "../css/style.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Form, ListGroup } from "react-bootstrap";

export type SettingStepProps = {
  label: string;
  active: boolean;
  disabled?: boolean;
};

function App({ data, className }: { data: SettingStepProps[]; onChange: (next: boolean) => any; className?: string }) {
  return (
    <nav className={`Setting-steps${className ? ` ${className}` : ""}`}>
      {data.map((_step, i) => {
        return (
          <div
            key={`step${i}`}
            className="Setting-steps__item"
            data-active={_step.active}
            data-disabled={_step.disabled}
          >
            <div className="Settings-steps__item-inner">{_step.label}</div>
          </div>
        );
      })}
    </nav>
  );
}

export default App;
