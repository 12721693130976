import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getSectors, initSectorStatus, putSectorStatus, selectClientState } from "../client/clientSlice";
import { SectorStatus, CHANGEABLE_SECTOR_IDS } from "../client/clientValues";
import ModalDialog from "../../component/ModalDialog";

function MasterDataList() {
  const dispatch = useAppDispatch();
  const { sectors, SectorStatusId, sectorUserStatus } = useAppSelector(selectClientState);

  const [state, $state] = useState({
    sectors: [] as SectorStatus[],
    activeModal: "",
  });
  const renameTargetSectors = useMemo(() => {
    return sectors.filter(({ has_rename_targets }) => has_rename_targets);
  }, [sectors]);

  const optionEditableSectors = useMemo(() => {
    return sectors.filter(({ has_option_editable_columns }) => has_option_editable_columns);
  }, [sectors]);

  const selectSector = (id: string) => {
    const next = state.sectors.map((s) => {
      return {
        ...s,
        active: s.key === id ? !s.active : s.active,
      };
    });
    $state({ ...state, sectors: next });
  };

  useEffect(() => {
    dispatch(initSectorStatus());
    dispatch(getSectors());
  }, [dispatch]);

  useEffect(() => {
    $state({ ...state, sectors: [...sectorUserStatus] });
  }, [sectorUserStatus]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <p>管理対象のテーブルを選択してください。管理対象はデータインポート後もいつでも変更可能です。</p>
              <Button className="mb-3" onClick={() => $state({ ...state, activeModal: "before_save" })}>
                保存
              </Button>
              <Row className="mb-3">
                <Col>
                  <Row>
                    <div className="Table">
                      <div className="Table__header">
                        <div className="Table__header-inner">
                          <div className="Table__header-col" style={{ width: "250px" }}>
                            {"テーブル"}
                          </div>
                          <div className="Table__header-col" style={{ width: "120px" }}>
                            {"アクション"}
                          </div>
                          <div className="Table__header-col" style={{ width: "120px" }}>
                            {"概要"}
                          </div>
                        </div>
                      </div>
                      {state.sectors.map((s, i) => {
                        const hasRenameTarget = renameTargetSectors.some((_) => _.sector_id === s.key);
                        const hasOptionEditableColumns = optionEditableSectors.some((_) => _.sector_id === s.key);
                        return (
                          <div className="Table__row" key={`sector-${s.key}`}>
                            <div className="Table__row-inner">
                              <div className="Table__col" style={{ width: "250px" }}>
                                <Form.Check type="checkbox" id={s.key}>
                                  <Form.Check.Input
                                    checked={s.active}
                                    onChange={() => selectSector(s.key)}
                                    disabled={!CHANGEABLE_SECTOR_IDS.includes(s.key)}
                                  />
                                  <Form.Check.Label style={{ opacity: 1 }}>{s.label}</Form.Check.Label>
                                </Form.Check>
                              </div>
                              <div className="Table__col" style={{ width: "120px" }}>
                                {hasRenameTarget && <Link to={`/_/masters/fields/edit/${s.key}`}>項目名の編集</Link>}
                                {hasOptionEditableColumns && (
                                  <Link to={`/_/masters/fields/options/${s.key}`}>選択肢の編集</Link>
                                )}
                                {!hasRenameTarget && !hasOptionEditableColumns && <span>--</span>}
                              </div>
                              <div className="Table__col" style={{ width: "auto" }}>
                                {s.description}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Button className="mb-3" onClick={() => $state({ ...state, activeModal: "before_save" })}>
                保存
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_save"}
        onConfirm={() => {
          const sectorStatus = state.sectors.reduce((prev: { [key: string]: boolean }, item) => {
            prev[item.key] = item.active;
            return prev;
          }, {});
          dispatch(
            putSectorStatus({
              SectorStatusId: SectorStatusId,
              sectorStatus,
            })
          );
          $state({ ...state, activeModal: "" });
        }}
        onCancel={() => $state({ ...state, activeModal: "" })}
        message={"保存します。よろしいですか？"}
      />
    </Container>
  );
}

export default MasterDataList;
