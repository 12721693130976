import { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Sidebar from "../../component/Sidebar";
import OperationCard from "../../component/OperationCard";
import { getOperations, selectOperationState } from "../../features/dashboard/operationSlice";
import { Container, Row, Col } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { operations } = useAppSelector(selectOperationState);
  useEffect(() => {
    dispatch(getOperations());
  }, []);

  useEffect(() => {
    // 通常の画面遷移ではhashが効かないための処理
    if (window.location.hash && operations.length > 0) {
      const _hash = window.location.hash;
      window.location.hash = "";
      window.location.hash = _hash;
    }
  }, [operations]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                {operations.map((s, i) => (
                  <Row key={`taskCard_${i}`} id={s.key} className="mb-4">
                    <Col>
                      <OperationCard data={s} />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
