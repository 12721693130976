const data = {
  category: "recruitment",
  table: "recruitment",
  subFields: {
    recruitment_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    recruitment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    recruitment_application_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    interview_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    interview_results_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    interviewee_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relation_file_id_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      recruitment_history: "採用履歴",
    },
  },
};

export default data;
