const data = {
  category: "reward_punishment",
  table: "reward_punishment",
  subFields: {
    reward_punishment_type: {},
    content: {},
    expiration_on: {},
    incentive_reduction_payments: {},
    remarks: {},
  },
};
export default data;
