const data = {
  category: "term_contract",
  table: "term_contract",
  subFields: {
    contract_start_on: {},
    contract_end_on: {},
    joined_on: {},
    place_of_work: {},
    description_of_work: {},
    basic_wage_monthly: {},
    basic_wage_daily: {},
    basic_wage_hourly: {},
    wage_grade: {},
    allowance: {},
    is_member_pension_insurance: {},
    is_member_health_insurance: {},
    is_member_employment_insurance: {},
    contract_days_per_week: {},
    contract_days_per_year: {},
    renewal_contract_type: {},
    reason_not_renewing: {},
    remarks: {},
  },
};
export default data;
