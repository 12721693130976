const data = {
  category: "attendance",
  table: "attendance",
  subFields: {
    days_worked: {},
    days_worked_holiday: {},
    days_special_holiday: {},
    days_paid_holiday: {},
    times_paid_holiday: {},
    days_compensatory_holiday: {},
    times_compensatory_holiday: {},
    days_absence: {},
    days_left_paid_holiday: {},
    times_left_paid_holiday: {},
    days_reserve1: {},
    days_reserve2: {},
    days_reserve3: {},
    days_reserve4: {},
    days_reserve5: {},
    days_reserve6: {},
    days_reserve7: {},
    days_reserve8: {},
    days_reserve9: {},
    days_reserve10: {},
    times_worked: {},
    times_late_early: {},
    times_overtime: {},
    times_overtime_late_night: {},
    times_worked_holiday: {},
    times_worked_holiday_late_night: {},
    times_worked_legal_holiday: {},
    times_worked_legal_holiday_late_night: {},
    times_reserve1: {},
    times_reserve2: {},
    times_reserve3: {},
    times_reserve4: {},
    times_reserve5: {},
    times_reserve6: {},
    times_reserve7: {},
    times_reserve8: {},
    times_reserve9: {},
    times_reserve10: {},
    times_reserve11: {},
    times_reserve12: {},
    times_reserve13: {},
    times_reserve14: {},
    times_reserve15: {},
  },
};
export default data;
