const data = {
  category: "attendance",
  table: "attendance",
  subFields: {
    attendance_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_worked_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_worked_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_special_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_paid_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_paid_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_compensatory_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_compensatory_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_absence_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_left_paid_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_left_paid_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    days_reserve10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_worked_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_late_early_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_overtime_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_overtime_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_worked_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_worked_holiday_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_worked_legal_holiday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_worked_legal_holiday_late_night_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve4_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve5_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve6_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve7_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve8_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve9_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve10_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve11_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve12_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve13_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve14_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    times_reserve15_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      attendance_history: "勤怠履歴",
    },
  },
};

export default data;
