import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector } from "../../app/store";
import { selectUserState } from "../login/userSlice";

function MyNumberListView() {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    if (user.id > 0) {
      navigate(user.role === "user" ? "/_/my_number/user" : "/_/my_number/admin");
    }
  }, [user]);
  return <div />;
}

export default MyNumberListView;
