import classNames from "classnames";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App(
  {
    className,
    data,
    onChange,
  }: {
    className?: string;
    data: { id: string; label: string; isSelected: boolean }[];
    onChange?: (id: string) => any;
  } = { data: [] }
) {
  return (
    <div className={`Tag-cloud ${className}`}>
      <div className="Tag-cloud__items">
        {data.map(({ id, label, isSelected }) => {
          return (
            <div
              key={id}
              onClick={() => {
                onChange && onChange(id);
              }}
              className={classNames({
                "Tag-cloud__item": !isSelected,
                "Tag-cloud__item--selected": isSelected,
              })}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
