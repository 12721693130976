const data = {
  category: "secondment",
  table: "secondment",
  subFields: {
    planned_end_on: {},
    is_add_duration: {},
    seconded_to_company_code: {},
    seconded_to_postcode: {},
    seconded_to_address: {},
    seconded_to_phone_number: {},
    seconded_to_person_responsible: {},
    assistance_fee: {},
    seconded_to_employee_number: {},
    seconded_to_email: {},
    seconded_to_section: {},
    seconded_to_position: {},
    seconded_to_job: {},
    seconded_to_duty: {},
    seconded_to_grade: {},
    previous_extend_planned_end_on: {},
    remarks: {},
  },
};
export default data;
