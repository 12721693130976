const data = {
  category: "withholding_tax_recipient",
  table: "withholding_tax_recipient",
  subFields: {
    withholding_tax_recipient_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    recipient_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    post_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    recipient_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    recipient_name_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    birthday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_joining_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_leaving_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joining_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    leaving_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      withholding_tax_recipient_history: "源泉徴収受給者情報履歴",
    },
  },
};

export default data;
