import "../css/style.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Form } from "react-bootstrap";

export type SettingCardProps = {
  title: string;
  description?: string;
  checkLists: { checked: boolean; label: string }[];
  links?: { label: string; to: string }[];
  folded?: boolean;
};

function App({
  data,
  onChange,
  className,
}: {
  data: SettingCardProps;
  onChange: (next: { [key: string]: any }) => any;
  className?: string;
}) {
  const { title, description, checkLists, links, folded } = data;
  const handler = (diff: { [key: string]: any }) => {
    const next = {
      checkLists,
      links,
      folded,
    };
    onChange &&
      onChange({
        ...next,
        ...diff,
      });
  };
  return (
    <article className={`Setting-card${className ? ` ${className}` : ""}`}>
      <h3
        onClick={() =>
          handler({
            folded: !folded,
          })
        }
        className={classNames({ "Setting-card__title--expanded": !folded, "Setting-card__title--folded": folded })}
      >
        {title}
      </h3>
      <div className={classNames({ "Setting-card__body": !folded, "Setting-card__body--folded": folded })}>
        {description && <div className="Setting-card__description">{description}</div>}
        {Array.isArray(links) && links.length > 0 && (
          <ul className="Setting-card__links">
            {links.map((link, ii) => {
              return (
                <li className="Setting-card__link" key={`link_${ii}`}>
                  <Link to={link.to}>{link.label}</Link>
                </li>
              );
            })}
          </ul>
        )}
        {Array.isArray(checkLists) && checkLists.length > 0 && (
          <ul className="Setting-card__check-lists">
            {checkLists.map((checkList, i) => {
              return (
                <li className="Setting-card__check-list" key={`checkList${i}`}>
                  <Form.Check
                    type="checkbox"
                    key={`check-list-${i}`}
                    label={checkList.label}
                    checked={checkList.checked}
                    onChange={() => {
                      handler({
                        checkLists: checkLists.map((_c, _ci) => {
                          if (_ci === i) {
                            return {
                              ..._c,
                              checked: !_c.checked,
                            };
                          } else {
                            return _c;
                          }
                        }),
                      });
                    }}
                    inline
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </article>
  );
}

export default App;
