import { RegularColumn } from "../../client/clientValues";
import { ProfileField, generateProfileFieldsBundler, generateValidator } from "../profileFieldValues";

const base = {
  category: "bank_account",
  table: "bank_account",
  subFields: {
    payroll_payment_type: {},
    payroll_cash_payment_category_type: {},
    payroll_cash_fixed_amount: {},
    payroll_data: {
      type: "tagHandler",
      tag: "pr_",
      minTagGroupsLength: 1,
      maxTagGroupsLength: 3,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`payroll_${i}_payment_category_type`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
        };
        if (i > 1) {
          _ = {
            ..._,
            [`payroll_${i}_fixed_amount`]: {
              tag: "pr_",
              tagGroupIndex: i,
            },
          };
        }

        _ = {
          ..._,
          [`payroll_${i}_company_bank_number`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_company_bank_name`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_payee_bank_number`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_payee_bank_name`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_payee_branch_number`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_payee_branch_name`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_deposit_type`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_account_no`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_account_name`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
          [`payroll_${i}_account_name_kana`]: {
            tag: "pr_",
            tagGroupIndex: i,
          },
        };
      });
      return _;
    })(),
    bonus_payment_type: {},
    bonus_cash_payment_category_type: {},
    bonus_cash_fixed_amount: {},
    bonus_data: {
      type: "tagHandler",
      tag: "bn_",
      minTagGroupsLength: 1,
      maxTagGroupsLength: 3,
    },
    ...(() => {
      let _ = {};
      [1, 2, 3].forEach((i) => {
        _ = {
          ..._,
          [`bonus_${i}_payment_category_type`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
        };
        if (i > 1) {
          _ = {
            ..._,
            [`bonus_${i}_fixed_amount`]: {
              tag: "bn_",
              tagGroupIndex: i,
            },
          };
        }
        _ = {
          ..._,
          [`bonus_${i}_company_bank_number`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_company_bank_name`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_payee_bank_number`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_payee_bank_name`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_payee_branch_number`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_payee_branch_name`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_deposit_type`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_account_no`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_account_name`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
          [`bonus_${i}_account_name_kana`]: {
            tag: "bn_",
            tagGroupIndex: i,
          },
        };
      });
      return _;
    })(),
  },
  labelMap: {
    ja: {
      payroll_data: "給与振込先",
      bonus_data: "賞与振込先",
    },
  },
};

const data = {
  ...base,
  toProfileFields: (responseResults: { [k: string]: any }[], columns?: RegularColumn[]): ProfileField[] => {
    const profileFields = generateProfileFieldsBundler("bank_account", base)(responseResults, columns);
    return profileFields.map((profileField) => {
      // 給与振込先 1, 2, 3　をどこまで表示するかを設定
      const payrollTagHandlerIndex = profileField.subFields.findIndex((_) => _.id === "payroll_data");
      const payrollTagHandler = profileField.subFields[payrollTagHandlerIndex];
      const payrollTagGroupsToUse: number[] = [];
      [1, 2, 3].forEach((index) => {
        const n = profileField.subFields.find((_) => _.id === `payroll_${index}_payment_category_type`)!;
        if (n.value !== "未設定") payrollTagGroupsToUse.push(index);
      });
      const _payrollTagHandler = { ...payrollTagHandler, tagGroupsToUse: payrollTagGroupsToUse };

      // 賞与振込先 1, 2, 3　をどこまで表示するかを設定
      const bonusTagHandlerIndex = profileField.subFields.findIndex((_) => _.id === "bonus_data");
      const bonusTagHandler = profileField.subFields[bonusTagHandlerIndex];
      const bonusTagGroupsToUse: number[] = [];
      [1, 2, 3].forEach((index) => {
        const n = profileField.subFields.find((_) => _.id === `bonus_${index}_payment_category_type`)!;
        if (n.value !== "未設定") bonusTagGroupsToUse.push(index);
      });
      const _bonusTagHandler = { ...bonusTagHandler, tagGroupsToUse: bonusTagGroupsToUse };
      return {
        ...profileField,
        subFields: profileField.subFields.map((f, i) => {
          if (i === payrollTagHandlerIndex) return _payrollTagHandler;
          else if (i === bonusTagHandlerIndex) return _bonusTagHandler;
          else return f;
        }),
      };
    });
  },
};
export default data;
