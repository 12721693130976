const data = {
  category: "recruitment",
  table: "recruitment",
  subFields: {
    recruitment_type: {},
    recruitment_application_type: {},
    interview_on: {},
    interview_results: {},
    interviewee: {},
    remarks: {},
    relation_file_id: {},
  },
};
export default data;
