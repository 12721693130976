const data = {
  category: "grade_old",
  table: "grade_old",
  subFields: {
    grade_old_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_fixed_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    grade_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    grade_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      grade_old_history: "等級履歴",
    },
  },
};

export default data;
