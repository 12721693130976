import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Alert, Form, OverlayTrigger, Popover, Tooltip, Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getTodos, putTodo, putTodos, selectTodoState, unselectTodos } from "./todoSlice";
import { GET_TODOS__DEFAULT_LIMIT, profileTodoTypes } from "./todoValues";
import ModalDialog from "../../component/ModalDialog";
import Table from "../../component/Table";
import Sidebar from "../../component/Sidebar";
import Icon from "../../component/Icon";
import { getRelatedAccounts, selectApplyState } from "../apply/applySlice";
import dayjs from "dayjs";
import classNames from "classnames";
import { getQuery } from "../../app/util";
import SnapshotTimeSelector, { onChangeOption } from "../profile/SnapshotTimeSelector";
import { selectUserRootRoles, selectUserState } from "../login/userSlice";

function RemindSettingList() {
  const dispatch = useAppDispatch();
  const { todos, fetchedTodoPage, hasMoreTodos, todoCount } = useAppSelector(selectTodoState);
  const { relatedAccounts } = useAppSelector(selectApplyState);
  const { user, policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const { search } = useLocation();
  const [state, $state] = useState({
    showDoneTodo: false,
    selectedTodoId: "",
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
    checkedIds: [] as string[],
    startDateOfTerm: dayjs().startOf("day"),
    endDateOfTerm: dayjs().endOf("day").add(1, "month"),
    searchWithDueDate: false,
  });
  const [activeModal, $activeModal] = useState("");

  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch(unselectTodos());
    };
  }, []);

  useEffect(() => {
    const keyword = query.keyword ? decodeURIComponent(query.keyword) : "";
    $state({ ...state, searchKeyword: keyword, inputKeyword: keyword });
  }, [query]);

  useEffect(() => {
    const id__in = todos.filter((t) => !relatedAccounts.some(({ id }) => id === t.object)).map(({ object }) => object);
    if (id__in.length) {
      dispatch(getRelatedAccounts({ id__in }));
    }
  }, [todos]);

  useEffect(() => {
    searchTodos({});
  }, [state.searchKeyword, state.showDoneTodo, state.searchWithDueDate, state.startDateOfTerm, state.endDateOfTerm]);

  const searchTodos = ({ page = 1 }: { page?: number }) => {
    const params = {
      limit: GET_TODOS__DEFAULT_LIMIT,
      page,
      done__in: state.showDoneTodo ? [true, false] : [false],
    } as { [key: string]: any };

    if (state.searchKeyword) {
      params["title__regex"] = state.searchKeyword;
    }
    if (state.searchWithDueDate) {
      params["due_date__range"] = [
        state.startDateOfTerm.format("YYYY-MM-DDT00:00:00"),
        state.endDateOfTerm.format("YYYY-MM-DDT00:00:00"),
      ];
    }
    dispatch(getTodos(params));
  };

  const isApplicationAdmin = useMemo(() => {
    return Object.keys(policies)
      .filter((key: string) => key.includes("application_admin_manager"))
      ?.some((api) => policies[api]?.includes("GET"));
  }, [roles, policies]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="dashboard" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">ToDo一覧</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {state.inputKeyword || state.searchWithDueDate || state.showDoneTodo
                    ? (() => {
                        let labels = [] as string[];
                        if (state.inputKeyword) {
                          labels = [...labels, "タイトル"];
                        }
                        if (state.searchWithDueDate) {
                          labels = [...labels, "対応期限"];
                        }
                        if (state.showDoneTodo) {
                          labels = [...labels, "完了済のToDoも表示"];
                        }
                        return labels.join(", ");
                      })()
                    : "（未選択）"}
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        id="search"
                        value={state.inputKeyword}
                        className="mb-2"
                        placeholder="タイトルで絞り込む"
                        onChange={(e) => {
                          const keyword = e.target.value;
                          // 打ち終わって500ms後に検索のリクエストをする
                          if (state.timeoutId) {
                            window.clearTimeout(state.timeoutId);
                          }
                          const timeoutId = window.setTimeout(() => {
                            $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                          }, 500);
                          $state({ ...state, timeoutId, inputKeyword: keyword });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Check
                        type="switch"
                        id="due_date_switch"
                        label="対応期限で絞り込む"
                        onChange={() => {
                          $state({ ...state, searchWithDueDate: !state.searchWithDueDate });
                        }}
                        checked={state.searchWithDueDate}
                        className="text-secondary mb-1"
                      />
                    </Col>
                    {state.searchWithDueDate && (
                      <>
                        <Row className="mb-2">
                          <Col md="5">
                            <SnapshotTimeSelector
                              selectedPointDate={state.startDateOfTerm}
                              onChange={({ selectedPointDate }: onChangeOption) => {
                                if (!selectedPointDate) return;
                                $state({
                                  ...state,
                                  startDateOfTerm: selectedPointDate.startOf("day"),
                                });
                              }}
                            />
                          </Col>
                          <Col md="1" className="--text-align-center pt-md-3">
                            ～
                          </Col>
                          <Col md="5">
                            <SnapshotTimeSelector
                              selectedPointDate={state.endDateOfTerm}
                              onChange={({ selectedPointDate }: onChangeOption) => {
                                if (!selectedPointDate) return;
                                $state({
                                  ...state,
                                  endDateOfTerm: selectedPointDate.endOf("day"),
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Check
                        type="switch"
                        key="switch"
                        id="switch"
                        label="完了済のToDoも表示する"
                        onChange={() => {
                          $state({ ...state, showDoneTodo: !state.showDoneTodo });
                        }}
                        checked={state.showDoneTodo}
                        className="text-secondary"
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row className="mt-2">
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (state.timeoutId) {
                      window.clearTimeout(state.timeoutId);
                    }
                    $state({
                      ...state,
                      showDoneTodo: false,
                      timeoutId: null,
                      inputKeyword: "",
                      searchKeyword: "",
                      startDateOfTerm: dayjs().startOf("day"),
                      endDateOfTerm: dayjs().endOf("day").add(1, "month"),
                      searchWithDueDate: false,
                    });
                  }}
                >
                  絞込条件をクリア
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                {todoCount > 0 ? (
                  <>
                    <span className="--font-s">
                      内容が設定されている場合、タイトル横に
                      <Icon width={15} height={15} type="question-circle" />
                      が表示されます。アイコンをクリックすると内容を確認できます。
                    </span>
                    <Table
                      col={["タイトル", "対象", "対応期限", "完了済"].map((name) => ({ name }))}
                      row={todos.map((todo, i) => {
                        const profileTodoType = profileTodoTypes[todo.type];
                        return {
                          id: todo.id,
                          data: [
                            <>
                              {todo.title}
                              {todo.content && (
                                <OverlayTrigger
                                  placement="right"
                                  trigger="click"
                                  overlay={(props) => (
                                    <Popover id={`todo_popover_${todo.id}`} {...props} className="--pre-wrap">
                                      <Popover.Body>{todo.content}</Popover.Body>
                                    </Popover>
                                  )}
                                >
                                  <span>
                                    <Icon width={15} height={15} type="question-circle" className="ms-1 __Link" />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </>,
                            <>
                              {relatedAccounts.find(({ id }) => id === todo.object)?.label}
                              <OverlayTrigger
                                placement="right"
                                overlay={(props) => (
                                  <Tooltip id={`deail-link-tooltip_${i}`} {...props}>
                                    従業員情報を表示
                                  </Tooltip>
                                )}
                              >
                                <Link
                                  className="ms-1"
                                  target="_blank"
                                  to={`/_/profile/${todo.object}/?field=${profileTodoType.field}${
                                    profileTodoType.searchHistory
                                      ? "&type=history"
                                      : `&type=detail&base_date=${dayjs(todo.created_at).format("YYYY-MM-DD")}`
                                  }`}
                                >
                                  <Icon type="person-fill" width={16} height={16} />
                                  <Icon type="external-tab" width={13} height={13} />
                                </Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                overlay={(props) => (
                                  <Tooltip id={`apply-link-tooltip_${i}`} {...props}>
                                    申請一覧を表示
                                  </Tooltip>
                                )}
                              >
                                <Link
                                  className="ms-1"
                                  target="_blank"
                                  to={
                                    user.id === todo.object
                                      ? `/_/apply/applicant`
                                      : isApplicationAdmin
                                      ? `/_/apply/admin/?applicant=${todo.object}`
                                      : `/_/apply/processor/?applicant=${todo.object}`
                                  }
                                >
                                  <Icon type="file-earmark-arrow-up" width={16} height={16} />
                                  <Icon type="external-tab" width={13} height={13} />
                                </Link>
                              </OverlayTrigger>
                            </>,
                            <span
                              className={classNames({
                                "--text-annotation": dayjs(todo.due_date) < dayjs(),
                              })}
                            >
                              {dayjs(todo.due_date).format("YYYY/MM/DD")}
                            </span>,
                            <Form.Check
                              type="switch"
                              key={`done_switch_${i}`}
                              id={`done_switch_${i}`}
                              label={todo.done ? "完了済" : "未完了"}
                              onChange={() => {
                                $state({ ...state, selectedTodoId: todo.id });
                                $activeModal("before_done");
                              }}
                              checked={todo.done}
                              className="text-secondary"
                            />,
                          ],
                        };
                      })}
                      checkedIds={state.checkedIds}
                      onCheck={(next) => {
                        const checkedIds = next as string[];
                        $state({ ...state, checkedIds });
                      }}
                    />
                    <Button
                      variant="outline-primary"
                      className="mt-2"
                      onClick={() => {
                        $activeModal("before_all_done");
                      }}
                      disabled={state.checkedIds.length === 0}
                    >
                      選択したToDoを完了にする
                    </Button>
                    {
                      <Button
                        variant="outline-secondary"
                        className="mt-2 float-end"
                        disabled={!hasMoreTodos}
                        onClick={() => {
                          searchTodos({ page: fetchedTodoPage + 1 });
                        }}
                      >
                        さらに表示（全 {todoCount} 件中 {todos.length} 件表示中）
                      </Button>
                    }
                  </>
                ) : (
                  <Alert variant={"info"}>該当するレコードはありません。</Alert>
                )}
              </Col>
            </Row>
            <ModalDialog
              show={activeModal === "before_done"}
              onConfirm={() => {
                const todo = todos.find((t) => t.id === state.selectedTodoId);
                if (!todo) return $activeModal("");
                dispatch(putTodo({ id: todo.id, done: !todo.done }));
                $activeModal("");
              }}
              onCancel={() => {
                $activeModal("");
              }}
              message="ステータスを変更します。よろしいですか？"
            />
            <ModalDialog
              show={activeModal === "before_all_done"}
              onConfirm={() => {
                if (!state.checkedIds) return $activeModal("");
                dispatch(putTodos({ id__in: state.checkedIds, done: true }));
                $activeModal("");
                $state({ ...state, checkedIds: [] });
              }}
              onCancel={() => {
                $activeModal("");
              }}
              message="選択した全てのToDoのステータスを完了にします。よろしいですか？"
            />
          </Container>
        </main>
      </div>
    </div>
  );
}

export default RemindSettingList;
