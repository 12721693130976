import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getRemindSettings, selectTodoState, unselectRemindSettings } from "./todoSlice";
import Table from "../../component/Table";
import { getAllTerms } from "../../app/translate";
import { IntervalTypes, PeriodTypes } from "./todoValues";

function RemindSettingList() {
  const TERMS = getAllTerms();
  const dispatch = useAppDispatch();
  const { remindSettings } = useAppSelector(selectTodoState);
  useEffect(() => {
    dispatch(getRemindSettings());
    return () => {
      dispatch(unselectRemindSettings());
    };
  }, []);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Alert variant="info">
            各種手続き担当者の対応漏れを防ぐため、日付項目を元にToDo作成・メールで通知するための設定一覧です。
            <br />
            作成されたToDoは
            <Link to="/_/todo">こちら</Link>から確認できます。
            <br />
            <br />
            <div className="--font-s">
              活用例）
              <br />
              ・社会保険の手続きのため 一定年齢到達者について担当部署へ通知
              <br />
              ・資格の更新対応のため 有効期限が近づいたら本人へ通知
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Link to="/_/masters/remind_setting/create">
            <Button className="mb-2" variant="outline-primary">
              ToDo設定を追加する
            </Button>
          </Link>
          <Table
            col={[
              { name: "タイトル" },
              { name: "条件" },
              { name: "ToDo作成タイミング", width: 200 },
              { name: "ToDo実施者区分", width: 200 },
              { name: "対応期限" },
            ]}
            row={remindSettings.map((r) => {
              return {
                data: [
                  <>
                    {r.is_active ? (
                      <span className="Badge--ok">有効</span>
                    ) : (
                      <span className="Badge--waiting">無効</span>
                    )}
                    <span className="ms-2">{r.title}</span>
                  </>,
                  `${TERMS[`TODO_TYPE_${r.todo_type}`]} ${
                    PeriodTypes[r.period_type].isValueRequired ? r.period_value : ""
                  }${TERMS[`PERIOD_TYPE_${r.period_type}`]}`,
                  `${TERMS[`INTERVAL_TYPE_${r.interval_type}`]}${
                    IntervalTypes[r.interval_type].isValueRequired ? r.timing : ""
                  }${IntervalTypes[r.interval_type].unit ?? ""}`,
                  TERMS[`SUBJECT_TYPE_${r.subject_type}`],
                  `${TERMS[`DUE_DATE_BASE_${r.due_date_base}`]}${
                    PeriodTypes[r.due_date_period_type].isValueRequired ? r.due_date_period_value : ""
                  }${TERMS[`PERIOD_TYPE_${r.due_date_period_type}`]}`,
                ],
                link: r.id
                  ? `/_/masters/remind_setting/edit/?id=${r.id}`
                  : `/_/masters/remind_setting/edit/?key=${r.key}`,
              };
            })}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default RemindSettingList;
