import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import { AuthProvider, RequireAuth } from "./AuthProvider";
import routingMap from "./routingMap";
// import { register } from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              {routingMap.map(({ path, element, role }) => {
                const thisElement =
                  role.length > 0 ? (
                    <RequireAuth path={path} role={role}>
                      {element}
                    </RequireAuth>
                  ) : (
                    element
                  );
                return <Route key={path ? path : "root"} path={path} element={thisElement} />;
              })}
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// register({
//   onMessage: (e)=>{
//     console.log(e.body)
//   }
// })
