const data = {
  category: "project",
  table: "project",
  subFields: {
    project_code: {},
    project_role: {},
    remarks: {},
  },
};
export default data;
