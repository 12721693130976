const data = {
  category: "duty",
  table: "duty_summary",
  subFields: {
    duty_summary: {
      type: "tableHandler",
      tag: "tb_",
    },
    duty_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    duty_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      duty_summary: "職務の通算経験年数",
    },
  },
};

export default data;
