const data = {
  category: "term_contract",
  table: "term_contract",
  subFields: {
    term_contract_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_start_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_end_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joined_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    place_of_work_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    description_of_work_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    basic_wage_monthly_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    basic_wage_daily_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    basic_wage_hourly_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    wage_grade_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    allowance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_member_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_member_health_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_member_employment_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_days_per_week_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    contract_days_per_year_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    renewal_contract_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    reason_not_renewing_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      term_contract_history: "有期雇用契約履歴",
    },
  },
};

export default data;
