import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { Mail, SearchCondition } from "./mailValues";
import { callCollaborator } from "../../app/api";

const SLICE_NAME = "mail";

interface MailState {
  mails: Mail[];
  selectedMail: Mail;
}

const emptyMail: Mail = {
  id: "",
  language: "",
  subject: "",
  content: "",
  name: "",
};

const initialState: MailState = {
  mails: [],
  selectedMail: emptyMail,
};

export const getMails = createAsyncThunk(
  SLICE_NAME + "/getMails",
  async ({ conditions }: { conditions?: SearchCondition }) => {
    const params = {} as SearchCondition;
    if (conditions && conditions["keyword"]) {
      params.name = conditions.keyword;
    }
    const res = await callCollaborator("get", "mail_manager/texttemplate/mail")(params);
    const mails: Mail[] = res.data.results[0].result.map((m: any) => {
      return {
        id: m.id,
        language: m.language,
        subject: m.subject,
        content: m.content,
        name: m.name,
      } as Mail;
    });
    return { mails };
  }
);

export const getMail = createAsyncThunk(SLICE_NAME + "/getMail", async ({ id }: { id: string }) => {
  const res = await callCollaborator("get", "mail_manager/texttemplate/mail")({ id });
  return res.data.results[0].result[0];
});

export const copyMails = createAsyncThunk(SLICE_NAME + "/copyMails", async ({ ids }: { ids: string[] }) => {
  const mails = store.getState().mail.mails;
  const mailsDataToCopy = ids.reduce((prev, current) => {
    const copyMail = mails.find((m: Mail) => m.id === current);
    if (copyMail) {
      const { id, name, ...rest } = copyMail;
      return [...prev, { ...rest, name: `${name}_copy` }];
    }
    return prev;
  }, [] as { language: string; subject: string; content: string; name: string }[]);
  await callCollaborator("post", "mail_manager/texttemplate/mail")(mailsDataToCopy);
});

export const deleteMails = createAsyncThunk(SLICE_NAME + "/deleteMails", async ({ ids }: { ids: string[] }) => {
  await callCollaborator("delete", "mail_manager/texttemplate/mail")({ id__in: ids });
});

export const testSendMail = createAsyncThunk(SLICE_NAME + "/testSendMail", async () => {
  const { user } = store.getState().user;
  const mainCompanyName = user.companyChoices.find((c: any) => c.company_code === user.main_company)?.company_name;
  const selectedMail = store.getState().mail.selectedMail;
  const replacedContent = selectedMail.content
    .replace(new RegExp("{main_company_code}", "g"), user.main_company)
    .replace(new RegExp("{main_company_name}", "g"), mainCompanyName)
    .replace(new RegExp("{company_name}", "g"), user.current_company.company_name)
    .replace(new RegExp("{login_code}", "g"), user.login_code)
    .replace(new RegExp("{account_name}", "g"), user.account_name)
    .replace(new RegExp("{create_password}", "g"), "***")
    .replace(new RegExp("{url}", "g"), `${window.location.protocol}//${window.location.hostname}/`);
  await callCollaborator(
    "post",
    "mail/send"
  )({
    messenger: "SHRPA <no-reply@fosterlink.co.jp>",
    receiver: user.mail_address,
    subject: selectedMail.subject,
    content: replacedContent,
  });
});

export const updateMail = createAsyncThunk(
  SLICE_NAME + "/updateMail",
  async ({ id, name, subject, content }: { id: string; name: string; subject: string; content: string }) => {
    const res = await callCollaborator("put", "mail_manager/texttemplate/mail")({ id, name, subject, content });
    return res.data.results[0].result[0];
  }
);

export const registerMail = createAsyncThunk(
  SLICE_NAME + "/registerMail",
  async ({
    name,
    subject,
    content,
    language = "ja",
  }: {
    name: string;
    subject: string;
    content: string;
    language?: string;
  }) => {
    const res = await callCollaborator("post", "mail_manager/texttemplate/mail")({ name, subject, content, language });
    return res.data.results[0].result[0];
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectMail: (state) => {
      state.selectedMail = emptyMail;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMails.fulfilled, (state, action) => {
      state.mails = action.payload.mails;
    });
    builder.addCase(getMail.fulfilled, (state, action) => {
      state.selectedMail = action.payload;
    });
    builder.addCase(updateMail.fulfilled, (state, action) => {
      state.selectedMail = action.payload;
    });
    builder.addCase(registerMail.fulfilled, (state, action) => {
      state.selectedMail = action.payload;
    });
  },
});

export const { unselectMail } = slice.actions;
export const selectMailState = (state: RootState) => {
  return state.mail as MailState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
