import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { selectTokenState } from "./idTokenSlice";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { idToken } = useAppSelector(selectTokenState);
  const navigate = useNavigate();
  useEffect(() => {
    const assessmentViewOrigin = process.env.REACT_APP_VIEW_ORIGIN_ASSESSMENT?.trim();
    if (assessmentViewOrigin) {
      window.open(`${assessmentViewOrigin}/gateway?id_token=${idToken}&next=/myaccount/setting`, "shrpa");
      navigate(-1);
    }
  }, []);
  return <div className="Layout"></div>;
}

export default App;
