const data = {
  category: "social_insurance",
  table: "social_insurance",
  subFields: {
    social_insurance_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    basic_pension_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    social_insurance_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_fee_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    parttime_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    health_insurance_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    care_insurance_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    health_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_health_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    health_insurance_standard_remuneration_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_insurance_reference_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_insurance_category_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_pension_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_insurance_standard_remuneration_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_fund_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    pension_fund_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_pension_fund_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    has_dependent_spouse_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    has_dependent_relative_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      social_insurance_history: "社会保険履歴",
    },
  },
};

export default data;
