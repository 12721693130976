const data = {
  category: "leave_of_absence",
  table: "leave_of_absence",
  subFields: {
    leave_of_absence_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    leave_of_absence_reason_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    planned_end_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    return_to_work_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_add_duration_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    leave_of_absence_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    leave_of_absence_email_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      leave_of_absence_history: "休職履歴",
    },
  },
};

export default data;
