import { logout, useAppDispatch } from "../../app/store";
import { Alert, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function NoRole() {
  const dispatch = useAppDispatch();
  const _logout = () => {
    dispatch(logout());
  };
  return (
    <div className="Layout">
      <div className="Layout__main">
        <Alert variant="warning">
          <Alert.Heading>表示する権限がありません</Alert.Heading>
          <div>意図せずこちらの画面が表示される場合、管理者にお問い合わせください。</div>
        </Alert>
        <Button onClick={_logout}>ログイン画面</Button>
      </div>
    </div>
  );
}

export default NoRole;
