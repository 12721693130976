import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { MyAccount, MyAccountToken } from "./myAccountValues";
import { call } from "../../app/api";

const SLICE_NAME = "myAccount";

interface MyAccountState {
  myAccount: MyAccount;
  myAccountToken: MyAccountToken;
}

const emptyMyAccount: MyAccount = {
  name: "",
  mail_address: "",
  language: "",
  image: "",
  is_remind: false,
  is_billing: false,
};

const emptyMyAccountToken: MyAccountToken = {
  id: 0,
  token: "",
};

const initialState: MyAccountState = {
  myAccount: emptyMyAccount,
  myAccountToken: emptyMyAccountToken,
};

export const getMyAccount = createAsyncThunk(SLICE_NAME + "/getMyAccount", async () => {
  const res = await call("get", "account_manager/my_account")();
  const accounts = res.data.result.map((data: any) => ({
    name: data.name,
    mail_address: data.mail_address,
    image: data.image,
    language: data.language,
    is_remind: data.is_remind,
    is_billing: data.is_billing,
  })) as MyAccount[];
  return { result: accounts[0] };
});

export const putMyAccount = createAsyncThunk(
  SLICE_NAME + "/putMyAccount",
  async (options: { name: string; mail_address: string; language: string; is_remind: boolean; image: string }) => {
    const res = await call(
      "put",
      "account_manager/my_account"
    )({
      name: options.name,
      mail_address: options.mail_address,
      language: options.language,
      is_remind: options.is_remind,
      image: options.image,
    });
    const r = res.data.result[0];
    const updated = {
      name: r.name,
      image: r.image,
      is_remind: r.is_remind,
      language: r.language,
      mail_address: r.mail_address,
    } as MyAccount;
    return updated;
  }
);

export const getMyAccountToken = createAsyncThunk(SLICE_NAME + "/getMyAccountToken", async () => {
  const res = await call("get", "account_manager/my_account_token")();
  const r = res.data.result[0];
  const data = {
    id: r.id,
    token: r.token,
  } as MyAccountToken;
  return { result: data };
});

export const postMyAccountToken = createAsyncThunk(SLICE_NAME + "/postMyAccountToken", async () => {
  const res = await call("post", "account_manager/my_account_token")();
  const r = res.data.result[0];
  const data = {
    id: r.id,
    token: r.token,
  } as MyAccountToken;
  return { result: data };
});

export const putMyAccountToken = createAsyncThunk(SLICE_NAME + "/putMyAccountToken", async (id: string) => {
  const res = await call(
    "put",
    "account_manager/my_account_token"
  )({
    id: id,
  });
  const r = res.data.result[0];
  const data = {
    id: r.id,
    token: r.token,
  } as MyAccountToken;
  return { result: data };
});

export const updateMyPassword = createAsyncThunk(
  SLICE_NAME + "/updateMyPassword",
  async (options: { oldPassword: string; newPassword: string; language: string }) => {
    const res = await call(
      "put",
      "account_manager/change_password"
    )({
      old_password: options.oldPassword,
      new_password: options.newPassword,
      language: options.language,
    });
    const r = res.data.result[0];
    return { result: r.finished };
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyAccount.fulfilled, (state, action) => {
      state.myAccount = action.payload.result;
    });
    builder.addCase(putMyAccount.fulfilled, (state, action) => {
      state.myAccount = action.payload;
    });
    builder.addCase(getMyAccountToken.fulfilled, (state, action) => {
      state.myAccountToken = action.payload.result;
    });
    builder.addCase(postMyAccountToken.fulfilled, (state, action) => {
      state.myAccountToken = action.payload.result;
    });
    builder.addCase(putMyAccountToken.fulfilled, (state, action) => {
      state.myAccountToken = action.payload.result;
    });
  },
});

export const myAccountState = (state: RootState) => {
  return state.myAccount as MyAccountState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
