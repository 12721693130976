import { useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store";
import { registerMail } from "./mailSlice";
import { INSERT_ITEM, CONTENT_MAX_LENGTH } from "./mailValues";
import { showToast } from "../notification/notificationSlice";
import { Row, Col, Button, ListGroup, Form, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, $state] = useState({
    name: "",
    subject: "",
    content: "",
    activeModal: "",
  });
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const errorMessages = useMemo(() => {
    const errorMessages = [] as { place: string; message: string }[];
    if (state.name === "") errorMessages.push({ place: "name", message: "入力してください" });
    if (state.subject === "") errorMessages.push({ place: "subject", message: "入力してください" });
    if (state.content === "") errorMessages.push({ place: "content", message: "入力してください" });
    return errorMessages;
  }, [state]);

  const register = async () => {
    const res = await dispatch(registerMail({ name: state.name, subject: state.subject, content: state.content }));
    $state({ ...state, activeModal: "" });
    dispatch(
      showToast({
        id: `toast-${Date.now()}`,
        content: `${res.payload.name}を登録しました。`,
      })
    );
    navigate(`/_/mail/detail/${res.payload.id}`);
  };

  const insertCode = (code: string) => {
    if (contentRef.current) {
      let sentence = state.content;
      const textarea = contentRef.current;
      const before = sentence.substring(0, textarea.selectionStart);
      const word = `{${code}}`;
      const after = sentence.substring(textarea.selectionStart, sentence.length);
      sentence = before + word + after;
      $state({ ...state, content: sentence });
      // 状態更新後にフォーカスとカーソル位置を設定する
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(before.length + word.length, before.length + word.length);
      }, 0);
    }
  };

  // 不正な差し込みをされないようチェックする
  const validateInsertItem = (): boolean => {
    const value = state.content.replace(/\r?\n/g, "");
    const regex = /\{([^}]*)\}/g;
    const matches = Array.from(value.matchAll(regex));
    const insertItemCodes = INSERT_ITEM.map((item) => item.code);
    const isError = matches.some((match) => !insertItemCodes.includes(match[1]));
    return isError;
  };

  return (
    <>
      <Row className="mb-1">
        <Col>
          <span className="--required-label"></span> は必須項目です。
        </Col>
      </Row>
      <ListGroup className="mb-4">
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={2}>
              <div className="--bold --required-label">名前</div>
            </Col>
            <Col md={10}>
              <Form.Control
                type="text"
                id="name"
                value={state.name}
                onChange={(e) => {
                  $state({ ...state, name: e.target.value });
                }}
              />
              {(() => {
                const m = errorMessages?.find((message) => message.place === "name");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={2}>
              <div className="--bold --required-label">件名</div>
            </Col>
            <Col md={10}>
              <Form.Control
                type="text"
                id="subject"
                value={state.subject}
                onChange={(e) => {
                  $state({ ...state, subject: e.target.value });
                }}
              />
              {(() => {
                const m = errorMessages?.find((message) => message.place === "subject");
                return m ? <div className="--text-annotation mt-1 --font-s">{m.message}</div> : null;
              })()}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={2}>
              <div className="--bold --required-label">本文</div>
            </Col>
            <Col md={10}>
              <Form.Control
                as="textarea"
                id="content"
                maxLength={CONTENT_MAX_LENGTH}
                rows={10}
                ref={contentRef}
                value={state.content}
                onChange={(e) => {
                  $state({ ...state, content: e.target.value });
                }}
              />
              <div className="mt-1">
                {state.content.length} / {CONTENT_MAX_LENGTH}
                {(() => {
                  const m = errorMessages?.find((message) => message.place === "content");
                  return m ? <span className="--text-annotation --font-s ms-2">{m.message}</span> : null;
                })()}
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row className="--align-items-center">
            <Col md={2}>
              <div className="--bold">差し込み項目</div>
            </Col>
            <Col md={10}>
              <div>クリックすると要素を本文に差し込めます。</div>
              <div className="mt-1">
                <Badge pill bg="danger" className="me-2">
                  !
                </Badge>
                <span className="--text-annotation">
                  差し込み項目「パスワード生成」を利用した場合、メール送信のたびにパスワードが変更されますのでご注意ください。​
                </span>
              </div>
              <div>
                {INSERT_ITEM.map((item) => (
                  <Button
                    className="me-2 my-1"
                    style={{ width: "300px" }}
                    variant="outline-secondary"
                    key={item.code}
                    onClick={() => insertCode(item.code)}
                  >
                    {item.name}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      <Row className="mb-1">
        <Col>
          <Button
            onClick={() => {
              if (validateInsertItem()) {
                $state({ ...state, activeModal: "invalid_insert_item" });
                return;
              }
              $state({ ...state, activeModal: "before_register" });
            }}
            disabled={errorMessages.length > 0}
            className="mx-2"
          >
            登録
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_register"}
        message="登録します。よろしいですか？"
        onConfirm={register}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
      <ModalDialog
        show={state.activeModal === "invalid_insert_item"}
        message="差し込み項目に不備があります。確認してください。"
        onConfirm={() => {
          $state({ ...state, activeModal: "" });
        }}
        type="alert"
      />
    </>
  );
}

export default App;
