import { useState, useEffect } from "react";
import "../css/style.scss";
import classNames from "classnames";
import { Alert, Button, Modal, Row, Col } from "react-bootstrap";
import { DecodedFileData } from "./Uploader";

type Props = {
  className?: string;
  onSave: (result: DecodedFileData) => any;
};

/*
    MediaStream, イベントハンドラなどを
    コンポーネントの外のスコープに保持する。
*/
const values = {} as {
  [slug: string]: {
    stream: MediaStream | null;
    previewImageURL: string;
    aspect: number;
    watcher: () => any;
  };
};

function PhotoCapturer({ className = "", onSave }: Props) {
  const [slug, _] = useState(`${Math.floor(Math.random() * 100000000)}`);
  const [isCameraActive, $isCameraActive] = useState(false);
  const [isPreviewModalActive, $isPreviewModalActive] = useState(false);
  const [cameraError, $cameraError] = useState(false);
  const useCamera = async () => {
    try {
      if (!values[slug]) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1920, height: 1080, facingMode: "user" },
          audio: false,
        });
        const { width, height } = stream.getVideoTracks()[0].getSettings();
        values[slug] = {
          stream,
          previewImageURL: "",
          aspect: width && height ? height / width : 9 / 16,
          watcher: () => {
            const frame = document.getElementById(`camera-preview-frame-${slug}`) as HTMLDivElement;
            const video = document.getElementById(`camera-preview-${slug}`) as HTMLVideoElement;
            video.width = frame.clientWidth * 2;
            video.height = video.width * values[slug].aspect;
          },
        };
      }

      $isCameraActive(true);
      const video = document.getElementById(`camera-preview-${slug}`) as HTMLVideoElement;
      video.srcObject = values[slug].stream;
      video.onloadedmetadata = () => {
        video.play();
        const frame = document.getElementById(`camera-preview-frame-${slug}`) as HTMLDivElement;
        video.width = frame.clientWidth * 2;
        video.height = video.width * values[slug].aspect;
      };

      window.addEventListener("resize", values[slug].watcher);
    } catch (e) {
      console.log(e);
      $cameraError(true);
    }
  };
  const closeCamera = () => {
    if (values[slug]) {
      values[slug].stream?.getTracks().forEach((track) => track.stop());
      window.removeEventListener("resize", values[slug].watcher);
      delete values[slug];
    }
    $isCameraActive(false);
  };
  const captureCamera = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const video = document.getElementById(`camera-preview-${slug}`) as HTMLVideoElement;
    if (!video || !ctx || !values[slug]) return;
    values[slug].previewImageURL = "";
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height);
    const data = canvas.toDataURL("image/jpg");
    values[slug].previewImageURL = data;
    $isPreviewModalActive(true);
  };

  const closePreview = () => {
    $isPreviewModalActive(false);
    values[slug].previewImageURL = "";
  };

  const saveImage = () => {
    onSave({
      dataURI: values[slug].previewImageURL.split(",")[1],
      dataURIprefix: values[slug].previewImageURL.split(",")[0],
      name: `camera-${Math.floor(new Date().getTime() / 1000)}.jpg`,
      type: "image/jpg",
    });
    $isPreviewModalActive(false);
  };

  useEffect(() => {
    return () => {
      closeCamera();
      if (values[slug]) {
        window.removeEventListener("resize", values[slug].watcher);
        delete values[slug];
      }
    };
  }, []);

  return (
    <div className={className} id={`camera-preview-frame-${slug}`}>
      <video
        id={`camera-preview-${slug}`}
        className={classNames({ "Camera-preview": true, "--active": isCameraActive })}
      />
      {cameraError && (
        <Alert variant="warning">
          カメラに接続できませんでした。ブラウザがカメラを許可する設定になっているか、他の
          アプリケーションがカメラを使用中でないかを確認してください。
        </Alert>
      )}
      <div className={classNames({ "mt-2": isCameraActive })}>
        {isCameraActive ? (
          <div className="--flex">
            <Button variant="outline-secondary" onClick={closeCamera} className="mx-1">
              カメラを終了する
            </Button>
            <Button variant="primary" onClick={captureCamera}>
              撮影する
            </Button>
          </div>
        ) : (
          <Button variant="outline-primary" onClick={useCamera} disabled={cameraError}>
            カメラを起動する
          </Button>
        )}
      </div>
      <Modal show={isPreviewModalActive} onHide={closePreview} size="lg" centered>
        <Modal.Header>プレビュー</Modal.Header>
        <Modal.Body>
          {values[slug]?.previewImageURL && (
            <Row>
              <Col className="--text-align-center">
                <img src={values[slug].previewImageURL} className="w-100" />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex">
            <Button variant="outline-secondary" onClick={closePreview} className="mx-1">
              再度撮影する
            </Button>
            <Button variant="primary" onClick={saveImage}>
              この画像を使用する
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PhotoCapturer;
