const data = {
  category: "calculation_unit_price",
  table: "calculation_unit_price",
  subFields: {
    daily_allowance1: {},
    daily_allowance2: {},
    daily_allowance3: {},
    daily_allowance4: {},
    daily_allowance5: {},
    time_allowance1: {},
    time_allowance2: {},
    time_allowance3: {},
    time_allowance4: {},
    time_allowance5: {},
    time_allowance6: {},
    time_allowance7: {},
    time_allowance8: {},
    time_allowance9: {},
    time_allowance10: {},
    time_allowance11: {},
    time_allowance12: {},
    time_allowance13: {},
    time_allowance14: {},
    time_allowance15: {},
    salary_base: {},
    housing_allowance: {},
    family_benefit: {},
    salary_payment1: {},
    salary_payment2: {},
    salary_payment3: {},
    salary_payment4: {},
    salary_payment5: {},
    salary_payment6: {},
    salary_payment7: {},
    salary_payment8: {},
    salary_payment9: {},
    salary_payment10: {},
    salary_payment11: {},
    salary_payment12: {},
    salary_payment13: {},
    salary_payment14: {},
    salary_payment15: {},
    salary_payment16: {},
    salary_payment17: {},
    salary_payment18: {},
    salary_payment19: {},
    salary_payment20: {},
    salary_payment21: {},
    salary_payment22: {},
    salary_payment23: {},
    salary_payment24: {},
    salary_payment25: {},
    salary_payment26: {},
    salary_payment27: {},
    salary_deduction1: {},
    salary_deduction2: {},
    salary_deduction3: {},
    salary_deduction4: {},
    salary_deduction5: {},
    salary_deduction6: {},
    salary_deduction7: {},
    salary_deduction8: {},
    salary_deduction9: {},
    salary_deduction10: {},
    salary_deduction11: {},
    salary_deduction12: {},
    salary_deduction13: {},
    salary_deduction14: {},
    salary_deduction15: {},
    salary_deduction16: {},
    salary_deduction17: {},
    salary_deduction18: {},
    salary_deduction19: {},
    salary_deduction20: {},
    salary_deduction21: {},
    salary_deduction22: {},
    salary_deduction23: {},
    salary_deduction24: {},
    salary_deduction25: {},
    salary_deduction26: {},
    salary_deduction27: {},
    salary_deduction28: {},
    salary_deduction29: {},
    salary_deduction30: {},
    bonus_payment1: {},
    bonus_payment2: {},
    bonus_payment3: {},
    bonus_payment4: {},
    bonus_payment5: {},
    bonus_payment6: {},
    bonus_payment7: {},
    bonus_payment8: {},
    bonus_payment9: {},
    bonus_payment10: {},
    bonus_payment11: {},
    bonus_payment12: {},
    bonus_payment13: {},
    bonus_payment14: {},
    bonus_payment15: {},
    bonus_payment16: {},
    bonus_payment17: {},
    bonus_payment18: {},
    bonus_payment19: {},
    bonus_payment20: {},
    bonus_payment21: {},
    bonus_payment22: {},
    bonus_payment23: {},
    bonus_payment24: {},
    bonus_payment25: {},
    bonus_payment26: {},
    bonus_payment27: {},
    bonus_payment28: {},
    bonus_payment29: {},
    bonus_payment30: {},
    bonus_deduction1: {},
    bonus_deduction2: {},
    bonus_deduction3: {},
    bonus_deduction4: {},
    bonus_deduction5: {},
    bonus_deduction6: {},
    bonus_deduction7: {},
    bonus_deduction8: {},
    bonus_deduction9: {},
    bonus_deduction10: {},
    bonus_deduction11: {},
    bonus_deduction12: {},
    bonus_deduction13: {},
    bonus_deduction14: {},
    bonus_deduction15: {},
    bonus_deduction16: {},
    bonus_deduction17: {},
    bonus_deduction18: {},
    bonus_deduction19: {},
    bonus_deduction20: {},
    bonus_deduction21: {},
    bonus_deduction22: {},
    bonus_deduction23: {},
    bonus_deduction24: {},
    bonus_deduction25: {},
    bonus_deduction26: {},
    bonus_deduction27: {},
    bonus_deduction28: {},
    bonus_deduction29: {},
    bonus_deduction30: {},
  },
};

export default data;
