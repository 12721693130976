import columnTypeChoicesJson from "../../column_type_choices.json";
import columnTypeMappingsJson from "../../column_type_mappings.json";

export type MasterDataItem = {
  [k: string]: any;
};

// e.g.) [{ "id": "section", "codesNames": { "100": "営業部", "200": "技術部"}, "label": {"code": "部署コード", "name": "部署名"},}, ...}]
export type MasterCodeChoice = {
  id: string;
  label: { [code: string]: string };
  codesNames: { [key: string]: string };
};

export type MasterCodeChoiceForIndividual = {
  sector: string;
  options: { [key: string]: string }[];
};

export const MASTER_TABLE_PREFIX = "profile_m_";

export const masterColumnChoices = (() => {
  // 2つのjsonファイル取得
  const columnTypeMappings = columnTypeMappingsJson as {
    [table: string]: { [column: string]: string };
  };
  const columnTypeChoices = columnTypeChoicesJson as {
    [key: string]: { [label: string]: string }[];
  };
  let masterColumnChoices = {} as {
    [table: string]: { [column: string]: string[] };
  };
  for (const tableKey in columnTypeMappings) {
    // ユーザデータ系のみに絞る
    if (!tableKey.startsWith("m_")) continue;
    const choiceKeys = columnTypeMappings[tableKey];
    const tableName = `profile_${tableKey}`;
    let columnChoice = {} as { [column: string]: string[] };
    for (const columnName in choiceKeys) {
      // カラムに対する選択肢の配列を取得
      const choiceKey = choiceKeys[columnName];
      const choices = columnTypeChoices[choiceKey]?.map((choice) => choice.label);
      columnChoice = { ...columnChoice, [columnName]: choices };
    }
    // テーブル毎にまとめる
    masterColumnChoices = { ...masterColumnChoices, [tableName]: columnChoice };
  }
  return masterColumnChoices;
})();

export const MASTER_DATA_LIST_UPTO = 20;
