const data = {
  category: "salary",
  table: "salary",
  subFields: {
    payment_salary_base: {},
    payment_salary_board: {},
    payment_allowance_legal_overtime: {},
    payment_allowance_extra_legal_overtime: {},
    payment_allowance_overtime_late_night: {},
    payment_allowance_worked_holiday: {},
    payment_allowance_worked_holiday_late_night: {},
    payment_allowance_worked_legal_holiday: {},
    payment_allowance_worked_legal_holiday_late_night: {},
    payment_allowance_overwork_over_45: {},
    payment_allowance_overwork_over_60: {},
    payment_allowance_transportation: {},
    payment_allowance_transportation_taxable: {},
    payment_deduction_late_early: {},
    payment_deduction_absence: {},
    payment_salary_item1: {},
    payment_salary_item2: {},
    payment_salary_item3: {},
    payment_salary_item4: {},
    payment_salary_item5: {},
    payment_salary_item6: {},
    payment_salary_item7: {},
    payment_salary_item8: {},
    payment_salary_item9: {},
    payment_salary_item10: {},
    payment_salary_item11: {},
    payment_salary_item12: {},
    payment_salary_item13: {},
    payment_salary_item14: {},
    payment_salary_item15: {},
    payment_salary_item16: {},
    payment_salary_item17: {},
    payment_salary_item18: {},
    payment_salary_item19: {},
    payment_salary_item20: {},
    deduction_health_insurance: {},
    deduction_health_care_insurance: {},
    deduction_health_pension_insurance: {},
    deduction_welfare_pension_fund: {},
    deduction_employment_insurance: {},
    deduction_income_tax: {},
    deduction_resident_tax: {},
    deduction_item1: {},
    deduction_item2: {},
    deduction_item3: {},
    deduction_item4: {},
    deduction_item5: {},
    deduction_item6: {},
    deduction_item7: {},
    deduction_item8: {},
    deduction_item9: {},
    deduction_item10: {},
    other_total_amount: {},
    other_total_amount_insurance: {},
    other_amount_payment_taxable: {},
    other_amount_payment_non_taxable: {},
    other_taxable_amount: {},
    other_tax_over_under: {},
    other_total_deductions: {},
    other_amount_after_deduction: {},
    other_amount_bank1: {},
    other_amount_bank2: {},
    other_amount_bank3: {},
    other_amount_cash: {},
    remarks: {},
  },
};
export default data;
