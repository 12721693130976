import "../css/style.scss";
import { Row, Col, ListGroup, Button } from "react-bootstrap";

function OrderEditor({
  data,
  onChange,
}: {
  data: { id: string; label: string; fixed: boolean }[];
  onChange: (data: { id: string; label: string; fixed: boolean }[]) => any;
}) {
  return (
    <ListGroup>
      {data.map(({ id, label, fixed }, i) => {
        return (
          <ListGroup.Item key={id}>
            <Row className="--align-items-center">
              <Col md="8">{label}</Col>
              <Col md="4">
                {i > 0 && !data[i - 1]?.fixed ? (
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      const next = data.filter((_) => _.id !== id);
                      next.splice(i - 1, 0, { id, label, fixed });
                      onChange && onChange(next);
                    }}
                  >
                    上に移動
                  </Button>
                ) : fixed ? (
                  <Button variant="outline-secondary" disabled>
                    固定
                  </Button>
                ) : null}
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default OrderEditor;
