import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getSectors,
  getDefaultLabelColumns,
  getExternalColumns,
  commitExternalColumns,
  selectClientState,
  selectActiveSectors,
  uploadExternalColumns,
  downloadExternalColumns,
} from "./clientSlice";
import ModalDialog from "../../component/ModalDialog";
import { useParams } from "react-router-dom";
import Uploader, { DecodedFileData } from "../../component/Uploader";

function ExternalRenameColumnList() {
  const { target } = useParams();
  const { externalColumns, defaultLabelColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    sectorId: target ?? "",
    inputtedColumns: {} as { [column: string]: string },
    switchingSectorId: "",
    activeModal: "",
    isUploading: false,
  });

  useEffect(() => {
    dispatch(getExternalColumns());
    dispatch(getSectors());
  }, []);

  useEffect(() => {
    window.history.replaceState({}, "", `/_/masters/external/edit/${state.sectorId}`);
    if (!defaultLabelColumns[state.sectorId]) {
      dispatch(getDefaultLabelColumns({ sectorId: state.sectorId }));
    }
  }, [state.sectorId]);

  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    $state({ ...state, isUploading: true });
    try {
      await dispatch(
        uploadExternalColumns({
          sector: state.sectorId,
          file: decodedFileData.dataURI,
          name: decodedFileData.name,
        })
      );
    } catch (error) {
    } finally {
      $state({ ...state, isUploading: false });
    }
  };
  const download = async () => {
    await dispatch(downloadExternalColumns({ sector: state.sectorId }));
  };

  const duplicatedValues = useMemo(() => {
    const currentIdLabels = {
      ...externalColumns[state.sectorId],
      ...Object.keys(state.inputtedColumns).reduce((prev, current) => {
        if (state.inputtedColumns[current] !== "") return { ...prev, [current]: state.inputtedColumns[current] };
        else return { ...prev, [current]: "" };
      }, {}),
    };
    return Object.values(currentIdLabels).filter((label, i, array) => label !== "" && !(array.indexOf(label) === i));
  }, [state]);

  const matchedSector: any = useMemo(() => {
    const matchedSector = activeSectors.find(({ sector_id }) => sector_id === state.sectorId);
    return matchedSector;
  }, [state.sectorId]);

  if (!matchedSector?.logical_name) {
    return <Container className="mt-4"></Container>;
  }
  return (
    <Container>
      {state.sectorId !== "" && (
        <div className="pt-4">
          <h2 className="Headline--section mb-2">{matchedSector.logical_name}の項目名変更</h2>
          <div className="my-2">
            {state.isUploading ? (
              <Alert variant={"info"}>リクエストを受け付けました。処理完了までしばらくお待ちください。</Alert>
            ) : (
              <Uploader
                onFileLoad={onFileLoad}
                accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
              />
            )}
          </div>
          <Button className="mb-2 mx-2" variant="outline-secondary" onClick={download}>
            ダウンロード
          </Button>
          {defaultLabelColumns[state.sectorId]?.map(({ id, label }, i) => {
            const inputted = state.inputtedColumns[id]; // いま入力している値
            const renamed = externalColumns[state.sectorId]?.[id]; // DBに設定している値
            const value = inputted ?? renamed ?? "";
            return (
              <Row key={`${i}`} className="mb-2">
                <Col>
                  <Form.Label className="--bold">{label}</Form.Label>
                  <Form.Control
                    type="text"
                    value={value}
                    onChange={(e) => {
                      $state({
                        ...state,
                        inputtedColumns: { ...state.inputtedColumns, [id]: e.target.value },
                      });
                    }}
                  />
                  {duplicatedValues.includes(value) ? (
                    <span className="--text-annotation mt-1 --font-s">他の項目名と重複するため設定できません。</span>
                  ) : null}
                </Col>
              </Row>
            );
          })}
          <Button onClick={() => $state({ ...state, activeModal: "commit" })} disabled={duplicatedValues.length > 0}>
            保存
          </Button>
        </div>
      )}
      <ModalDialog
        show={state.activeModal === "commit"}
        message="保存します。よろしいですか？"
        onConfirm={() => {
          const renameColumns = { ...externalColumns[state.sectorId], ...state.inputtedColumns };
          dispatch(
            commitExternalColumns({
              sectorId: state.sectorId,
              renameColumns: {
                ...Object.keys(renameColumns).reduce((prev, current) => {
                  if (renameColumns[current] === "") return prev;
                  return { ...prev, [current]: renameColumns[current] };
                }, {}),
              },
            })
          ).then(() => {
            dispatch(getExternalColumns());
          });
          $state({ ...state, activeModal: "", inputtedColumns: {} });
        }}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
      <ModalDialog
        show={state.activeModal === "switch"}
        message="入力済の内容は破棄されますが、切り替えてよろしいですか？"
        onConfirm={() => {
          $state({
            ...state,
            sectorId: state.switchingSectorId,
            inputtedColumns: {},
            activeModal: "",
            switchingSectorId: "",
          });
        }}
        onCancel={() => {
          $state({ ...state, switchingSectorId: "", activeModal: "" });
        }}
        type="destructiveConfirm"
        confirmButtonName="破棄して切替"
      />
    </Container>
  );
}

export default ExternalRenameColumnList;
