import "../css/style.scss";
import { useState } from "react";
import { Operation } from "../features/dashboard/operationValues";
import { Row, Col, Form, Button } from "react-bootstrap";

function App({
  data,
  className,
  onChange,
}: {
  data: Operation;
  className?: string;
  onChange: (next: Operation, validated: boolean) => any;
}) {
  const { title, description, flows } = data;
  const [isWaiting, $isWaiting] = useState(false);
  if (isWaiting) {
    return null;
  }
  const validate = (next: Operation): boolean => {
    let ok = true;
    next.flows.forEach((f) => {
      if (f.title === "" || f.tasks.length === 0) ok = false;
      f.tasks.forEach((p) => {
        if (p.title === "" || p.links?.some((_) => _.label === "")) ok = false;
      });
    });
    return ok;
  };
  const update = (next: Operation, isDelayedUpdate = false) => {
    if (!onChange) return;
    if (!isDelayedUpdate) return onChange(next, validate(next));
    $isWaiting(true);
    setTimeout(() => {
      onChange(next, validate(next));
      $isWaiting(false);
    }, 10);
  };
  return (
    <article className={`Setting-card ${className ? ` ${className}` : ""}`}>
      <h2 className="Setting-card__title">{title}</h2>
      <div className="Setting-card__body">
        {description && <div className="Setting-card__description">{description}</div>}
        {flows.length > 0 && (
          <section className="Setting-card__flows">
            {flows.map((flow, flowIndex) => {
              return (
                <div className="Setting-card__flow" key={`flow${flowIndex}`}>
                  <h3 className="Setting-card__flow-title" data-invalid={flow.title === ""}>
                    <Form.Control
                      type="text"
                      value={flow.title}
                      onChange={(e) => {
                        update({
                          ...data,
                          flows: flows.map((f, fi) => ({
                            ...f,
                            title: fi === flowIndex ? e.target.value : f.title,
                          })),
                        });
                      }}
                    ></Form.Control>
                  </h3>
                  {flow.tasks.length > 0 ? (
                    flow.tasks.map((p, pi) => {
                      return (
                        <section className="Setting-card__task-section" key={`task_${pi}`}>
                          <div className="Setting-card__task--editor">
                            <div className="mb-1 --flex --align-items-center">
                              <div>【業務 {pi + 1}】</div>
                              <Button
                                onClick={() => {
                                  update(
                                    {
                                      ...data,
                                      flows: flows.map((f, fi) => {
                                        if (fi !== flowIndex) return f;
                                        return {
                                          ...f,
                                          tasks: f.tasks.filter((_p, _pi) => _pi !== pi),
                                        };
                                      }),
                                    },
                                    true
                                  );
                                }}
                                variant="outline-danger"
                                className="mx-1 --pre --font-s"
                              >
                                削除
                              </Button>
                            </div>
                            <div data-invalid={p.title === ""}>
                              <Form.Control
                                type="text"
                                value={p.title}
                                onChange={(e) => {
                                  update({
                                    ...data,
                                    flows: flows.map((f, fi) => {
                                      if (fi !== flowIndex) return f;
                                      return {
                                        ...f,
                                        tasks: f.tasks.map((_p, _pi) => {
                                          if (_pi !== pi) return _p;
                                          return { ..._p, title: e.target.value };
                                        }),
                                      };
                                    }),
                                  });
                                }}
                              ></Form.Control>
                            </div>

                            <div className="Setting-card__task-steps--editor">
                              {p.links && p.links.length > 0 ? (
                                p.links.map((link, linkIndex) => {
                                  return (
                                    <div key={`link${linkIndex}`}>
                                      <div className="Setting-card__task-step--editor">
                                        <Row className="mb-2">
                                          <Col>
                                            （業務 {pi + 1} - ステップ {linkIndex + 1}）
                                            <Button
                                              variant="outline-danger"
                                              onClick={() => {
                                                update(
                                                  {
                                                    ...data,
                                                    flows: flows.map((f, fi) => {
                                                      if (fi !== flowIndex) return f;
                                                      return {
                                                        ...f,
                                                        tasks: f.tasks.map((_p, _pi) => {
                                                          if (_pi !== pi) return { ..._p };
                                                          return {
                                                            ..._p,
                                                            links: _p.links?.filter((_, li) => li !== linkIndex) ?? [],
                                                          };
                                                        }),
                                                      };
                                                    }),
                                                  },
                                                  true
                                                );
                                              }}
                                              className="mx-1 --font-s"
                                            >
                                              削除
                                            </Button>
                                          </Col>
                                        </Row>
                                        <Row className="mb-1">
                                          <Col md={3} className="--font-s">
                                            表記
                                          </Col>
                                          <Col md={9}>
                                            <Form.Control
                                              type="text"
                                              value={link.label}
                                              data-invalid={link.label === ""}
                                              onChange={(e) => {
                                                update({
                                                  ...data,
                                                  flows: flows.map((f, fi) => {
                                                    if (fi !== flowIndex) return { ...f };
                                                    return {
                                                      ...f,
                                                      tasks: f.tasks.map((_p, _pi) => {
                                                        if (_pi !== pi) return { ..._p };
                                                        return {
                                                          ..._p,
                                                          links:
                                                            p.links?.map((_l, _li) => {
                                                              if (_li !== linkIndex) return { ..._l };
                                                              return {
                                                                ..._l,
                                                                label: e.target.value,
                                                              };
                                                            }) ?? [],
                                                        };
                                                      }),
                                                    };
                                                  }),
                                                });
                                              }}
                                            ></Form.Control>
                                          </Col>
                                        </Row>
                                        <Row className="mb-1">
                                          <Col md={3} className="--font-s">
                                            リンク先
                                          </Col>
                                          <Col md={9}>
                                            <Form.Control
                                              type="text"
                                              value={link.to ?? ""}
                                              onChange={(e) => {
                                                update({
                                                  ...data,
                                                  flows: flows.map((f, fi) => {
                                                    if (fi !== flowIndex) return { ...f };
                                                    return {
                                                      ...f,
                                                      tasks: f.tasks.map((_p, _pi) => {
                                                        if (_pi !== pi) return { ..._p };
                                                        return {
                                                          ..._p,
                                                          links:
                                                            p.links?.map((_l, _li) => {
                                                              if (_li !== linkIndex) return { ..._l };
                                                              return {
                                                                ..._l,
                                                                to: e.target.value,
                                                              };
                                                            }) ?? [],
                                                        };
                                                      }),
                                                    };
                                                  }),
                                                });
                                              }}
                                            ></Form.Control>
                                            <Form.Check
                                              type="checkbox"
                                              label={"外部リンクとして開く"}
                                              key={`isExternal_${pi}_${linkIndex}`}
                                              id={`isExternal_${pi}_${linkIndex}`}
                                              checked={link.isExternalLink}
                                              value={`${link.isExternalLink}`}
                                              className="--font-s"
                                              onChange={() => {
                                                update({
                                                  ...data,
                                                  flows: flows.map((f, fi) => {
                                                    if (fi !== flowIndex) return { ...f };
                                                    return {
                                                      ...f,
                                                      tasks: f.tasks.map((_p, _pi) => {
                                                        if (_pi !== pi) return { ..._p };
                                                        return {
                                                          ..._p,
                                                          links:
                                                            p.links?.map((_l, _li) => {
                                                              if (_li !== linkIndex) return { ..._l };
                                                              return {
                                                                ..._l,
                                                                isExternalLink: !_l.isExternalLink,
                                                              };
                                                            }) ?? [],
                                                        };
                                                      }),
                                                    };
                                                  }),
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={3} className="--font-s">
                                            備考
                                          </Col>
                                          <Col md={9}>
                                            <Form.Control
                                              type="text"
                                              value={link.description ?? ""}
                                              onChange={(e) => {
                                                update({
                                                  ...data,
                                                  flows: flows.map((f, fi) => {
                                                    if (fi !== flowIndex) return { ...f };
                                                    return {
                                                      ...f,
                                                      tasks: f.tasks.map((_p, _pi) => {
                                                        if (_pi !== pi) return { ..._p };
                                                        return {
                                                          ..._p,
                                                          links:
                                                            p.links?.map((_l, _li) => {
                                                              if (_li !== linkIndex) return { ..._l };
                                                              return {
                                                                ..._l,
                                                                description: e.target.value,
                                                              };
                                                            }) ?? [],
                                                        };
                                                      }),
                                                    };
                                                  }),
                                                });
                                              }}
                                            ></Form.Control>
                                          </Col>
                                        </Row>
                                      </div>
                                      <Row className="mt-2 mb-4">
                                        <Col>
                                          <Button
                                            onClick={() => {
                                              update({
                                                ...data,
                                                flows: flows.map((f, fi) => {
                                                  if (fi !== flowIndex) return { ...f };
                                                  return {
                                                    ...f,
                                                    tasks: f.tasks.map((_p, _pi) => {
                                                      if (_pi !== pi) return { ..._p };
                                                      let _links = [...(p.links ?? [])];
                                                      _links.splice(linkIndex + 1, 0, {
                                                        label: "",
                                                        description: "",
                                                        to: "",
                                                        isExternalLink: false,
                                                      });
                                                      return {
                                                        ..._p,
                                                        links: _links,
                                                      };
                                                    }),
                                                  };
                                                }),
                                              });
                                            }}
                                            variant="outline-primary"
                                          >
                                            ステップを挿入
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })
                              ) : (
                                <Button
                                  className="mt-4"
                                  onClick={() => {
                                    update({
                                      ...data,
                                      flows: flows.map((f, fi) => {
                                        if (fi !== flowIndex) return { ...f };
                                        return {
                                          ...f,
                                          tasks: f.tasks.map((_p, _pi) => {
                                            if (_pi !== pi) return { ..._p };
                                            return {
                                              ..._p,
                                              links: [
                                                {
                                                  label: "",
                                                  description: "",
                                                  to: "",
                                                  isExternalLink: false,
                                                },
                                              ],
                                            };
                                          }),
                                        };
                                      }),
                                    });
                                  }}
                                  variant="outline-primary"
                                >
                                  ステップを追加
                                </Button>
                              )}
                            </div>
                          </div>
                          <div className="mt-4 px-2 pb-2">
                            <Button
                              onClick={() => {
                                update({
                                  ...data,
                                  flows: flows.map((f, fi) => {
                                    if (fi !== flowIndex) return { ...f };
                                    let next = [...f.tasks];
                                    next.splice(pi + 1, 0, {
                                      title: "",
                                      links: [],
                                    });
                                    return {
                                      ...f,
                                      tasks: next,
                                    };
                                  }),
                                });
                              }}
                              variant="outline-primary"
                            >
                              業務を挿入
                            </Button>
                          </div>
                        </section>
                      );
                    })
                  ) : (
                    <Button
                      onClick={() => {
                        update({
                          ...data,
                          flows: flows.map((f, fi) => {
                            if (fi !== flowIndex) return { ...f };
                            return {
                              ...f,
                              tasks: [
                                {
                                  title: "",
                                  links: [],
                                },
                              ],
                            };
                          }),
                        });
                      }}
                      variant="outline-primary"
                    >
                      業務を追加
                    </Button>
                  )}
                </div>
              );
            })}
          </section>
        )}
      </div>
    </article>
  );
}

export default App;
