import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const SLICE_NAME = "layout";
type sidebarStatus = "open" | "folded";
interface LayoutState {
  sidebarStatus: sidebarStatus;
  isTouchDevice: boolean;
}

const initialState: LayoutState = {
  sidebarStatus: window.outerWidth > 768 ? "open" : "folded",
  isTouchDevice: document.ontouchstart !== undefined,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSidebarStatus: (state, action: PayloadAction<sidebarStatus>) => {
      state.sidebarStatus = action.payload;
    },
  },
});

export const { setSidebarStatus } = slice.actions;

export const selectLayoutState = (state: RootState) => {
  return state.layout as LayoutState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
