import { useEffect, useState, useMemo } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
  id: string;
  value: string;
  name: string;
  status: string;
  status_reason?: string;
  handler?: (options: { id: string; name: string; action: "register" | "review" | "browse" | "list_logs" }) => any;
  className?: string;
  canReview?: boolean;
  isAdmin?: boolean;
};
function MyNumberRow({
  id,
  value,
  name,
  status,
  status_reason,
  handler,
  canReview,
  className,
  isAdmin = false,
}: Props) {
  const [current, $current] = useState("");
  const substitute = useMemo(() => {
    if (status === "unregistered") return "未登録";
    else if (status === "todo") return "登録中";
    else if (status === "reviewing") return "************（承認待ち）";
    else if (status === "deletable") return "削除予定のため閲覧不可";
    else return "************";
  }, [status]);

  useEffect(() => {
    if (value && value !== current) {
      $current(value);
      setTimeout(() => {
        $current("");
      }, 3000);
    }
  }, [value]);
  return (
    <Row className={`${className} --align-items-center`}>
      <Col md="3">
        <div>{name}</div>
      </Col>
      <Col md="4">
        <Form.Control type="text" disabled={true} isInvalid={status === "rejected"} value={current || substitute} />
        {status_reason && <Form.Control.Feedback type="invalid">{status_reason}</Form.Control.Feedback>}
      </Col>
      <Col md="5" className="--flex mt-2 mt-md-0">
        {status === "done" && handler && (
          <Button
            onClick={() => {
              handler({ id, action: "browse", name });
            }}
            variant="outline-primary"
            disabled={!!current}
            className="mx-1"
          >
            表示
          </Button>
        )}
        {(status === "unregistered" || status === "todo") && handler && (
          <Button onClick={() => handler({ id, action: "register", name })} variant="outline-primary" className="mx-1">
            登録
          </Button>
        )}
        {status === "reviewing" &&
          handler &&
          (canReview ? (
            <Button onClick={() => handler({ id, action: "review", name })} variant="outline-primary" className="mx-1">
              確認
            </Button>
          ) : (
            <Button onClick={() => handler({ id, action: "browse", name })} variant="outline-primary" className="mx-1">
              表示
            </Button>
          ))}
        {status === "rejected" && !isAdmin && handler && (
          <Button onClick={() => handler({ id, action: "register", name })} variant="outline-primary" className="mx-1">
            再登録
          </Button>
        )}
        {status === "deletable" && (
          <Button variant="outline-primary" disabled className="mx-1">
            表示
          </Button>
        )}
        {handler && !["unregistered", "todo"].includes(status) && (
          <Button
            onClick={() => {
              handler && handler({ id, action: "list_logs", name });
            }}
            variant="outline-secondary"
            className="mx-1"
          >
            操作ログ
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default MyNumberRow;
