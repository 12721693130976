import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { call } from "../../app/api";
import { Operation } from "./operationValues";

const SLICE_NAME = "operation";

interface OperationState {
  operations: Operation[];
}

const initialState: OperationState = {
  operations: [],
};

/*
  @getOperations
*/
export const getOperations = createAsyncThunk(SLICE_NAME + "/getOperations", async () => {
  const res = await call("get", `template_manager/operation`)();
  return res.data.result as Operation[];
});

export const postOperation = createAsyncThunk(
  SLICE_NAME + "/postOperation",
  async ({
    key,
    description,
    title,
    flows,
  }: {
    key: string;
    description: string;
    title: string;
    flows: { [key: string]: any };
  }) => {
    const res = await call("post", "template_manager/operation")({ key, description, title, flows });
    return res.data.result[0] as Operation;
  }
);

export const putOperation = createAsyncThunk(
  SLICE_NAME + "/putOperation",
  async ({
    id,
    key,
    description,
    title,
    flows,
  }: {
    id: string;
    key: string;
    description: string;
    title: string;
    flows: { [key: string]: any };
  }) => {
    const res = await call("put", "template_manager/operation")({ id, key, description, title, flows });
    return res.data.result[0] as Operation;
  }
);

export const deleteOperation = createAsyncThunk(SLICE_NAME + "/deleteOperation", async ({ id }: { id: string }) => {
  const res = await call("delete", "template_manager/operation")({ id });
  return res.data.result[0] as Operation;
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOperations.fulfilled, (state, action) => {
      state.operations = action.payload;
    });
    builder.addCase(postOperation.fulfilled, (state, action) => {
      const next = state.operations.map((operation) => {
        if (operation.key === action.payload.key) {
          operation = action.payload;
        }
        return operation;
      }) as Operation[];
      state.operations = next;
    });
    builder.addCase(putOperation.fulfilled, (state, action) => {
      const next = state.operations.map((operation) => {
        if (operation.key === action.payload.key) {
          operation = action.payload;
        }
        return operation;
      }) as Operation[];
      state.operations = next;
    });
    builder.addCase(deleteOperation.fulfilled, (state, action) => {
      const next = state.operations.map((operation) => {
        if (operation.key === action.payload.key) {
          operation = action.payload;
        }
        return operation;
      }) as Operation[];
      state.operations = next;
    });
  },
});

// export const {  } = slice.actions;

export const selectOperationState = (state: RootState) => {
  return state.operation as OperationState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
