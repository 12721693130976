const data = {
  category: "mid_career",
  table: "mid_career",
  subFields: {
    mid_career_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    mid_career_income_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    mid_career_social_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    mid_career_income_tax_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joining_resident_taxes_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      mid_career_history: "中途入社履歴",
    },
  },
};

export default data;
