import { ReportTemplate } from "./reportValues";

export const adminReportTemplates = [
  {
    id: "salary_slip_bulk_download",
    title: "給与明細",
  },
  {
    id: "bonus_slip_bulk_download",
    title: "賞与明細",
  },
  {
    id: "withholding_tax_certificate_bulk_download",
    title: "源泉徴収票",
  },
  {
    id: "roster_of_workers_bulk_download",
    title: "労働者名簿",
  },
] as ReportTemplate[];

export const userReportTemplates = [
  {
    id: "salary_slip_single_download",
    title: "給与・賞与明細",
  },
  {
    id: "withholding_tax_single_download",
    title: "源泉徴収票",
  },
  {
    id: "roster_of_workers_single_download",
    title: "労働者名簿",
  },
] as ReportTemplate[];
