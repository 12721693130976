const data = {
  category: "education",
  table: "education",
  subFields: {
    entry_on: {},
    graduation_on: {},
    dropout_on: {},
    education_type: {},
    school_name: {},
    faculty_name: {},
    department_name: {},
    major_name: {},
  },
};
export default data;
