const data = {
  category: "employment_insurance",
  table: "employment_insurance",
  subFields: {
    employment_insurance_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employment_insurance_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employment_insurance_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employment_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_employment_insurance_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      employment_insurance_history: "雇用保険履歴",
    },
  },
};

export default data;
