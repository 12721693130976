import "../css/style.scss";
import classNames from "classnames";

type Step = {
  label: string;
};

type Props = {
  steps: Step[];
  activeIndex?: number;
};

function App({ steps, activeIndex = 0 }: Props) {
  return (
    <div className="Stepper">
      {steps.map(({ label }, i) => (
        <div
          className={classNames({
            Stepper__item: true,
            "Stepper__item--done": i <= activeIndex,
            "Stepper__item--active": i === activeIndex,
          })}
          key={`step${i}`}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

export default App;
