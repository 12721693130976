import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getClients, selectClientState } from "../client/clientSlice";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import { Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const companyState = useAppSelector(selectClientState);
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">顧客一覧</h1>
        <div className="List mt-3">
          <div className="List__item">
            <Link to={"/_/client/create/"}>
              <Button variant="primary">顧客を作成する</Button>
            </Link>
          </div>
          <div className="List__item">
            <Button variant="outline-primary">CSV出力</Button>
          </div>
        </div>
        <section className="mt-3">
          <Table
            col={[{ name: "企業名", className: "--bold" }, { name: "プラン" }, { name: "ステータス" }]}
            row={companyState.clients.map((c) => {
              return {
                data: [c.name, c.plan, c.settingStatus],
                link: `/_/client/${c.id}/import/`,
              };
            })}
          />
        </section>
      </div>
    </div>
  );
}

export default App;
