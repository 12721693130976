import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { dummyData } from "./statisticsDummyValues";

const SLICE_NAME = "statistics";

interface StatisticsState {
  initialized: boolean;
  time: {
    over: string;
    absent: string;
    late: string;
  };
}

export const getStatistics = createAsyncThunk(
  SLICE_NAME + "/getStatistics",
  async ({ companyId }: { companyId: string }) => {
    await new Promise<void>((resolve, reject) =>
      setTimeout(() => {
        resolve();
      }, 2000)
    );
    return dummyData;
  }
);

/*
  state の初期状態
*/
const initialState: StatisticsState = {
  initialized: false,
  time: { over: "", absent: "", late: "" },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.time = { ...action.payload.time };
      state.initialized = true;
    });
  },
});

// export const {  } = slice.actions;

export const selectStatisticsState = (state: RootState) => {
  return state.statistics as StatisticsState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
