import { useEffect, useMemo } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getMembers, selectProfileState } from "../../features/profile/profileSlice";
import { getSections, selectSectionState } from "../../features/section/sectionSlice";
import { Member } from "../../features/profile/profileValues";
import dayjs from "dayjs";

function MemberListSelector({
  checkedsectionCodes,
  onSelectedMemberChange,
}: {
  checkedsectionCodes: string[];
  onSelectedMemberChange: (checkedMemberIds: number[], checkedsectionCodes: string[]) => any;
}) {
  const dispatch = useAppDispatch();

  const { members } = useAppSelector(selectProfileState);
  const { sections } = useAppSelector(selectSectionState);

  const listedMembers = useMemo(() => {
    return members.reduce((prev, current) => {
      if (checkedsectionCodes.includes(current.sectionCode.toString()) && !prev.some((_) => _.id === current.id))
        return [...prev, current];
      else return prev;
    }, [] as Member[]);
  }, [members, checkedsectionCodes]);

  const checkedMembers = useMemo(() => {
    if (checkedsectionCodes.length < 1) return [] as Member[];
    return listedMembers;
  }, [listedMembers, checkedsectionCodes]);

  const selectSection = (code: string) => {
    let nextCheckedsectionCodes = [...checkedsectionCodes];
    if (nextCheckedsectionCodes.includes(code)) {
      nextCheckedsectionCodes = nextCheckedsectionCodes.filter((_) => _ !== code);
    } else {
      nextCheckedsectionCodes = [...nextCheckedsectionCodes, code];
    }
    const nextCheckedMemberIds = members
      .reduce((prev, current) => {
        if (nextCheckedsectionCodes.includes(current.sectionCode.toString()) && !prev.some((_) => _.id === current.id))
          return [...prev, current];
        else return prev;
      }, [] as Member[])
      .map((member) => member.id);
    onSelectedMemberChange(nextCheckedMemberIds, nextCheckedsectionCodes);
  };

  useEffect(() => {
    dispatch(getMembers());
    dispatch(getSections({ baseDate: dayjs() }));
  }, []);

  return (
    <Row className="mb-2">
      <Col md="4">
        <div className="--bold mb-2">対象者</div>
      </Col>
      <Col md="12">
        <div className="mb-2">現在の部署</div>
        {sections.map((s) => (
          <Form.Check
            key={s.id}
            checked={checkedsectionCodes.includes(s.sectionCode)}
            id={`${s.id}`}
            type="checkbox"
            label={`${s.name} (${members.filter((_) => _.sectionCode.toString() === s.sectionCode).length})`}
            onChange={() => selectSection(s.sectionCode)}
          />
        ))}
      </Col>
    </Row>
  );
}

export default MemberListSelector;
