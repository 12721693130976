const data = {
  category: "address",
  table: "address",
  subFields: {
    moved_on: {},
    is_living_abroad: {},
    postcode: {},
    address: {},
    prefecture: {},
    city_town_village: {},
    street_address: {},
    apartment_building_etc: {},
    address_kana: {},
    phone_number: {},
    certificate_postcode: {},
    certificate_address: {},
    certificate_prefecture: {},
    certificate_city_town_village: {},
    certificate_street_address: {},
    certificate_apartment_building_etc: {},
    certificate_address_kana: {},
    personal_mobile_number: {},
    personal_email: {},
    household_head_name: {},
    household_head_relationship_type: {},
  },
};

export default data;
