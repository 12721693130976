import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import { selectMember, unselectMember, selectProfileState } from "../profile/profileSlice";
import { getRoles, selectPermissionState } from "../../features/permission/permissionSlice";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { memberId } = useParams();
  const { roles, selectedRoleId } = useAppSelector(selectPermissionState);
  const { selectedMember } = useAppSelector(selectProfileState);

  const [state, $state] = useState({
    roleId: "",
    nextOwner: "",
    nextResource: "",
    nextOperation: "",
  });
  const currentRole = useMemo(() => {
    return roles.find((r) => r.id === state.roleId);
  }, [state.roleId, roles]);
  useEffect(() => {
    $state({ ...state, roleId: selectedRoleId });
  }, [selectedRoleId]);
  useEffect(() => {
    dispatch(getRoles());
    dispatch(selectMember({ id: Number(memberId) }));
    return () => {
      dispatch(unselectMember());
    };
  }, [memberId, dispatch]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"permission"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">権限管理 - {selectedMember?.name}</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row>
              <Col>
                <h2 className="Headline--section mb-2">ロール</h2>
                {/* <Form className="mb-2">
                  {roles.map((r) => {
                    return (
                      <Form.Check
                        checked={state.roleId === r.id}
                        type="radio"
                        id={r.id}
                        label={r.label}
                        key={r.id}
                        onChange={() => $state({ ...state, roleId: r.id })}
                      />
                    );
                  })}
                </Form>

                <Table
                  col={[]}
                  row={
                    currentRole?.policies.map((p) => {
                      return {
                        data: [p.owner.label, p.resource.label, p.operate.label],
                      };
                    }) ?? []
                  }
                /> */}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Button>保存</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
