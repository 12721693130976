const data = {
  category: "residence_with_working",
  table: "residence_with_working",
  subFields: {
    residence_with_working_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    nationality_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    status_of_residence_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    residence_end_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    residence_card_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    work_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    activity_permission_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relation_file_id_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      residence_with_working_history: "在留就労履歴",
    },
  },
};

export default data;
