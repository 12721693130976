import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { RemindSetting, Todo, profileTodoTypes } from "./todoValues";
import { call } from "../../app/api";

const SLICE_NAME = "todo";

interface TodoState {
  remindSettings: RemindSetting[];
  selectedRemindSetting: RemindSetting;
  todos: Todo[];
  fetchedTodoPage: number;
  hasMoreTodos: boolean;
  todoCount: number;
}

const emptyRemindSetting = {
  id: "",
  title: "",
  todo_type: "",
  period_type: "",
  period_value: 0,
  interval_type: "",
  timing: 0,
  subject_type: "",
  subject_values: [],
  due_date_base: "",
  due_date_period_value: 0,
  due_date_period_type: "",
  is_active: false,
};

const initialState: TodoState = {
  remindSettings: [],
  selectedRemindSetting: emptyRemindSetting,
  todos: [],
  fetchedTodoPage: 0,
  hasMoreTodos: false,
  todoCount: 0,
};

export const getRemindSettings = createAsyncThunk(SLICE_NAME + "/getRemindSettings", async () => {
  const res = await call("get", "template_manager/remind_setting")({ sort_by: '[{ "key": 1 }]' });
  return res.data.result;
});

export const getRemindSetting = createAsyncThunk(
  SLICE_NAME + "/getRemindSetting",
  async (params: { [key: string]: any }) => {
    const res = await call("get", "template_manager/remind_setting")(params);
    return res.data.result.length ? res.data.result[0] : emptyRemindSetting;
  }
);

export const postRemindSetting = createAsyncThunk(
  SLICE_NAME + "/postRemindSetting",
  async (remindSetting: RemindSetting) => {
    await call("post", "template_manager/remind_setting")(remindSetting);
  }
);

export const putRemindSetting = createAsyncThunk(
  SLICE_NAME + "/putRemindSetting",
  async (remindSetting: RemindSetting) => {
    await call("put", "template_manager/remind_setting")(remindSetting);
  }
);

export const deleteRemindSetting = createAsyncThunk(SLICE_NAME + "/deleteRemindSetting", async (id: string) => {
  await call("delete", "template_manager/remind_setting")({ id });
});

export const getTodos = createAsyncThunk(SLICE_NAME + "/getTodos", async (params: { [key: string]: any }) => {
  const res = await call(
    "get",
    "todo_manager/todo"
  )({
    sort_by: '[{ "due_date": 1 }]',
    type__in: Object.keys(profileTodoTypes),
    ...params,
  });
  return {
    page: params.page,
    todos: res.data.result,
    hasMore: res.data.has_more,
    count: res.data.count,
  };
});

export const putTodo = createAsyncThunk(
  SLICE_NAME + "/putTodo",
  async ({ id, done }: { id: string; done: boolean }) => {
    const res = await call("put", "todo_manager/todo")({ id, done });
    return res.data.result[0];
  }
);

export const putTodos = createAsyncThunk(
  SLICE_NAME + "/putTodos",
  async ({ id__in, done }: { id__in: string[]; done: boolean }) => {
    const res = await call("put", "todo_manager/todo")({ id__in, done });
    return {
      ids: res.data.result.map((r: any) => r.id),
      done,
    };
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectRemindSettings: (state) => {
      state.remindSettings = [];
    },
    unselectRemindSetting: (state) => {
      state.selectedRemindSetting = emptyRemindSetting;
    },
    unselectTodos: (state) => {
      state.todos = [];
      state.hasMoreTodos = false;
      state.fetchedTodoPage = 0;
      state.todoCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRemindSettings.fulfilled, (state, action) => {
      state.remindSettings = action.payload;
    });
    builder.addCase(getRemindSetting.fulfilled, (state, action) => {
      state.selectedRemindSetting = action.payload;
    });
    builder.addCase(
      getTodos.fulfilled,
      (
        state,
        action: PayloadAction<{
          todos: Todo[];
          page: number;
          hasMore: boolean;
          count: number;
        }>
      ) => {
        state.fetchedTodoPage = action.payload.page;
        state.todos = action.payload.page === 1 ? action.payload.todos : [...state.todos, ...action.payload.todos];
        state.hasMoreTodos = action.payload.hasMore;
        state.todoCount = action.payload.count;
      }
    );
    builder.addCase(putTodo.fulfilled, (state, action) => {
      state.todos = state.todos.map((t) => {
        if (action.payload.id === t.id) return { ...t, done: action.payload.done };
        return t;
      });
    });
    builder.addCase(putTodos.fulfilled, (state, action) => {
      state.todos = state.todos.map((t) => {
        if (action.payload.ids.includes(t.id)) return { ...t, done: action.payload.done };
        return t;
      });
    });
  },
});

export const { unselectRemindSettings, unselectRemindSetting, unselectTodos } = slice.actions;

export const selectTodoState = (state: RootState) => {
  return state.todo as TodoState;
};
const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
