const data = {
  category: "address",
  table: "address",
  subFields: {
    address_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    moved_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_living_abroad_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    prefecture_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    city_town_village_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    street_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    apartment_building_etc_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_prefecture_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_city_town_village_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_street_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_apartment_building_etc_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    certificate_address_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    personal_mobile_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    personal_email_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    household_head_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    household_head_relationship_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      address_history: "住所履歴",
    },
  },
};

export default data;
