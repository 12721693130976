import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getRoleBehaviors,
  getApiSummaries,
  commitRoleBehaviors,
  deleteRoleBehaviors,
  selectPermissionState,
  stageRoleApiBehaviors,
  unselectRoleApiBehaviors,
} from "./permissionSlice";
import EditPolicyComponent from "./EditPolicyComponent";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { roleName } = useParams();
  const { policyDocId, roleBehaviors, apiSummaries } = useAppSelector(selectPermissionState);
  const [state, $state] = useState({
    roleDocId: "",
    roleName: "",
    roleLabel: {
      value: "",
      validated: true,
      message: "",
    },
    description: {
      value: "",
      validated: true,
      message: "",
    },
    initRoleName: "",
    initDescription: "",
  });
  const [activeModal, $activeModal] = useState("");

  useEffect(() => {
    dispatch(getApiSummaries());
  }, []);
  useEffect(() => {
    if (apiSummaries.length > 0) dispatch(getRoleBehaviors({ apiSummaries }));
    return () => {
      dispatch(unselectRoleApiBehaviors());
    };
  }, [apiSummaries.length]);

  const currentRole = useMemo(() => {
    const currentRole = roleBehaviors.find((r) => r.roleName === roleName);
    if (currentRole) {
      const initRoleName = currentRole.label;
      const initDescription = currentRole.description;
      $state({
        ...state,
        roleDocId: currentRole.roleDocId,
        roleName: currentRole.roleName,
        roleLabel: {
          ...state.roleLabel,
          value: initRoleName,
        },
        initRoleName,
        description: {
          ...state.description,
          value: initDescription,
        },
        initDescription,
      });
    }
    return currentRole;
  }, [roleName, roleBehaviors]);

  useEffect(() => {
    const next = { ...state };
    next.roleLabel.validated = !!next.roleLabel.value;
    next.roleLabel.message = next.roleLabel.validated ? "" : "入力してください";
    next.description.validated = !!next.description.value;
    next.description.message = next.description.validated ? "" : "入力してください";
    $state(next);
  }, [state.roleLabel.value, state.description.value]);

  const validated = useMemo(() => {
    return state.roleLabel.validated && state.description.validated;
  }, [state.roleLabel.validated, state.description.validated]);
  const saveRole = async () => {
    if (!validated) return;
    if (currentRole?.canEdit) {
      await dispatch(
        commitRoleBehaviors({
          policyDocId,
          roleDocId: state.roleDocId,
          roleBehaviors: roleBehaviors.map((roleBehavior) => {
            if (roleBehavior.roleDocId !== state.roleDocId) return roleBehavior;
            return {
              ...roleBehavior,
              label: state.roleLabel.value,
              description: state.description.value,
            };
          }),
        })
      );
    }
    navigate("/_/masters/role");
  };

  const deleteRole = async () => {
    await dispatch(
      deleteRoleBehaviors({
        roleDocId: state.roleDocId,
      })
    );
    navigate("/_/masters/role");
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          {state.initRoleName && <h2 className="Headline--section mb-2">{state.initRoleName}</h2>}
          {!currentRole?.canEdit && <Alert variant="warning">システム既定のロールのため編集できません。</Alert>}
          <div className="mb-3">
            <Form.Label htmlFor="clientName" className="--required-label">
              ロール ID
            </Form.Label>
            <Form.Control type="text" placeholder="" value={state.roleName} disabled />
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="clientName" className="--required-label">
              ロール名
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={state.roleLabel.value}
              onChange={(e) => $state({ ...state, roleLabel: { ...state.roleLabel, value: e.target.value } })}
              disabled={!state.roleDocId || currentRole?.isReadymadeRole}
            />
            {state.roleLabel.message && (
              <div className="--font-s --text-annotation mt-1">{state.roleLabel.message}</div>
            )}
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="clientName" className="--required-label">
              説明
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={state.description.value}
              onChange={(e) => $state({ ...state, description: { ...state.description, value: e.target.value } })}
              disabled={!state.roleDocId || currentRole?.isReadymadeRole}
            />
            {state.description.message && (
              <div className="--font-s --text-annotation mt-1">{state.description.message}</div>
            )}
          </div>
          <div className="mb-3">
            <div className="mb-2">設定</div>
            <Alert variant="info">
              すべて・・・・・・・・登録されているメンバー全員の情報にアクセス可能。
              <br />
              アクセス可能な部署・・アクセス可能な部署テーブルの情報を元に、許可された部署に所属するメンバーの情報にアクセス可能。
              <br />
              　　　　　　　　　　　役職の指定がある場合は、役職も考慮されます。
              <br />
              部下・・・・・・・・・所属部署・役職テーブルの情報を元に、同一部署（主務のみ）で役職のランクが下位のメンバーの情報にアクセス可能。
              <br />
              　　　　　　　　　　　なお、部下自身も主務の場合に限られます。
              <br />
              自分自身・・・・・・・自分の情報のみにアクセス可能。
            </Alert>
            <EditPolicyComponent
              roleApiBehaviors={currentRole?.content ?? []}
              onChange={(content) => {
                const next = roleBehaviors.map((role) => {
                  if (role.roleName !== roleName) return role;
                  return {
                    ...role,
                    content,
                  };
                });
                dispatch(stageRoleApiBehaviors(next));
              }}
              editable={currentRole?.canEdit}
            />
          </div>
        </Col>
      </Row>
      <Button
        variant="primary"
        disabled={!validated || !currentRole?.canEdit}
        onClick={() => {
          if (!validated || !currentRole?.canEdit) return;
          $activeModal("before_save");
        }}
      >
        保存
      </Button>

      <Button
        variant="danger"
        className="float-end"
        disabled={!state.roleDocId || !currentRole?.canDelete}
        onClick={() => {
          $activeModal("before_delete");
        }}
      >
        削除
      </Button>
      <ModalDialog
        show={activeModal === "before_save"}
        onConfirm={() => {
          $activeModal("");
          saveRole();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="保存します。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_delete"}
        onConfirm={() => {
          $activeModal("");
          deleteRole();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        type="destructiveConfirm"
        confirmButtonName="削除"
        message={`ユーザに${state.initRoleName}の権限が設定されている場合、あわせて削除されます。${state.initRoleName} を削除してもよろしいですか？`}
      />
    </Container>
  );
}

export default App;
