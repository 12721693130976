const data = {
  category: "emergency_contact",
  table: "emergency_contact",
  subFields: {
    emergency_contact_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    emergency_contact_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    emergency_contact_relationship_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    emergency_contact_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    emergency_contact_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      emergency_contact_history: "緊急連絡先履歴",
    },
  },
};

export default data;
