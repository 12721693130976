import { useState, useEffect, useMemo } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getMembers, selectProfileState } from "../../features/profile/profileSlice";
import { getSections, selectSectionState } from "../../features/section/sectionSlice";
import { Member } from "../../features/profile/profileValues";
import dayjs from "dayjs";

function MemberListSelector({
  excludedMemberKeyword,
  excludedMemberIds,
  checkedsectionCodes,
  onExcludedMemberKeywordChange,
  onSelectedMemberChange,
}: {
  excludedMemberKeyword: string;
  excludedMemberIds: number[];
  checkedsectionCodes: string[];
  onExcludedMemberKeywordChange: (next: string) => any;
  onSelectedMemberChange: (
    checkedMemberIds: number[],
    excludedMemberIds: number[],
    checkedsectionCodes: string[]
  ) => any;
}) {
  const dispatch = useAppDispatch();

  const { members } = useAppSelector(selectProfileState);
  const { sections } = useAppSelector(selectSectionState);

  const listedMembers = useMemo(() => {
    return members.reduce((prev, current) => {
      if (checkedsectionCodes.includes(current.sectionCode.toString()) && !prev.some((_) => _.id === current.id))
        return [...prev, current];
      else return prev;
    }, [] as Member[]);
  }, [members, checkedsectionCodes]);

  const checkedMembers = useMemo(() => {
    if (checkedsectionCodes.length < 1) return [] as Member[];
    return listedMembers.filter((member) => !excludedMemberIds.includes(member.id));
  }, [listedMembers, checkedsectionCodes, excludedMemberIds]);

  const selectTarget = async (id: number) => {
    let next = [...excludedMemberIds];
    if (excludedMemberIds.includes(id)) {
      next = excludedMemberIds.filter((_) => _ !== id);
    } else {
      next = [...excludedMemberIds, id];
    }

    const nextCheckedMemberIds = members
      .filter((member) => {
        return !next.includes(member.id) && checkedsectionCodes.includes(member.sectionCode.toString());
      })
      .map((member) => member.id);
    onSelectedMemberChange(nextCheckedMemberIds, next, checkedsectionCodes);
  };

  const selectSection = (code: string) => {
    let nextCheckedsectionCodes = [...checkedsectionCodes];
    if (nextCheckedsectionCodes.includes(code)) {
      nextCheckedsectionCodes = nextCheckedsectionCodes.filter((_) => _ !== code);
    } else {
      nextCheckedsectionCodes = [...nextCheckedsectionCodes, code];
    }
    const nextCheckedMemberIds = members
      .filter((member) => {
        return (
          !excludedMemberIds.includes(member.id) && nextCheckedsectionCodes.includes(member.sectionCode.toString())
        );
      })
      .map((member) => member.id)
      .filter((_, i, self) => self.indexOf(_) === i);
    const nextExcludedMemberIds = members
      .filter((member) => {
        return excludedMemberIds.includes(member.id) && nextCheckedsectionCodes.includes(member.sectionCode.toString());
      })
      .map((member) => member.id)
      .filter((_, i, self) => self.indexOf(_) !== i);
    onSelectedMemberChange(nextCheckedMemberIds, nextExcludedMemberIds, nextCheckedsectionCodes);
  };

  useEffect(() => {
    dispatch(getMembers());
    dispatch(getSections({ baseDate: dayjs() }));
  }, []);

  return (
    <>
      <Row className="mb-2">
        <Col md="4">
          <div className="--bold mb-2">対象者</div>
        </Col>
        <Col md="12">
          <div className="mb-2">現在の部署</div>
          {sections.map((s) => (
            <Form.Check
              key={s.id}
              checked={checkedsectionCodes.includes(s.sectionCode)}
              id={`${s.id}`}
              type="checkbox"
              label={`${s.name} (${members.filter((_) => _.sectionCode.toString() === s.sectionCode).length})`}
              onChange={() => selectSection(s.sectionCode)}
            />
          ))}
        </Col>
      </Row>
      {checkedsectionCodes.length > 0 && (
        <Row className="mt-3">
          <Col md="12">
            <div className="mb-2">除外する対象者</div>
          </Col>
          <Col md="12">
            <Form.Control
              type="text"
              id="search"
              value={excludedMemberKeyword}
              className="mb-2"
              placeholder="名前で絞り込む"
              onChange={(e) => {
                const next = e.target.value;
                onExcludedMemberKeywordChange(next);
              }}
            />
            {listedMembers.length < 10 || excludedMemberKeyword ? (
              <Row>
                {listedMembers
                  .filter((_) => !excludedMemberKeyword || _.name.indexOf(excludedMemberKeyword) !== -1)
                  .map((t) => (
                    <Col md="6" key={`${t.id}_${t.positionCode}_${t.sectionCode}`}>
                      <Form.Check
                        checked={!checkedMembers.map((_) => _.id).includes(t.id)}
                        id={`${t.id}_${t.positionCode}_${t.sectionCode}`}
                        type="checkbox"
                        label={t.name}
                        onChange={() => selectTarget(t.id)}
                      />
                    </Col>
                  ))}
              </Row>
            ) : (
              <div className="--text-annotation">対象者が多いため、名前で絞り込んでください</div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

export default MemberListSelector;
