import { store, logout, useAppDispatch, useAppSelector } from "../app/store";
import { testResponse } from "../app/util";
import { handleFatalError } from "../features/error/fatalErrorSlice";
import { getParentThreadComments, selectComments } from "../features/client/threadSlice";
import { switchCompany } from "../features/login/idTokenSlice";
import { selectUserState, selectHasDataLoaderPermissions, getUserByIdToken } from "../features/login/userSlice";
import { getRoles, selectPermissionState } from "../features/permission/permissionSlice";
import {
  selectUnreadNotifications,
  selectNotificationState,
  putNotificationsRead,
  getNotifications,
  getNoCheckNotifications,
} from "../features/notification/notificationSlice";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../component/Icon";
import classNames from "classnames";
import { useEffect, useState, useMemo } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { selectProfileState } from "../features/profile/profileSlice";
import { selectLayoutState, setSidebarStatus } from "../features/layout/layoutSlice";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, policies } = useAppSelector(selectUserState);
  // 企業に登録されているロール一覧
  const { roles } = useAppSelector(selectPermissionState);
  const { selfAccount } = useAppSelector(selectProfileState);
  const comments = useAppSelector(selectComments);
  const hasHasDataLoaderPermissions = useAppSelector(selectHasDataLoaderPermissions);
  const { notifications, fetchedNotificationsPage, hasMore } = useAppSelector(selectNotificationState);
  const [isNotificationsVisible, $isNotificationsVisible] = useState(false);
  const [isMyAccountVisible, $isMyAccountVisible] = useState(false);
  const [currentRole, $currentRole] = useState("");
  const unreadNotifications = useAppSelector(selectUnreadNotifications);
  const { sidebarStatus } = useAppSelector(selectLayoutState);
  const putAllRead = (ids: string[]) => {
    dispatch(putNotificationsRead({ ids })).then(() => {
      dispatch(getNoCheckNotifications());
    });
  };
  const _logout = () => {
    dispatch(logout());
  };
  const switchRoles = async (
    code: string,
    role: string,
    isProfile: boolean,
    isServiceSwich: boolean,
    nextPath: string
  ) => {
    const reject = () =>
      store.dispatch(
        handleFatalError({ type: "error", message: "ロールの切り替えでエラーが発生しました。再ログインしてください。" })
      );
    const result = testResponse(
      await dispatch(
        switchCompany({
          companyCode: code,
          role: role,
          isProfile: isProfile,
          isServiceSwitch: isServiceSwich,
        })
      )
    );
    if (!result) return reject();
    window.location.href = nextPath;
    const user = testResponse(await dispatch(getUserByIdToken(result.payload.idToken)));
    if (!user) return reject();
    if (user.payload.user.profile_roles.length === 0) return reject();
    $isMyAccountVisible(false);
    $isNotificationsVisible(false);
  };
  // 通知バルーン表示
  const toggleNotification = () => {
    $isNotificationsVisible(!isNotificationsVisible);
  };
  const selfImage = useMemo(() => {
    return selfAccount?.image;
  }, [selfAccount]);
  useEffect(() => {
    const onClick = (e: any) => {
      if (e.path?.every((p: any) => p.id !== "Header") && isNotificationsVisible) {
        $isNotificationsVisible(false);
      }
      return false;
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isNotificationsVisible]);
  useEffect(() => {
    if (comments.length < 1) dispatch(getParentThreadComments());
    dispatch(getRoles());
  }, []);

  // アカウントバルーン
  const toggleMyAccount = () => {
    $isMyAccountVisible(!isMyAccountVisible);
  };
  useEffect(() => {
    const onClick = (e: any) => {
      if (e.path?.every((p: any) => p.id !== "Header") && isMyAccountVisible) {
        $isMyAccountVisible(false);
      }
      return false;
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isMyAccountVisible]);
  useEffect(() => {
    const role = roles.find((r) => r.id === user.role);
    if (role) {
      $currentRole(role.label);
    }
  }, [roles, user]);
  useEffect(() => {
    dispatch(getNoCheckNotifications());
  }, [notifications]);
  const hasFileOutputPermissions = useMemo(() => {
    // ファイル管理かレポート管理のGET権限がある場合、ファイルアイコンを表示
    return policies.file_project_manager?.some((_) => _ === "GET") || policies.report_manager?.some((_) => _ === "GET");
  }, [policies]);

  const [latestCommentTime, isRecentComment, path] = useMemo(() => {
    if (!user.id || comments.length === 0) return ["", false, ""];
    const pathArray = comments[0].webScreen.split("/");
    return [comments[0].time, comments[0].isRecentComment, pathArray[1]];
  }, [comments, user]);
  const toggle = () => dispatch(setSidebarStatus(sidebarStatus === "open" ? "folded" : "open"));
  return (
    <header className="Header" id="Header">
      <div className="Header__nav-content">
        <div onClick={toggle} className="Header__side-bar-toggler">
          <div className="Header__side-bar-toggler-icon">
            <Icon width={30} height={30} type={sidebarStatus === "open" ? "x" : "list"} />
          </div>
        </div>
        <div
          className="Header__logo-frame"
          onClick={() => {
            navigate("/_/dashboard/");
          }}
        >
          <div className="Header__logo">SHRPA</div>
        </div>
        <nav className="Header__nav-links">
          <div className="Header__nav-link-item --current">人事労務</div>
          {(user.current_company.services.admin?.some((_) => _ !== 9) ||
            user.current_company.services.user?.some((_) => _ !== 9)) && (
            <div
              className="Header__nav-link-item"
              onClick={() => {
                switchRoles(user.current_company.code, user.role, true, true, "/assess/dashboard/");
              }}
            >
              人材開発
            </div>
          )}
        </nav>
      </div>

      <div className="Header__col">
        {/* コメント通知 */}
        {hasHasDataLoaderPermissions && (
          <Link to={`/_/import/?sector=${path}`} className="Header__threads">
            <div className="Header__threads-icon">
              <Icon width={30} height={30} type="chat-left-text-fill" />
            </div>
            <div
              className={classNames({
                "Header__threads-text--active": isRecentComment,
                "Header__threads-text": !isRecentComment,
              })}
            >
              {latestCommentTime ? (
                <span>
                  最新
                  <br />
                  {latestCommentTime}
                </span>
              ) : (
                <span>コメントなし</span>
              )}
            </div>
          </Link>
        )}
        {/* ファイル */}
        {hasFileOutputPermissions && (
          <Link
            to={`/_/file`}
            className={classNames({
              "ms-1": true,
              Header__file: true,
              "Header__file--open": window.location.pathname.startsWith("/_/file"),
            })}
          >
            <div className="Header__file-icon">
              <Icon
                width={30}
                height={30}
                type={window.location.pathname.startsWith("/_/file") ? "folder2-open" : "folder2"}
              />
            </div>
          </Link>
        )}
        {/* 通知バルーン */}
        <div className="Header__notifications">
          <div onClick={toggleNotification} className="Header__notifications-toggler">
            <Icon width={30} height={30} type="bell-fill" />
            {unreadNotifications.length > 0 && (
              <span className="Header__notifications-badge">{unreadNotifications.length}</span>
            )}
          </div>
          <div
            className={classNames({
              "Header__notifications-balloon": !isNotificationsVisible,
              "Header__notifications-balloon--active": isNotificationsVisible,
            })}
          >
            <nav className="Notifications">
              <h2 className="Notifications__headline">
                通知
                <div>
                  {unreadNotifications.length > 0 && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        if (unreadNotifications.length > 0) putAllRead(unreadNotifications);
                      }}
                    >
                      全てを既読にする
                    </Button>
                  )}
                  <Button size="sm" variant="close" className="ms-2" onClick={toggleNotification}></Button>
                </div>
              </h2>
              <div className="Notifications__list">
                {notifications.map((n) => {
                  return (
                    <article
                      className={classNames({
                        Notifications__item: n.checked,
                        "Notifications__item--unopened": !n.checked,
                      })}
                      id={n.id}
                      key={n.id}
                    >
                      {n.url ? (
                        <a
                          href={n.url}
                          onClick={() => {
                            $isNotificationsVisible(false);
                          }}
                        >
                          <div className="Notifications__item-meta">{n.timeLabel}</div>
                          {n.content && <div className="Notifications__item-body --pre-wrap">{n.content}</div>}
                        </a>
                      ) : (
                        <div>
                          <div className="Notifications__item-meta">{n.timeLabel}</div>
                          {n.content && <div className="Notifications__item-body --pre-wrap">{n.content}</div>}
                        </div>
                      )}
                    </article>
                  );
                })}
                {notifications.length > 0 && (
                  <Button
                    className="mt-2"
                    variant="outline-secondary"
                    onClick={() => {
                      dispatch(
                        getNotifications({
                          page: fetchedNotificationsPage,
                        })
                      );
                    }}
                    disabled={hasMore === false}
                  >
                    過去の通知を表示する
                  </Button>
                )}
                {notifications.length === 0 && <div>通知はありません。</div>}
              </div>
            </nav>
          </div>
        </div>
        <div onClick={toggleMyAccount} className="Header__profile">
          <figure className="Header__profile-image-frame">
            <span className="Header__profile-image" data-testid="selfImage">
              {selfImage && <img src={selfImage} />}
            </span>
          </figure>
          <div className="Header__profile-text">
            <div className="Header__profile-row">{user.account_name}</div>
            <div className="Header__profile-row">{currentRole}</div>
          </div>
        </div>
      </div>
      {/*アカウントバルーン*/}
      <div
        className={classNames({
          "Header__myaccount-balloon": !isMyAccountVisible,
          "Header__myaccount-balloon--active": isMyAccountVisible,
        })}
      >
        <nav className="MyAccount">
          <Button size="sm" variant="close" className="float-end" onClick={toggleMyAccount}></Button>
          <p className="MyAccount__item-name">{user.account_name}</p>
          <p className="MyAccount__item-email">{user.mail_address}</p>
          <p className="MyAccount__item-company">{user.current_company.company_name}</p>
          <Link className="MyAccount__item-textlink" to={"/_/myaccount/"}>
            個人設定
          </Link>
        </nav>
        <nav className="MyAccount__item-role-list">
          {roles
            .filter((_) => user.profile_roles.includes(_.id))
            .map((r) => {
              return (
                <p key={r.id}>
                  {r.label === currentRole ? (
                    <span className="MyAccount__item-role-current">
                      <span>{r.label}</span>
                      <span className="ms-2 Badge--ok">現在のロール</span>
                    </span>
                  ) : (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 50, hide: 50 }}
                      overlay={(props) => (
                        <Tooltip id={`tooltip_${r.id}`} className="MyAccount__item-tooltip" {...props}>
                          {r.label} に切り替える
                        </Tooltip>
                      )}
                    >
                      <span
                        className="MyAccount__item-role-choices"
                        onClick={() => switchRoles(user.current_company.code, r.id, true, false, "/_/dashboard/")}
                      >
                        {r.label}
                      </span>
                    </OverlayTrigger>
                  )}
                </p>
              );
            })}
        </nav>
        <nav>
          <div className="MyAccount__item-logout" onClick={_logout}>
            ログアウト
          </div>
        </nav>
      </div>
    </header>
  );
}

export default App;
