export interface Mail {
  id: string;
  language: string;
  subject: string;
  content: string;
  name: string;
}

export type SearchCondition = {
  [key: string]: any;
};

export const INSERT_ITEM = [
  { name: "送信先アカウントの所属企業コード", code: "main_company_code" },
  { name: "送信先アカウントの所属企業名", code: "main_company_name" },
  { name: "実施企業名", code: "company_name" },
  { name: "ログインID", code: "login_code" },
  { name: "個人名称", code: "account_name" },
  { name: "パスワード生成", code: "create_password" },
  { name: "ログインURL", code: "url" },
];

export const CONTENT_MAX_LENGTH = 5000;
