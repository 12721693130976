import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import StatisticsTop from "../statistics/StatisticsTop";
import SectionTree from "../client/SectionTree";
import MasterDataList from "../profile/MasterDataList";
import Sidebar from "../../component/Sidebar";
import { selectUserRootRoles, PolicyMap, selectUserState } from "../login/userSlice";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};

const TABS: TAB[] = [
  {
    label: "部署",
    eventKey: "sections",
    isAvailable: (policies, _) => {
      return true;
    },
  },
  {
    label: "コード",
    eventKey: "codes",
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("master_data_manager"))
        ?.some((api) => policies[api]?.includes("POST"));
    },
  },
  // {
  //   label: "統計",
  //   eventKey: "statistics",
  //   isAvailable: (policies, _) => {
  //     return true;
  //   },
  // },
];

const getCodePageType = (pathname: string) => {
  if (pathname.indexOf("/_/property/codes") === 0) return "list";
  else return "";
};

function PropertyTop() {
  const location = useLocation();
  const { mode } = useParams();
  const { policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable(policies, roles));
    return tabPanes;
  }, [roles, policies]);
  const [state, $state] = useState({
    codePageType: getCodePageType(location.pathname),
    activeTabKey: (() => {
      if (getCodePageType(location.pathname) !== "") return "codes";
      switch (mode) {
        case "sections":
        case "codes":
        case "statistics":
          return mode;
        default:
          return "sections";
      }
    })() as string,
  });

  useEffect(() => {
    $state({
      ...state,
      codePageType: getCodePageType(location.pathname),
    });
  }, [location]);

  const tabContent = useMemo(() => {
    if (state.activeTabKey === "sections") return <SectionTree />;
    else if (state.activeTabKey === "codes") return <MasterDataList />;
    else if (state.activeTabKey === "statistics") {
      return <StatisticsTop />;
    } else {
      return <SectionTree />;
    }
  }, [state]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"property"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">組織データ</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tab.Container
            onSelect={(key: string | null) => {
              let next = { ...state };
              if (key) {
                next = { ...next, activeTabKey: key };
                if (key !== "sections") {
                  window.history.replaceState({}, "", `/_/property/${key}`);
                } else window.history.replaceState({}, "", `/_/property/`);
              }
              $state(next);
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              {tabPanes.map((t) => (
                <Nav.Item key={t.eventKey}>
                  <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>{tabContent}</Tab.Content>
          </Tab.Container>
        </main>
      </div>
    </div>
  );
}

export default PropertyTop;
