import Sidebar from "../../component/Sidebar";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"import"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">データインポート作業の進め方</h1>
        <Form className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-4">
              <Col>
                <h2 className="Headline--section mb-4">作業の流れ</h2>
                <p className="mb-2">企業、従業員の各種情報を順次登録していきましょう。</p>
                <figure className="mb-2">
                  <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                </figure>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                  <Col>
                    <h2 className="Headline--section">操作方法（進捗リスト画面）</h2>
                    <nav className="Anchor-link my-2">
                      <a href="#original" className="Anchor-link__item">
                        元データの保存
                      </a>
                      <a href="#chukan" className="Anchor-link__item">
                        アップロードファイルの保存
                      </a>
                      <a href="#status" className="Anchor-link__item">
                        ステータスの変更
                      </a>
                    </nav>
                  </Col>
                </Row>
                <Row className="pt-4" id="original">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">元データの保存</h3>
                    <div className="mb-2">各種情報の元データファイルを保存する手順です。</div>
                    <figure className="mb-2">
                      <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                    </figure>
                  </Col>
                </Row>
                <Row className="pt-4" id="chukan">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">アップロードファイルの保存</h3>
                    <div className="mb-2">
                      元データを一時的に編集したファイル、作業に必要な情報をメモしたファイルなどを保存する手順です。
                    </div>
                    <figure className="mb-2">
                      <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                    </figure>
                  </Col>
                </Row>
                <Row className="pt-4" id="status">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">ステータスの変更</h3>
                    <div className="mb-2">
                      ステータスを適宜更新して進捗管理しましょう。以下は標準的なステータスの変更方法です。
                    </div>
                    <figure className="mb-2">
                      <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                    </figure>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Row>
                  <Col>
                    <h2 className="Headline--section">操作方法（インポート画面）</h2>
                    <nav className="Anchor-link my-2">
                      <a href="#karitouroku" className="Anchor-link__item">
                        インポート開始
                      </a>
                      <a href="#kenshou" className="Anchor-link__item">
                        検証と修正
                      </a>
                      <a href="#hontouroku" className="Anchor-link__item">
                        完了処理
                      </a>
                    </nav>
                  </Col>
                </Row>
                <Row className="pt-4" id="karitouroku">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">インポート開始</h3>
                    <div className="mb-2">
                      収集した元データファイルや編集したアップロードファイルをアップロードし、データをインポートしましょう。
                    </div>
                    <figure className="mb-2">
                      <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                    </figure>
                  </Col>
                </Row>
                <Row className="pt-4" id="kenshou">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">検証と修正</h3>
                    <div className="mb-2">
                      インポート中のデータに対して誤字脱字の修正、表記ゆれの統一、日付表記の統一などの処理が可能です。
                    </div>
                    <figure className="mb-2">
                      <img src="https://placehold.jp/800x400.png" className="--max-width-100" alt="" />
                    </figure>
                  </Col>
                </Row>
                <Row className="mt-4" id="hontouroku">
                  <Col>
                    <h3 className="Headline--section-sub mb-2">完了処理</h3>
                    <div className="mb-2">データの完了処理を行います。</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </div>
  );
}

export default App;
