import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase用トークンの設定
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY?.trim(),
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN?.trim(),
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID?.trim(),
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET?.trim(),
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID?.trim(),
  appId: process.env.REACT_APP_FIREBASE_API_ID?.trim(),
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
