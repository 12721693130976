import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getSectors,
  initSectorStatus,
  selectClientState,
  getPayrollSetting,
  downloadExternalOptions,
} from "./clientSlice";
import { SectorStatus } from "./clientValues";

function ExternalFieldList() {
  const dispatch = useAppDispatch();
  const { sectorUserStatus, payrollSetting } = useAppSelector(selectClientState);

  const [state, $state] = useState({
    sectors: [] as SectorStatus[],
    activeModal: "",
  });

  useEffect(() => {
    dispatch(initSectorStatus());
    dispatch(getSectors());
    dispatch(getPayrollSetting());
  }, [dispatch]);

  useEffect(() => {
    $state({ ...state, sectors: [...sectorUserStatus] });
  }, [sectorUserStatus]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Link to={"/_/masters/payroll_setting/edit/"}>
            <Button className="mx-1" variant="outline-primary">
              外部システム連携の設定
            </Button>
          </Link>
          {payrollSetting?.payroll_system ? (
            <span>※現在は「{payrollSetting.payroll_system}」とデータ連携しています</span>
          ) : (
            <span>現在は外部システムとデータ連携されていません</span>
          )}
          <Button
            className="mr-2 float-end"
            variant="outline-primary"
            disabled={!payrollSetting?.payroll_system}
            onClick={() => {
              dispatch(downloadExternalOptions());
            }}
          >
            選択肢一括ダウンロード
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <Row className="mb-3">
                <Col>
                  <Row>
                    <div className="Table">
                      <div className="Table__header">
                        <div className="Table__header-inner">
                          <div className="Table__header-col" style={{ width: "250px" }}>
                            {"テーブル"}
                          </div>
                          <div className="Table__header-col" style={{ width: "160px" }}>
                            {"アクション"}
                          </div>
                        </div>
                      </div>
                      {state.sectors
                        .filter((sector) => sector.active)
                        .map((s, i) => {
                          return (
                            <div className="Table__row" key={`sector-${s.key}`}>
                              <div className="Table__row-inner">
                                <div className="Table__col" style={{ width: "250px" }}>
                                  {s.label}
                                </div>
                                <div className="Table__col" style={{ width: "320px" }}>
                                  {payrollSetting?.payroll_system ? (
                                    <Link className="me-1" to={`/_/masters/external/edit/${s.key}`}>
                                      項目名のマッピング
                                    </Link>
                                  ) : (
                                    <span className="me-1">項目名のマッピング</span>
                                  )}
                                  {payrollSetting?.payroll_system ? (
                                    <Link to={`/_/masters/external/options/${s.key}`}>選択肢のマッピング</Link>
                                  ) : (
                                    <span>選択肢のマッピング</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ExternalFieldList;
