const data = {
  category: "training",
  table: "training",
  subFields: {
    training_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    training_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    training_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    completion_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      training_history: "教育研修履歴",
    },
  },
};
export default data;
