import { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getPositions, getSections, selectSectionState } from "../section/sectionSlice";
import { SectionTreeNode, getSectionTree } from "../section/sectionValues";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import dayjs from "dayjs";
import { Member } from "../profile/profileValues";

type SectionTreeNodeWithMembers = SectionTreeNode & { members: Member[]; children: SectionTreeNodeWithMembers[] };
const DEFAULT_MEMBER_TO_SHOW = 20;

function SectionTree() {
  const dispatch = useAppDispatch();
  const { sections, positions } = useAppSelector(selectSectionState);
  const { members } = useAppSelector(selectProfileState);
  const [openSectionIds, $openSectionIds] = useState([] as number[]);
  const [checkedPositionCodes, $checkedPositionCodes] = useState([] as string[]);
  const [selectedPointDate, $selectedPointDate] = useState(dayjs());
  const [showSectionCode, $showSectionCode] = useState(false);
  const [showDetail, $showDetail] = useState(true);
  const [showPortrait, $showPortrait] = useState(true);
  useEffect(() => {
    dispatch(getSections({ baseDate: selectedPointDate }));
    dispatch(getMembers());
    dispatch(getPositions({ baseDate: selectedPointDate }));
  }, [selectedPointDate]);
  const [expandedSections, $expandedSections] = useState([] as string[]);

  const sortedPositions = useMemo(() => {
    return positions.map((p) => ({ ...p })).sort((a, b) => a.order - b.order);
  }, [positions]);

  const tree = useMemo(() => {
    const tree = getSectionTree(sections);
    const _ = (node: SectionTreeNode): SectionTreeNodeWithMembers => {
      return {
        ...node,
        members: members.filter((m) => `${m.sectionCode}` === node.sectionCode),
        children: node.children.map((n) => _(n)) as SectionTreeNodeWithMembers[],
      };
    };
    return tree.map((node) => _(node));
  }, [sections, members, positions]);

  useEffect(() => {
    if (positions.length > 0) {
      $checkedPositionCodes(positions.map((p) => p.positionCode));
    }
  }, [positions]);

  const getNodeLayout = (node: SectionTreeNodeWithMembers, root: boolean = false) => {
    return (
      <div
        key={`node${node.id}`}
        className={classNames({
          Tree__node: true,
          "Tree__node--has-children": node.children.length > 0,
          "Tree__node--open": openSectionIds.includes(node.id),
          "--root": root,
        })}
      >
        <div
          className={classNames({
            Tree__item: true,
            "Tree__item--has-children": node.children.length > 0,
            "Tree__item--open": openSectionIds.includes(node.id),
          })}
        >
          {node.children.length > 0 && (
            <div
              onClick={() => {
                if (node.children.length === 0) return;
                openSectionIds.includes(node.id)
                  ? $openSectionIds(openSectionIds.filter((_) => _ !== node.id))
                  : $openSectionIds([...openSectionIds, node.id]);
              }}
              className="Tree__item-toggler"
            ></div>
          )}
          <div className="Tree__item-inner">
            <div className="Tree__box">
              <div className="Tree__box-title">
                <div>
                  <span className="--bold">{node.name}</span>
                  {showSectionCode && <span> / 部署コード : {showSectionCode ? node.sectionCode : ""}</span>}
                </div>
              </div>
              {showDetail && (
                <div className="Tree__box-content">
                  {node.members.length > 0 ? (
                    <div className="border-bottom mb-2">
                      所属 : <span className="--bold">{node.members.length}</span> 名
                    </div>
                  ) : (
                    <div className="text-secondary">所属なし</div>
                  )}
                  {node.members.length > 0 &&
                    (() => {
                      let count = 0;
                      const result = sortedPositions.map((p) => {
                        if (
                          node.members.some(
                            (m) =>
                              `${m.positionCode}` === p.positionCode && checkedPositionCodes.includes(p.positionCode)
                          )
                        ) {
                          return (
                            <div key={`${p.positionCode}`} className="mt-1 --flex">
                              <div className="Tree__position">{p.positionName}</div>
                              <div className="Tree__members">
                                <Row>
                                  {node.members
                                    .filter(
                                      (m) =>
                                        `${m.positionCode}` === p.positionCode &&
                                        checkedPositionCodes.includes(p.positionCode)
                                    )
                                    .map((m) => {
                                      if (
                                        ++count > DEFAULT_MEMBER_TO_SHOW &&
                                        !expandedSections.includes(node.sectionCode)
                                      )
                                        return;
                                      return (
                                        <Col md={6} key={m.id}>
                                          <div className="--flex --align-items-center">
                                            {showPortrait && (
                                              <figure className="Tree__portrait">
                                                {m.image && <img src={m.image} alt={m.name} />}
                                              </figure>
                                            )}
                                            <div className="Tree__member-name">{m.name}</div>
                                            {m.isConcurrent ? <div className="Badge--cancel mx-1">兼務</div> : null}
                                          </div>
                                        </Col>
                                      );
                                    })}
                                </Row>
                                {count > DEFAULT_MEMBER_TO_SHOW && !expandedSections.includes(node.sectionCode) && (
                                  <div className="mt-2">
                                    <Button
                                      variant="link"
                                      className="btn-sm"
                                      onClick={() => {
                                        $expandedSections([...expandedSections, node.sectionCode]);
                                      }}
                                    >
                                      省略された社員(
                                      {node.members.length - DEFAULT_MEMBER_TO_SHOW}
                                      名)を表示
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      });
                      return result;
                    })()}
                </div>
              )}
            </div>
          </div>
        </div>
        {openSectionIds.includes(node.id) && (
          <div className="Tree__children">
            {node.children.map((n) => getNodeLayout(n as SectionTreeNodeWithMembers))}
          </div>
        )}
      </div>
    );
  };
  return (
    <Container>
      <Row className="mt-4 --align-items-center">
        <Col md="2" className="--bold">
          基準日
        </Col>
        <Col md="10">
          <SnapshotTimeSelector
            selectedPointDate={selectedPointDate}
            useFarthestDaySelector={false}
            useFarthestPastDaySelector={false}
            onChange={({ selectedPointDate }: onChangeOption) => {
              if (!selectedPointDate) return;
              $selectedPointDate(selectedPointDate);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2" className="--bold">
          表示する項目
        </Col>
        <Col md="10">
          <Form.Check
            type="checkbox"
            label="部署コード"
            id="show_section_code"
            checked={showSectionCode}
            value="showSectionCode"
            onChange={(e) => {
              $showSectionCode(!showSectionCode);
            }}
          />
          <div className="--flex">
            <Form.Check
              type="checkbox"
              label="従業員"
              id="show_detail"
              checked={showDetail}
              value="showDetail"
              onChange={(e) => {
                $showDetail(!showDetail);
              }}
            />
            <span className="mx-1">(</span>
            <Form.Check
              type="checkbox"
              label="画像"
              disabled={!showDetail}
              id="show_portrait"
              checked={showPortrait}
              value="showPortrait"
              onChange={(e) => {
                $showPortrait(!showPortrait);
              }}
            />
            <span>)</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2" className="--bold">
          表示する役職
        </Col>
        <Col md="10">
          <Row>
            {sortedPositions.map((p) => {
              return (
                <Col md="4" key={p.positionCode}>
                  <Form.Check
                    onChange={() => {
                      if (checkedPositionCodes.includes(p.positionCode)) {
                        $checkedPositionCodes(checkedPositionCodes.filter((code) => code !== p.positionCode));
                      } else {
                        $checkedPositionCodes([...checkedPositionCodes, p.positionCode]);
                      }
                    }}
                    type="checkbox"
                    label={p.positionName}
                    disabled={!showDetail}
                    id={`show_position_${p.positionCode}`}
                    checked={checkedPositionCodes.includes(p.positionCode)}
                    value={p.positionCode}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Row className="mb-3">
            <Col>
              <div className="Tree">
                <div className="Tree__nodes">
                  {tree.map((node) => {
                    return getNodeLayout(node, true);
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SectionTree;
