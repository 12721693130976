import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { SearchItem } from "./profileValues";
import MultiSelectItem from "./MultiSelectItem";

function SearchItemSelector({
  searchItems,
  onChange,
}: {
  searchItems: SearchItem[];
  onChange: (column: string, selected: string[]) => any;
}) {
  const onSearchItemChange = (column: string, selected: string[]) => {
    onChange(column, selected);
  };

  return (
    <Row className="mb-2">
      {searchItems.map((search, i) => (
        <Col md="4" className="my-1" key={`${search.column}_${i}`}>
          <div className="--bold mb-2">{search.label}</div>
          <MultiSelectItem
            choices={search.choices}
            onChange={(selected) => {
              onSearchItemChange(search.column, selected);
            }}
          />
        </Col>
      ))}
    </Row>
  );
}

export default SearchItemSelector;
