const data = {
  category: "leave_of_absence",
  table: "leave_of_absence",
  subFields: {
    leave_of_absence_reason_type: {},
    payment_type: {},
    planned_end_on: {},
    return_to_work_on: {},
    is_add_duration: {},
    leave_of_absence_phone_number: {},
    leave_of_absence_email: {},
    remarks: {},
  },
};
export default data;
