import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch, ASSET_PATH } from "../../app/store";
import { postNewPassword, selectUserState } from "../../features/login/userSlice";
import { getPasswordComplexityText } from "../../features/permission/permissionValues";
import { Alert, Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginState = useAppSelector(selectUserState);
  const [state, $state] = useState({
    password: "",
    password2: "",
    processing: false,
    errorMessage: "",
    isMailSent: false,
    enteredFields: [] as string[],
  });
  const { ot } = useParams();

  const isEasyValidated = useMemo(() => {
    return state.password && /\s/.test(state.password) === false;
  }, [state]);
  const isRepeated = useMemo(() => {
    return state.password === state.password2;
  }, [state]);
  const formedErrorMessage = useMemo(() => {
    const m = loginState.newPasswordErrorMessage;
    if (m) {
      if (m.indexOf("length") !== -1) {
        return `${loginState.newPasswordErrorHint}文字以上のパスワードを設定してください。`;
      } else if (m.indexOf("complexity") !== -1) {
        return `パスワードは${getPasswordComplexityText(loginState.newPasswordErrorHint)}必要があります。`;
      }
      return m;
    } else {
      return "";
    }
  }, [loginState]);

  const submit = useCallback(async () => {
    if (state.processing) return;
    $state({ ...state, processing: true });
    await dispatch(
      postNewPassword({
        ot: ot || "",
        password: state.password,
        language: navigator.language.indexOf("ja") !== 0 ? "en" : "ja",
      })
    );

    $state({ ...state, isMailSent: true, processing: false });
  }, [dispatch, state, loginState.processing, navigate]);

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && isEasyValidated && isRepeated && !state.processing) submit();
    };
    window.addEventListener("keydown", keydown);
    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, [state, loginState, isEasyValidated, isRepeated, submit]); // [] に渡さないと、次回関数コンポーネントが実行されたとき古いほうが参照されてしまう。

  return (
    <div className="Centered">
      <div className="Centered__content">
        <figure className="Centered__logo">
          <img src={`${ASSET_PATH}/login-logo-common.png`} alt="SHRPA" />
        </figure>
        <Container fluid="md" className="Grouping px-4">
          <Row className="mb-4">
            <Col>
              <h2 className="Centered__header">新しいパスワードの設定</h2>
            </Col>
          </Row>
          <Row>
            <Form>
              <Form.Group className="mb-3" controlId="password">
                <Form.Control
                  value={state.password}
                  onChange={(e) => $state({ ...state, password: e.target.value })}
                  onBlur={() => $state({ ...state, enteredFields: [...state.enteredFields, "password"] })}
                  isInvalid={!isEasyValidated && state.enteredFields.includes("password")}
                  type="password"
                  placeholder="パスワード"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password2">
                <Form.Control
                  value={state.password2}
                  onChange={(e) => $state({ ...state, password2: e.target.value })}
                  onBlur={() => $state({ ...state, enteredFields: [...state.enteredFields, "password2"] })}
                  isInvalid={!isRepeated && state.enteredFields.includes("password2")}
                  type="password"
                  placeholder="パスワード（確認のため再入力）"
                />
                {!isRepeated && state.enteredFields.includes("password2") && (
                  <Form.Text muted className="--text-annotation">
                    パスワードが一致しません。
                  </Form.Text>
                )}
              </Form.Group>

              {formedErrorMessage && (
                <Alert variant="danger" className="my-3">
                  {formedErrorMessage}
                </Alert>
              )}
              {state.isMailSent && !formedErrorMessage && (
                <Alert variant="info" className="my-3">
                  パスワードの変更が完了しました。ログイン画面からログインしてください。
                </Alert>
              )}

              <div className="d-grid gap-2">
                {(!state.isMailSent || formedErrorMessage) && (
                  <Button
                    className="mt-4"
                    variant="primary"
                    disabled={!isEasyValidated || !isRepeated}
                    onClick={submit}
                  >
                    {state.processing && (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    )}
                    <span className="pl-2">パスワードを変更する</span>
                  </Button>
                )}
                <Link to="/" className="Anchor-link__item--label">
                  ログイン画面へ戻る
                </Link>
              </div>
            </Form>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ResetPassword;
