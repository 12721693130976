import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Icon from "../../component/Icon";
import Sidebar from "../../component/Sidebar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getStatistics, selectStatisticsState } from "./statisticsSlice";
import { setLoading } from "../../features/notification/notificationSlice";
import React, { useEffect } from "react";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function App() {
  const { initialized, time } = useAppSelector(selectStatisticsState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (initialized) return;
    dispatch(setLoading(true));
    dispatch(getStatistics({ companyId: "company_1" })).then(() => {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
    });
  }, []);
  return initialized ? (
    <Container className="mt-4">
      <Row>
        <Col>
          <h2 className="Headline--section mb-2">
            <Icon type="people-fill" width={25} height={25} />
            <span className="mx-2">人事労務</span>
          </h2>
          <Row className="g-1">
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">勤怠・時間外・欠勤・遅刻・早退</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      時間外勤務 : <span className="--bold --font-l --text-main-color">{time.over}</span> %
                    </div>
                    <div className="--bullet">
                      欠勤 : <span className="--bold --font-l --text-main-color">{time.absent}</span> %
                    </div>
                    <div className="--bullet">
                      遅刻 : <span className="--bold --font-l --text-main-color">{time.late}</span> %
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    勤怠テーブルの情報をもとに、出勤日数・欠勤日数・時間外時間・遅刻早退の合計・平均を期間を指定して、人別・部署別・全体で出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">休暇取得率</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">65</span> %
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    勤怠テーブルの情報をもとに、有休取得日数の合計・平均を期間を指定して、人別・部署別・全体で出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">平均年齢</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">34.1</span> 歳
                    </div>

                    <div>
                      <Line
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                        data={{
                          labels: ["2015", "2016", "2017", "2018", "2019", "2020", "2021"],
                          datasets: [
                            {
                              label: "平均年齢",
                              data: ["2015", "2016", "2017", "2018", "2019", "2020", "2021"].map(
                                () => Math.floor(Math.random() * 10) + 30
                              ),
                              borderColor: "rgb(255, 99, 132)",
                              backgroundColor: "rgba(255, 99, 132, 0.5)",
                            },
                          ],
                        }}
                      ></Line>
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    生年月日フィールドから、指定した年月日時点の、人別・部署別・雇用区分・全体での平均年齢を出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">人数変動表</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">+ 6</span> 名
                    </div>
                  </div>
                  <div className="mb-2 --font-s">部署・役職・雇用区分・性別の情報から人数を月別に集計する</div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">離職率</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">2.5</span> %
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    退職情報（退職日・退職理由）により、部署別・雇用区分別・退職理由別・全体の離職率を出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">平均勤続年数</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">17.7</span> 年
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    入社年月日フィールドから、指定した年月日時点の、人別・部署別・雇用区分・全体での平均勤続年数を出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">滞留年数</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">3.1</span> 年
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    部署基準日・役職基準日・等級基準日から、指定した年月日時点の、人別での滞留年数を出力する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">労務費</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      ￥<span className="--bold --font-l --text-main-color">131,267,124</span>
                    </div>
                  </div>
                  <div className="mb-2 --font-s">
                    給与明細テーブルの金額を項目別に人別・部署別・雇用区分・全体で合計・平均を集計する
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2 className="Headline--section mb-2">
            <Icon type="emoji-smile" width={25} height={25} />
            <span className="mx-2">ダイバーシティ</span>
          </h2>
          <Row className="g-1">
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">障がい者雇用率</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">1.2</span> %
                    </div>
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="my-1 my-md-0">
                <Card.Header className="--bold">管理職における女性の比率</Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div className="--bullet">
                      <span className="--bold --font-l --text-main-color">45</span> %
                    </div>
                  </div>
                  <Button variant="outline-primary">ダウンロード</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="mt-4">
      <Row>
        <Col>統計データのダウンロード中・・・</Col>
      </Row>
    </Container>
  );
}

export default App;
