const data = {
  category: "former_job",
  table: "former_job",
  subFields: {
    joined_on: {},
    resigned_on: {},
    last_paid_on: {},
    company_name: {},
    business_category: {},
    employment_status: {},
    postcode: {},
    company_address: {},
    is_abroad: {},
    job_title: {},
    resignation_reason_type: {},
    resignation_reason: {},
    remarks: {},
  },
};
export default data;
