import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import { searchCompanyMembers, selectProfileState } from "../../features/profile/profileSlice";
import { selectUserState } from "../../features/login/userSlice";
import { selectYearlyData, getBonus, singleDownloadReportByValidFrom } from "../report/reportSlice";
import dayjs from "dayjs";

function App() {
  const { accountId } = useParams();
  const { bonusMetaDataPerYear } = useAppSelector(selectYearlyData);
  const { filteredAccounts } = useAppSelector(selectProfileState);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const _accountId = accountId ? +accountId : user.id;
    dispatch(getBonus({ accountId: _accountId, before: dayjs() }));
    dispatch(
      searchCompanyMembers({ conditions: { accountId: _accountId }, current_company_code: user.current_company.code })
    );
  }, [user]);

  const userName = useMemo(() => {
    if (!accountId) return "";
    const account = filteredAccounts.find((a) => a.id === +accountId);
    return account?.name ?? "";
  }, [user, filteredAccounts]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"fileOutput"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">賞与明細{userName ? ` - ${userName}` : ""}</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                {bonusMetaDataPerYear.length > 0 ? (
                  <Accordion defaultActiveKey="0">
                    {bonusMetaDataPerYear.map((yearlyData, i) => {
                      return (
                        <Accordion.Item eventKey={`${i}`} key={`year${i}`}>
                          <Accordion.Header>{yearlyData.year}年</Accordion.Header>
                          <Accordion.Body>
                            {yearlyData.data.map((data, j) => {
                              return (
                                <div key={`year${i}_${j}`}>
                                  <a
                                    className="--inline-link --cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        singleDownloadReportByValidFrom({
                                          resource: data.downloadResource,
                                          accountId: data.accountId,
                                          conditions: {},
                                          validFrom: dayjs(data.validFrom),
                                        })
                                      );
                                    }}
                                  >
                                    {data.title}
                                  </a>
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                ) : (
                  <div>データがありません</div>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
