const data = {
  category: "former_job",
  table: "former_job",
  subFields: {
    former_job_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joined_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resigned_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    last_paid_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    business_category_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employment_status_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_abroad_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    job_title_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_reason_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resignation_reason_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      former_job_history: "前職履歴",
    },
  },
};

export default data;
