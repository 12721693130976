import { useAppSelector, useAppDispatch } from "../../app/store";
import "../../css/style.scss";
import { Toast as ToastType, hideToast, selectNotificationState } from "../../features/notification/notificationSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "react-bootstrap";

function App() {
  const { toasts } = useAppSelector(selectNotificationState);
  const dispatch = useAppDispatch();

  return (
    <aside className="Toast-view">
      {toasts.map((t: ToastType) => {
        return (
          <Toast
            show={t.active}
            delay={6000}
            bg="light"
            autohide
            animation
            key={t.id}
            className="Toast-view__item --pre-wrap"
            onClose={() => dispatch(hideToast({ id: t.id }))}
          >
            <Toast.Header>
              <strong className="me-auto">{t.strong}</strong>
            </Toast.Header>
            <Toast.Body>{t.content}</Toast.Body>
          </Toast>
        );
      })}
    </aside>
  );
}

export default App;
