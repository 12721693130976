const data = {
  category: "withholding_tax",
  table: "withholding_tax",
  subFields: {
    withholding_tax_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    category_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    payment_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    salary_income_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    income_deduction_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    withholding_tax_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_spouse_deductible1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_spouse_deductible2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_deductible_spouse_aged_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    exemption_for_spouse_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_specific1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_specific2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_aged1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_aged2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_aged3_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_other1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_other2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    under_16_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    handicapped_special1_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    handicapped_special2_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    handicapped_other_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    non_resident_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    social_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    social_insurance_in_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    life_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    earthquake_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    summary_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    new_life_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    old_life_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    medical_care_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    new_private_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    old_private_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan_applicable_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan_deductible_amount_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan1_residence_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan1_tax_deduction_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan1_year_end_balance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan2_residence_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan2_tax_deduction_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    housing_loan2_year_end_balance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    spouse_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    spouse_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    spouse_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    total_income_of_spouse_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    national_pension_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    old_long_term_damage_insurance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    basic_deduction_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    adjustment_deduction_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative1_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative1_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative1_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative2_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative2_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative2_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative3_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative3_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative3_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative4_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative4_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative4_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young1_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young1_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young1_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young2_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young2_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young2_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young3_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young3_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young3_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young4_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young4_furigana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relative_young4_div_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_minor_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_alien_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_retirement_death_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_disasters_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_b_column_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_disabled_person_special_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_disabled_person_other_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_widow_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_single_parent_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_work_study_student_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      withholding_tax_history: "源泉徴収情報履歴",
    },
  },
};

export default data;
