import { Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMemo } from "react";

const HOURS = ["--", ...[...Array(24)].map((_, h) => `00${h}`.slice(-2))];
const MINUTES = ["--", ...[...Array(60)].map((_, h) => `00${h}`.slice(-2))];

function TimeSelector({ onChange, selectedValue }: { onChange: (value: string) => any; selectedValue: string | null }) {
  const [hour, minute] = useMemo(() => {
    return selectedValue?.includes(":") ? selectedValue.split(":") : ["--", "--"];
  }, [selectedValue]);

  return (
    <Row className="align-items-center centered">
      <Col md={5}>
        <Form.Select
          value={hour}
          onChange={(v) => {
            const value = v.target.value;
            if (value === "--" && minute === "--") {
              onChange("");
            } else {
              onChange(`${value}:${minute}`);
            }
          }}
        >
          {HOURS.map((h) => {
            return (
              <option key={`hour_${h}`} value={h}>
                {h}
              </option>
            );
          })}
        </Form.Select>
      </Col>
      <Col md={1}>:</Col>
      <Col md={5}>
        <Form.Select
          value={minute}
          onChange={(v) => {
            const value = v.target.value;
            if (value === "--" && hour === "--") {
              onChange("");
            } else {
              onChange(`${hour}:${value}`);
            }
          }}
        >
          {MINUTES.map((m) => {
            return (
              <option key={`min_${m}`} value={m}>
                {m}
              </option>
            );
          })}
        </Form.Select>
      </Col>
    </Row>
  );
}

export default TimeSelector;
