import { configureStore, combineReducers, ThunkAction, Action, Reducer, EnhancedStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// https://github.com/rt2zz/redux-persist

// state の一部である slice ごとに、slice名・Reducer・初期 state をインポート
import idToken, {
  importIdToken,
  selectIsIdTokenExpired,
  resetIdTokenErrorMessage,
} from "../features/login/idTokenSlice";
import {
  default as userSlice,
  setInitialized,
  getUserByIdToken,
  getCompanyChoices,
  User,
} from "../features/login/userSlice";
import { default as fatalErrorSlice, handleFatalError } from "../features/error/fatalErrorSlice";
import notification from "../features/notification/notificationSlice";
import layout from "../features/layout/layoutSlice";
import client, {
  getAdhocCells,
  getSectorSettingStatus,
  putSectorSettingStatus,
  getSectors,
  initSectorSettingStatus,
  initSectorStatus,
  getAddingProfileFile,
} from "../features/client/clientSlice";
import permission, { getPermissions } from "../features/permission/permissionSlice";
import profile, { getSelfAccount, getMembers } from "../features/profile/profileSlice";
import section from "../features/section/sectionSlice";
import statistics from "../features/statistics/statisticsSlice";
import apply from "../features/apply/applySlice";
import masterData from "../features/masterData/masterDataSlice";
import operation from "../features/dashboard/operationSlice";
import todo from "../features/todo/todoSlice";
import { METADATA_COLUMN, RegularColumn, Sector } from "../features/client/clientValues";
import thread, { getAllThreadComments } from "../features/client/threadSlice";
import report from "../features/report/reportSlice";
import serializer from "../features/serializer/serializerSlice";
import myNumber from "../features/myNumber/myNumberSlice";
import theme from "../features/theme/themeSlice";
import mail from "../features/mail/mailSlice";
import mailSetting from "../features/mailSetting/mailSettingSlice";
import file from "../features/file/fileSlice";
import myAccount from "../features/myAccount/myAccountSlice";
import jwtDecode from "jwt-decode";

type SliceItem = {
  name: string;
  reducer: Reducer;
  onStoreInitialized?: (store: EnhancedStore) => any;
};

const slices = [
  idToken,
  userSlice,
  fatalErrorSlice,
  notification,
  layout,
  client,
  permission,
  profile,
  section,
  statistics,
  apply,
  masterData,
  thread,
  operation,
  report,
  todo,
  serializer,
  myNumber,
  theme,
  mail,
  mailSetting,
  file,
  myAccount,
] as SliceItem[];
const sliceMap = {} as {
  [key: string]: Reducer;
};
slices.forEach((slice) => {
  sliceMap[slice.name] = slice.reducer;
});
const persistedReducer = persistReducer(
  {
    key: "shrpa",
    storage,
    whitelist: ["token"],
  },
  combineReducers(sliceMap)
);

const rootReducer = (state: any, action: any) => {
  if (action.type === "root/logout") {
    state = undefined; // react-redux の仕様で initialState に戻る
    // render 中に再度 render されないよう時間をずらして永続化を解除
    setTimeout(() => {
      persistor.purge();
      // /assessment 向けの idToken, refresh_token などを削除
      // shrpa-front の exclude.js を参考に
      const c = localStorage.getItem("prev.main_company_code");
      if (c) localStorage.removeItem(`refresh_token.${c}`);
      localStorage.removeItem("jwt");
      localStorage.removeItem("adminMode");
      localStorage.removeItem("switch_company_automatically");
      localStorage.removeItem("temporary_company_code");
      localStorage.removeItem("prev_temporary_company_code");
      localStorage.removeItem("reloadRequired");
      localStorage.removeItem("switched_message");

      // 続けて再ログインできるよう、ルーティング変更ではなく再読み込み
      window.location.href = "/";
    }, 1);
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // 参考: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "client/getAdhocCells/fulfilled",
          "client/postLoadData/fulfilled",
          "client/convertLoadDataToCells/fulfilled",
          "client/setAdhocColumns",
        ],
      },
    }),
});

export const callOnStoreInitialized = () => {
  slices.forEach((slice) => {
    slice.onStoreInitialized && slice.onStoreInitialized(store);
  });
};

export const persistor = persistStore(store, null, () => {
  /*
    永続化されていた idToken が state に反映された直後に
    ユーザー情報を取得する
  */
  // アセスメントと共通のトークンがある場合はそのトークンを、なければプロファイルのトークンを使用
  let idToken = localStorage.getItem("jwt");
  if (!idToken) {
    const state = store.getState();
    idToken = state.token.idToken;
  }

  // 永続化したいスライスの中で永続化したくないフィールドをここでリセットする
  store.dispatch(resetIdTokenErrorMessage());

  if (!idToken || window.location.pathname === "/") {
    // idTokenがない場合、ログイン画面へのアクセスの場合 ユーザー情報を取得しない
    store.dispatch(setInitialized(true));
    return false;
  }

  const decoded: any = jwtDecode(idToken);
  const exp = decoded.exp;
  if (exp > Math.floor(Date.now() / 1000)) {
    store.dispatch(importIdToken({ idToken: idToken, refreshToken: localStorage.getItem("refresh_token") ?? "" }));
  } else {
    store.dispatch(setInitialized(true));
    return false;
  }

  Promise.all([
    store.dispatch(getUserByIdToken(idToken)),
    store.dispatch(getCompanyChoices()),
    store.dispatch(initSectorStatus()),
  ])
    .then((results) => {
      const userData = results[0].payload as {
        user: User;
        exp: number;
        roles: string[];
        policies: any;
      };
      store.dispatch(getSelfAccount({ account_id: userData.user.id }));
      if (userData.policies.master_data_manager?.includes("POST")) {
        Promise.all([
          new Promise((resolve, reject) => {
            store.dispatch(getSectors()).then((result: any) => {
              const sectors = result.payload as Sector[];
              if (sectors?.length > 0)
                store
                  .dispatch(initSectorSettingStatus({ sectors: sectors }))
                  .then(resolve)
                  .catch(reject);
            });
          }),
          store.dispatch(getAllThreadComments()),
        ]);
      }
    })
    .catch(() => {
      store.dispatch(
        handleFatalError({
          type: "error",
          message: "ユーザー情報を正しく取得できませんでした。",
        })
      );
    })
    .finally(() => {
      store.dispatch(setInitialized(true));
      callOnStoreInitialized();
    });

  return false;
});

export const logout = () => {
  return {
    type: "root/logout",
  };
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ReturnedType = {
  meta: any;
  payload: any;
  error?: any;
  type: string;
};

// Notification を受信したタイミングでアクションを起こしたりしたい場合に呼び出す関数。
export const externalEffects = {
  _getAdhocCells: async ({ sectorId }: { sectorId: string }) => {
    const { processedSectorId, sectorRegularColumns } = store.getState().client;
    if (sectorId !== processedSectorId) return;
    const regularColumns = sectorRegularColumns[sectorId]?.filter((c: RegularColumn) => !c.virtual_field);
    if (!regularColumns) return;
    const _regularColumns = [METADATA_COLUMN, ...regularColumns];
    await store.dispatch(
      getAdhocCells({
        sectorId,
        regularColumns: _regularColumns,
      })
    );
    await store.dispatch(getSectorSettingStatus());
  },
  _onAdhocCellsFinalized: async ({
    loadId,
    sectorId,
    sectorSettingStatusId,
    status,
  }: {
    loadId: string;
    sectorId: string;
    sectorSettingStatusId: string;
    status: string;
  }) => {
    const itemName: string = `${sectorId}.status`;
    const updateData: { [key: string]: string } = { [itemName]: status };
    await Promise.all([
      store.dispatch(
        putSectorSettingStatus({
          sectorSettingStatusId,
          updateData,
        })
      ),
    ]);
  },
  _getPermissions: async () => {
    await store.dispatch(getPermissions({}));
  },
  _getAddingProfileFile: async ({ sectorId }) => {
    await store.dispatch(getAddingProfileFile({ sectorId }));
  },
  _getMembers: async () => {
    await store.dispatch(getMembers());
  },
} as {
  [effectName: string]: (v: any) => any;
};

// 定数
export const ASSET_PATH = process.env.REACT_APP_LOCATION?.trim() === "development" ? process.env.PUBLIC_URL : "/_";
