const data = {
  category: "assignment2",
  table: "section2_summary",
  subFields: {
    section2_summary: {
      type: "tableHandler",
      tag: "tb_",
    },
    section_code_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    section_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      section2_summary: "部署の通算在籍年数",
    },
  },
};

export default data;
