import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import ModalDialog from "../../component/ModalDialog";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import Stepper from "../../component/Stepper";
import { getServiceContracts } from "../permission/permissionSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { getMembers, uploadAccounts } from "../profile/profileSlice";
import { Account, UploadingAccountData, UploadingAccountRawData, accountFieldTerms } from "../profile/profileValues";
import { Container, Row, Col, Button, Card, ListGroup, Alert, Form, Dropdown, DropdownButton } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { testResponse, trimSpaces } from "../../app/util";
import { serviceLabels } from "../permission/permissionValues";
import { serialize } from "../serializer/serializerSlice";
import { setLoading, selectNotificationState } from "../notification/notificationSlice";

function App() {
  const dispatch = useAppDispatch();
  const current_company = useAppSelector(selectCurrentCompany);
  const { loading } = useAppSelector(selectNotificationState);
  const [activeModal, $activeModal] = useState("");
  const [activeStepperIndex, $activeStepperIndex] = useState(0);
  const [fileData, $fileData] = useState<DecodedFileData | null>(null);
  const [analyzedData, $analyzedData] = useState({
    updated: [] as UploadingAccountData[],
    updatedExcluded: [] as UploadingAccountData[],
    registered: [] as UploadingAccountData[],
  });
  const [isAnalyzing, $isAnalyzing] = useState(false);
  const [analyzingErrorMessage, $analyzingErrorMessage] = useState("");

  const abort = () => {
    $activeStepperIndex(0);
    $analyzingErrorMessage("");
    $analyzedData({
      updated: [],
      updatedExcluded: [],
      registered: [],
    });
    $isAnalyzing(false);
    $fileData(null);
  };
  const onFileLoad = (data: DecodedFileData) => {
    $fileData(data);
  };
  const analyze = async () => {
    /*
      - 現在の企業で利用可能なサービス（ただしアップロードで設定できるのは type: "all" | "user"）を取得
      - ファイルの内容を json にする
      - アカウント一覧を取得
      - 更新・追加・削除の内容を組み立てる
    */
    if (loading || !fileData) return;
    try {
      dispatch(setLoading(true));
      $isAnalyzing(true);
      const res = testResponse(await dispatch(getServiceContracts({ companyId: current_company.id })));
      if (!res) throw new Error("サービス情報の取得に失敗しました。");
      const allowedServices = res.payload
        .filter((service: any) => service.serviceType === "all" || service.serviceType === "user")
        .map((service: any) => {
          return {
            service_id: service.serviceId,
            service_name: serviceLabels[service.serviceName].label,
          };
        });
      const [fileContents, accountList] = await Promise.all([
        (async () => {
          // xlsx の各列を json に変換する
          const res: any = await dispatch(
            serialize({
              data: fileData.dataURI,
              filetype: "xlsx",
              filename: fileData.name,
            })
          );
          return res.payload as UploadingAccountRawData[];
        })(),
        (async () => {
          const res: any = await dispatch(getMembers());
          return res.payload.accounts as Account[];
        })(),
      ]);
      if (fileContents.length === 0) {
        throw new Error("ファイルにレコードが1件もないため、アップロードできません。");
      }

      const allowedKeys = allowedServices.reduce(
        (
          prev: {
            [key: string]: string;
          },
          current: any
        ) => {
          return {
            ...prev,
            [current.service_name]: current.service_name,
          };
        },
        accountFieldTerms
      );

      const allowedKeysList = Object.keys(allowedKeys).map((key) => allowedKeys[key]);
      const fileContentsKeys = Object.keys(fileContents[0]);
      if (fileContentsKeys.sort().toString() !== allowedKeysList.sort().toString()) {
        // アップロードできる項目とファイルの項目が一致しない場合、中断
        throw new Error("ファイルの項目名の不備があります。");
      }
      const assorted = (() => {
        const makeReqData = (update: UploadingAccountRawData) => {
          let data = {
            id: -1,
            main_company_code: trimSpaces(update[allowedKeys["main_company_code"]].toString()),
            login_code: trimSpaces(update[allowedKeys["login_code"]].toString()),
            is_active: !!update[allowedKeys["is_active"]],
            name: trimSpaces(update[allowedKeys["name"]].toString()),
            password: trimSpaces(update[allowedKeys["password"]].toString()),
            mail_address: trimSpaces(update[allowedKeys["mail_address"]].toString()),
            language: trimSpaces(update[allowedKeys["language"]].toString()),
            is_remind: !!update[allowedKeys["is_remind"]],
            remarks: trimSpaces(update[allowedKeys["remarks"]].toString()),
            services: [] as string[],
            enabled_service_ids: [] as number[],
            disabled_service_ids: [] as number[],
            line: 0,
          };

          //サービスを分類する
          for (let update_key in update) {
            for (let service in allowedServices) {
              let service_name = allowedServices[service].service_name;
              let service_id = allowedServices[service].service_id;
              if (service_name == update_key) {
                // trueでもfalseでもない場合は更新対象から除外する
                if (update[update_key] == true) {
                  data["services"].push(service_name);
                  data["enabled_service_ids"].push(service_id);
                } else if (update[update_key] === false) {
                  data["disabled_service_ids"].push(service_id);
                }
              }
            }
          }
          return data;
        };
        let counts = {} as {
          [key: string]: number;
        };
        fileContents.forEach((update: UploadingAccountRawData) => {
          let data = makeReqData(update);
          let key = data["main_company_code"] + "-" + data["login_code"];
          counts[key] = counts[key] ? counts[key] + 1 : 1;
        });
        let isDuplicate = false;
        Object.keys(counts).forEach((key) => {
          if (counts[key] > 1) isDuplicate = true;
        });
        if (isDuplicate) {
          throw new Error("ログインIDが重複しています。ファイルをご確認ください。");
        }
        const putData = [] as UploadingAccountData[];
        const postData = [] as UploadingAccountData[];
        const exclusionData = [] as UploadingAccountData[];
        fileContents.forEach((update: UploadingAccountRawData, index: number) => {
          let data = makeReqData(update);
          data["line"] = index + 1;
          let account_exist = false;
          let test_account = false;
          let guest_account = false;
          // ゲストアカウントは更新対象外とする
          if (data["main_company_code"] !== current_company.code) {
            guest_account = true;
          } else {
            if (accountList.length > 0) {
              for (let i = 0; i < accountList.length; i++) {
                let r = accountList[i];
                if (data["main_company_code"] == r.mainCompanyCode && data["login_code"] == r.loginCode) {
                  // テスト用アカウントは更新対象外とする
                  if (r.isBilling === false) {
                    test_account = true;
                  } else {
                    data["id"] = r.id;
                    account_exist = true;
                  }
                  break;
                }
              }
            }
          }
          if (account_exist) {
            putData.push(data);
          } else if (test_account || guest_account) {
            exclusionData.push(data);
          } else {
            postData.push(data);
          }
        });
        return {
          putData,
          postData,
          exclusionData,
        };
      })();
      $analyzedData({
        updated: assorted.putData,
        updatedExcluded: assorted.exclusionData,
        registered: assorted.postData,
      });
      $isAnalyzing(false);
      $activeStepperIndex(1);
    } catch (e: any) {
      $analyzingErrorMessage(e.message);
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const commit = async () => {
    dispatch(setLoading(true));
    $activeModal("");
    try {
      const res = testResponse(
        await dispatch(
          uploadAccounts({
            post_data: analyzedData.registered,
            put_data: analyzedData.updated,
          })
        )
      );
      if (!res) throw new Error("アップロードに失敗しました。");
    } catch (e) {
      $analyzingErrorMessage("アップロードが正常に開始できませんでした。");
    } finally {
      $activeStepperIndex(2);
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    return () => {
      dispatch(setLoading(false));
    };
  }, []);
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Stepper
              steps={[{ label: "ファイル選択" }, { label: "ファイルアップロード確認" }, { label: "完了" }]}
              activeIndex={activeStepperIndex}
            ></Stepper>
          </Col>
        </Row>
        {activeStepperIndex === 0 && (
          <Row className="mt-4">
            <Col>
              {analyzingErrorMessage ? (
                <Alert variant="danger">{analyzingErrorMessage}</Alert>
              ) : fileData ? (
                <Alert variant="info">{fileData.name}</Alert>
              ) : (
                <Uploader
                  onFileLoad={onFileLoad}
                  accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                />
              )}
              <div className="my-2">
                <Button onClick={abort} disabled={!fileData} variant="outline-danger">
                  ファイルを変更
                </Button>
                <Button onClick={analyze} disabled={!fileData} variant="primary" className="mx-1">
                  ファイルを検証
                </Button>
              </div>
              <h3 className="Headline--section-sub mt-4">アップロードの注意点</h3>
              <Card className="mt-2">
                <Card.Body>
                  <div className="mb-2 --bullet">
                    アップロードファイルのフォーマットは、ダウンロードファイルと同じです。事前に一覧画面のオプションよりダウンロードファイルを取得してください。
                  </div>
                  <div className="mb-2 --bullet">
                    「ログイン時の企業コード」、「ログインID」、「名前」、「パスワード（新規登録時のみ）」、「ステータス」、「言語」、「リマインドメール」、「J列目以降の利用可能なサービス」は必須項目です。
                  </div>
                  <div className="mb-2 --bullet">
                    アカウントを新規登録または有効にする場合は「ステータス」にTRUEを、無効にする場合はFALSEを指定してください。
                  </div>
                  <div className="mb-2 --bullet">
                    「言語」に日本語を設定する場合はjaを、英語の場合はenを指定してください。
                  </div>
                  <div className="mb-2 --bullet">
                    利用するサービスにはTRUEを、利用しないサービスにはFALSEを指定してください。
                  </div>
                  <div className="mb-2 --bullet">
                    アカウント更新時にパスワードを変更しない場合は、パスワードには何も入力しないでください。
                  </div>
                  <div className="mb-2 --bullet">
                    リマインドメールを有効にする場合は「リマインドメール」にTRUEを、無効にする場合はFALSEを指定してください。
                  </div>
                  <div className="mb-2 --bullet">
                    テスト用アカウント(ログインID：test) 及び、ゲストアカウントは更新できません。
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {activeStepperIndex === 1 && (
          <Row className="mt-4">
            <Col>
              {!isAnalyzing ? (
                analyzedData.updated.length + analyzedData.updatedExcluded.length + analyzedData.registered.length >
                0 ? (
                  <ListGroup>
                    <ListGroup.Item>
                      <div className="--flex --align-items-center">
                        <span className="--icon-square --update"></span>
                        <span>
                          更新 : <span className="--bold">{analyzedData.updated.length}</span> 件
                        </span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="--flex --align-items-center">
                        <span className="--icon-square --update-sub"></span>
                        <span>
                          更新対象外※ : <span className="--bold">{analyzedData.updatedExcluded.length}</span> 件
                        </span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="--flex --align-items-center">
                        <span className="--icon-square --register"></span>
                        <span>
                          追加 : <span className="--bold">{analyzedData.registered.length}</span> 件
                        </span>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                ) : null
              ) : (
                <Alert variant="info">ファイルを解析中です。</Alert>
              )}
              {analyzedData.updatedExcluded.length > 0 && (
                <div className="mt-2 --font-s">
                  ※テスト用アカウント(ログインID: test) 及び、ゲストアカウントは更新できません。
                </div>
              )}
              <div className="mt-4">
                <Button onClick={abort} disabled={isAnalyzing} variant="outline-danger">
                  戻る
                </Button>
                <Button
                  onClick={() => {
                    $activeModal("before_commit");
                  }}
                  disabled={isAnalyzing}
                  variant="primary"
                  className="mx-1"
                >
                  アップロード
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {activeStepperIndex === 2 && (
          <Row className="mt-4">
            {!analyzingErrorMessage ? (
              <Col>
                <div>
                  アップロードのリクエストを受け付けました。この処理には時間がかかる場合があります。処理が完了しましたら、処理結果を通知します。
                </div>
              </Col>
            ) : (
              <Col>
                <Alert variant="warning">{analyzingErrorMessage}</Alert>
              </Col>
            )}
          </Row>
        )}
      </Container>
      <ModalDialog
        show={activeModal === "before_commit"}
        onCancel={() => {
          $activeModal("");
        }}
        onConfirm={commit}
        message={"アップロードを開始しますか？"}
      />
    </div>
  );
}

export default App;
