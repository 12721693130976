const data = {
  category: "residence_with_working",
  table: "residence_with_working",
  subFields: {
    nationality: {},
    status_of_residence: {},
    residence_end_on: {},
    residence_card_number: {},
    work_type: {},
    activity_permission_type: {},
    relation_file_id: {},
  },
};
export default data;
